export const categories = {
	add: "Add category",
	addProduct: "Add product in{{category}}",
	addSubcategory: "Add subcategory in{{category}}",
	categoryModal: {
		create: "New category",
		createSubcategory: "Create subcategory",
		edit: "Edit category",
		inputs: {
			image: "Image",
			name: {
				label: "Name",
				placeholder: "Enter the name",
			},
			description: {
				label: "Description",
				placeholder: "Describe the category",
			},
			priorityLevel: {
				label: "Priority level",
				errorMessage: "Enter a valid priority level (ex: 1, 2, 3...)",
			},
			prefix: "Level",
			chartAccount: {
				label: "Chart of Account",
				placeholder: "Select the chart of accounts",
			},
			errors: {
				requiredName: "Name is required",
				requiredPriority: "Priority level is required",
				invalidPriority: "Invalid priority level",
			},
		},
	},
	columns: {
		actions: "Actions",
		combo: "Combo",
		product: "Product",
		type: "Type",
	},
	deleteCategory: "Do you want to delete the category? This operation cannot be undone.",
	deleteProduct: "Do you want to delete this product? This operation cannot be undone.",
	label: "Categories",
	priorityLevel: "Priority level {{level}}",
	productModal: {
		create: "Create product",
		edit: "Edit product",
		inputs: {
			hasAlcohol: "Contains Alcohol",
			image: "Image",
			name: {
				emptyError: "Product name not filled in",
				label: "Name",
				noCategory: "Category name not filled in",
				noPriority: "Invalid priority level",
				noStringError: "Use only valid characters",
			},
			type: {
				error: "Select a product type",
				label: "Product type",
				placeholder: "Select a product type",
			},
		},
	},
	subcategory: "subcategory",
	subcategories: "Subcategories",
	description: "Description",
	chartAccount: "Chart of Accounts",
	search: {
		label: "Search",
		placeholder: "Category, subcategory or product",
	},
};
