export const fiscalBoard = {
	addPlace: "Add place",
	column: {
		date: "Date",
		errorType: "Error type",
		total: "Total Issuable",
		type: "Type",
		value: "Value",
	},
	fiscalProfile: "Issuance by fiscal profile",
	issuingErrors: "Issuing Errors",
	nfce: "NFC-e",
	nfceIssued: "NFC-e issued",
	nfse: "NFS-e",
	nfseIssued: "NFS-e issued",
	selectMonth: "Select a month",
	selectPlace: "Select a place",
};
