import { EditProductImage, NewPlaceProduct } from "#api/enterprise-generated";
import { ItemModel } from "./item";

/**  @deprecated models should not ne used anymore */
export class ProductModel extends ItemModel {
	public edit = (
		placeId: string,
		product: NewPlaceProduct,
		avatar: EditProductImage | null,

		sucessCallback?: () => void,
	) => this.itemStore.editProduct(placeId, this.id, product, avatar, sucessCallback);

	public delete(placeId: string) {
		this.itemStore.deleteProductFromPlace(placeId, this.id);
	}

	public changeQuantity(
		productId: string,
		diff: number,
		placeId: string,
		barId: string,
		skipReload: boolean = false,
	) {
		this.itemStore.changeProductQuantity(productId, diff, placeId, barId, skipReload);
	}

	public changePrice(placeId: string, productId: string, price: number) {
		this.itemStore.changeProductPrice(placeId, productId, price);
	}
}
