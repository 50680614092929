export const flow = {
	emptyText: "Não há nada a mostrar para o período selecionado",
	filename: "extrato-fluxo-de-caixa",
	table: {
		balance: "Saldo",
		competency: "Data de transação",
		date: "Data de liquidação",
		title: "Descrição",
		value: "Valor",
	},
	title: "Extrato-Fluxo de Caixa",
};
