import {
	CpfOrPhone,
	MultipleUserPromotionResult,
	MultipleUserPromotionResultError,
	NewPromotion,
	Promotion,
	PromotionUser,
} from "#api/enterprise-generated";
import { differenceBy, intersectionBy } from "lodash-es";
import {
	showErrorNotification,
	showMessageOnTop,
	showSuccessNotification,
} from "#helpers/notifications";
import { RootStore } from ".";
import allSettled from "promise.allsettled";
import enterprise from "#api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import { jobExecutor } from "#resources/helpers/job-executor";
import i18n from "#i18n/index";

interface CreatePromoArgs {
	promotion: NewPromotion;
}
interface EditPromotionArgs {
	promotionId: string;
	promotion: NewPromotion;
}
interface GetPromotionArgs {
	promotionId: string;
}
interface TooglePromoArgs {
	promotionId: string;
	setActive: boolean;
}
interface DeletePromoArgs {
	promotionId: string;
}
interface GetPromtionsArgs {
	placeId: string;
}
interface SearchUserArgs {
	placeId: string;
	search: string;
}
interface SearchUsersArgs {
	placeId: string;
	cpfs: string[];
}
interface GetPromotionPeopleArgs {
	promotionId: string;
}

interface GivePromotionsToManyUsersArgs {
	promotionId: string;
	cpfPhones: CpfOrPhone[];
}

interface UpdatePromotionPeopleArgs {
	promotionId: string;
	previousPeople: SearchUserRtn[];
	nextPeople: SearchUserRtn[];
}

export interface SearchUserRtn {
	id: string;
	cpf: string;
	name: string;
	phone: string | null;
}

type SearchUsersRtn = SearchUserRtn[];

const t = i18n.t;

export class PromotionStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	private updatePromotion = (promo: Promotion) => {
		if (this.promotions.value) {
			const promoIndex = this.promotions.value.findIndex(p => p.id === promo.id);
			if (promoIndex !== -1) {
				// haven't found the promotion returned
				this.promotions.value[promoIndex] = promo;
			}
		}
	};

	public createPromotion = new fetchModel<CreatePromoArgs, Promotion>({
		fnPromise: args => enterprise.createPromotion(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: promo => {
			showSuccessNotification(t("store:promotionStore.createPromotion"));
			this.updatePromotion(promo);
		},
	});

	public editPromotion = new fetchModel<EditPromotionArgs, Promotion>({
		fnPromise: args => enterprise.editPromotion(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: promo => {
			showSuccessNotification(t("store:promotionStore.editPromotion"));
			this.updatePromotion(promo);
		},
	});

	public deletePromotion = new fetchModel<DeletePromoArgs, void>({
		fnPromise: args => enterprise.deletePromotion({ id: args.promotionId }),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => {
			if (this.rootStore.placeStore.place !== null) {
				this.promotions.fetch({
					placeId: this.rootStore.placeStore.place.id!,
				});
			}
		},
	});

	public togglePromotion = new fetchModel<TooglePromoArgs, Promotion>({
		fnPromise: ({ promotionId, setActive }) =>
			enterprise.togglePromotion({ isActive: setActive, promotionId }),
		onError: err => showErrorNotification(err.message),
		onSuccess: promo => {
			this.updatePromotion(promo);
		},
	});

	public promotions = new fetchModel<GetPromtionsArgs, Promotion[]>({
		fnPromise: args => enterprise.getPromotions(args),
		onError: err => showErrorNotification(err.message),
	});

	public getPromotion = new fetchModel<GetPromotionArgs, Promotion>({
		fnPromise: args => enterprise.getPromotion(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: promo => {
			this.updatePromotion(promo);
		},
	});

	public promotionPeople = new fetchModel<GetPromotionPeopleArgs, PromotionUser[]>({
		fnPromise: args => enterprise.getPromotionUsers(args),
		onError: err => showErrorNotification(err.message),
	});

	public givePromotionToManyUsers = new fetchModel<
		GivePromotionsToManyUsersArgs,
		MultipleUserPromotionResult
	>({
		fnPromise: args => enterprise.givePromotionToManyUsers(args),
		onError: err => showErrorNotification(err.message),
	});

	public usersDiff = (originalUsers: SearchUserRtn[], editUsers: SearchUserRtn[]) => ({
		notEdited: intersectionBy(originalUsers, editUsers, u => u.id),
		toAdd: differenceBy(editUsers, originalUsers, u => u.id),
		toRemove: differenceBy(originalUsers, editUsers, u => u.id),
	});

	public updatePromotionPeople = new fetchModel<
		UpdatePromotionPeopleArgs,
		MultipleUserPromotionResultError[]
	>({
		fnPromise: args =>
			new Promise(async (resolve, reject) => {
				const promotionPeopleParser = makePromotionPeopleParser(
					this.rootStore.placeStore.isCurrentPlaceInternational,
				);
				try {
					const grouped = this.usersDiff(args.previousPeople, args.nextPeople);

					let addErrors: MultipleUserPromotionResultError[] = [];
					if (grouped.toAdd.length !== 0) {
						const rtn = await enterprise.givePromotionToManyUsers({
							promotionId: args.promotionId,
							cpfPhones: grouped.toAdd.map<CpfOrPhone>(promotionPeopleParser),
						});
						addErrors = rtn.error;
					}

					if (grouped.toRemove.length !== 0) {
						await enterprise.removePromotionFromManyUsers({
							promotionId: args.promotionId,
							userIds: grouped.toRemove.map(p => p.id),
						});
					}

					resolve(addErrors);
				} catch (err) {
					reject(err);
				}
			}),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(t("store:promotionStore.updatePromotionPeople")),
	});

	public usersInfo = new fetchModel<SearchUsersArgs, SearchUsersRtn>({
		fnPromise: args =>
			new Promise(async (mainResolve, MainReject) => {
				const promises = args.cpfs.map(
					cpf =>
						new Promise<SearchUserRtn>(async (loopResolve, loopReject) => {
							try {
								const userInfo1 = await enterprise.getUser({
									cpf,
									phone: cpf,
									placeId: args.placeId,
								});
								const userInfo2 = await enterprise.getClientDetails({
									clientId: userInfo1.id,
								});
								loopResolve({
									cpf: userInfo1.cpf,
									id: userInfo1.id,
									name: userInfo1.name,
									phone: userInfo2.phone,
								});
							} catch (err) {
								if (err instanceof Error) {
									loopReject(`${cpf}: ${err.message}`);
								}
							}
						}),
				);

				allSettled(promises)
					.then(results => {
						const successCases = results.filter(r => r.status === "fulfilled");
						const errorCases = results.filter(r => r.status === "rejected");
						//@ts-ignore erro de tipagem da lib
						errorCases.map(r => showErrorNotification(r.reason));
						mainResolve(
							//@ts-ignore erro de tipagem da lib
							successCases.map(r => r.value),
						);
					})
					.catch(MainReject);
			}),
		onError: err => showErrorNotification(err.message),
	});

	public usersInfoRewrite = new fetchModel<SearchUsersArgs, SearchUsersRtn>({
		fnPromise: args =>
			new Promise<SearchUserRtn[]>((resolve, reject) => {
				const results: SearchUserRtn[] = [];

				const hideMessage = showMessageOnTop({
					description: t("store:promotionStore.usersInfoRewriteLoading"),
					type: "loading",
				});
				jobExecutor(
					args.cpfs,
					cpf =>
						new Promise<SearchUserRtn>((resolveP2, rejectP2) => {
							enterprise
								.getUser({
									cpf,
									phone: cpf,
									placeId: args.placeId,
								})
								.then(userInfo1 => {
									enterprise
										.getClientDetails({
											clientId: userInfo1.id,
										})
										.then(userInfo2 => {
											resolveP2({
												cpf: userInfo1.cpf,
												id: userInfo1.id,
												name: userInfo1.name,
												phone: userInfo2.phone,
											});
										})
										.catch(err => rejectP2(`${cpf}: ${err.message}`));
								})
								.catch(err => rejectP2(`${cpf}: ${err.message}`));
						}),
					(_, PRet) => results.push(PRet),
					err => showErrorNotification(String(err)),
				)
					.then(() => resolve(results))
					.catch(reject)
					.finally(hideMessage);
			}),
		onError: err => showErrorNotification(err.message),
	});

	public userInfo = new fetchModel<SearchUserArgs, SearchUserRtn>({
		fnPromise: args =>
			new Promise(async (resolve, reject) => {
				try {
					const userInfo1 = await enterprise.getUser({
						cpf: args.search,
						phone: args.search,
						placeId: args.placeId,
					});
					const userInfo2 = await enterprise.getClientDetails({ clientId: userInfo1.id });
					resolve({
						cpf: userInfo1.cpf,
						id: userInfo1.id,
						name: userInfo1.name,
						phone: userInfo2.phone,
					});
				} catch (err) {
					reject(err);
				}
			}),
		onError: err => showErrorNotification(err.message),
	});
}

function makePromotionPeopleParser(isForeign: boolean) {
	if (isForeign) {
		return (user: SearchUserRtn): CpfOrPhone => ({
			type: "phone",
			value: user.phone || user.cpf,
		});
	}
	return (user: SearchUserRtn): CpfOrPhone => ({ type: "cpf", value: user.cpf });
}
