import { accountsPayableReceivable } from "./accounts-payable-receivable";
import { bills } from "./bills";
import { finExManagerialReport } from "./finex-managerial-report";
import { planOfAccounts } from "./plan-of-accounts";
import { customerAndSupplier } from "./customer-and-supplier";
import { stockTransferHistory } from "./stock-transfer-history";
import { CONCILIATION_EN_US } from "#pages/backoffice/conciliation/translations/conciliation-en-us";
import { eventConference } from "./event-conference";

export const backofficeEN = {
	eventConference,
	accountsPayableReceivable,
	stockTransferHistory,
	bills,
	cashFlow: {
		pageTitle: "Cash Flow",
		pageParagraph:
			"This tool makes it possible to determine the current available balance and project the future, so that there is always working capital available both to fund the company's operations and to invest in improvements.",
		tooltipMessage:
			"The category lines of level {{level}} display the sum of all the levels grouped within it.",
		fileName: "cash-flow",
		topActions: {
			lblAccountName: "Search by Account",
			plhAccountName: "Enter the account name",
			lblDate: "Filter by Expiration",
			btnPdf: "Export to PDF",
			btnExcel: "Export to Excel",
		},
		totalizers: {
			income: "Total revenues",
			costs: "Total costs",
			expenses: "Total expenses",
			balance: "Total of the Period",
		},
		rowTotal: "Period Balance",
		rowHead: "Initial Balance",
		rowTail: "Balance for the Day",
		all: "All",
		viewPerDay: "View per day",
		viewPerMonth: "View per month",
		columns: {
			income: "Revenues",
			costs: "Costs",
			expenses: "Expenses",
			level: "Level",
			name: "Name",
		},
		status: {
			paid: "Paid",
			received: "Received",
			opening: "Open",
		},
	},
	costCenter: {
		costCenter: "Cost centers",
		addCostCenter: "Add cost center",
		editCostCenter: "Edit cost center",
		namePlaceholder: "Cost center name",
		name: "Name",
		action: "Actions",
	},
	planOfAccounts,
	bankAccounts: {
		titlePage: "Bank Accounts",
		addAccount: "Register Account",
		columnsTable: {
			name: "Name",
			bank: "Bank",
			agency: "Agency",
			purpose: "Account Purpose",
			actions: "Actions",
			account: "Account",
			cpfOrCnpj: "CPF/CNPJ",
			ownerName: "Account Holder",
			currentBalance: "Current Balance",
			initialBalance: "Initial balance",
		},
		form: {
			newNameBankAccount: "Account Name",
			bank: "Banking Institution",
			agency: "Agency Code",
			purpose: "Account Purpose",
			account: "Current Account with Digit",
			cpfOrCnpj: "CPF/CNPJ",
			ownerName: "Account Holder",
			addAccount: "Add Bank Account",
			namePlaceholder: "Enter a name for the account",
			bankPlaceholder: "Search for institution",
			purposePlaceholder: "Search by account type",
			selectPurpose: "Select account purpose",
			followInstructions: "Follow the instructions to continue",
			accessBankAccount:
				"To continue, access your internet banking and check if it is possible to download your statement in OFX format.",
			verifyBankAccount:
				"If the bank does not export this file format, click Finish to proceed without validation.",
			successContent:
				"The information imported from OFX is in accordance with the information entered in the previous step",
			inconsistentContent:
				"Some information imported from OFX does not match the information entered in the previous step. When continuing, we will replace the information entered with the information imported.",
			emptyBankData:
				"Some information required for account validation is missing from the selected OFX file. Click continue to complete the registration without validation.",
			imported: "Imported",
			registered: "Registered",
			titlesContentVerifield: {
				success: "Verified Information",
				inconsistency: "Conflicting Information",
				emptyBankData: "Missing information",
			},
			ofxTitles: {
				upload: "Click or drag the OFX file",
				file: "OFX File",
			},
			incompatibleFile: "Incompatible file",
			purposeList: {
				inHouseFlow: "Cash (Petty Cash)",
				creditCard: "Credit Card",
				virtualWallet: "Virtual Wallet",
				applicationAccount: "Investment Account",
				checkingAccount: "Checking Account",
				savingsAccount: "Savings Account",
			},
			accountPlaceholder: "Enter the account",
			agencyPlaceholder: "Enter the code",
			cpfPlaceholder: "000.000.000-00",
			ownerNamePlaceholder: "Account holder's name",
			unlink: "Unlink Bank Account",
			linkBankAccount: "Link Bank Account",
			defaultAccount: "Set as Main Account:",
			defaultAccountTooltip:
				"The main account is used to automatically fill forms that require this information.",
			default: "Default",
			initialBalance: "Initial Balance in $",
			initialBalanceDate: "Initial Balance Date",
			balancePlaceholder: "0.00",
			balanceDatePlaceholder: "mm/dd/yyyy",
			saveChanges: "Save Changes",
			errors: {
				required: "Required Field",
				name: "Enter a valid name",
				account: "Enter a valid account number",
				agency: "Enter a valid agency number",
				cpf: "Invalid CPF",
				cnpj: "Invalid CNPJ",
			},
		},
		confirmModal: {
			editInfo: "Edit Information",
			cancelMessage:
				"You have not saved the changes made. Do you still want to continue?",
			cancelButton: "Exit without saving",
			confirmButton: "Save Changes",
			deleteAccount: "Delete Account",
			deleteMessage:
				"Deleting the account is a definitive action. Do you wish to proceed?",
			deleteTransferError:
				"This account cannot be deleted because it is linked to recorded payments or receipts.",
			deleteMainError:
				"This account cannot be deleted because it is registered as the main account.",
			updateMainAccount: "Change Main Account",
			updateMainAccountMessage:
				"There is already a main account registered in this location. If you proceed, this account will be replaced. Do you still wish to continue?",
			confirmUpdate: "Change Account",
		},
		emptyStateTitle: "You do not have any registered accounts yet",
		emptyStateSubtitle: "Click add new account to start",
		notification: {
			deleteAccount: "Account successfully deleted",
			createAccount: "Account successfully created",
			updateAccount: "Account successfully edited",
		},
	},
	billType: {
		actions: "Actions",
		addCategory: "Add account category",
		addType: "Add account type",
		categories: "Categories",
		categoryName: "Account category name",
		createCategory: "Create account category",
		createType: "Create account type",
		createTypeIn: "Create account type in {{categoryName}}",
		editCategory: "Edit account category",
		editType: "Edit account type",
		editTypeIn: "Edit account type in {{categoryName}}",
		name: "Name",
		typeName: "Account type name",
	},
	reports: {
		graphs: {
			balance: "Balance",
			category: "Category",
			costCenterCaps: "COST CENTER",
			date: "Date",
			dateCaps: "DATE",
			description: "Description",
			descriptionCaps: "DESCRIPTION",
			expense: "Expense",
			expenseCaps: "EXPENSE",
			expenses: "Expenses",
			finalBalance: "Final balance",
			finalBalanceExtract: "Ending balance",
			period: "Period",
			periodBalance: "Period balance",
			periodBalanceExtract: "Balance in the period",
			previousBalance: "Previous balance",
			previousBalanceExtract: "Previous balance",
			profit: "Profit in exercise",
			receipt: "Receipt",
			receiptCaps: "RECEIPT",
			receipts: "Receipt",
			result: "Income before tax",
			supplierCaps: "SUPPLIER",
			tax: "Tax",
			totalCaps: "TOTAL",
			totalExpenses: "Total expenses",
			totalPaid: "Total paid",
			totalReceipts: "Total receipts",
			totalReceived: "Total Received",
			type: "Type",
			typeCaps: "TYPE",
			until: "until",
			value: "Value",
			valueCaps: "VALUE",
		},
		noComponent: "Wait, it will be available soon",
		reportNames: {
			cashFlow: {
				demonstrative: "Demonstrative",
				expensses: "Expenses/Receipts",
				extract: "Extract",
				history: "Historic",
			},
			expense: {
				perAnything: "Expense Paid to...",
				perCategory: "Expense by Category",
				perCostCenter: "Expense by Cost Center",
				perDay: "Expense per Day",
				perDescripion: "Expense by Description",
				perHistory: "Historical Expense",
				perType: "Expense by Type",
			},
			receipts: {
				perAnything: "Receipts Received from...",
				perCategory: "Receipts by Category",
				perCostCenter: "Receipts by Cost Center",
				perDay: "Receipts per Day",
				perDescripion: "Receipts by Description",
				perHistory: "Historical Receipts",
				perType: "Receipts by Type",
			},
		},
	},
	finExManagerialReport,
	customerAndSupplier,
	viewLevel: "View level {{level}}",
	conciliation: CONCILIATION_EN_US,
};
