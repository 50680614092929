import classNames from "classnames";
import { FilterPill } from "../filter-pill";
import s from "./file-upload.scss";

import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import * as React from "react";

export interface IFileUploadProps
	extends Omit<React.HTMLAttributes<HTMLInputElement>, "onChange"> {
	accept?: string[];
	className?: string;
	onChange?: (files: File[]) => void;
	value?: File[];
	containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export function FileUpload({
	accept,
	className,
	onChange,
	value = [],
	containerProps,
	...props
}: IFileUploadProps) {
	const [files, setFiles] = React.useState<File[]>(value);
	const onDrop = React.useCallback(
		(acceptedFiles: File[], _fileRejections: FileRejection[], _event: DropEvent) => {
			setFiles(acceptedFiles);
			onChange?.(acceptedFiles);
		},
		[],
	);
	const { getInputProps, getRootProps, isDragAccept, isDragReject } = useDropzone({
		accept,
		onDrop,
	});

	function onRemoveFile(file: File) {
		setFiles(files.filter(f => f !== file));
	}

	return (
		<div
			className={classNames(s.container, className, {
				[s.accept]: isDragAccept,
				[s.reject]: isDragReject,
			})}
			{...containerProps}
			{...getRootProps()}
		>
			<input {...props} {...getInputProps()} />
			{/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
			{files.length === 0 ? (
				<span>Clique ou arraste aqui para anexar</span>
			) : (
				files.map((file, index) => (
					<FilterPill
						key={`file-${file.name}-${index}`}
						label={file.name}
						onRemove={() => onRemoveFile(file)}
					/>
				))
			)}
		</div>
	);
}
