import { passwordCardHeader } from "./password-card-header";
import { passwordCardDeleteModal } from "./password-card-delete-modal";

export const passwordCard = {
	blockCard: "Bloquear cartão",
	columns: {
		authorizedTransactioneration: "Operação/Transação autorizada",
		cardCreator: "Criador do cartão",
		cardNumber: "Número do cartão",
		cardOwner: "Dono do cartão",
		cardStatus: "Status do cartão",
		date: "Data/Hora",
		pdvId: "Id do PDV",
		permissions: "Ações que autoriza",
		timeCreation: "Data/Hora Criação",
		value: "Valor",
		employeeName: "Funcionário",
		sourceId: "Id da transação",
	},
	passwordCardDeleteModal,
	passwordCardHeader,
	statusEmployeeCard: {
		active: "Ativo",
		blocked: "Bloqueado",
		inactive: "Inativo",
	},
	stockHistory: "Histórico de ações",
	filename: "historico_de_acoes",
};
