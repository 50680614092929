export const vendorReport = {
	columns: {
		deliveryFee: "Taxa de entrega",
		tip: "Gorjeta",
		value: "Valor",
		vendor: "Vendor",
	},
	sheet: {
		columns: {
			deliveryFee: "Taxa de entrega",
			tip: "Gorjeta",
			value: "Valor",
			vendor: "Vendor",
		},
		filename: "vendor-delivery-report",
		title: "Vendas delivery por vendor",
	},
	title: "Relatório delivery de vendors",
};
