export const invoices = {
	title: "Notas Fiscais",
	filename: "notas-fiscais",
	table: {
		invoiceNumber: "Número",
		issueDate: "Data de emissão",
		period: "Período de referência",
		status: "Status",
		value: "Valor",
		generatedAs: "Gerado como",
		pdf: "PDF",
		noAttachments: "Sem anexos",
		attachments: "Anexos",
	},
	status: {
		scheduled: "Agendado",
		synchronized: "Sincronizado",
		authorized: "Autorizado",
		processingCancellation: "Processando cancelamento",
		canceled: "Cancelado",
		cancellationDenied: "Cancelamento negado",
		error: "Erro",
		zigPending: "Pendente",
	},
	emptyText: "Não há nada a mostrar para o período selecionado",
};
