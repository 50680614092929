export const clients = {
	averagePublicPerWeekDay: {
		averageTicket: "Average Ticket",
		label: "Average audience value per day of the week during the period",
		title: "Average audience by day of the week",
	},
	clientRecordPerDay: {
		label: "Larger amounts of audience during the period",
		none: "None",
		people: "{{num}} people",
		person: "{{num}} person",
		title: "Audience record",
	},
	headerChart: {
		averagePublic: "Average audience",
		filters: {
			label: "Filters",
			men: "Men",
			others: "Others",
			women: "Women",
		},
		label: "During the period",
		total: "Total customers",
	},
	topClients: {
		infoLabel: "Select a customer to see details.",
		label: "Value during the period",
		title: "TOP Clients",
	},
	topTables: {
		title: "TOP Tables",
		label: "Value during the period",
		infoLabel: "Select a table to see details.",
	},
};
