import allSettled from "promise.allsettled";
import { CoalaTokenManager } from "#resources/helpers/coalaTokenManager";
import { deliveryStoreApi } from "#resources/api/delivery-client";
import { fetchModel } from "#resources/helpers/fetch-model";
import { RootStore } from ".";
import shortid from "shortid";
import { uniq } from "lodash-es";
import enterprise from "#resources/api/enterprise-client";
import { Category as ZigCategory } from "#resources/api/enterprise-generated";

import {
	BaseCategoryV2,
	BaseProductWithSections,
	Category,
	CompletePaymentMethod,
	CompleteProduct,
	EditError,
	GeneralProductOrderReport,
	GeneralProductOrderReportV2,
	LiteEditProduct,
	Menu,
	MenuProduct,
	MenuType,
	MenuWorkingPeriod,
	OrderReport,
	Product,
	ProductOrderReport,
	Store,
	StoreAdditionalFields,
	StoreBaseData,
	Vendor,
	VendorReport,
} from "#resources/api/delivery-generated";
import {
	showErrorNotification,
	showMessageOnTop,
	showSuccessNotification,
	showWarningNotification,
} from "#resources/helpers/notifications";
import i18n from "#i18n/index";

const dayBegin = (d: Date): Date => {
	const S = d;
	S.setHours(0);
	S.setMinutes(0);
	return S;
};
const dayEnd = (d: Date): Date => {
	const U = d;
	U.setHours(23);
	U.setMinutes(59);
	return U;
};

const t = i18n.t;

export class DeliveryStore {
	public rootStore: RootStore;
	public tokenManager: CoalaTokenManager;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
		this.tokenManager = new CoalaTokenManager(false, this.placeIdHasChanged);

		//@ts-ignore
		window.SHOWMEALL = () => {
			console.log(
				"here it is:",
				uniq(this.addProductArray).length !== this.addProductArray.length,
			);
		};
	}

	private placeIdHasChanged = () => {
		this.clearAll();
	};

	public clearAll = () => {
		this.getMyStore.reset();
		this.activateManualMode.reset();
		this.getMenus.reset();
		this.getMenu.reset();
		this.addMenu.reset();
		this.updateMenu.reset();
		this.deleteMenu.reset();
		this.addProductToMenu.reset();
		this.removeProductFromMenu.reset();
		this.getProductsFromMenu.reset();
		this.getMenuWorkingPeriods.reset();
		this.setMenuWorkingPeriods.reset();
		this.deactivateManualMode.reset();
		this.setManualMenu.reset();
		this.addProduct.reset();
		this.getProduct.reset();
		this.getProducts.reset();
		this.getProductByExternalId.reset();
		this.removeProduct.reset();
		this.removeProductByExternalId.reset();
		this.editProduct.reset();
		this.editProductByExternalId.reset();
		this.activateProduct.reset();
		this.activateManyProducts.reset();
		this.deactivateProduct.reset();
		this.deactivateManyProducts.reset();
		this.activateProductByExternalId.reset();
		this.deactivateProductByExternalId.reset();
		this.getCompleteProduct.reset();
		this.getCompleteProductByExternalId.reset();
		this.editStore.reset();
		this.editMenuProductPrice.reset();
		this.setStorePaymentMethods.reset();
		this.getStorePaymentMethods.reset();
		this.getAllPossibleMethods.reset();
		this.getAddtionalFields.reset();
		this.setAddtionalFields.reset();
		this.getOrdersByVendorReport.reset();
		this.getAllOrdersReport.reset();
		this.getAllOrdersProductsReport.reset();
		this.getGeneralProductReport.reset();
		this.tokenManager.clearPlaceId();
		this.tokenManager.clearToken();
		this.addMultipleCategories.reset();
	};

	public getMyStore = new fetchModel<{}, Store>({
		fnPromise: () =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getMyStore({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public activateManualMode = new fetchModel<{}, void>({
		fnPromise: () =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.activateManualMode({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => {
			if (this.getMyStore.value) {
				this.getMyStore.value.isManualMenu = true;
			}
		},
	});

	public getMenus = new fetchModel<{}, Menu[]>({
		fnPromise: () =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getMenus({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getMenu = new fetchModel<{ menuId: string }, Menu>({
		fnPromise: ({ menuId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getMenu({
					auth,
					menuId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public addMenu = new fetchModel<{ name: string; menuType?: MenuType }, Menu>({
		fnPromise: ({ name, menuType }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.addMenu({
					auth,
					menuType,
					name,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public updateMenu = new fetchModel<
		{ name: string; menuId: string; menuType?: MenuType },
		void
	>({
		fnPromise: ({ name, menuId, menuType }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.updateMenu({
					auth,
					menuId,
					menuType,
					name,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public deleteMenu = new fetchModel<{ menuId: string }, void>({
		fnPromise: ({ menuId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.deleteMenu({
					auth,
					menuId,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("store:deliveryStore.deleteMenu")),
	});

	public addProductToMenu = new fetchModel<
		{
			productId: string;
			menuId: string;
			price: number;
		},
		void
	>({
		fnPromise: ({ productId, menuId, price }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.addProductToMenu({
					auth,
					menuId,
					price,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public removeProductFromMenu = new fetchModel<
		{
			productId: string;
			menuId: string;
		},
		void
	>({
		fnPromise: ({ productId, menuId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.removeProductFromMenu({
					auth,
					menuId,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getProductsFromMenu = new fetchModel<
		{
			menuId: string;
		},
		MenuProduct[]
	>({
		fnPromise: ({ menuId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getProductsFromMenu({
					auth,
					menuId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getMenuWorkingPeriods = new fetchModel<
		{
			menuId: string;
		},
		MenuWorkingPeriod[]
	>({
		fnPromise: ({ menuId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getMenuWorkingPeriods({
					auth,
					menuId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public setMenuWorkingPeriods = new fetchModel<
		{
			menuId: string;
			workingPeriods: MenuWorkingPeriod[];
		},
		void
	>({
		fnPromise: ({ menuId, workingPeriods }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.setMenuWorkingPeriods({
					auth,
					menuId,
					workingPeriods,
				}),
			),
	});

	public deactivateManualMode = new fetchModel<{}, void>({
		fnPromise: () =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.deactivateManualMode({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onFetching: () => {
			if (this.getMyStore.value) {
				this.getMyStore.value.isManualMenu = false;
			}
		},
	});

	public setManualMenu = new fetchModel<
		{
			menuId: string | null;
		},
		void
	>({
		fnPromise: ({ menuId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.setManualMenu({
					auth,
					menuId,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_ret, args) => {
			const menu = this.getMenu.value;
			if (menu) menu.isCurrentMenu = menu.id === args.menuId;
		},
	});

	public addProductArray: string[] = [];

	public addProduct = new fetchModel<
		{
			product: BaseProductWithSections;
			imageUrl: string | null;
			hideNotification?: boolean;
		},
		Product
	>({
		fnPromise: ({ product, imageUrl }) =>
			this.tokenManager.callWithAuth(auth => {
				this.addProductArray.push(product.sku);
				return deliveryStoreApi.addProduct({
					auth,
					imageUrl,
					product,
				});
			}),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_, { hideNotification }) => {
			if (!hideNotification) showSuccessNotification(t("store:deliveryStore.addProduct"));
		},
	});

	public getProduct = new fetchModel<
		{
			productId: string;
		},
		Product
	>({
		fnPromise: ({ productId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getProduct({
					auth,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getProducts = new fetchModel<{}, Product[]>({
		fnPromise: () =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getProducts({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getProductByExternalId = new fetchModel<
		{
			sku: string;
		},
		Product
	>({
		fnPromise: ({ sku }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getProductByExternalId({
					auth,
					sku,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public removeProduct = new fetchModel<
		{
			productId: string;
		},
		void
	>({
		fnPromise: ({ productId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.removeProduct({
					auth,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_, args) => {
			if (this.getProducts.value) {
				this.getProducts.value.filter(p => p.id !== args.productId);
			}
		},
	});

	public removeProductByExternalId = new fetchModel<
		{
			sku: string;
		},
		void
	>({
		fnPromise: ({ sku }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.removeProductByExternalId({
					auth,
					sku,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public editProduct = new fetchModel<
		{
			productId: string;
			product: BaseProductWithSections;
			imageUrl: string | null;
		},
		Product
	>({
		fnPromise: ({ productId, product, imageUrl }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.editProduct({
					auth,
					imageUrl,
					product,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public liteEditProducts = new fetchModel<
		{
			products: LiteEditProduct[];
		},
		EditError[]
	>({
		fnPromise: ({ products }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.liteEditProducts({
					auth,
					products,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public editProductByExternalId = new fetchModel<
		{
			sku: string;
			product: BaseProductWithSections;
		},
		Product
	>({
		fnPromise: ({ sku, product }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.editProductByExternalId({
					auth,
					product,
					sku,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public activateProduct = new fetchModel<
		{
			productId: string;
		},
		void
	>({
		fnPromise: ({ productId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.activateProduct({
					auth,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_, args) => {
			if (this.getProducts.value) {
				const product = this.getProducts.value.find(p => p.id === args.productId);
				if (product) product.isActive = true;
			}
			if (this.getProductsFromMenu.value) {
				const product = this.getProductsFromMenu.value.find(p => p.id === args.productId);
				if (product) product.isActive = true;
			}
		},
	});

	public activateManyProducts = new fetchModel<
		{
			productIds: string[];
		},
		void
	>({
		fnPromise: ({ productIds }) =>
			new Promise<void>((resolve, reject) => {
				const endLoading = showMessageOnTop({
					description: t("store:deliveryStore.activateManyProductsLoading"),
					time: 0,
					type: "loading",
				});

				const promises = productIds.map(productId =>
					this.tokenManager.callWithAuth(auth =>
						deliveryStoreApi.activateProduct({ auth, productId }),
					),
				);

				allSettled(promises)
					.then(result => {
						const countFailures = result.reduce(
							(pv, cv) => pv + (cv.status === "rejected" ? 1 : 0),
							0,
						);
						if (countFailures > 0)
							showWarningNotification(
								t("store:deliveryStore.activateManyProductsWarning", {
									countFailures,
								}),
							);
						resolve();
					})
					.catch(reject)
					.finally(endLoading);
			}),
	});

	public deactivateProduct = new fetchModel<
		{
			productId: string;
		},
		void
	>({
		fnPromise: ({ productId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.deactivateProduct({
					auth,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_, args) => {
			if (this.getProducts.value) {
				const product = this.getProducts.value.find(p => p.id === args.productId);
				if (product) product.isActive = false;
			}
			if (this.getProductsFromMenu.value) {
				const product = this.getProductsFromMenu.value.find(p => p.id === args.productId);
				if (product) product.isActive = false;
			}
		},
	});

	public deactivateManyProducts = new fetchModel<
		{
			productIds: string[];
		},
		void
	>({
		fnPromise: ({ productIds }) =>
			new Promise<void>((resolve, reject) => {
				const endLoading = showMessageOnTop({
					description: t("store:deliveryStore.deactivateManyProductsLoading"),
					time: 0,
					type: "loading",
				});

				const promises = productIds.map(productId =>
					this.tokenManager.callWithAuth(auth =>
						deliveryStoreApi.deactivateProduct({ auth, productId }),
					),
				);

				allSettled(promises)
					.then(result => {
						const countFailures = result.reduce(
							(pv, cv) => pv + (cv.status === "rejected" ? 1 : 0),
							0,
						);
						if (countFailures > 0)
							showWarningNotification(
								t("store:deliveryStore.deactivateManyProductsWarning", {
									countFailures,
								}),
							);
						resolve();
					})
					.catch(reject)
					.finally(endLoading);
			}),
	});

	public activateProductByExternalId = new fetchModel<
		{
			sku: string;
		},
		void
	>({
		fnPromise: ({ sku }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.activateProductByExternalId({
					auth,
					sku,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public deactivateProductByExternalId = new fetchModel<
		{
			sku: string;
		},
		void
	>({
		fnPromise: ({ sku }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.deactivateProductByExternalId({
					auth,
					sku,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getCompleteProduct = new fetchModel<
		{
			productId: string;
		},
		CompleteProduct
	>({
		fnPromise: ({ productId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getCompleteProduct({
					auth,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getCompleteProductByExternalId = new fetchModel<
		{
			sku: string;
		},
		CompleteProduct
	>({
		fnPromise: ({ sku }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getCompleteProductByExternalId({
					auth,
					sku,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public editStore = new fetchModel<
		{
			store: StoreBaseData;
			imageUrl: string | null | undefined;
		},
		Store
	>({
		fnPromise: ({ store, imageUrl }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.editStore({
					auth,
					imageUrl,
					store,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public editMenuProductPrice = new fetchModel<
		{
			productId: string;
			menuId: string;
			price: number;
		},
		void
	>({
		fnPromise: ({ menuId, price, productId }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.editMenuProductPrice({
					auth,
					menuId,
					price,
					productId,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public setStorePaymentMethods = new fetchModel<
		{
			methods: CompletePaymentMethod[];
		},
		void
	>({
		fnPromise: ({ methods }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.setStorePaymentMethods({
					auth,
					methods,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getStorePaymentMethods = new fetchModel<{}, CompletePaymentMethod[]>({
		fnPromise: ({}) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getStorePaymentMethods({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getAllPossibleMethods = new fetchModel<{}, CompletePaymentMethod[]>({
		fnPromise: ({}) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getAllPossibleMethods({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getAddtionalFields = new fetchModel<{}, StoreAdditionalFields[]>({
		fnPromise: ({}) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getAdditionalFields({
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public setAddtionalFields = new fetchModel<
		{
			additionalFields: StoreAdditionalFields[];
		},
		StoreAdditionalFields[]
	>({
		fnPromise: ({ additionalFields }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.setAdditionalFields({
					additionalFields,
					auth,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getOrdersByVendorReport = new fetchModel<
		{
			since: Date;
			until: Date;
		},
		VendorReport[],
		GlobalTypes.WithId<VendorReport>[]
	>({
		fnPromise: ({ since, until }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getOrdersByVendorReport({
					auth,
					since: dayBegin(since),
					until: dayEnd(until),
				}),
			),
		onError: err => showErrorNotification(err.message),
		transform: data => data.map(a => ({ id: shortid(), ...a })),
	});

	public getAllOrdersReport = new fetchModel<
		{
			since: Date;
			until: Date;
		},
		OrderReport[]
	>({
		fnPromise: ({ since, until }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getAllOrdersReport({
					auth,
					since: dayBegin(since),
					until: dayEnd(until),
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getAllOrdersProductsReport = new fetchModel<
		{
			since: Date;
			until: Date;
		},
		ProductOrderReport[]
	>({
		fnPromise: ({ since, until }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getAllOrdersProductsReport({
					auth,
					since: dayBegin(since),
					until: dayEnd(until),
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getGeneralProductReport = new fetchModel<
		{
			since: Date;
			until: Date;
		},
		GeneralProductOrderReport[]
	>({
		fnPromise: ({ since, until }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getGeneralProductReport({
					auth,
					since: dayBegin(since),
					until: dayEnd(until),
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public uploadImage = new fetchModel<
		{
			image: Buffer;
		},
		string
	>({
		fnPromise: ({ image }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.uploadImage({
					auth,
					image,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public addMultipleCategories = new fetchModel<
		{
			categories: BaseCategoryV2[];
		},
		Category[]
	>({
		fnPromise: ({ categories }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.addMultipleCategories({
					auth,
					categories,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});

	public getZigCategories = new fetchModel<{}, ZigCategory[]>({
		fnPromise: () => enterprise.getCategories({}),
		onError: err => showErrorNotification(err.message),
	});

	public addProductV2 = new fetchModel<
		{
			product: BaseProductWithSections;
			imageUrl: string | null;
			hideNotification?: boolean;
		},
		Product
	>({
		fnPromise: ({ product, imageUrl }) =>
			this.tokenManager.callWithAuth(auth => {
				this.addProductArray.push(product.sku);
				return deliveryStoreApi.addProductV2({
					auth,
					imageUrl,
					product,
				});
			}),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_, { hideNotification }) => {
			if (!hideNotification)
				showSuccessNotification(t("store:deliveryStore.addProductV2"));
		},
	});

	public getGeneralProductReportV2 = new fetchModel<
		{
			since: Date;
			until: Date;
			vendors: Vendor[] | null;
		},
		GeneralProductOrderReportV2[]
	>({
		fnPromise: ({ since, until, vendors }) =>
			this.tokenManager.callWithAuth(auth =>
				deliveryStoreApi.getGeneralProductReportV2({
					auth,
					since,
					until,
					vendors,
				}),
			),
		onError: err => showErrorNotification(err.message),
	});
}
