export const coalaAddMenuModal = {
	addMenu: "Add a new menu",
	create: "Create menu",
	day: "Day",
	editMenu: "Edit menu",
	end: "End",
	endTime: "End time",
	menuForm: {
		delivery: "Delivery",
		table: "Order on the table",
	},
	menuInformation: "Menu information",
	name: "Menu name",
	save: "Save changes",
	start: "Start",
	startTime: "Start time",
	type: "Type of menu",
	validateField: "Required field",
	workingPeriods: "Menu opening hours",
};
