export const orderReport = {
	channelLabel: {
		marketplace: "Marketplace",
		phone: "Telefone",
		whatsapp: "Whatsapp",
		whitelabel: "Whitelabel",
	},
	columns: {
		chanel: "Plataforma",
		customerName: "Cliente",
		customerPhone: "Telefone Cliente",
		date: "Data e hora",
		deliveryFee: "Taxa de entrega",
		discount: "Desconto",
		obs: "OBS",
		paymentMethods: "Métodos de pagamento",
		reference: "Nº do pedido",
		status: "Estado",
		subtotal: "Sub Total",
		tip: "Gorjeta",
		value: "Valor",
		vendor: "Vendor",
	},
	methodsLabel: {
		alelo: "Alelo",
		amex: "Amex",
		ben: "Ben",
		cash: "Dinheiro",
		creditCard: "Cartão de crédito",
		creditCardOnline: "Cartão de cŕedito online",
		debitCard: "Cartão de débito",
		diners: "Diners",
		elo: "Elo",
		hipercard: "Hiper-card",
		mastercard: "Master-card",
		sodexo: "Sodexo",
		ticket: "Ticket",
		visa: "Visa",
		voucherInPlace: "Voucher",
		vr: "VR",
	},
	sheet: {
		columns: {
			chanel: "Plataforma",
			customerName: "Cliente",
			customerPhone: "Telefone Cliente",
			date: "Data e hora",
			deliveryFee: "Taxa de entrega",
			discount: "Desconto",
			obs: "OBS",
			paymentMethods: "Métodos de pagamento",
			reference: "Nº do pedido",
			status: "Estado",
			subtotal: "Subtotal",
			tip: "Gorjeta",
			value: "Valor",
			vendor: "Vendor",
		},
		filename: "vendor-delivery-report",
		title: "Vendas Delivery",
	},
	statusLabel: {
		canceled: "Cancelado",
		concluded: "Concluído",
		confirmed: "Confirmado",
		dispatched: "Despachado",
		integrated: "Integrado",
		pendingPayment: "Pagamento pendente",
		pickupAreAssigned: "Cliente no local",
		placed: "Criado",
		ready: "Pronto",
		scheduled: "Agendado",
	},
	title: "Relatório de pedidos",
	vendorLabels: {
		coala: "Whitelabel",
		ifood: "Ifood",
		rappi: "Rappi",
		ubereats: "Uber Eats",
	},
};
