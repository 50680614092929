export const anticipationDrawer = {
	title: "Request Anticipation and Bill Payment",
	titleAnticipation: "Request Anticipation",
	titleBilletPayment: "Request Bill Payment",
	anticipationValue: "Anticipation value",
	accountData: "Account information",
	form: {
		accountType: "Transfer to",
		accountTypes: {
			default: "Default account",
			existing: "Registered account",
			new: "New account",
			tooltip: "The one signed in contract",
		},
		bankData: "Bank data",
		liquidValue: "Net value",
		liquidValuePlaceholder: "Enter the net amount here",
		name: "Account identifier",
		reason: "Reason for request",
		reasonPlaceholder: "Enter here the reason for the anticipation",
		selectAccountPlaceholder: "Enter or select account",
		shouldSaveForFutureRequests: "Save account for future transactions",
		summaryAnticipationFee: "Anticipation fee",
		summaryLiquidValue: "Liquid value requested",
		summaryReason: "Reason",
		summaryThirdPartyFee: "Third party anticipation fee",
		summaryTitle: "Review of values",
		summaryTotal: "Total amount to be anticipated",
		summaryValue: "Requested amount",
		totalValue: "Total to be anticipated",
		totalValueTooltip: "The net amount plus the advance fee.",
		withdrawAll: "Anticipate all available value",
	},
	formStatusError: "The request could not be completed.",
	formStatusSuccess: "Advance requested successfully!",
	summary: "Data review",
	warningAccountChoice:
		"A fee of {{value}} will be charged for anticipation on third-party accounts.",
	warningBanner:
		"Requests made after 10:30 am (Brasília time) will only be dealt with the following day.",
};
