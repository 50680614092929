export const financial = {
	details: "see details",
	event: "Events",
	filename: "daily-extract",
	modality: "Modality",
	noDataToShow: "There is nothing to show for the selected period",
	noResume: "There is no closing in the period",
	pay: "Payable",
	payvalue: "Payable/Receivable",
	receive: "Receivable",
	summaryTitle: "Summary for {{date}}",
	table: {
		balance: "Balance",
		datetime: "Date",
		in: "Entry",
		out: "Exit",
		total: "Total",
		withdrew: "Withdrew",
	},
	title: "Daily Extract",
};
