export * from "./masked";

import { ICoreProps, Input as MdsInput } from "@mds/react-components";

MdsInput.displayName = "InputMds";

// @ts-ignore
MdsInput.Number.displayName = "InputNumberMds";

export const Input = MdsInput;

export type IInputProps = ICoreProps;
