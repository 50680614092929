import * as React from "react";

export interface IUseDisclosureOptions {
	onOpenCallback?: () => void;
	onCloseCallback?: () => void;
	onToggleCallback?: (isOpen: boolean) => void;
	initialValue?: boolean;
}

export interface IUseDisclosureReturn {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	onToggle: () => void;
}

export function useDisclosure(options?: IUseDisclosureOptions): IUseDisclosureReturn {
	const [isOpen, setIsOpen] = React.useState(Boolean(options?.initialValue));

	function onOpen() {
		setIsOpen(true);
		options?.onOpenCallback?.();
	}

	function onClose() {
		setIsOpen(false);
		options?.onCloseCallback?.();
	}

	function onToggle() {
		const newIsOpen = !isOpen;
		setIsOpen(newIsOpen);
		options?.onToggleCallback?.(newIsOpen);
	}

	return { isOpen, onClose, onOpen, onToggle };
}
