export const supplies = {
	pageTitle: "Insumos",
	pageDescription:
		"Insumos são todos os materiais que são utilizados para a produção de determinado produto. ou na criação de um outro insumo. Nesta tela é possível cadastrar e visualizar todos os insumos cadastrados em um place.",
	searchPlaceholder: "Pesquisar insumos",
	table: {
		name: "Nome",
		productionRule: "Receitas e produção",
		hasProductionRule: "Tem ficha técnica?",
		actions: "Ações",
		clickToEdit: "Clique para editar",
		sku: "SKU",
		yieldPercentage: "Rendimento",
		links: "Produtos e insumos Vinc.",
		stocks: "Estoques",
	},
	supplyActionModal: {
		title: {
			supply: "Vínculos do insumo",
			stock: "Vínculos do estoque",
		},
		description: {
			supply: "Relação de cadastro com os quais esse item está vinculado",
			stock: "Relação de estoques onde o produto está presente",
		},
		seeProductButton: "Ver produto",
		seeStockButton: "Ver estoque",
	},
	deleteWarning: "Tem certeza que deseja apagar este item?",
	addSupplies: "Adicionar insumo",
	addSuppliesModal: {
		errorMessage: "Esse campo é obrigatório",
		name: "Nome",
		unitMeasure: "Unidade de Medida",
		units: "Unidades",
		yieldPercentage: "Rendimento",
	},
	cancel: "Cancelar",
	conclude: "Concluir",
	delete: "Deletar",
	edit: "Editar",
	editSupplies: "Editar insumo",
	erase: "Apagar",
	exportToExcel: "Exportar para excel",
	importModal: {
		buttonText: "Escolha uma planilha",
		conclude: "Concluir",
		sheetFileName: "Insumos",
		title: "Importar insumo",
	},
	importSupplies: "Importar insumos",
	no: "Não",
	noCaps: "NÃO",
	productRule: {
		addAnotherSupply: "Adicionar outro insumo",
		form: {
			loss: "Perda",
			product: "Produto",
			quantity: "Quantidade",
		},
		generatedQuantity: "Quantidade gerada",
		hasProductionRuleError:
			"Atenção, esta ficha técnica está com erro. Remova a ficha técnica e adicione novamente.",
		title: "Ficha técnica - {{productName}}",
		tooltipMessage:
			"Para alterar a quantidade gerada é necessário remover a ficha técnica e cria-lá novamente.",
		warnings: {
			emptyQuantity: "Adicione a quantidade do insumo",
			emptySupplies: "Selecione um insumo",
			repeatEntry: "Você adicionou insumos repetidos",
		},
	},
	remove: "Remover",
	suppliesErrorsTableColumns: {
		supply: "Insumo",
		error: "Erro",
	},
	step: {
		supply: "Insumo",
		ingredients: "Ingredientes",
	},
	addInputModal: {
		title: "Adicionar insumo",
		supply: "Insumo ou produto",
		name: "Nome",
		placeholderName: "Insira o nome do insumo",
		sku: "SKU",
		placeholderSKU: "Insira o SKU",
		image: "Foto",
		descriptionImage: "Imagem quadrada  ou 500x500 pixels",
		yieldPercentage: "Rendimento",
		basicStorageUnit: "Unidade de medida",
		placeholderBasicStorageUnit: "Insira a unidade de medida",
		aboutIngredients: "Sobre ingredientes",
		ingredientsInfo:
			"Preencha os campos de ingredientes apenas se o produto for uma receita que utiliza outros insumos. Por exemplo, molho de tomate precisa de ingredientes, mas tomate não.",
		selectInput: "Selecione um insumo ou produto",
		quantity: "Quantidade",
		loss: "Perda",
		addIngredient: "Adicionar ingrediente",
	},
	yes: "Sim",
	yesCaps: "SIM",
	link: "Vínculo",
	links: "Vínculos",
	deleteSelectedSupplies: "Excluir insumos selecionados",
	deleteSelectedItens: "Excluir itens selecionados",
	deleteSupply: "Excluir insumo",
	deleteItems: "Excluir itens",
	importSupply: "Importar insumo",
	import: "Importar",
	confirmDeleteMessage: {
		multiple:
			"Ao confirmar a ação você exclui permanentemente {{quantity}} insumos. Deseja continuar?",
		single:
			"Ao confirmar a ação você exclui permanentemente o insumo {{supply}}. Deseja continuar?",
	},
	viewRules: "Ver receitas",
	doesNotHave: "Não possui",
	copiedSuccessfully: "Copiado com sucesso",
	cannotDeleteSupply:
		"Não é possível deletar pois o insumo está vinculado a outros produtos",
};
