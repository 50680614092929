import * as React from "react";

import css from "../device-status.scss";

import Table from "antd/lib/table";
import rootStore from "#stores/index";
import { DefaultLayout, FullSpinner, T4 } from "@cubos/zig-components";
import { TFunction, useTranslation } from "react-i18next";
import { browserHistory } from "react-router";
import Arrow from "#assets/svg/arrow-back.svg";
import { DeviceHistoryTransactions } from "#resources/api/enterprise-generated";
import { TagLabel } from "#components/primitive";
import { formatNumber } from "#helpers/number";
import { formatCurrency } from "#helpers/currency";
interface IProps {
	routeParams?: GlobalTypes.RouteParams;
}
type StatusKeys =
	| "status.manualCanceled"
	| "status.transferred"
	| "status.open"
	| "status.refunded";

type PaymentTypes = "credit" | "debit";
const translateColumns = (translate: TFunction<"event", "pos">) => [
	{
		title: translate("deviceHistoryColumns.date"),
		dataIndex: "date",
		render: (text: number) => formatNumber(text).replace(",", " "),
	},
	{
		title: translate("deviceHistoryColumns.userName"),
		dataIndex: ["user", "name"],
	},
	{
		title: translate("deviceHistoryColumns.paymentMethod"),
		dataIndex: "paymentMethod",
		render: (text: string | null) => translate(text as PaymentTypes) || "N/A",
	},
	{
		title: translate("deviceHistoryColumns.amount"),
		dataIndex: "amount",
		render: formatCurrency,
	},
	{
		title: translate("deviceHistoryColumns.usedValue"),
		dataIndex: "usedValue",
		render: formatCurrency,
	},
	{
		title: translate("deviceHistoryColumns.status"),
		key: "statusError",
		render: (record: Record<string, any>) => {
			const { status, error } = record;
			const type = status || (!status && !error) ? "success" : "error";
			const errorKey = `status.${error}` as StatusKeys;
			const value =
				status || (!status && !error)
					? translate("status.approved")
					: translate(errorKey);
			return (
				<div style={{ display: "flex", justifyContent: "center" }}>
					<TagLabel type={type}>{value}</TagLabel>
				</div>
			);
		},
	},
];

const MAX_LENGTH = 7;
const START = 0;
const DeviceHistory = (props: React.PropsWithChildren<IProps>) => {
	const [loading, setLoading] = React.useState(true);
	const [deviceHistory, setDeviceHistory] = React.useState<DeviceHistoryTransactions[]>(
		[],
	);
	const [employeeName, setEmployeeName] = React.useState("");
	const [fingerprint, setFingerprint] = React.useState("");
	const { deviceStore } = React.useContext(rootStore);
	const { t: tGlobal } = useTranslation("globalKeys");
	const { t } = useTranslation("event", { keyPrefix: "pos" });
	const [columns] = React.useState(translateColumns(t));

	React.useEffect(() => {
		deviceStore.getDeviceHistory
			.fetch({
				eventId: props.routeParams!.eventId!,
				deviceId: props.routeParams!.deviceId!,
			})
			.then(deviceHistoryResponse => {
				const {
					fingerprint: fp,
					employeeName: name,
					transactions,
				} = deviceHistoryResponse;
				setDeviceHistory(transactions);
				setFingerprint(fp.toUpperCase().substring(START, MAX_LENGTH));
				setEmployeeName(name);
				setLoading(false);
			});
	}, [props.routeParams!.deviceId!]);

	if (loading) return <FullSpinner />;

	return (
		<DefaultLayout
			title={
				<div>
					<button className={css.buttonGoBack} onClick={() => browserHistory.goBack()}>
						<Arrow /> {tGlobal("goBack")}
					</button>

					<T4 fontWeight="bold">{fingerprint}</T4>
					<h2 className={css.pageInfo}>{employeeName}</h2>
				</div>
			}
		>
			<div className={css.tableContainer}>
				<Table
					className={css.table}
					columns={columns}
					dataSource={deviceHistory}
					scroll={{ x: 1150 }}
					sortDirections={["ascend", "descend"]}
				/>
			</div>
		</DefaultLayout>
	);
};

export default DeviceHistory;
