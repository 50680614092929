export const menu = {
	header: {
		create: "Create Menu",
		manualMode: "Manual Mode",
		search: "Search menus",
	},
	menuCard: {
		active: "active",
		at: "at",
		businessHour: "Operating Hours",
		delivery: "Delivery",
		inactive: "inactive",
		message: "24h operation",
		table: "Order on table",
		type: "Type",
	},
	menuList: "Menu for delivery",
	noMenu: {
		create: "Create Menu",
		message: "Looks like you don't have any menu created yet",
		title: "Menu for delivery",
	},
	warningNotification:
		"Menu created, but there was a problem registering the opening hours:",
};
