export const vendorReport = {
	columns: {
		deliveryFee: "Delivery Fee",
		tip: "Tip",
		value: "Value",
		vendor: "Vendor",
	},
	sheet: {
		columns: {
			deliveryFee: "Delivery Fee",
			tip: "Tip",
			value: "Value",
			vendor: "Vendor",
		},
		filename: "vendor-delivery-report",
		title: "Sales delivery by vendor",
	},
	title: "Vendor delivery report",
};
