export const newEvent = {
	form: {
		addButton: "Adicionar",
		backgroundEventPicture: "Foto de capa do evento:",
		cancel: "Cancelar",
		editButton: "Editar",
		eventPicture: "Foto do evento:",
		labelBegin: "Início:",
		labelDescription: "Descrição:",
		labelName: "Nome:",
		peopleCapacity: "Capacidade do evento",
		peopleCapacityTooltip: "Quantidade de pessoas que o evento comporta.",
	},
	modalTitle: "Adicionar evento",
	title: "Novo evento",
};
