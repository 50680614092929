import * as React from "react";
import { Field, Formik } from "formik";
import { LockOutlined, UserOutlined, WalletOutlined } from "@ant-design/icons";
import Form from "#components/form-components-shared/form";
import s from "./login-form.scss";
import { Organization } from "#api/enterprise-generated";
import { withTranslation, WithTranslation } from "react-i18next";
import { Button, Input } from "#components/primitive";

interface IProps extends WithTranslation<"components"> {
	disabled?: boolean;
	isLoading?: boolean;
	organizations: Organization[];
	error?: Error;
	onSubmit: (fields: ILoginFields) => void;
}

export interface ILoginFields {
	username: string;
	password: string;
	organization: string;
}

class LoginForm extends React.Component<IProps> {
	public render() {
		const { t } = this.props;
		return (
			<Formik<{}, ILoginFields>
				initialValues={{ organization: "", password: "", username: "" }}
				onSubmit={this.props.onSubmit}
				render={formikBag => (
					<Form onSubmit={formikBag.handleSubmit} className={s.form}>
						<div className={s.formElement}>
							<Field
								render={() => (
									<Input
										size="large"
										disabled={this.props.disabled || false}
										placeholder={t("forms.loginForm.org")}
										leadingIcon={<WalletOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
										name="organization"
										onChange={formikBag.handleChange}
										required
									/>
								)}
								name="organization"
								props={{
									autoComplete: "on",
									autoFocus: true,
									disabled: this.props.disabled,
									placeholder: t("forms.loginForm.org"),
								}}
							/>
						</div>

						<div className={s.formElement}>
							<Field
								name="username"
								render={() => (
									<Input
										size="large"
										disabled={this.props.disabled || false}
										placeholder={t("forms.loginForm.login")}
										leadingIcon={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
										name="username"
										onChange={formikBag.handleChange}
										required
									/>
								)}
								props={{
									autoComplete: "on",
									disabled: this.props.disabled,
									placeholder: t("forms.loginForm.login"),
									type: "text",
								}}
							/>
						</div>

						<div className={s.formElement}>
							<Field
								name="password"
								render={() => (
									<Input
										size="large"
										placeholder={t("forms.loginForm.password")}
										leadingIcon={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
										name="password"
										onChange={formikBag.handleChange}
										disabled={this.props.disabled || false}
										type="password"
										required
									/>
								)}
								props={{
									autoComplete: "on",
									disabled: this.props.disabled,
									placeholder: t("forms.loginForm.password"),
									type: "password",
								}}
							/>
						</div>

						<Button
							loading={this.props.isLoading}
							disabled={
								this.props.disabled ||
								!formikBag.values.organization ||
								!formikBag.values.username ||
								!formikBag.values.password
							}
							variant="primary"
							className={s.btnLogin}
							htmlType="submit"
						>
							{t("forms.loginForm.submit")}
						</Button>
					</Form>
				)}
			/>
		);
	}
}

export default withTranslation("components")(LoginForm);
