export const productReport = {
	columns: {
		baseValue: "Preço base",
		count: "Quantidade",
		integration: "Integração",
		name: "Produto",
		sku: "SKU",
		totalValue: "Preço total",
	},
	sheet: {
		allOrdersProductsReport: {
			columns: {
				baseUnitPrice: "Preço base",
				count: "Quantidade",
				deliveryOrderId: "Identificador da ordem",
				name: "Produto",
				obs: "Observação",
				productId: "Identificador do produto",
				sku: "SKU",
				totalUnitPrice: "Preço total",
			},
			title: "Relatório de pedidos de produtos",
		},
		filename: "relatório-delivery",
		generalProductReport: {
			columns: {
				baseValue: "Preço base",
				count: "Quantidade",
				integration: "Integração",
				name: "Produto",
				sku: "SKU",
				totalValue: "Preço total",
			},
			title: "Relatório de produtos delivery",
		},
	},
	title: "Relatório delivery por produto",
	vendors: {
		coala: "Whitelabel",
		ifood: "Ifood",
		rappi: "Rappi",
		ubereats: "Uber eats",
	},
};
