export const storages = {
	addNewStorage: "Adicionar novo estoque",
	addProductModal: {
		form: {
			addedProducts: "Produtos Adicionados",
			submit: "Finalizar importação",
			successNotification: "Dados importados",
		},
		manual: {
			addOtherProduct: "Adicionar outro produto",
			costValue: "Preço de custo",
			error: {
				noneValidProduct: "Nenhum produto válido selecionado",
				zeroCount: "Não é possível adicionar produto com quantidade 0",
			},
			label: "Manualmente",
			product: "Produto",
			quantity: "Quantidade",
		},
		noteInfo: {
			accessKey: "Chave de acesso",
			addBill: "Adicionar conta a pagar",
			addSupplier: "Adicionar emitente aos seus favoritos",
			bill: "Conta",
			cpnj: "CNPJ",
			error: "Algum problema com a nota ocorreu e não foi possível criar o Fornecedor",
			number: "Número da nota",
			operationType: "Natureza da Operação",
			provider: "Fornecedor",
			removeBill: "Remover conta a pagar",
			series: "Série",
			supplierName: "Emitente",
			title: "Dados da Nota Fiscal",
			totalValue: "Valor total",
			unavailable: "Indisponível",
		},
		product: {
			code: "SKU",
			commercialQuantity: "Qtd",
			commercialUnit: "Un",
			cost: "Preço de custo por",
			description: "Descrição do produto",
			equals: "equivale a",
			label: "Produto",
			skip: "Pular",
			unitValue: "Preço unitário",
		},
		title: "Adicionar Produtos",
		xml: {
			label: "Importar XML",
			upload: "Clique ou arraste arquivos para fazer upload",
		},
	},
	confirmMessage: "Tem certeza que deseja apagar esse estoque?",
	createStorage: "Criar estoque",
	deleteAllProducts: "Remover todos os produtos",
	deleteProduct: {
		title: "Tem certeza que deseja apagar esse produto?",
		tooltip: "Apagar produto",
	},
	editStorage: "Editar estoque",
	error: "Erro ao atualizar estoques",
	label: "Estoques",
	modalImportInvoice: {
		errors: {
			notKeyAccess: "Não foi possível trazer a chave de acesso XML",
		},
		labelInputs: {
			storage: "Estoque",
			xmlKey: "Chave XML",
		},
		placeholders: {
			selectStorage: "Selecionar estoque",
		},
		typeOptionsImport: {
			xmlFile: "Anexar arquivo XML",
			xmlKeyAccess: "Inserir chave de acesso do XML",
		},
		validationMessage: {
			emptyFileXml: "Arquivo XML não selecionado",
			emptySelectTypeImport: "Selecione uma opção de importação",
			emptyStorage: "Selecione um estoque",
			emptyXmlKey: "Campo de Chave de Acesso vazio",
		},
	},
	modalTransfer: "Transferir produtos de {{storage}}",
	newStorage: "Novo estoque",
	removeStorage: "Excluir estoque",
	search: "Busque um estoque",
	storageCard: {
		addProduct: "Adicionar produto",
		edit: "Editar estoque",
		error: "Não é possível {{action}} produto com quantidade 0",
		modal: {
			add: "adicionar e seu preço de custo unitário",
			addProducts: "Adicionar produtos",
			amount: "Digite a quantidade que você deseja",
			costValue: "Preço de custo",
			count: "Quantidade",
			note: "Observação",
			removeProducts: "Remover produtos",
		},
		modalTransfer: {
			error: {
				selectCount: "Selecione uma quantidade",
				selectStorage: "Selecione um estoque",
			},
			okText: "Transferir",
			quantity: "Quantidade",
			storage: "Estoque",
			title: "Transferir {{name}}",
		},
		productSearch: "Busque por um produto",
		transfer: "Transferir produtos",
	},
	storageList: {
		central: "Geral",
		default: "Local",
		emptyArea: "Sem estoques para exibir",
		emptyMessage: "Sem itens para mostrar",
	},
	storageName: "Nome do estoque",
	tableProducts: {
		add: "Adicionar produto",
		columns: {
			actions: "Ações",
			category: "Categoria",
			count: "Quantidade",
			name: "Produto",
		},
		cost: "Preço de custo",
		countError: "Não é possível adicionar produto com quantidade 0",
		product: "Produto",
		productError: "Produto não encontrado",
		quantity: "Quantidade",
		total: "no total",
	},
	transferProductModal: {
		destinyStorage: {
			label: "Estoque de destino",
			placeholder: "Selecione um estoque",
		},
		error: {
			addProduct: "Produto não pode ser adicionado sem quantidade",
			selectProduct: "Quantidade em estoque negativa. Produto não pôde ser selecionado",
		},
		printTransfer: {
			associate: "Clique aqui para associá-los",
			download: "Baixar Excel de transferências",
			infoText:
				"Transferência realizada com sucesso, veja abaixo o resumo dos produtos transferidos",
			none: "Nenhum",
			notFound: "Não encontrado",
			notTrasnfer: "Os seguintes produtos não puderam ser transferidos",
			print: "Imprimir Doc. de transferências",
			quantity: "Quantidade",
			responsible: "Responsável pelo estoque destino",
			sheet: {
				columns: {
					count: "Quantidade",
					name: "Produto",
					totalCost: "Custo Total",
					unit: "Unidade",
					unitaryCost: "Custo Unitário",
				},
				filename: "transferencia-estoque",
				title: "Transferência do estoque {{selectedStorage}} para {{toStorage}}",
			},
			successNotification: "Produtos associados e transferidos",
			text:
				"Esse documento comprova a transferência de produtos do estoque {{fromStorage}} para o estoque {{toStorage}}. No dia {{date}} feita por {{employee}}. Os produtos transferidos estão na seguinte lista:",
			title: "Comprovante de transferência",
			unassociated: "Eles não estão associados ao local de destino",
			withoutName: "Sem nome",
		},
		product: {
			label: "Produto",
			placeholder: "Selecione um produto",
		},
		quantity: "Quantidade",
		table: {
			empty: "Sem produtos selecionados",
			product: "Produto",
			quantity: "Quantidade",
			totalCost: "Custo Total",
			unityCost: "Custo Unitário",
		},
		transferFor: {
			central: "Estoque Geral",
			label: "Transferir para",
			local: "Estoque Local",
			placeSelect: {
				label: "Local de destino",
				placeholder: "Selecione um local",
			},
		},
	},
	transferResume: "Relatório de transferência",
	zeroBackoffice: "Zerar todos os produtos em estoque",
};
