export const nfReport = {
	columns: {
		date: "Data de importação",
		emission: "Data de emissão",
		name: "Número da nota",
		value: "Valor",
	},
	empty: "Sem produtos",
	label: "Notas fiscais de compra",
	notAssociatedProduct: "(Produto não associado)",
	productColumns: {
		name: "Produtos",
		quantity: "Quantidade",
		total: "Valor total",
		unit: "Unidade",
		unitValue: "Valor unitário",
	},
};
