export const purchaseInvoice = {
	detailingProduct: {
		unassociatedProduct: "Produto não associado",
		columns: {
			nf: "NF",
			supplierName: "Fornecedor",
			emission: "Data Emissão",
			unitQuantity: "QTD (NF)",
			commercialUnit: "UN (NF)",
			unitValue: "Custo Unitário (NF)",
			totalValue: "Custo total (NF)",
			processedAt: "Data entrada estoque",
			invoiceEntry: "Produto Importado",
			associateProduct: "Produto associado (ZIG)",
			unitQuantityZig: "QTD (ZIG)",
			commercialUnitZig: "UN (ZIG)",
			unitValueZig: "Custo unitário (ZIG)",
		},
		emptyText: "Não existem produtos não associados neste perfil fiscal",
		excelExportDetailingProduct: {
			fileName: "detailing_product",
			title: "Detalhamento por produto",
		},
		fields: {
			isAssociatedProduct: "Apenas produtos não associados",
			searchDateRange: {
				emissionDate: {
					end: "Final data emissão",
					start: "Início data emissão",
				},
				processedAtDate: {
					end: "Final data entrada",
					start: "Início data entrada",
				},
			},
			searchNf: "Insira o número da nota",
			searchSupplier: "Insira o nome do emitente",
		},
	},
	importHistory: {
		actionTable: {
			delete: "Excluir",
			download: "Download",
			unprocess: "Desprocessar",
		},
		columns: {
			createdAt: "Data de Importação",
			emission: "Data Emissão",
			nf: "NF",
			status: "Status",
			statusMessage: {
				Deprocessed: "Desprocessada",
				Unprocessed: "Não Processada",
				processed: "Processada",
			},
			supplier: "Fornecedor",
			totalValue: "Valor",
		},
		excelExportHistoryImport: {
			filename: "historico_importacao",
			title: "Histórico de importação",
		},
		fields: {
			searchDateEmissionRange: {
				end: "Final Data Emissão",
				start: "Início Data Emissão",
			},
			searchDateImportRange: {
				end: "Final Data Importação",
				start: "Início Data Importação",
			},
			searchNf: "Número da nota",
			searchSupplier: "Nome do fornecedor",
		},
		modalDelete: {
			cancelText: "Cancelar",
			message1: "A exclusão da NF",
			message2: "é permanente. Deseja continuar mesmo assim?",
			okText: "Excluir",
			title: "Excluir nota",
		},
		modalEdit: {
			associetedProduct: "Produto equivalente",
			cancelText: "Cancelar",
			equals: "equivale a",
			okText: "Associar produto",
			selectStorage: "Selecionar estoque",
			storage: "Estoque",
			title: "Associar produto",
			unitValue: "Preço de custo por unidade",
		},
		modalUnprocess: {
			cancelText: "Cancelar",
			closeText: "Fechar",
			message1: "Ao desprocessar uma nota você gera as seguintes ações:",
			message2: "Os produtos adicionados no estoque serão removidos;",
			message3: "A lançamento da nota será removido do contas a pagar.",
			message4: "Deseja desprocessar a nota mesmo assim?",
			okText: "Desprocessar nota",
			title: "Desprocessar nota",
		},
		table: {
			emptyText: "Não existem notas a serem mostradas",
			reportPrint: "Histórico de importação",
		},
	},
	importationInvoices: {
		backups: {
			placeholder: "Baixar um backup",
			error: "Erro: {{error}}",
			empty: "Nenhum backup disponível",
		},
		btnUpdate: "Atualizar",
		columns: {
			issueDate: "Data Emissão",
			issuerCnpj: "CNPJ ou CPF",
			issuerName: "Fornecedor",
			status: {
				authorized: "Autorizada",
				imported: "Importada",
				notAllowed: "Não autorizada",
				title: "Status",
				xmlUnavailable: "XML indisponível",
			},
			totalValue: "Valor da Nota",
		},
		emptyText: "Nenhuma Nota Fiscal de Compra",
		fiscalProfiles: "Selecionar perfil fiscal",
		issueDate: "Filtrar por data",
		issuer: "Filtrar por nome do fornecedor",
		xmlUnavailableInfo:
			"Para disponibilizar os arquivos XML com status indisponível é necessário realizar a manifestação da nota. Em caso de dúvidas na manifestação consulte o seu contador.",
		modalInfoImport: {
			notAllowed: {
				title: "Não autorizada",
			},
			xmlUnavailable: {
				reason:
					"O código XML desta nota fiscal ainda não está disponível. Aguarde para fazer a importação da nota fiscal.",
				title: "XML indisponível",
			},
		},
		status: "Filtrar por status",
		title: "Selecione um perfil fiscal para começar",
	},
	description:
		"A nota fiscal de compra é o documento referente à movimentação do estoque das mercadorias que a sua empresa está recebendo. Aqui será possível importar suas notas, ver seu histórico de importação, além do detalhamento por produto das	mesmas.",
	tabs: {
		detailingByProduct: "Detalhamento por produto",
		historyNf: "Histórico de importação",
		importationInvoices: "Importação de notas fiscais",
	},
	titlePage: "Notas fiscais de compra",
};
