export const infos = {
	addGuest: "Add guest",
	addGuestModal: {
		cpf: {
			error: "Invalid CPF",
			label: "CPF",
		},
		name: {
			error: "Invalid name",
			label: "First name and last name",
			placeholder: "Customer's full name",
		},
		phone: "Phone",
		promoter: {
			label: "Promoter",
			placeholder: "Enter the promoter's name",
		},
	},
	columns: {
		actions: "Actions",
		cpf: "CPF",
		emptyText: "No data to display.",
		name: "Name",
		phone: "Phone",
		promoter: "Promoter",
	},
	couvert: "Couvert discount",
	description: {
		empty: "No description added",
		label: "Description",
	},
	download: "Download list",
	edit: "Edit information",
	editGuest: "Edit guest",
	editInfos: "Edit list information",
	editModal: {
		color: "Assign a color",
		couvert: "Couvert discount",
		description: {
			label: "Description",
			limit: "{{limit}} characters left",
			placeholder: "Add a note to your list if you want",
		},
		entrance: {
			label: "Associated entry",
			placeholder: "Type or select the entry type",
		},
		errors: {
			description: "List description must be less than 280 characters",
			emptyName: "Enter the name of the list",
			expires: "Expired date and time",
			length: "List name must be less than 100 characters",
		},
		expiresAt: "List expiration",
		name: {
			label: "List name",
			placeholder:
				"Specify your list so that it quickly identifies (list name - event - date - location)",
		},
		new: "New list",
		save: "Save edit",
		title: "Edit list",
	},
	emptyList: {
		defaultMessage: "Looks like you haven't registered any guests",
		importList: "Import list",
		text: "The guest list will appear here when you have registered guests.",
	},
	entrances: {
		empty: "No associated entry",
		error: "No entry associated with this list",
		label: "Associated entry",
	},
	expires: "Expiration",
	goBack: "Back to lists",
	guest: "Guest",
	guests: "Guests",
	import: "Import spreadsheet",
	print: "Print out",
	search: "Search for guest or promoter",
};
