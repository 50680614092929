export const panel = {
	closeEvent: {
		emptyName: "O nome do evento está vazio",
		eventNameMessage: "Digite o nome do evento para confirmar o fechamento",
		finishedActivityDevices:
			"Existem {{finishedActivityDevices}} PDV's com operação em aberto!",
		incorectName: "O nome do evento está incorreto",
		message:
			"Tem certeza de que quer fechar o evento? Isso fará checkout de todos os usuários e fechará o relatório, o estoque e os caixas.",
	},
	closeEventButton: "Fechar evento",
	deleteEvent: {
		confirm: "Tem certeza de que quer remover o evento? Isso será uma ação permamente",
	},
	eventNotOpen: "Esse evento ainda não foi aberto",
	eventStatus: "Status do evento",
	finished: "Fechado",
	canceled: "Cancelado",
	notOpened: "Não aberto",
	openedBy: "Aberto por",
	finishedActivityText: "{{activeDevices}} PDVs com operação em aberto",
	goTo: "ir para",
	importConsumption: {
		balanceType: "Tipo de saldo",
		bonus: "Bônus",
		columns: {
			document: "CPF",
			email: "E-mail",
			gender: "Gênero",
			isForeign: "É estrangeiro?",
			name: "Nome",
			phone: "Telefone",
			reason: "Razão",
			value: "Valor",
		},
		confirm: "Confirmar",
		consumption: "Consumação",
		consumptionErrors: "Consumações que falharam",
		consumptionType: "Tipo de consumação",
		document: "CPF",
		import: "Importar consumação",
		minimumConsumption: "Consumação antecipada",
		normal: "Normal",
		reason: "Motivo",
		recharge: "Recarga",
		spreadsheet: "Planilha",
		successNotification: "Consumação importada!",
		title: "Importar Consumação",
		user: "Usuário",
		warningNotification: "Os dados não foram enviados. Algumas consumações falharam",
	},
	importConsumptionCardsGroup: {
		title: "Grupo de cartões",
		fields: {
			group: {
				label: "Nome do grupo de cartões",
				required: "Selecione um grupo de cartão",
			},
			balanceType: {
				label: "Tipo de Saldo",
				required: "O valor do saldo deve ser maior que zero",
			},
			balance: { label: "Valor do Saldo" },
		},
	},
	importConsumptionZigCode: {
		title: "Zig Code",
		fileName: "Planilha de Consumação",
		rechargeSuccess: "Recarga efetuada com sucesso!",
		balanceType: "Tipo de Saldo",
		columns: {
			reason: "Razão",
			value: "Valor",
			zigCode: "Zig Code",
		},
	},
	importConsumptionUser: {
		bonus: "Bônus",
		document: "Documento",
		documentError: "Documento é obrigatório",
		documentType: "Tipo de documento",
		minimumConsumption: "Consumação antecipada",
		paymentMethod: "Método de pagamento",
		paymentMethodError: "Método de pagamento é obrigatório",
		reason: "Motivo",
		recharge: "Recarga",
		submitRecharge: "Recarregar ",
		value: "Valor da recarga",
		valueError: "Valor da recarga é obrigatório",
	},
	open: "Aberto",
	openEvent: "Abrir evento",
	publicCard: {
		allInsideText: "ainda estão curtindo",
		allOutsideText: "já foram embora",
		capacity: "Capacidade",
		genderCard: {
			averageTicket: "Ticket Médio",
			female: "Mulheres",
			invoice: "Faturado",
			male: "Homens",
			other: "Outros",
			realTicket: "Ticket Real",
			zeroTicket: "Ticket Zero",
		},
		newPublicText: "novos clientes",
		oldPublicText: "retornaram",
		people: "pessoas",
		title: "Público",
		totalPublic: "{{totalPublic}} estiveram no evento",
	},
	removeEventButton: "Remover evento",
	topClientsCard: {
		clientInfo: "Informações do cliente",
		clients: "Clientes",
		count: "Consumo",
		title: "Clientes que mais consomem",
	},
	topProductsPriceCard: {
		allProductsSold: "Todos os produtos vendidos",
		altNavlink: "Link de navegação para relatório de todos os produtos vendidos",
		consumption: "Consumo",
		noData: "Sem Dados",
		other: "Outros",
		products: "Produtos",
		quantity: "Quantidade",
		title: "Produtos por representação em vendas",
		unavailable: "Indisponivel",
	},
	totalOpenedTable: "mesa em aberto",
	totalOpenedTables: "mesas em aberto",
	totalTables: "Total de mesas",
	editEvent: {
		title: "Editar evento",
		confirm: "Confirmar",
		passTemplate: "ID do modelo do passe",
	},
	zigMesaOperationMode: {
		message: "Status do servidor no PDV",
		online: "Online",
		local: "Local",
	},
	operationCard: {
		title: "Operação Zig Mesa",
		peopleSingle: "Pessoa",
		peoplePlural: "Pessoas",
		tableSingle: "Mesa",
		tablePlural: "Mesas",
		time: "Horário",
	},
	totalReceiptCard: {
		averageTicket: "Ticket Médio",
		column: {
			adjustedReceipt: "Receita ajustada",
			bar: "Bar",
			entrance: "Entrada",
			entranceConsumptions: "Consumação bilheteria",
			extraConsumptions: "Consumação extra",
			invoicing: "Faturamento",
			opened: "Contas em aberto",
			rechargeReturned: "Recargas devolvidas",
			tableRow: {
				ame: "Ame",
				anticipated: "Antecipado",
				app: "Aplicativo",
				cash: "Dinheiro",
				credit: "Crédito",
				debit: "Débito",
				deliveryOnline: "Delivery Online",
				ifood: "Ifood",
				pix: "Pix",
				postEvent: "Pago após evento",
				rappi: "Rappi Pay",
				uber: "Uber",
				voucher: "Voucher",
				integratedFoodVoucher: "Vale-refeição",
				mbway: "Mbway",
			},
			title: "Modo De Pagamento",
			total: "Total",
			value: "Valor",
		},
		emptyContent: "Não há nada a mostrar aqui",
		errorContent: "Erro!",
		notIdealStateMessage:
			"Essa informação só está disponivel em eventos a partir de 01/08/2017",
		realTicket: "Ticket Real",
		receipt: "Receita",
		operationTable: {
			title: "Operação",
			operationMode: "Modo de operação",
			zigTable: "Zig Mesa",
			zigCard: "Zig Card",
			total: "Total",
			invoicing: "Faturamento",
		},
		timelineCard: {
			consumption: "Consumo",
			period: "Período",
			recharge: "Recargas",
			zigCardConsumption: "Consumo no Zig Card",
			tableConsumption: "Consumo no Zig Mesa",
			tooltipMessage: "{{titleMap}} no período de {{start}} a {{end}}",
			value: "Valor consumido",
		},
		title: "Financeiro",
		zeroTicket: "Ticket Zero",
	},
	vendorsResumeCard: {
		name: "Vendors",
		title: "Vendas por vendors",
		totalSold: "Total vendido",
	},
};
