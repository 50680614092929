import { crudMessage } from "./crud-message";
import { modalImportClients } from "./modal-import-clients";
import { modalMyModels } from "./modal-my-models";

export const message = {
	columnsTableClients: {
		cpf: "CPF",
		name: "Nome do Cliente",
		presences: "Visitas",
		ticket: "Ticket médio",
	},
	crudMessage,
	emptyWhatsappList:
		"Nenhum local da organização tem números cadastrados no WhatsApp. Entre em	contato com o time de atendimento da Zig e solicite o cadastro de um número	para poder enviar mensagens por WhatsApp para os seus clientes.",
	footer: {
		confirmWithSaveGroup: "Salvar grupo e avançar",
		confirmWithoutSaveGroup: "Avançar sem salvar grupo",
		foward: "Avançar",
		save: "Salvar alteração e editar mensagem",
	},
	header: {
		isCreate: {
			subtitle:
				"Selecione uma casa da sua organização para listar o grupo de clientes destinatário.",
			title: "Nova mensagem",
		},
		isEdit: {
			subtitle: "Altere a casa para selecionar outro grupo de destinatários.",
			title: "Editar mensagem programada",
		},
	},
	import: "Importar planilhas do excel",
	infos: {
		agree: "Entendido",
		text:
			"Ao importar a lista de contatos dos clientes, com a finalidade de envio de SMS, você confirma que os mesmos aceitaram o recebimento das mensagens, com base na Lei Geral de Proteção de Dados (LGPD).",
		title: "Responsabilidade de informações de clientes",
	},
	modalImportClients,
	modalMyModels,
	modalSaveGroup: {
		filtersList: "Filtros aplicados",
		groupName: {
			placeholder: "Selecione um nome para o novo grupo de clientes",
			title: "Nome do grupo",
		},
		text:
			"Salve os filtros aplicados para criar um grupo de clientes e facilitar o envio de SMS no futuro.",
		title: "Salvar grupo",
	},
	myModels: "Meus modelos",
	places: "Casas",
	sheet: {
		columns: {
			cpf: "CPF",
			name: "Nome",
			observation: "Observação",
			phone: "Telefone",
		},
		title: "Lista de Clientes (exemplo)",
	},
};
