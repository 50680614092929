import { action, observable } from "mobx";
import { RootStore } from ".";
import dayjs from "dayjs";
import { datetime, startOf, subtract } from "#resources/helpers/date-format";

interface DateRange {
	from: Date;
	until: Date;
}

export type FilterConfig = "category" | "product";

export interface ICategoryFilter {
	categoryName: string;
	categoryId: string;
}

export interface IProductFilter {
	productName: string;
	productId: string;
}

export interface StorageReportFilter {
	selectedStorages: string[];
	selectedCategories: string[];
	selectedProducts: string[];
	filterMode: "product" | "category";
	hideProductsWithProductRule: boolean;
	dateInterval: {
		since: Date;
		until: Date;
	};
}

export class UiStore {
	public rootStore: RootStore;

	@observable
	public placeReportRange: DateRange = {
		from: datetime().compose(subtract(7, "day"), startOf("day")),
		until: datetime().endOf("day"),
	};

	@observable
	public organizationReportRange: DateRange = {
		from: datetime().compose(subtract(7, "day"), startOf("day")),
		until: datetime().endOf("day"),
	};

	@observable
	public organizationReportDate: Date = dayjs().toDate();

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable
	public isStockDialogOpen: boolean = false;
	@observable
	public isCashierDialogOpen: boolean = false;
	@observable
	public isProductDialogOpen: boolean = false;
	@observable
	public isAddPlaceModalOpen: boolean = false;
	@observable
	public isAddBarModalOpen: boolean = false;
	@observable
	public isAddEventModalOpen: boolean = false;
	@observable
	public isEmployeeDialogOpen: boolean = false;
	@observable
	public isEntranceDialogOpen: boolean = false;
	@observable
	public isAddComboModalOpen: boolean = false;
	@observable
	public isAddAnnounceModalOpen: boolean = false;
	@observable
	public isShowGraph: boolean = true;

	@observable
	public topLoading: boolean = false;

	// DO NOT NEED TO BE OBSERVABLE
	private storageReportSavedFilters: Record<string, StorageReportFilter | undefined> = {};

	public getStorageReportSavedFilters(placeId: string) {
		return this.storageReportSavedFilters[placeId];
	}

	public setStorageReportSavedFilters(placeId: string, info: StorageReportFilter) {
		this.storageReportSavedFilters[placeId] = info;
	}

	@action
	public updatePlaceReportRange = (from: Date, until: Date) => {
		this.placeReportRange = { from, until };
	};

	@action
	public setLoadingStatus = (status: boolean) => {
		this.topLoading = status;
	};

	@action
	public setShowGraph = (status: boolean) => {
		this.isShowGraph = status;
	};
}
