exports = module.exports = require("../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-skeleton--skeleton-skeleton{border-radius:5px;position:relative;overflow:hidden;min-width:20px;min-height:20px;background:#dcdcdc}.src-components-skeleton--skeleton-skeleton::after{content:\"\";position:absolute;top:0;left:0;width:100%;height:100%;background:linear-gradient(90deg, GAINSBORO, #eeeeee, GAINSBORO);animation:src-components-skeleton--skeleton-progress 1s ease-in-out infinite}@keyframes src-components-skeleton--skeleton-progress{0%{transform:translate3d(-100%, 0, 0)}100%{transform:translate3d(100%, 0, 0)}}", ""]);

// exports
exports.locals = {
	"skeleton": "src-components-skeleton--skeleton-skeleton",
	"progress": "src-components-skeleton--skeleton-progress"
};