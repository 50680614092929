export const consolidated = {
	excelButton: "Exportar para Excel",
	extract: "Extrato",
	filename: "extrato-zig",
	formatFee: "De {{minFee}} a {{maxFee}}",
	table: {
		fee: "Taxa",
		feeMax: "Taxa Máxima",
		feeMin: "Taxa Mínima",
		name: "Nome",
		payValue: "A Receber/A Pagar Zig",
		section: "Seção",
		totalValue: "Valor Bruto",
	},
};
