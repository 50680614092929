export const eventReports = {
	tabs: {
		resume: "Fechamento",
		promotion: "Promoções utilizadas",
		debtors: "Contas em aberto",
		devolution: "Devolução de cartão por caixa",
		cashierClosings: "Fechamento de caixa",
		userBonus: "Bônus por cliente",
		discounts: "Descontos em conta",
		minimumConsumption: "Consumação antecipada",
		consumptionObligation: "Consumação Mínima",
		refunded: "Entradas/Produtos estornados",
		servicesRemoval: "Serviços removidos",
		bonusByProduct: "Bônus por produto",
		rechargeConsumption: "Devolução de recargas",
		refundedRecharges: "Recargas estornadas",
		individualLimitPost: "Alteração de limite individual de pós",
		rappiDiscounts: "Descontos Rappi",
		openedBillPayment: "Pagamentos de contas em aberto",
		openSales: "Vendas avulsas",
		afterClosingEvent: "Ações sincronizadas após o evento",
		cashierAdjustments: "Sangrias e Fundos de Troco",
		forciblySynchronizedBalances: "Saldos Sincronizados Forçadamente",
		zigAppReport: "Vendas por transações do Zig.app",
		tipReport: "Relatório de Gorjeta",
		transactionTransfer: "Transferências de contas",
	},
	resumeReport: {
		title: "Fechamento",
		finished: {
			end: "Fechado: {{end}}h",
			not: "O evento não foi fechado",
		},
		reportIn: "Faturamento do evento '{{eventName}}'",
		fileName: "faturamento-{{eventName}}",
	},
	cashierClosings: {
		all: "Todos",
		balanceTitle: "Receita por caixa",
		balanceTitleDay: "Receita por caixa no dia {{day}}",
		card: {
			bleeding: "Sangria",
			cardDevolution: "Devoluções de cartão",
			cash: "Dinheiro",
			casher_one: "{{numberOfCashiers}} Caixa",
			cashier_other: "{{numberOfCashiers}} Caixas",
			changeFund: "Fundo de troco",
			closed: "Fechado",
			difference: "Diferença",
			modality: "Modalidade",
			noFlag: "Sem Bandeira",
			notClosed_one: "({{numberOfOpenCashiers}} não fechado)",
			notClosed_other: "({{numberOfOpenCashiers}} não fechados)",
			open: "Em aberto",
			received: "Faturado",
			productDevolution: "Devoluções de produto",
			rechargeDevolutions: "Devoluções de recarga",
			total: "TOTAL",
			xlsx: {
				cashier: "Caixa",
				closed: "Fechado",
				closingCashier: "Fechamento por Caixa",
				closingResume: "Resumo do Fechamento",
				difference: "Diferença",
				filename: "fechamento-de-caixa-em-local",
				filenameEvent: "fechamento-de-caixa-{{eventDate}}",
				modality: "Modalidade",
				received: "Faturado",
			},
		},
		cashier: "Caixas",
		filenameBalance: "receita_por_caixa",
		header: {
			addBleed: "Fazer sangria",
			addChange: "Adicionar fundo de troco",
			addObs: "Adicionar observação",
			cashierHistory: "Ver histórico do caixa",
			closeCashier: "Tem certeza que deseja fechar o caixa {{cashierName}}?",
			closeCashierButton: "Fechar caixa",
			historyTitle: "Histórico do caixa - {{cashierName}}",
			obs: "Observação",
			reopenCashier: "Tem certeza que quer reabrir o caixa?",
			reopenCashierButton: "Reabrir caixa",
			transactionalHistory: "Ver histórico transacional",
		},
		info: {
			addNewModality: "Adicionar nova modalidade",
			billed: "Faturado",
			bleeding: "Sangria",
			bleeds: "Sangrado",
			cancel: "Cancelar alterações",
			cardDevolution: "Devoluções de cartão",
			cashierNotClosed: "Caixa não fechado",
			cashierReceipt: "Receita por caixa",
			cashier_one: "caixa",
			cashier_other: "caixas",
			changeFund: "Fundo de troco",
			changes: "Troco",
			closeCashier: "Fechar caixa",
			closed: "Fechado",
			closingCashier: "Fechamento de caixa",
			createBleed: "Fazer Sangria",
			createChange: "Adicionar fundo de troco",
			devolutions: "Devolvido",
			modality: "Modalidade",
			negative: "Diferença",
			notClosed: "não fechado",
			rechargeDevolutions: "Devoluções de recarga",
			save: "Salvar fechamento",
			searchCashier: "Buscar caixa por nome",
			totalBilled: "Total Faturado",
			totalClosed: "Total Fechado",
		},
		modal: {
			addFlag: "Adicionar bandeira",
			cashierPosts: "Transações pós-pagas",
			cashierRecharges: "Recargas",
			cashiers: "Caixas",
			changeFund: "Fundo de troco",
			column: {
				author: "Funcionario",
				client: "Cliente",
				clients: "Cliente(s)",
				zigCode: "ZigTag",
				date: "Data",
				method: "Método",
				obs: "Observação",
				payments: "Pagamentos",
				total: "Total",
				valueComsumed: "Valor consumido",
				valueRecharged: "Valor recarregado",
			},
			history: "Histórico",
			historyTitle: "Histórico de {{name}}",
			saveValues: "Salvando valores",
			value: "Valor",
		},
		valueGreaterThanZero: "O valor precisa ser maior que 0",
		value: "Valor",
		noCard: "Sem cartão",
		noCashierSelected: "Nenhum caixa selecionado",
		paymentMethod: {
			ame: "Ame",
			anticipated: "Antecipado",
			bonus: "Bonus",
			cash: "Dinheiro",
			credit: "Crédito",
			dashboard: "Dashboard",
			debit: "Débito",
			deliveryOnline: "Delivery Online",
			devolution: "Devoluções",
			foodVoucher: "Vale-refeição",
			ifood: "Ifood",
			installment: "Parcelamento",
			pix: "Pix",
			postEvent: "Pago após evento",
			rappi: "Rappi",
			subtotal: "Subtotal",
			total: "Total",
			uber: "Uber",
			voucher: "Voucher",
			zigCredit: "Crédito Zig",
			zigDebit: "Débito Zig",
		},
		postPaid: "Transações pós-pagas",
		recharges: "Recargas",
		total: "Total",
		transactionsTitle: "Histórico de {{name}}",
	},
	debtorsReport: {
		modal: {
			addPayment: "Adicionar pagamento",
			bonus: "Pagar com bônus",
			cash: "Dinheiro",
			column: {
				bonus: "Bônus",
				value: "Valor",
			},
			confirm: "Confirmar",
			creditCard: "Crédito",
			debitCard: "Débito",
			eventScope: "evento",
			multiplePaymentConfirm_part1: "Esse pagamento ",
			multiplePaymentConfirm_part2: "irá pagar {{value}}",
			multiplePaymentConfirm_part3:
				"do cliente no {{scope}}, tem certeza que deseja fazer isso?",
			multiplePaymentError: "Atenção! Adicione um método de pagamento",
			no: "No",
			observation: "Observação",
			observationPlaceholder: "Insira uma observação",
			openConsumption: "Consumo em aberto",
			pay: "Pagar",
			paymentConfirm_part1: "Esse pagamento ",
			paymentConfirm_part2: "irá pagar TODA a dívida",
			paymentConfirm_part3: "do cliente no {{scope}}, tem certeza que deseja fazer isso?",
			paymentConsumption: "Pagando em consumo",
			paymentMethod: "Método de pagamento",
			paymentMethodError: "Selecione um método de pagamento",
			paymentService: "Pagando em serviço",
			placeScope: "local",
			postEvent: "Marcar como pago",
			reason: "Justificativa",
			reasonPlaceholder: "Adicione a justificativa do bônus",
			select: "Selecione",
			service: "Serviço ({{tip}}%)",
			submitError: "Só é possível pagar a mais até 25% do valor total em aberto.",
			total: "Total",
			voucher: "Voucher",
			yes: "Sim",
			pixCharge: "Cobrança via PIX",
			sendCharge: "Enviar cobrança",
			openValues: "Valores em aberto",
			totalDebit: "Total em aberto",
			pixValue: "Valor do pix",
			email: "Email",
			emailPlaceholder: "Email do cliente",
			fieldRequired: "Campo obrigatório",
			emailError: "Email inválido",
			phoneError: "Telefone inválido",
			discharge: "Dar baixa",
			openService: "Serviço em aberto",
		},
		title: "Contas em aberto",
		alertTitleOpenBill: "Não é possível enviar cobranças no relatório do evento",
		alertMessageOpenBill:
			"Para enviar cobranças, acesse o menu [Locais] > escolha o local > menu [Relatórios Financeiros] > aba 'Contas em aberto' e utilize o link na coluna 'Cobrar'.",
	},
	individualLimitPost: {
		columns: {
			client: "Cliente",
			date: "Horário",
			employeeName: "Funcionário",
			emptyText: "Sem dados a serem exibidos.",
			limit: "Aumento de limite",
			newLimite: "Novo limite",
			username: "Usuário",
		},
		filename: "relatorio_de_alteracao_de_limite_individual_de_pos",
		searchPlaceholder: "Busque por usuário, id ou nome",
		title: "Informações de clientes",
		titleTab: "Alteração de limite individual de pôs",
	},
	minimumConsumption: {
		title: "Consumação antecipada",
	},
	openedBillPayment: {
		columns: {
			client: "Cliente",
			document: "CPF",
			employeeName: "Pago para",
			method: "Método",
			openedEventDate: "Data da conta em aberto",
			tipValue: "Valor de gorjeta",
			value: "Valor",
		},
		filename: "relatorio_de_pagamentos_de_contas_em_aberto_em_evento",
		title: "Pagamentos de contas em aberto",
	},
	rappiDiscounts: {
		columns: {
			date: "Data",
			employee: "Funcionário que aplicou o desconto",
			products: "Produtos",
			value: "Valor do desconto",
			total: "Total Removido",
			name: "Cliente",
			reason: "Motivo",
		},
		filename: "relatorio_de_descontos_do_rappi",
		rappiDiscounts: "Descontos Rappi",
		title: "Descontos do Rappi",
	},
	rechargeConsumption: {
		title: "Devolução de recargas",
		filename: "devolucao-de-recargas",
		detailDialog: "Ver recargas devolvidas pelo caixa",
		rechargesRefundedBy: "Recargas devolvidas por {{author}}",
		header: {
			title: "Devolução de recargas",
			description: "Veja o relatório de todas as devoluções de recargas",
		},
		printTitle: "Relatórios de devoluções de recargas",
		paymentIn: "Pgto. em {{category}}",
		categoryPayment: {
			cash: "dinheiro",
			pix: "pix",
			rescue: "site de recarga",
		},
		tableTitle: "Devolução em {{category}}",
		emptyTableDescription: "Não houveram devoluções em {{category}}",
		modalTitle: "Recargas devolvidas em {{category}}",
		columns: {
			ame: "Ame",
			anticipated: "Antecipado",
			author: "Caixa",
			cash: "Dinheiro",
			client: "Cliente",
			credit: "Crédito",
			dashboard: "Dashboard",
			debit: "Débito",
			deliveryOnline: "Delivery Online",
			ifood: "Ifood",
			method: "Método",
			pix: "PIX",
			rappi: "Rappi",
			total: "Total",
			uber: "Uber",
			value: "Valor",
			voucher: "Voucher",
			totalCashReturn: "Total devolvido em dinheiro",
			totalPixReturn: "Total devolvido em pix",
			totalRescueReturn: "Total devolvido pelo site de recarga",
			sumReturned: "Total devolvido somados",
		},
		columnsDetailsTable: {
			client: "Cliente",
			paymentMethod: "Método de pagamento",
			value: "Valor",
			totalCashReturn: "Total devolvido em dinheiro",
			totalPixReturn: "Total devolvido em pix",
			totalRescueReturn: "Total devolvido pelo site de recarga",
			sumReturned: "Total devolvido somados",
		},
		excel: {
			title: "Recargas devolvidas em ",
			fileName: "recargas-devolvidas-detalhes",
		},
	},
	refunded: {
		zigTable: "Zig Mesa",
		zigTag: "Zig Card",
		columns: {
			canceled: "Cancelado",
			category: "Categoria",
			clients: "Clientes",
			count: "Quantidade",
			date: "Data",
			moreRefundedBuyer_other: "e mais {{buyers}} pessoas",
			moreRefundedBuyer_one: "e mais {{buyers}} pessoa",
			operationType: "Operação",
			name: "Nome do Produto",
			refundObs: "Observação",
			reason: "Motivo de Estorno",
			refunded: "Estornado",
			refundedBy: "Estornado Por",
			soldBy: "Vendido Por",
			totalCount: "Quantidade Total",
			totalRefunded: "Total ({{total}} estornos)",
			totalValue: "Valor Total",
			type: "Tipo",
			value: "Valor",
		},
		filename: "produtos-estornados",
		title: "Produtos estornados/cancelados",
	},
	servicesRemoval: {
		clientInfo: "Informações de Cliente",
		columns: {
			date: "Data",
			name: "Cliente",
			reason: "Motivo",
			total: "Total Removido",
			value: "Valor",
			employee: "Funcionário",
		},
		goTo: "ir para",
		title: "Remoção de serviços por caixa",
		titlePrint: "Serviços Removidos",
	},
	afterClosingEvent: {
		title: "Ações sincronizadas após o evento",
		description: "Relatório com ações ou transações após o fechamento do evento.",
		searchLabel: "Pesquisar por nome do funcionário",
		searchPlaceHolder: "Digite o nome do funcionário",
		filterTransactionLabel: "Filtrar por tipo de transação",
		filterOriginLabel: "Visualizar",
		offlineActionLabel: "Após fechamento",
		historyMachineLabel: "Histórico da maq.",
		historyMachineAfterClosingLabel: "Histórico após fechamento",
		optionAll: "Todos os saldos",
		optionOfflineAction: "Saldos sincronizados após fechamento de evento",
		optionHistoryMachine: "Saldos do histórico da maquininha",
		optionHistoryMachineAfterClosing:
			"Saldos do histórico da maquininha sincronizados após o fechamento do evento",
		columns: {
			transactionType: "Tipo de transação",
			employee: "Funcionário",
			customerName: "Nome do cliente",
			date: "Data/Hora da sinc",
			value: "valor",
			origin: "Origem",
		},
	},
	forciblySynchronizedBalance: {
		title: "Saldos Sincronizados Forçadamente",
		table: {
			columns: {
				date: "Data/Hora",
				user: "Cliente",
				employee: "Funcionário",
				reason: "Justificativa",
				balance: "Saldo Sincronizado",
			},
		},
		successfulExportMsg: "Planilha exportada com sucesso!",
	},
	zigAppReport: {
		title: "Vendas por transações do Zig.app",
	},
	salesTotetemTransaction: {
		title: "Vendas por transações do Totem",
		subtitle:
			"Acompanhe as transações feitas pelos seus clientes usando um totem do evento.",
	},
};
