export const storageHistory = {
	label: "Transaction Report",
	description:
		"The Stock Movement Report allows a detailed analysis of all incoming and outgoing movements carried out in a given period.",
	filename: "transaction-report",
	print: "Print",
	export: "Download in Excel",
	cleanFilters: "Clear filters",
	emptyState: {
		title: "Select and apply filters to start",
		description:
			"You need to select at least one stock, one category or one product and the period to start",
	},
	storages: {
		label: "Stocks",
		placeholder: "Search for stock",
	},
	filterBy: {
		label: "Filter by",
		category: "Category",
		product: "Product",
	},
	products: {
		label: "Products",
		placeholder: "Search for product",
	},
	categorys: {
		label: "Categories",
		placeholder: "Search for category",
	},
	period: "Period",
	filter: "Apply filters",
	hideProducts: "Do not show items with datasheet",
	dateFilterBy: {
		label: "Filter period by",
		options: {
			events: "Events between interval",
			interval: "All between interval",
		},
	},
	columns: {
		cardExchange: "Returns",
		inicial: "Initial Quantity",
		input: "Product entry",
		lost: "Losses",
		manual: "Manual Adjustment",
		name: "Product",
		productionIn: "Production input",
		productionOut: "Production output",
		result: "Final",
		sell: "Sales",
		transfer: "Internal transfer",
		ube: "Unit",
		lossPercentage: "Loss percentage",
	},
	editModal: {
		alert: "Alert",
		below: "below",
		critical: "Critical",
		delete: "Delete alert",
		deleteWarning: "Are you sure you want to delete the alert for {{productName}}?",
		invalidText: "The alert stock value cannot be less than the critical stock value",
		storage: "Stock",
		storageIn: "Stock in",
		title: "Edit stock alerts for {{productName}}",
	},
	printable: {
		final: "End",
		initialQuantity: "Initial Qtt",
		internalTransfer: "Internal Transfer",
		losses: "Loss",
		manualAdjustment: "Manual Adjustment",
		product: "Product",
		productEntry: "Prod Ent.",
		productionEntry: "Production Entry",
		productionOutput: "Production Output",
		sales: "Sales",
		withoutProducts: "No products selected",
	},
	storageReport: "Inventory Report",
};
