import * as React from "react";
import { useTranslation } from "react-i18next";
import ProdTheme from "./prod-theme";
import DevTheme from "./dev-theme";
import s from "../nav-bar/nav-bar.scss";
import { MenuIconContainer } from "#components/primitive";
import SettingsIcon from "../nav-bar/icons/settings.svg";

export function ChangeLayout() {
	const [layoutEnv, setLayoutEnv] = React.useState("dev");
	const { t } = useTranslation("globalKeys");

	function changeLayout() {
		const newLayout = layoutEnv === "dev" ? "prod" : "dev";
		setLayoutEnv(newLayout);
	}

	React.useEffect(() => {
		console.log("layout changed", layoutEnv);
	}, [layoutEnv]);

	return (
		<>
			<button className={s.item} onClick={changeLayout}>
				<MenuIconContainer>
					<SettingsIcon />
				</MenuIconContainer>
				<div>{t("changeLayout")}</div>
			</button>
			{layoutEnv === "prod" ? <ProdTheme /> : <DevTheme />}
		</>
	);
}
