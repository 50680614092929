import { employees } from "./employees/";
import { products } from "./products/";
import { clientArea } from "./client-area/";
import { clients } from "./clients";
import { communication } from "./communication/";
import { helpers } from "./helpers";
import { reports } from "./reports";
import { fiscal } from "./fiscal";
import { satisfactionSurvey } from "./satisfaction-survey";
import { chartOfAccounts } from "./chart-of-accounts";
import { fiscalBoard } from "./fiscal-board";
import { productFeatureTemplates } from "./product-feature-templates";
import { fiscalProfileForm } from "./fiscal-profile-form";
import { fiscalCouvertForm } from "./fiscal-couvert-form";
import { fiscalGroupForm } from "./fiscal-group-form";
import { fiscalData } from "./fiscal-data";
import { loyalty } from "./loyalty";
import { minimmConsummation } from "./fiscal-minimum-consummation-form";
import { teamAndRoles } from "./team-and-roles";
import { vendors } from "./vendors";
import { fiscalRecharge } from "./fiscal-recharge-form";
import { giftback } from "./giftback";

export const orgEN = {
	employees,
	fiscal,
	fiscalBoard,
	fiscalData,
	fiscalProfileForm,
	fiscalCouvertForm,
	fiscalGroupForm,
	// fiscalSettings,
	helpers,
	loyalty,
	clients,
	productFeatureTemplates,
	communication,
	products,
	clientArea,
	reports,
	satisfactionSurvey,
	chartOfAccounts,
	minimmConsummation,
	teamAndRoles,
	vendors,
	fiscalRecharge,
	giftback,
};
