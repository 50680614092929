export const soldProducts = {
	columns: {
		category: "Categoria",
		count: "Quantidade",
		discount: "Descontos",
		fiscalCode: "SKU",
		variable: "Variável",
		devolution: "Devolução",
		variableTooltip:
			"O valor unitário de produtos com valor variável é a média dos valores vendidos",
		fracionalCount: {
			grams: "gramas",
			kg: "Kilos",
			label: "Quantidade Frac.",
			lt: "Litros",
		},
		grossTotalValue: "Total bruto",
		mountable: "Montável",
		name: "Nome",
		net_sales: "Total liquido",
		subtotal: "Subtotal",
		operationType: "Operação",
		tax_money: "Imposto {{currencySymbol}}",
		tax_percentage: "Imposto %",
		totalValue: "Valor total",
		unitaryCount: "Quantidade Unit.",
		unitaryValue: "Valor unitário",
	},
	downloadAllSales: "Baixar todas as vendas",
	filter: {
		all: "Todos",
		balcony: "Balcão",
		cashless: "Cashless",
		dashboard: "Dashboard",
		delivery: "Delivery",
		ficha: "Ficha",
		grabngo: "Grab n Go",
		label: "Filtrar por tipo de transação",
		onlineBill: "Comanda Online",
		placeholder: "Todas",
		zigQrCode: "QR Code Zig",
		onlineFicha: "Ficha Digital",
	},
	label: "Total de produtos vendidos",
	loading: "Gerando planilha",
	mountableModal: {
		count: "Quantidade",
		name: "Nome",
		section: "Sessão Montável",
	},
	mountables: {
		category: "Categoria Produto",
		count: "Quantidade",
		fiscalCode: "SKU",
		label: "Montáveis",
		name: "Item Montável",
		product: "Produto",
		section: "Etapa Montável",
	},
	sheetFileName: "total-produtos-vendidos",
	since: "De",
	total: "TOTAL",
	totalItem: "Total",
	until: "Até",
	zigCard: "Zig Card",
	zigTable: "Zig Mesa",
	selectDate: "Selecione a data",
	selectTime: "Selecione o horário",
};
