exports = module.exports = require("../../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-nav-bread-crumbs--bread-crumbs-breadcrumbContainer{padding:0 1em}", ""]);

// exports
exports.locals = {
	"breadcrumbContainer": "src-components-nav-bread-crumbs--bread-crumbs-breadcrumbContainer"
};