import { cashierAdjustmentsReport } from "#i18n/translations/pt-BR/components/reports/cashier-adjustments-report";

export const reports = {
	cashierAdjustmentsReport,
	clientsReportTable: {
		appTransactionsValue: "Pago pelo app",
		bonusTransactionsValue: "Pago com bônus",
		cpf: "CPF",
		customerConsumption: "Consumo por cliente",
		editData: "Editar dados",
		empty: "Sem dados a serem exibidos.",
		filename: "consumo-por-cliente",
		hasApp: "Tem app",
		housePostPaidTransactionsValue: "Pós pago na casa",
		housePrePaidTransactionsValue: "Pré pago na casa",
		invoices: "Notas fiscais",
		name: "Nome",
		noApp: "Não tem app",
		phone: "Telefone",
		searchClient: "Busque aqui um cliente",
		seeExtract: "Ver extrato",
		title: "Consumo por cliente",
		total: "Total",
		zigCode: "ZigCode",
	},
	cmvReportTable: {
		mountableCaps: "MONTÁVEL",
		mountable: "Produto montável",
		name: "Nome",
		section: "Sessão",
		count: "Quantidade",
		productUnitCost: "Custo unitário",
		total: "Custo Total",
	},
	goTo: "ir para",
	rappiDiscount: {
		date: "Data",
		employee: "Funcionário que aplicou o desconto",
		emptyText: "Sem dados a serem exibidos.",
		products: "Produtos",
		value: "Valor do desconto",
	},
	reserveReportTable: {
		confirmedPeople: "{{value}} clientes confirmados",
		confirmedPeopleExcel: "Confirmados",
		count: "Quant.",
		date: "Data do pacote",
		discount: "Desconto",
		discountTooltip: "Eventuais descontos na conta fazem parte desse valor",
		downloadTable: "Baixar Tabela",
		filenameExcel: "pacotes",
		filenameExcelDetails: "datalhes_do_pacote_",
		invitedPeople: "Convidados",
		issued: "Emissão de NF",
		issuedInvoices: "Emissão de nota fiscal",
		name: "Nome",
		noPromotion: "Produtos fora de promoção",
		packageName: "Nome do Pacote",
		productName: "Total",
		productNameExcel: "Produto",
		products: "Produtos",
		promotionName: "Promoção",
		receivedValue: "Valor recebido",
		reserveValue: "faturado em valor de pacote",
		revenue: "Faturamento",
		selledCount: "Quantidade",
		ticketTooltip: "Ticket médio dos clientes do pacote",
		tips: "gorjetas",
		title: "Detalhes do pacote",
		titleExcel: "pacotes",
		titleExcelDetails: "detalhes do pacote ",
		total: "Faturamento total",
		totalProductsValue: "faturado em produtos",
		totalValue: "Valor total",
		unitValue: "Valor unitário",
		value: "Valor do pacote",
	},
	stockReportTable: {
		bar: "Bar",
		category: "Transferências de {{product}}",
		employeeBar: "Funcionário do bar",
		quantity: "Quantidade",
		stocker: "Responsável",
		time: "Hora",
		title: "Transferências de estoque",
	},
};
