export const nfReport = {
	columns: {
		date: "Import date",
		emission: "Issue date",
		name: "Note number",
		value: "Value",
	},
	empty: "No products",
	label: "Purchase invoices",
	notAssociatedProduct: "(Unassociated product)",
	productColumns: {
		name: "Products",
		quantity: "The amount",
		total: "Amount",
		unit: "Unit",
		unitValue: "Unitary value",
	},
};
