export const storeEN = {
	backofficeStore: {
		addBar: "Bar added",
		addStorage: "Inventory created successfully",
		createBillTypes: "Account type created successfully",
		createCategorie: "Category created",
		createCostCenters: "Created cost center",
		createNewSupplier: "New supplier added successfully",
		createTransfer: "Transfer performed successfully",
		deleteAccount: "Customer account deleted",
		deleteBackofficeProduct: "Product deleted from stock",
		deleteBackofficeProducts: "All products have been deleted from stock",
		deleteBar: "Bar deleted successfully",
		deleteBill: "Account deleted",
		deleteBillTypes: "Account type deleted",
		deleteCategorie: "Category deleted",
		deleteCostCenter: "Deleted Cost Center",
		deleteSupplier: "Supplier deleted successfully",
		deleteTransfer: "Transfer deleted successfully",
		editAccount: "Edited customer account",
		editBill: "Account successfully edited",
		editBillTypes: "Edited Account Type",
		editCategorie: "Edited Category",
		editCostCenter: "Edited Cost Center",
		editSupplier: "Supplier edited successfully",
		editTransfer: "Transfer edited successfully",
		importBackofficeBills: "Imported accounts",
		inputBackofficeProductsV2_other: "One or more products were not added",
		inputBackofficeProductsV2_zero: "Products added to stock",
		saveBackofficeProductsQuantityV2: "Updated Product",
		transferBackofficeProduct: "Transferred Product",
		updateBar: "Bar edited successfully",
		updateStorage: "Successfully modified stock",
		zeroBackofficeAllProductsAtStorage: "All products zeroed",
		createAccount: "Account launched successfully",
		deleteInvoices: "Invoices deleted successfully",
	},
	barStore: {
		addTableBarRange: "Added table printing configuration.",
		deleteBar: "Bar removed.",
		deleteRange: "Tablet printing configuration removed.",
		editBar: "Bar updated.",
		editTableBarRange: "Updated table printing configuration.",
	},
	cashierStore: {
		addCashierClosingObs: "Added note.",
		bleedCashier_other: "Bleeding was successful.",
		bleedCashier_zero: "Successful Exchange Fund",
		createBleedLEGACY: "Bleed added",
		createCashierChangeLEGACY: "Added Change Fund",
		createMultipleChangesLEGACY: "All funds have been registered",
		createMultipleChangesLEGACYError:
			"{{errors}} from {{cashiersId}} gave error: {{jsonErrors}}",
		updateCashierClosingLEGACY: "Cashier closed successfully",
	},
	clientStore: {
		clientAlreadyAdded: "This client has already been added",
		createPixCharge: "PIX created successfully",
		editClientDetails: "Updated client details.",
		editClientDetailsPlace: "Updated client details.",
		editUserDetails: "Updated customer details.",
		getClientByCPFOrPhone: "Customer added",
		payAllBillsAtPlace: "Bills successfully paid",
		payBillAtPlace: "Account successfully paid",
		payBillsLoading: "Marking as paid",
		payBillsSuccess: "Accounts paid successfully",
		payBillsWarning:
			"{{success}} bills successfully paid, and {{errors}} errors paying bills.",
	},
	cmvStore: {
		error: {
			getCategories: "Error fetching category",
		},
		payBillsLoading: "Marking as paid",
		createPixCharge: "PIX created successfully",
		updatedProductsWithoutCostOnSells: "Cost updated successfully",
		refundAppSell:
			"Transaction reversed successfully! Refresh the page to view the information correctly.",
		cancelAppSell:
			"Transaction canceled successfully! Refresh the page to view the information correctly.",
	},
	comboStore: {
		createCombo: "Combo created.",
		deleteCombo: "Combo removed.",
		editCombo: "Updated combo.",
	},
	deliveryStore: {
		activateManyProductsLoading: "Activating products",
		activateManyProductsWarning: "{{countFailures}} product(s) could not be updated.",
		addProduct: "Successfully added product!",
		addProductV2: "Successfully added product!",
		deactivateManyProductsLoading: "Disabling products",
		deactivateManyProductsWarning: "{{countFailures}} product(s) could not be updated.",
		deleteMenu: "Menu deleted successfully!",
	},
	deviceStore: {
		addDevice: "Device added to Location successfully",
		removeDeviceFromEvent: "Device removed from event.",
		updateEmployeeDeviceConfigs: "POS configured successfully",
	},
	employeeStore: {
		addEmployee: "Employee added.",
		addMultipleEmployees: "Employees added successfully",
		editMultipleEmployees: "Successfully modified contributors",
		removeMultipleEmployees: "{{successCount}} employees successfully removed",
		removeMultipleEmployeesError: "{{errorCount}} employees could not be removed",
		createRole: "Role created successfully",
		deleteRole: "Role successfully removed",
		editEmployee: "Employee updated.",
		removeEmployee: "Employee removed.",
		updateRole: "Role edited successfully",
	},
	eventStore: {
		closeEvent: "Closed event.",
		eventEditedSuccessfully: "Event edited successfully",
		closeEventLoading: "Closing event",
		createEvent: "Created event.",
		deleteEvent: "Event removed.",
		emitInnovaroGeneralInvoices: "Notes issued successfully",
		giveRechargeToUser: "Recharge created successfully",
		openEvent: "Open event.",
		payAllBillsAtEvent: "Bills successfully paid",
		payBillAtEventWithMultiplePayments: "Bill paid successfully",
		refundFichaSell: "Token successfully reversed",
	},
	extractStore: {
		createAdjustmentInVendorExtract: "Adjustment added successfully",
		createBankAccount: "Bank account created successfully.",
		deleteBankAccount: "Bank account successfully removed!",
		orderWithdrawCancellation: "Cancellation request sent successfully.",
		payVendorWithdrawRequests: "Approvals sent successfully!",
		revertManyVendorsWithDrawRequests: "Reject request sent successfully!",
		updateBankAccount: "Bank account updated successfully!",
	},
	fiscalStore: {
		createFiscalProductGroup: "Successful creating tax group.",
		createFiscalProfile: "Successful creating tax profile.",
		deleteFiscalProductGroup: "Success while updating tax group.",
		editFiscalProductGroup: "Success while updating tax group.",
		editFiscalProfile: "Success while updating fiscal profile.",
		issueManualInvoiceForReserveError: "Error n°{{number}}: {{error}}",
		issueManualInvoiceForReserveError_zero:
			"Error generating {{failedInvoiceCount}} invoices",
		issueManualInvoiceForReserve_other:
			"{{issuedInvoices}} Invoices generated successfully",
		issueManualInvoiceForReserve_zero: "All invoices generated successfully",
		minimumConsumptionFiscalSuccess: "Minimum consumption configured successfully",
		rechargeFiscalSuccess: "Recharge configured successfully",
	},
	invoiceStore: {
		cancelInvoice: "Invoice canceled successfully!",
		issueAllEventInvoices: "Event invoices issued.",
		notifyOfIssuingWarning_other:
			"{{issuedInvoices}} invoices issued, but the following errors were found: {{errors}}",
		notifyOfIssuingWarning_zero:
			"No invoice issued, the following errors were found: {{errors}}",
		notifyOfIssuing_other: "{{invoiceCount}} invoices issued",
		notifyOfIssuing_zero: "There were no notes to issue",
		sendInvoicesByEmail: "Notes sent by email.",
		cancelInvoiceNC:
			"Credit note generated successfully! Your credit note will be available shortly",
		issueInvoicesOfTransactions: "Invoices issued",
	},
	itemStore: {
		activateMountableSectionItem_all: "Successfully activated items",
		activateMountableSectionItem_one: "Item activated successfully",
		activateProductsInPlace: "Activated Products",
		activateProductsInPlaceLoading: "Activating products",
		activateSingleProductInPlace: "Activated Product",
		activatedEntrance: "Activated entrance",
		activatedProduct: "Activated product",
		activatingEntrance: "Activating entrance",
		activatingProduct: "Activating product",
		addProduct: "Added Product",
		addSupply: "Input added",
		attachProductionRule: "Data sheet created successfully",
		createBeerTap: "Shop created successfully!",
		createCategory: "Category created successfully",
		createEntrance: "Added Entry",
		deactivateMountableSectionItem_all: "Successfully deactivated items",
		deactivateMountableSectionItem_one: "Item deactivated successfully",
		deactivateProductsInPlace: "Deactivated Products",
		deactivateProductsInPlaceLoading: "Deactivating products",
		deactivateSingleProductInPlace: "Deactivated Product",
		deactivatedEntrance: "Deactivated entrance",
		deactivatedProduct: "Deactivated product",
		deactivatingEntrance: "Deactivating entrance",
		deactivatingProduct: "Deactivating product",
		deleteCategory: "Category deleted",
		deleteEntrance: "Entry removed",
		deleteMultipleProducts: "Successfully removed",
		deleteProduct: "Product removed",
		deleteProductError: "Error removing product",
		deleteProductFromPlace: "Product removed",
		editBeerTap: "Chopeira edits successfully!",
		editCategory: "Updated Category",
		editEntrance: "Updated entry",
		editEntranceImage: "Updated entry",
		editMultipleProducts: "{{name}} successfully modified!",
		editMultipleProductsError: "{{name}} could not be changed: {{reason}}",
		editProduct: "Updated Product",
		editProductionRule: "Updated Production Rule",
		editSupply: "Input changed",
		errorToAdd: "Error adding {{name}}",
		importProducts: "{{products}} Imported products",
		importProductsError: "Error editing product {{name}}: {{message}}",
		importProductsToEdit: "{{products}} Edited products",
		importProductsToEditError: "Error editing product {{name}}: {{message}}",
		importProductsToEditWarning:
			"{{products}} Edited products and {{errorCounter}} products had editing problems",
		importProductsWarning:
			"{{products}} Imported products and {{errorCounter}} products had problems importing",
		removeBeerTap: "Successfully deleted brewery!",
		removeProductionRule: "Data sheet removed successfully",
	},
	organizationStore: {
		addGiftback: "Giftback created successfully",
		addMultipleOrgProducts: "Added Products",
		addMultipleProductsInPlace: "Locations changed",
		addProductToOrg: "Edited Products",
		deleteGiftback: "Giftback deleted successfully",
		deleteMultipleOrgProducts:
			"({{products}}) products have been removed from inventory at all locations.",
		deleteProduct: "Product removed",
		deleteProductError: "Error removing product",
		editGiftback: "Giftback edited successfully",
		editMultipleOrgProducts: "Edited Products",
		editOrgProduct: "Added Product",
		tooglePlaces: "Locations changed",
		activateGiftback: "Giftback activated successfully",
		deactivateGiftback: "Giftback deactivated successfully",
	},
	placeStore: {
		editEverestConfigWarning: "Validating data with everest",
		editEverestConfig: "Updated everest data.",
		editPlace: "Updated location.",
		confirmSignPlaceContracts: "Signature completed successfully.",
		createStorageAlerts: "Alert created successfully",
		editStorageAlerts: "Successfully edited alert",
		deleteStorageAlerts: "Alert deleted successfully",
		addEmailToMinimumStorageAlert: "Emails successfully registered",
		removeEmailToMinimumStorageAlert: "Email successfully removed",
		deleteDiscountCategory: "Discount successfully removed!",
		activateDiscountCategory: "Discount activated successfully!",
		disableDiscountCategory: "Discount successfully disabled!",
		createManualLostReport: "Manual loss created successfully.",
		updatePlaceSettingsGeneralSection: "Updated general settings.",
		updatePlaceSettingsFichaSection: "Updated token settings.",
		updatePlaceSettingsCouvertSection: "Updated Couvert settings.",
		updatePlaceSettingsPlaceFeatureSection: "Update Feature settings.",
		updatePlaceSettingsPDVSection: "Update PDV settings.",
		updatePlaceSettingsTotemSection: "Totem settings updated.",
		createPixCharge: "PIX created successfully",
	},
	productFeatureStore: {
		createProductFeature: "Product feature created successfully!",
		createProductFeatureTemplate: "Product functionality model created successfully!",
		editProductFeature: "Successfully edited product feature!",
		editProductFeatureTemplate: "Product functionality template edited successfully!",
		org: "ORG",
		removeProductFeature: "Product functionality removed successfully!",
		removeProductFeatureTemplate: "Product functionality template removed successfully!",
		removeProductFeaturesByTemplateId:
			"Product features linked to the template were successfully removed!",
		zig: "ZIG",
	},
	promotionStore: {
		createPromotion: "Promotion created successfully",
		editPromotion: "Successfully edited promotion",
		updatePromotionPeople: "Customers successfully updated",
		usersInfoRewriteLoading: "Fetching customer data, this can take a long time",
	},
	relationStore: {
		activateOrDeactivateRelation_active: "Lists activated successfully",
		activateOrDeactivateRelation_deactive: "Successfully deactivated list",
		addRelationUser: "Guest added successfully!",
		deleteMultipleRelation_one: "Successfully deleted list",
		deleteMultipleRelation_other: "Successfully deleted lists",
		deleteRelation: "Successfully deleted list",
		deleteRelationUser: "User deleted from list successfully",
		editRelation: "Successfully edited list information!",
		editRelationUser: "Guest edited successfully!",
	},
	reserveStore: {
		addReserve: "Package created successfully",
		createReserveTemplate: "Package template created successfully!",
		deleteReserve: "Package deleted successfully",
		editReserve: "Package modified successfully",
	},
	smsStore: {
		cancelSmsCampaign: "Schedule canceled.",
		editSmsCampaignClients: "Recipient group changed.",
	},
	vendorStore: {
		createVendor: "Vendor created successfully",
		editVendor: "Successfully edited vendor",
	},
	whatsUpStore: {
		setPlaceDetailStatus_active: "Active location in Zig App",
		setPlaceDetailStatus_inactive: "Place inactive in Zig App",
	},
};
