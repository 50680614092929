import { IStepProps, Steps } from "#components/stepper";
import {
	BasePrechargeOperation,
	PeriodTime,
	PrechargeOperationBonus,
	PrechargeOperationPaymentMethod,
	PrechargeStatus,
} from "#resources/api/enterprise-generated";

export type PrechargeOperationRouteKey = keyof typeof PrechargeOperationRoutes;
export type PrechargeOperationRoute = typeof PrechargeOperationRoutes[PrechargeOperationRouteKey];

export const PrechargeOperationRoutes = {
	HOME: "precharge",
	FORM: "precharge/form",
	EDIT: "precharge/:prechargeOperationId/form",
	REPORT: "precharge/:prechargeOperationId/precharge-report",
} as const;

export type BonusSettingWithIndex = Omit<PrechargeOperationBonus, "type"> & {
	index?: number;
};

export type BonusSetting = Pick<PrechargeOperationBonus, "bonusValue" | "value">;

export interface PrechargeOperationFormValues
	extends Omit<
		BasePrechargeOperation,
		"placeId" | "bonus" | "mainPageInfo" | "rechargesLimit"
	> {
	logoBuffer: Buffer | null;
	logoUrl?: string | null;
	mainPageImageBuffer: Buffer | null;
	mainPageImageUrl?: string | null;
	preSetedValue: number;
	bonusSettingsDict: { [key: number]: number };
	mainPageInfoTitle: string;
	mainPageInfoSubTitle: string;
	rechargesLimitMinimumValue: number;
	rechargesLimitMaximumValuePerUser: number;
}

export const PrechargeOperationStepsEnum = {
	EVENT_DATA: "EVENT_DATA",
	CUSTOMIZATION_SETTINGS: "CUSTOMIZATION_SETTINGS",
	RECHARGE_SETTINGS: "RECHARGE_SETTINGS",
	TIPS: "TIPS",
} as const;

export type PrechargeOperationStepsKeys = keyof typeof PrechargeOperationStepsEnum;

export type PrechargeOperationExtraStepData = {
	[key in PrechargeOperationStepsKeys]: {
		icon: JSX.Element;
		validations: {
			key: GlobalTypes.ExtractKeys<PrechargeOperationFormValues>;
			isRequired: boolean;
			format?: {
				regexp: RegExp;
				message: string;
			}[];
		}[];
	};
};

export type PrechargeOperationSteps = Steps<
	PrechargeOperationStepsKeys,
	PrechargeOperationFormValues
>;

export type PrechargeOperationStepProps = IStepProps<
	PrechargeOperationFormValues,
	PrechargeOperationStepsKeys
>;

export type FilterStatesTypes = {
	name: string | null;
	document: string | null;
	email: string | null;
	acquirerTransactionId: string | null;
	paymentMethod: PrechargeOperationPaymentMethod | null;
	status: PrechargeStatus[];
	period: PeriodTime | null;
};

export type FilterActionsTypeOptions =
	| "name"
	| "document"
	| "email"
	| "acquirerTransactionId"
	| "paymentMethod"
	| "status"
	| "period";

type FilterActionPayloads = {
	[K in FilterActionsTypeOptions]: K extends "paymentMethod"
		? PrechargeOperationPaymentMethod | null
		: K extends "status"
		? []
		: string | null;
};

const actionTypeToStateKeyMap: Record<string, FilterActionsTypeOptions> = {
	SET_NAME: "name",
	SET_DOCUMENT: "document",
	SET_EMAIL: "email",
	SET_ACQUIRERTRANSACTIONID: "acquirerTransactionId",
	SET_PAYMENTMETHOD: "paymentMethod",
	SET_STATUS: "status",
	SET_PERIOD: "period",
};

export type FilterActionTypes = {
	[K in keyof FilterActionPayloads]: {
		type: `SET_${Uppercase<K>}`;
		payload: FilterActionPayloads[K];
	};
}[keyof FilterActionPayloads];

export function filterReducer(state: FilterStatesTypes, action: FilterActionTypes) {
	const key = actionTypeToStateKeyMap[action.type];
	return {
		...state,
		[key]: action.payload,
	};
}

export const handleTransformToObject = (data: Record<any, string>) =>
	Object.entries(data).map(([key, value]) => ({
		key,
		value,
	}));
