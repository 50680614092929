import { RouterStore } from "mobx-react-router";
import React, { createContext } from "react";
import { AuthStore } from "./auth";
import { BackofficeStore } from "./backoffice";
import { BarStore } from "./bar";
import { CMVStore } from "./c-m-v";
import { CashierStore } from "./cashier";
import { ClientStore } from "./client";
import { ClientAreaStore } from "./client-area";
import { ComboStore } from "./combo";
import { DeliveryStore } from "./delivery";
import { DeviceStore } from "./devices";
import { EmployeeStore } from "./employee";
import { EventStore } from "./event";
import { ExtractStore } from "./extract";
import { FiscalStore } from "./fiscal";
import { ImageStore } from "./images";
import { InvoiceStore } from "./invoice";
import { ItemStore } from "./item";
import { LiveStore } from "./live";
import { MetabaseStore } from "./metabase";
import { OrganizationStore } from "./organization";
import PlaceStore from "./place";
import { PlanAccountStore } from "./plan-account";
import { PrechargeStore } from "./precharge";
import { ProductFeatureStore } from "./product-feature";
import { PromotionStore } from "./promotion";
import { RelationStore } from "./relation";
import { ReserveStore } from "./reserve";
import { MessageStore } from "./message";
import { UiStore } from "./ui";
import { VendorStore } from "./vendor";
import { RoiStore } from "./roi";
import { WhatsUpStore } from "./whats-up";
import { PromoterStore } from "./promoter";
import { ZigTagStore } from "./zig-tag";
import { ChartOfAccountsStore } from "./chart-of-accounts";
import { PasswordCardStore } from "./password-card";
import { LoyaltyStore } from "./loyalty";
import { StorageStore } from "./storage";
import { RechargesStore } from "./recharges";
import { TableStore } from "./table";
import { HawkersStore } from "./hawkers";

export class RootStore {
	public liveStore = new LiveStore();
	public deviceStore = new DeviceStore();
	public routerStore = new RouterStore();
	public authStore = new AuthStore(this);
	public placeStore = new PlaceStore(this);
	public barStore = new BarStore(this);
	public itemStore = new ItemStore(this);
	public comboStore = new ComboStore(this);
	public promotionStore = new PromotionStore(this);
	public deliveryStore = new DeliveryStore(this);
	public vendorStore = new VendorStore(this);
	public cashierStore = new CashierStore(this);
	public prechargeStore = new PrechargeStore();
	public relationStore = new RelationStore(this);
	public employeeStore = new EmployeeStore();
	public eventStore = new EventStore(this);
	public organizationStore = new OrganizationStore(this);
	public clientStore = new ClientStore(this);
	public reserveStore = new ReserveStore(this);
	public imageStore = new ImageStore();
	public backofficeStore = new BackofficeStore(this);
	public planAccountStore = new PlanAccountStore(this);
	public CMVStore = new CMVStore(this);
	public uiStore = new UiStore(this);
	public fiscalStore = new FiscalStore(this);
	public invoiceStore = new InvoiceStore(this);
	public productFeatureStore = new ProductFeatureStore();
	public messageStore = new MessageStore(this);
	public roiStore = new RoiStore(this);
	public extractStore = new ExtractStore();
	public metabaseStore = new MetabaseStore(this);
	public whatsUpStore = new WhatsUpStore(this);
	public promoterStore = new PromoterStore(this);
	public zigTagStore = new ZigTagStore();
	public chartOfAccountsStore = new ChartOfAccountsStore(this);
	public passwordCardStore = new PasswordCardStore(this);
	public loyaltyStore = new LoyaltyStore(this);
	public storageStore = new StorageStore(this);
	public rechargesStore = new RechargesStore(this);
	public tableStore = new TableStore(this);
	public hawkersStore = new HawkersStore(this);
	public clientAreaStore = new ClientAreaStore();
}

export const rootStore = new RootStore();

const rootStoreContext = createContext(rootStore);

export const useStores = () => {
	return React.useContext(rootStoreContext);
};

export default rootStoreContext;
