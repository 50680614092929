export const awards = {
	description: "Selecione os produtos e os descontos que o cliente terá direito.",
	discountType: {
		error: "Os seguintes produtos possuem valor menor que o desconto dado",
		label: "Tipo do desconto",
		percentage: "Porcentagem",
		table: {
			add: "Adicionar produtos",
			empty: {
				description:
					"Caso não selecione produtos, a promoção funcionará em todos os produtos.",
				label: "Nenhum produto selecionado",
			},
			error: "Valor máximo e mínimo não corresponde",
			price: "Preço de venda",
			product: "Produto",
			promotionalPrice: "Preço promocional",
			total: "Total de {{count}} produto(s)",
		},
		value: "Valor",
	},
	maxUses: {
		label: "Quantidade máxima de usos por ganho",
		withoutLimits: "Sem limites",
	},
	title: "Prêmios",
};
