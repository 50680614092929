export const cmv = {
	details: {
		contributionMargin: "M. de Contrib.",
		cost: "Custo",
		editProductPrice: "Alterar Preço",
		markup: "Markup",
		productSimulator: "Simulador de CMV",
		realCost: "Custo Real",
		salePrice: "Preço de Venda",
	},
	searchPlaceholder: "Selecione um produto ou categoria",
	searchProduct: "Encontre um produto",
	supply: {
		columns: {
			input: "Insumo",
			loss: "Perda",
			quantity: "Qnt",
			realCost: "Custo Real",
			unitCost: "Custo Unitário",
		},
	},
};
