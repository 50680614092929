export const resume = {
	description:
		"Confira se todas as informações estão corretas e depois publique a promoção. Ela vai ser ativada automaticamente e já vai estar pronta para uso.",
	sections: {
		allProducts: "Todos os produtos",
		andMore: "e mais {{count}} produtos",
		awards: {
			discount: "Desconto(%) sobre o valor do produto",
			products: "Produtos do desconto",
			title: "Prêmios",
		},
		goals: {
			frequency: "Recorrência de ganhos",
			title: "Metas",
		},
		promotionInfo: {
			description: "Descrição",
			maxUses: {
				label: "Quantidades de usos por ganho",
				oneUse: "{{use}} uso",
				uses: "{{uses}} usos",
			},
			name: "Nome",
			places: "Locais",
			title: "Dados da promoção",
		},
		usageConditions: "Condições do uso",
	},
	title: "Resumo",
};
