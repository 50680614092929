export const entranceBilling = {
	tabs: {
		boxOfficeBilling: {
			title: "Faturamento de Bilheteria",
			tabLabel: "Faturamento",
		},
		entranceReport: {
			columns: {
				count: "Quantidade",
				discountValue: "Descontos",
				name: "Nome",
				subtotal: "Subtotal",
				totalValue: "Valor total",
				unitValue: "Valor unitário",
			},
			tabLabel: "Entradas vendidas",
			tabs: {
				total: "Total",
				clients: "Clientes por entrada",
			},
			title: "Total de entradas vendidas",
			description: "Relatório de entrada",
			printButton: "Imprimir relatório",
			exportButton: "Exportar relatório",
			selectPeriod: "Selecione o período",
			periodDescription: `Valores referentes aos período {{since}} - {{until}}`,
			cards: {
				totalBoxOffice: "Bilheteria total",
				entranceValue: "Valor de entrada",
				minimumConsumption: "Consumação mínima",
			},
			emptyText: "Nenhum dado encontrado",
		},
	},
};
