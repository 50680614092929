export const cmv = {
	details: {
		contributionMargin: "Contribution Margin",
		cost: "Cost",
		editProductPrice: "Change Price",
		markup: "Markup",
		productSimulator: "CMV Simulator",
		realCost: "Real Cost",
		salePrice: "Sale Price",
	},
	searchPlaceholder: "Select a product or category",
	searchProduct: "Find a product",
	supply: {
		columns: {
			input: "Input",
			loss: "Loss",
			quantity: "Qty",
			realCost: "Real Cost",
			unitCost: "Unit Cost",
		},
	},
};
