export const soldBaseProductReport = {
	emptyText: "Sem dados a serem exibidos.",
	filename: "relatorio_de-saida_geral_de_produtos",
	tabTitle: "Saída geral de produtos",
	table: {
		code: "SKU",
		count: "Quantidade",
		product: "Produto",
	},
};
