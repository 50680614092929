export const productFeatureTemplates = {
	activeTab: "product-feature-templates",
	form: {
		addProduct: {
			fields: "Fill in at least one field",
			isRequired: "This field is required",
			wrongFields: {
				field: "field",
				from: "from",
				label: "Fill in all data",
				of: "of",
			},
		},
		createModel: "Create model",
		editModel: "Edit Model",
		isRequired: "This field is required",
		templateFieldsContainer: {
			add: "Add field",
			field: "Field {{number}}",
			isRequired: "Is field {{number}} required?",
			label: "Model Fields",
			name: {
				label: "Field name {{number}}",
				placeholder: "Enter the field name {{number}}",
			},
			remove: "Remove field",
			type: {
				bool: "True/False",
				enum: "Options",
				label: "Field type {{number}}",
				number: "Number",
				placeholder: "Select field type",
				text: "Text",
			},
			value: {
				label: "Options for field {{number}}",
				placeholder: "Value {{count}} of field {{number}}",
			},
		},
		templateName: {
			label: "Model Name",
			placeholder: "Enter the template name",
		},
		templateObservation: {
			label: "Note",
			placeholder: "Enter some remark (optional)",
		},
	},
	header: {
		add: "Add Template",
	},
	modal: {
		add: "Add",
		edit: "Edit",
		title: "product functionality model",
	},
	templateList: {
		actions: {
			edit: "Edit Template",
			remove: "Remove Template",
			title:
				"Do you really want to remove the template? All functionality that uses it will be disabled as well.",
		},
		status: {
			title:
				"Do you really want to disable the template? All functionality that uses it will be disabled as well.",
		},
	},
	title: "Product Functionality Templates",
	tooltip: "Create templates with custom fields for your product",
};
