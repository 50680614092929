export const products = {
	bottomText: "All changes made here will affect all menus",
	error:
		"unable to intentify the corresponding product of {{name}}, download a new template sheet and be careful with the 'identifier' column",
	errorIn: "Error in ",
	header: {
		add: "Add products",
		delete: "Delete products",
		deleteConfirm: "Are you sure you want to delete all selected products?",
		edit: "Bulk edit products",
		massDelete: "Delete products in bulk",
		search: "Search Products",
	},
	success: "All products edited successfully",
	title: "Products",
};
