import { tableRangeSettings } from "./table-range-settings";

export const bars = {
	addBar: "Add bar",
	cancel: "Cancel",
	delete: "Delete",
	deleteBar: "Delete bar",
	deleteMessage: "Are you sure you want to delete this bar?",
	editBar: "Edit bar",
	emptyMessage: "No items to show",
	newBarButton: "New bar",
	search: "Search",
	searchBarPlaceholder: "Enter the name of the bar",
	searchProductPlaceholder: "Product's name",
	stock: "Inventory:",
	printerIps: "Printer ips",
	activeProducts: "Active Products",
	inactiveProducts: "Inactive Products",
	modal: {
		barName: "Bar name",
		barStock: "Bar stock",
		addNewIp: "Add a new ip",
		printerType: "Type",
		printerOffline: "Offline",
		numberOfLanes: "Number of lanes",
		logoFicha: "Logo on ficha",
		logoFichaInfo:
			"If you don't select a logo for the bar token, the logo printed on the token will be the location's logo.",
		errors: {
			printerTypeRequired: "Printer type field is required",
			printerNameRequired: "Printer name field is required",
			printerStockRequired: "Printer stock field is required",
			pagesToPrintMin: "The number of copies must be at least {{min}}",
			pagesToPrintMax: "The number of copies must be at most {{max}}",
			validIp: "The IP field must be valid",
		},
	},
	tableRangeSettings,
};
