export const flow = {
	emptyText: "There is nothing to show for the selected period",
	filename: "cash-flow-extract",
	table: {
		balance: "Balance",
		competency: "Transaction date",
		date: "Settlement date",
		title: "Description",
		value: "Value",
	},
	title: "Cash Flow Extract",
};
