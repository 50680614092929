export const helpers = {
	humanize: {
		paymentMethod: {
			ame: "Ame",
			anticipated: "Antecipado",
			app: "Aplicativo",
			automaticDebit: "Débito Automático",
			bankTransfer: "Transferência bancária",
			bleedings: "Sangria",
			boleto: "Boleto",
			bonus: "Bonus",
			cash: "Dinheiro",
			check: "Cheque",
			consumptionFromBar: "Consumação antecipada",
			creditCard: "Cartão de crédito",
			dashboard: "Dashboard",
			debitCard: "Cartão de débito",
			deliveryOnline: "Delivery Online",
			ifood: "Ifood",
			other: "Outro",
			pix: "Pix",
			postEvent: "Após o evento",
			promissory: "Nota promissória",
			rappi: "Rappi",
			uber: "Uber",
			voucher: "Voucher",
			zigCredit: "Zig crédito",
			zigDebit: "Zig débito",
			zigPosCredit: "Zig pós crédito",
			zigPosDebit: "Zig pós débito",
			zigPosCreditInInstallments: "Crédito parcelado",
			integratedFoodVoucher: "Vale-refeição",
		},
	},
	string: {
		grams: "Gramas",
		kilogram: "Kilogramas",
		liter: "Litros",
		lowercase: {
			grams: "gramas",
			kilogram: "kilogramas",
			liter: "litros",
			milliliter: "mililitros",
			unit: "unidades",
		},
		milliliter: "Mililitros",
		unit: "Unidades",
	},
};
