import { tableRangeSettings } from "./table-range-settings";

export const bars = {
	addBar: "Adicionar bar",
	cancel: "Cancelar",
	delete: "Apagar",
	deleteBar: "Deletar Bar",
	deleteMessage: "Tem certeza que deseja apagar esse bar?",
	editBar: "Editar bar",
	emptyMessage: "Sem itens para mostrar",
	newBarButton: "Novo bar",
	search: "Pesquisar",
	searchBarPlaceholder: "Insira o nome do bar",
	searchProductPlaceholder: "Nome do produto",
	stock: "Estoque:",
	printerIps: "Ips da impressora",
	activeProducts: "Produtos Ativos",
	inactiveProducts: "Produtos Inativos",
	modal: {
		barName: "Nome do bar",
		barStock: "Estoque do bar",
		addNewIp: "Adicionar um novo ip",
		printerType: "Tipo",
		printerOffline: "Offline",
		numberOfLanes: "Nº de vias",
		logoFicha: "Logo na ficha",
		logoFichaInfo:
			"Caso não selecione uma logo pra ficha no bar, a logo impressa na ficha será a do local.",
		errors: {
			printerTypeRequired: "O campo tipo de impressora é obrigatório",
			printerNameRequired: "Campo nome obrigatório",
			printerStockRequired: "Campo estoque obrigatório",
			pagesToPrintMin: "O campo Nº de vias deve ser de no mínimo {{min}}",
			pagesToPrintMax: "O campo Nº de vias deve ser no máximo {{max}}",
			validIp: "O campo de ip deve ser válido",
		},
	},
	tableRangeSettings,
};
