export const planOfAccounts = {
	chooseName: "Escolha um nome!",
	createNewAccount: "Criar nova conta",
	createSuggestedPlan: "Usar plano de contas sugerido",
	delete: "Apagar Plano de Contas",
	deleteBillPlanConfirmDialog: 'Tem certeza que deseja deletar a conta "{{name}}"?',
	deleteBillPlanSuccessNotification: "Conta {{name}} apagada com sucesso",
	deleteConfirmDialog: "Tem certeza que deseja apagar o plano de contas?",
	deleteSuccessNotification: "Plano de contas apagado.",
	downloadTemplate: "Baixar Planilha Modelo",
	editPlanOfAccountsSuccess: "Plano de contas editado com sucesso.",
	enter: "enter",
	errorNotification: "Envio do arquivo {{name}} falhou",
	esc: "esc",
	existingName: "Nome já existente",
	export: "Exportar Planilha",
	hasBillsMessage: "Essa conta já possui transações associadas a ela.",
	importSuccessNotification: "Plano de contas importado com sucesso.",
	name: "Nome",
	onChangeSuccessNotification: "Arquivo {{name}} enviado com sucesso",
	parent: "Pai da categoria",
	parentPlaceholder: "Selecionar um novo pai...",
	showTree: "Visualizar árvore do plano de contas",
	showTreeTitle: "Árvore do plano de contas",
};
