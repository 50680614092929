export const supplies = {
	pageTitle: "Inputs",
	pageDescription:
		"Inputs are all the materials that are used for the production of a certain product or in the creation of another input. On this screen, it is possible to register and view all registered inputs in one place.",
	searchPlaceholder: "Search inputs",
	table: {
		name: "Name",
		productionRule: "Recipes and production",
		hasProductionRule: "Do you have a technical sheet?",
		actions: "Actions",
		clickToEdit: "Click to edit",
		sku: "SKU",
		yieldPercentage: "Yield",
		links: "Products and inputs link",
		stocks: "Stocks",
	},
	supplyActionModal: {
		title: {
			supply: "Supply links",
			stock: "Stock links",
		},
		description: {
			supply: "List of records to which this item is linked",
			stock: "List of stocks where the product is present",
		},
		seeProductButton: "See product",
		seeStockButton: "See stock",
	},
	deleteWarning: "Are you sure you want to delete this item?",
	addSupplies: "Add input",
	addSuppliesModal: {
		errorMessage: "This field is mandatory",
		name: "Name",
		unitMeasure: "Unit of measurement",
		units: "Units",
		yieldPercentage: "Yield",
	},
	cancel: "Cancel",
	conclude: "Conclude",
	delete: "Delete",
	edit: "To edit",
	editSupplies: "Edit input",
	erase: "Delete",
	exportToExcel: "Export to excel",
	importModal: {
		buttonText: "Choose a worksheet",
		conclude: "Conclude",
		sheetFileName: "Inputs",
		title: "Import input",
	},
	importSupplies: "Import inputs",
	no: " No",
	noCaps: "NO",
	productRule: {
		addAnotherSupply: "Add another input",
		form: {
			loss: "Loss",
			product: "Product",
			quantity: "The amount",
		},
		generatedQuantity: "Generated quantity",
		hasProductionRuleError:
			"Attention, this data sheet is in error. Remove the datasheet and add it again.",
		title: "Datasheet - {{productName}}",
		tooltipMessage:
			"To change the amount generated, it is necessary to remove the data sheet and create it again.",
		warnings: {
			emptyQuantity: "Add the amount of input",
			emptySupplies: "Select an input",
			repeatEntry: "You added repeated inputs",
		},
	},
	remove: "To remove",
	suppliesErrorsTableColumns: {
		supply: "Supply",
		error: "Error",
	},
	step: {
		supply: "Supply",
		ingredients: "Ingredients",
	},
	addInputModal: {
		title: "Add supply",
		supply: "Supply or product",
		name: "Name",
		placeholderName: "Enter the name of the supply",
		sku: "SKU",
		placeholderSKU: "Enter the SKU",
		image: "Photo",
		descriptionImage: "Square image or 500x500 pixels",
		yieldPercentage: "Yield",
		basicStorageUnit: "Unit of measure",
		placeholderBasicStorageUnit: "Enter the unit of measure",
		aboutIngredients: "About ingredients",
		ingredientsInfo:
			"Fill in the ingredient fields only if the product is a recipe that uses other supplies. For example, tomato sauce needs ingredients, but tomato does not.",
		selectInput: "Select a supply or product",
		quantity: "Quantity",
		loss: "Loss",
		addIngredient: "Add ingredient",
	},
	yes: "Yes",
	yesCaps: "YES",
	link: "Link",
	links: "Links",
	deleteSelectedSupplies: "Delete selected supplies",
	deleteSelectedItens: "Delete selected items",
	deleteSupply: "Delete supply",
	deleteItems: "Delete items",
	importSupply: "Import supply",
	import: "Import",
	confirmDeleteMessage: {
		multiple:
			"By confirming the action, you permanently delete {{quantity}} supplies. Do you wish to continue?",
		single:
			"By confirming the action, you permanently delete the supply {{supply}}. Do you wish to continue?",
	},
	viewRules: "See recipes",
	doesNotHave: "Doeas not have",
	copiedSuccessfully: "Copied successfully",
	cannotDeleteSupply: "Cannot delete because the input is linked to other products",
};
