export const storageAlerts = {
	addAlertModal: {
		add: "Criar alerta",
		invalidText:
			"O valor de estoque em alerta não pode ser menor ou igual ao valor de estoque critico",
		storageAlert: {
			below: "abaixo de",
			label: "Alerta",
			storageIn: "Estoque em",
		},
		storageCritic: {
			below: "abaixo de",
			label: "Crítico",
			storage: "Estoque",
		},
		title: "Adicionar novo alerta",
	},
	addEmailModal: {
		email: "Email",
		emptyText: "Sem emails cadastrados",
		name: "Nome",
		title: "Configurar recebimento de alertas por email",
	},
	btnAddEmail: "Configurar recebimento de alerta",
	btnAddProductAlert: "Adicionar alerta para um produto",
	columns: {
		actions: "Ações",
		product: "Produto",
		storageAlert: {
			from: "a partir de",
			label: "Alerta",
			storageIn: "Estoque em",
		},
		storageCritic: {
			from: "a partir de",
			label: "Crítico",
			storage: "Estoque",
		},
		storageQuantity: "Estoque atual",
	},
	filters: {
		ok: "Ok",
		storageAlert: "Estado de alerta",
		storageCritic: "Estado crítico",
	},
	label: "Alertas de estoque",
	search: "Pesquisar produtos",
};
