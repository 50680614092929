import { MessageTemplateStatus } from "#resources/api/enterprise-generated";

export const modalMyModels = {
	columns: {
		model: "Modelo",
		status: "Status",
	},
	emptyState: {
		text:
			"Solicite um novo template para enviar o mensagens de WhatsApp para seus clientes",
		title: "Nenhum template cadastrado",
	},
	requestNewModel: "Solicitar novo modelo",
	status: {
		approved: "Aprovado",
		pending: "Processando",
		received: "Recebido",
		rejected: "Recusado",
	} as Record<MessageTemplateStatus, string>,
	title: "Meus modelos",
};
