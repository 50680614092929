export const transferForm = {
	balanceAvailable: "Saldo disponível",
	enoughBalanceMessage: "Saldo disponível para transferência",
	loadingTargetPlaceData: "Carregando dados do place de destino...",
	reviewLabels: {
		balanceAfterTransferAtSourcePlace: "Saldo após transferência no place de origem",
		balanceAfterTransferAtTargetPlace: "Saldo após transferência no place de destino",
		from: "De",
		to: "Para",
		value: "Valor",
	},
	selectPlaceLabel: "Selecione o place de destino",
	selectPlacePlaceholder: "Selecione o place que receberá a transferência",
	submitFailedMessage: "Não foi possível transferir o valor",
	submitSucceededMessage: "Valor transferido com sucesso",
	transferTotalValueBtn: "Transferir todo saldo disponível",
	wizardLabels: {
		review: "Revisão dos dados",
		target: "Destino",
		value: "Valor da transferência",
	},
};
