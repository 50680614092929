export const xlsxPicker = {
	cell: {
		falseHasAlcohol: "FALSO",
		trueHasAlcohol: "VERDADEIRO",
	},
	columns: {
		bars: "Bares",
		category: "Categoria",
		cest: "Fiscal - CEST",
		description: "Descrição",
		fiscalCode: "SKU",
		fiscalProductGroup: "Fiscal - Grupo fiscal",
		fiscalProfile: "Fiscal - Perfil fiscal",
		hasAlcohol: "Contém álcool?",
		id: "Id do produto",
		image: "Imagem",
		kind: "Tipo de produto",
		menuCategory: "Categoria do menu",
		name: "Nome",
		ncm: "Fiscal - NCM",
		price: "Preço",
		products: "produtos",
		variablePrice: "Preço variável",
		beerBrand: "Marca da cerveja",
		beerStyle: "Estilo da cerveja",
		beerAbv: "ABV - Teor alcoólico",
	},
	sheetDownload: "Baixar tabela exemplo",
	sheetFeedback: {
		errorMessage:
			"Planilha inválida, baixe-a novamente e faça as alterações necessárias.",
		successMessage: "Planílha válida!",
	},
	sheetHumanizeErrorMessage: {
		default: "Ocorreu um erro ao importar a planilha",
		emptySheetRows:
			"Essa planilha está com linhas vazias, por favor remova as linhas vazias.",
		emptyHeaderCell: "Falta nome nas colunas",
		emptySheet: "Essa planilha está vazia",
		sheetPoorlyFormatted:
			"Formate a planilha para fonte Calibri, tamanho 11, remova os espaços, quebras de linhas desnecessárias e negrito",
		unidentifieldColumn: "Erro nos nomes das colunas",
		verifyBaseSheet:
			", por favor verifique o modelo da planilha exemplo ou utilize-a como planilha base.",
	},
	sheetNameExample: "(exemplo)",
	sheetNameInvalid: "(inválida)",
	sheetXlsxPicker: "Escolha uma planilha",
};
