export const invoicecCardInfo = {
	CFOP: "CFOP",
	CNPJAndCPF: "CNPJ/CPF",
	accesskey: "Chave de acesso",
	amount: "Valor total",
	billNumber: "Número da nota",
	issuer: "Emitente",
	natureOfOperation: "Natureza da operação",
	noteData: "Data da nota",
	noteSeries: "Série da nota",
	title: "Dados da nota fiscal",
};
