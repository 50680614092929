export const closings = {
	calendar: {
		toReceive: "A receber",
		totalSoldValue: "Valor total",
	},
	choosePeriod: "Escolha período para exportar planilha",
	download: "Baixa planilha",
	modal: {
		details: "Detalhes do dia",
		eventDate: "Data do evento",
		fixedFee: "Taxa de aluguel fixo",
		generalFeeValue: "Taxa variável geral",
		generalPercentageFee: "Taxa variável sobre faturamento",
		otherProductsFeeValue: "Taxa variável sobre produtos especiais",
		payDate: "Data de repasse",
		totalSoldValue: "Faturamento total",
		vendorName: "Vendor",
		vendorValueToReceive: "Valor a receber",
	},
	sheet: {
		columns: {
			eventDate: "Data do evento",
			fixedFee: "Taxa de aluguel fixa",
			generalFeeValue: "Taxa variável geral",
			generalPercentageFee: "Taxa variável sobre faturamento",
			otherProductsFeeValue: "Taxa variável sobre produtos especiais",
			payDate: "Data de repasse",
			totalSoldValue: "Faturamento total",
			vendorName: "vendor",
			vendorValueToReceive: "Valor a receber",
		},
		filename: "fechamento-de-vendors",
		title: "Fechamento de vendors",
	},
	title: "Fechamento por vendor",
};
