export const invoices = {
	descriptionForeign:
		"This report displays the issued Invoices and Credit Notes, allowing filtering by period, fiscal profile, employee, invoice type (Invoice and Credit Note), and item type (Recharge or Product).",
	title: "Invoices",
	period: "Period",
	downloadXML: "Download XML",
	downloadJSON: "Download JSON",
	modelLabel: {
		nfce: "NFC-e",
		nfse: "NFS-e",
		sat: "SAT",
		ivaVendus: "IVA - Vendus",
		ivaFactura: "IVA - Factura",
	},
	sheet: {
		title: "Invoices",
		filename: "invoices",
		columns: {
			issuedAt: "Issued on",
			mode: "Emission Mode",
			fiscalProfile: "Fiscal Profile",
			isCanceled: "Canceled",
			transaction: "Transaction",
			recharge: "Recarga",
			employee: "Employee",
			eventDate: "Event Date",
			serie: "Note series",
			number: "Note number",
			value: "Note value",
			imgUrl: "URL Image",
			pdfUrl: "PDF URL",
			xml: "XML",
			json: "JSON",
		},
	},
	columns: {
		issuedAt: "Issued on",
		mode: "Emission Mode",
		fiscalProfile: "Fiscal Profile",
		isCanceled: "Canceled",
		type: "Type",
		eventDate: "Event Date",
		serie: "Note series",
		transaction: "Transaction",
		recharge: "Recarga",
		employee: "Employee",
		errorMsg: "Error",
		number: "Note number",
		value: "Note value",
		status: {
			label: "Status",
			pending: "Pending creation",
			waiting: "Pending issuance",
		},
		expirationDate: "Expiration Date",
		tooltips: {
			nc: "Credit Note / Canceled Note",
			ft: "Invoice / Invoices",
		},
		actions: {
			title: "Actions",
			pdf: "PDF",
			image: "Image",
			xml: "XML",
			cancelledXml: "Cancelled XML",
			json: "JSON",
			cancelledJson: "Cancelled JSON",
			cancelUnnavailable: {
				okText: "Ok",
				title: "Invoices issued more than 30 minutes ago cannot be canceled",
			},
			cancel: {
				okText: "Yes, cancel invoice",
				title: "Are you sure you want to cancel the invoice?",
			},
		},
	},
	missing: {
		title: "Missing Notes",
		titleForeing: "Pending Notes",
		period: "Period",
		fiscalProfile: "Fiscal Profile",
		selectFiscalProfile: "Select a tax profile",
		fiscalProfileLabel: "Filter by fiscal profile",
		employeeLabel: "Filter by employee",
		invoiceTypeLabel: "Filter document type",
		pendingAutoissuanceOrders: "Total gross in pending",
		zigSystemEmployee: "ZIG (system)",
		documentId: "Identifier",
		creditInvoiceLabel: "Credit Notes",
		itemTypeLabel: "Filter item type",
		recharges: "Recharges",
		products: "Products",
		invoiceLabel: "Invoices",
		download: "Download XML",
		descriptionForeign:
			"This report shows all pending Notes, meaning those that have reached the system but have not been issued for some reason. It allows viewing the error message associated with each Pending Note.",
		descriptionForeignMEX:
			"This report shows all pending Notes, meaning 1) those that have reached the system but have not been issued for some reason and 2) those that autoissuance order was created and is waiting for the client to issue. It allows viewing the error message associated with each Pending Note.",
		columns: {
			serie: "Series",
			number: "Number",
			employee: "Employee",
			date: "Date",
			actions: "Actions",
		},
	},
	downloadBackupModal: {
		title: "Download XML Files",
		placeholder: "Select",
		error: "Error: {{error}}",
		empty: "No backup available",
		content:
			"To download this number of files, it's necessary to download the entire month.",
		selectMonth: "Select below the reference month for download.",
	},
	filters: {
		zigSystemEmployee: "ZIG (system)",
		fiscalProfileLabel: "Filter by fiscal profile",
		employeeLabel: "Filter by employee",
		invoiceItemType: "Filter by item type",
		invoiceItemTypeProduct: "Products",
		invoiceItemTypeRecharge: "Recharges",
		invoiceType: "Filter by invoice type",
		invoiceTypeAll: "All invoices",
		invoiceTypeWithCreditInvoice: "Invoices with Credit Invoices",
		invoiceTypeWithoutCreditInvoice: "Invoices without Credit Invoices",
	},
};
