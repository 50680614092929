export const smartposConfig = {
	newAutoBar: "Novo AutoBar",
	smartpos: "SMARTPOS",
	generalConfigs: "Configurações gerais",
	autoBarCustomization: "Personalização do AutoBar",
	logoDescription:
		"Selecione o logo Zig que melhor se aplica na sua imagem. Dica: Imagens com fundo claro ficam melhores com a logo preta. Imagens com fundo escuro, com a logo branca.",
	submit: "Salvar configurações",
	notificationInvalidField:
		"Algumas informações obrigatórias não foram preenchidas. Preencha-as para poder salvar suas configurações.",
	notificationSuccess: "Suas alterações foram salvas.",
	formSections: {
		choppCharacteristics: {
			title: "1. Características do Chopp",
			description: "Preencha de acordo com as especificações do chopp.",
		},
		initialScreen: {
			title: "2. Tela Inicial",
			description:
				"Escolha uma imagem que capture a essência da marca e a mensagem que deseja transmitir aos clientes. É muito importante que também apareça o nome ou logo do produto nessa imagem.",
		},
		productLogo: {
			title: "3. Logo do Produto",
			description:
				"Carregue uma imagem com a logo do seu produto para personalizar a tela de compras e do dispenser da bebida. Dica: utilize uma imagem com fundo transparente ou branco.",
		},
		primaryColor: {
			title: "4. Cor primária",
			description: "Escolha uma cor para ser usada nos botões e ações primárias.",
		},
	},
	fields: {
		beerType: {
			label: "Selecione o tipo de bebida:",
		},
		autoBarName: {
			label: "Nome do Autobar",
			placeholder: "Digite o nome do AutoBar",
		},
		menu: {
			label: "Cardápio vinculado",
			placeholder: "Selecione o cardápio vinculado",
		},
		connectionType: {
			label: "Tipo de conexão",
			placeholder: "Selecione o tipo de conexão",
		},
		partner: {
			label: "Parceiro",
			placeholder: "Selecione o parceiro",
		},
		choppName: {
			label: "Nome do chopp",
			placeholder: "Digite o nome do chopp",
		},
		choppStyle: {
			label: "Estilo",
			placeholder: "Ex.: Lager",
			tooltip:
				"O estilo de cerveja é a categorização da bebida com base em características como ingredientes, produção, sabor, cor, teor alcoólico e origem, facilitando a escolha e comparação entre diferentes tipos. Ex.: Larger.",
		},
		ibu: {
			label: "IBU",
			placeholder: "Ex.: 25",
			tooltip:
				"O IBU é uma sigla internacional que significa International Bitter Unit, que pode ser traduzido comunidade internacional de amargor. Ex.: 25",
		},
		alcoholContent: {
			label: "Teor alcóolico%",
			placeholder: "Ex.: 5",
			tooltip:
				"Teor alcóolico trata da quantidade de álcool que uma bebida tem. Ex.: 5%.",
		},
		choppDescription: {
			label: "Descrição do Chopp",
			placeholder: "Crie uma descrição de até 100 caracteres",
		},
		dontAddInitialScreen: {
			label: "Não adicionar tela inicial.",
		},
		initialScreenImage: {
			placeholder: "1280x780 pixels (.PNG ou .JPG)",
		},
		productImage: {
			placeholder: "1280x780 pixels (.PNG ou .JPG)",
		},
		primaryColor: {
			label: "Cor primária",
		},
	},
	cropImageModal: {
		title: "Adicionar imagem",
		okText: "Salvar imagem",
	},
	preview: {
		title: "Preview",
		needHelp: "Preciso de ajuda",
		language: "Idioma",
		initialScreen: {
			touchToStart: "TOQUE NA TELA PARA INICIAR",
		},
		productScreen: {
			productName: "Nome do Produto",
			choppType: "CHOPP TYPE",
			withdrawalCode: "Informar código de retirada",
			pay: "Pagar {{price}}",
		},
		choppScreen: {
			title: "Você tem 3 chopps(s).",
			subtitle: "Libere a chopeira e aguarde finalizar.",
			cta: "Liberar chopeira",
		},
	},
	confirmationScenarios: {
		submitWithNoCustomization: {
			title: "Personalização",
			subtitle:
				"Você não personalizou o seu AutoBar. Caso deseje seguir sem personalizar, a cor primária e as imagens da Zig. estarão nas telas inicial, do produto e do dispenser do seu AutoBar. Deseja continuar assim mesmo?",
			confirmActionText: "Personalizar AutoBar",
			cancelActionText: "Salvar sem personalizar",
		},
		delete: {
			title: "Excluir AutoBar",
			subtitle:
				"Você tem certeza que deseja excluir este AutoBar? Suas alterações serão descartadas permanentemente. Deseja continuar assim mesmo?",
			confirmActionText: "Excluir AutoBar",
		},
		goBack: {
			title: "Descartar alterações",
			subtitle:
				"Suas alterações serão descartadas permanentemente. Deseja continuar assim mesmo?",
			confirmActionText: "Descartar alterações",
		},
	},
};
