export const chipsBurned = {
	byEmployee: {
		filename: "chips-burned-employee",
		printTitle: "Chips burned by employee",
		tabTitle: "Chips burned by employee",
	},
	fileName: "total-chips-burned",
	printTitle: "Total chips burned",
	tabTitle: "Total chips burnd",
	table: {
		count: "Amount",
		fracionalCount: "Fract. amount",
		name: "Name",
		product: "Product",
		totalValue: "Total",
		unitCount: "Unit quantity",
		unitValue: "Unit value",
		unitaryValue: "Unitary value",
	},
	title: "Total chips burned",
};
