export const awards = {
	description: "Select the products and discounts that the customer will be entitled to.",
	discountType: {
		error: "The following products are worth less than the discount given",
		label: "Discount type",
		percentage: "Percentage",
		table: {
			add: "Add products",
			empty: {
				description:
					"If you do not select products, the promotion will work on all products.",
				label: "No products selected",
			},
			error: "Maximum and minimum value does not match",
			price: "Sale price",
			product: "Product",
			promotionalPrice: "Promotional price",
			total: "Total of {{count}} product(s)",
		},
		value: "Value",
	},
	maxUses: {
		label: "Maximum number of uses per gain",
		withoutLimits: "No Boundaries",
	},
	title: "Awards",
};
