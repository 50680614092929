export const planOfAccounts = {
	chooseName: "Choose a name!",
	createNewAccount: "Create new account",
	createSuggestedPlan: "Use suggested Plan of accounts",
	delete: "Delete Plan of Accounts",
	deleteBillPlanConfirmDialog: 'Are you sure you want to delete the account "{{name}}"?',
	deleteBillPlanSuccessNotification: "Account {{name}} successfully deleted",
	deleteConfirmDialog: "Are you sure you want to delete the Plan of accounts?",
	deleteSuccessNotification: "Plan of accounts deleted.",
	downloadTemplate: "Download Template Spreadsheet",
	editPlanOfAccountsSuccess: "Plan of accounts successfully edited.",
	enter: "enter",
	errorNotification: "File {{name}} upload failed",
	esc: "esc",
	existingName: "Name already existing",
	export: "Export Spreadsheet",
	hasBillsMessage: "This account already has transactions associated with it.",
	importSuccessNotification: "Plan of accounts imported successfully.",
	name: "Name",
	onChangeSuccessNotification: "File {{name}} successfully uploaded",
	parent: "Category parent",
	parentPlaceholder: "Select a new parent...",
	showTree: "View Plan of accounts tree",
	showTreeTitle: "Plan of Accounts tree",
};
