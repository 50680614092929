export const chipsBurned = {
	byEmployee: {
		label: "Chips burned by employee",
		sheetFileName: "chips-burned-employee",
	},
	columns: {
		count: "Amount",
		discount: "Discounts",
		fracionalCount: {
			grams: "grams",
			kg: "Kilos",
			label: "Fracional amount",
			lt: "Liters",
		},
		name: "Name",
		subtotal: "Subtotal",
		totalValue: "Total",
		unitaryCount: "Unit quantity",
		unitaryValue: "Unitary value",
		type: "Type",
	},
	label: "Total chips burned",
	loading: "Generating spreadsheet",
	mountableModal: {
		count: "Amount",
		name: "Name",
		section: "Mountable session",
	},
	mountables: {
		category: "Category",
		count: "Amount",
		fiscalCode: "SKU",
		label: "Mountables",
		name: "Mountable item",
		product: "Product",
		section: "Mountable step",
	},
	sheetFileName: "total-chips-burned",
	since: "Since",
	total: "TOTAL",
	totalItem: "Total",
	until: "Until",
};
