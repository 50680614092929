export const resume = {
	description:
		"Check that all the information is correct and then publish the promotion. It will automatically activate and be ready to use.",
	sections: {
		allProducts: "All products",
		andMore: "and more {{count}} products",
		awards: {
			discount: "Discount(%) on the value of the product",
			products: "Discount products",
			title: "Awards",
		},
		goals: {
			frequency: "Recurrence of earnings",
			title: "Goals",
		},
		promotionInfo: {
			description: "Description",
			maxUses: {
				label: "Quantities of uses per gain",
				oneUse: "{{use}} use",
				uses: "{{uses}} uses",
			},
			name: "Name",
			places: "Locations",
			title: "Promotion data",
		},
		usageConditions: "Conditions of use",
	},
	title: "Summary",
};
