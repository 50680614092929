import { action, observable } from "mobx";
import { RootStore } from "./";
import { ICategoriesCMVFilter } from "#pages/place/cmv/types";
import {
	Category,
	CmvReport,
	CmvReportResult,
	CmvReportType,
	CostOfGoodsSold,
	CostOfGoodsSoldSupplies,
	PlaceProduct,
	ProductWithoutCostAtStorage,
	UpdatedProductWithoutCostAtStorage,
} from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import { fetchModel } from "#resources/helpers/fetch-model";
import { stringNumberParser } from "#resources/helpers/string";
import { middleDay } from "#resources/helpers/middle-day";
import i18n from "#i18n/index";

export interface CostOfGoodsSoldSuppliesEnhanced
	extends Omit<CostOfGoodsSoldSupplies, "unitCost" | "realCost"> {
	unitCost: number | null;
	realCost: number | null;

	unitCostFloat: number;
	lossCostFloat: number;
	totalCostFloat: number;
}

export interface CostOfGoodsSoldEnhanced
	extends Omit<CostOfGoodsSold, "productUnitCost" | "productRealCost" | "supplies"> {
	productUnitCost: number | null;
	productRealCost: number | null;

	// productUnitCostFloat: number;
	// productLossCostFloat: number;
	// productTotalCostFloat: number;

	supplies: CostOfGoodsSoldSuppliesEnhanced[];
}

const t = i18n.t;

export class CMVStore {
	protected rootStore: RootStore;

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.getCmvReportAtPlace.reset();
		this.isProductsCategoryLoading = false;
	}

	@observable
	public productsCategory: Category[] = [];

	@observable
	public isProductsCategoryLoading = false;

	@observable
	public productsByPlace: PlaceProduct[] = [];

	@observable
	public cmvProductsFilter: ICategoriesCMVFilter[] = [];

	@action
	public getCategories = async () => {
		this.isProductsCategoryLoading = true;
		try {
			this.productsCategory = await enterprise.getCategories();
		} catch (err) {
			throw new Error(t("store:cmvStore.error.getCategories"));
		} finally {
			this.isProductsCategoryLoading = false;
		}
	};

	@action
	public getProductsByPlace = async (placeId: string) => {
		try {
			this.productsByPlace = await enterprise.getPlaceProducts({ placeId });
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			throw new Error(t("store:cmvStore.error.getCategories"));
		}
	};

	@action
	public ProductsInCategory = () => {
		this.cmvProductsFilter = this.productsCategory
			.reduce(
				(acCat, currCat) => [
					...acCat,
					{
						id: currCat.id,
						name: currCat.name,
						products: [],
					},
					...currCat.subCategories.reduce(
						(acSubCat, currSubCat) => [
							...acSubCat,
							{
								id: currSubCat.id,
								name: currSubCat.name,
								products: [],
							},
							...currSubCat.subCategories.reduce(
								(acSubSubCat, currSubSubCat) => [
									...acSubSubCat,
									{
										id: currSubSubCat.id,
										name: currSubSubCat.name,
										products: [],
									},
								],
								[],
							),
						],
						[],
					),
				],
				[],
			)
			.map(cat => ({
				...cat,
				products: this.productsByPlace
					.filter(prod => cat.id === prod.categoryId && prod.type === "Product")
					.reduce(
						(acProd, currProd) => [
							...acProd,
							{
								categoryId: currProd.categoryId,
								id: currProd.id!,
								name: currProd.name,
							},
						],
						[],
					),
			}))
			.filter(cat => cat.products.length);
	};

	public getCostOfGoodsSoldByProductList = new fetchModel<
		{ placeId: string; productIds: string[] },
		CostOfGoodsSold[],
		CostOfGoodsSoldEnhanced[]
	>({
		fnPromise: args => enterprise.getCostOfGoodsSoldByProductList(args),
		onError: err => showErrorNotification(err.message),
		transform: value =>
			value.map(prod => {
				const productUnitCost = stringNumberParser(prod.productUnitCost);
				const productRealCost = stringNumberParser(prod.productRealCost);

				// const prodUnitCostFloat = parserNumberWithFixed4Decimals(prod.productUnitCost || "");
				// const prodLossCostFloat = parserNumberWithFixed4Decimals(prod.productRealCost || "");

				return {
					...prod,
					productRealCost:
						prod.productRealCost === null
							? prod.productUnitCost === null
								? null
								: productUnitCost
							: productRealCost,
					productUnitCost: prod.productUnitCost === null ? null : productUnitCost,

					// productUnitCostFloat: prodUnitCostFloat,
					// productLossCostFloat: prodLossCostFloat,
					// productTotalCostFloat: prodUnitCostFloat + prodLossCostFloat,

					supplies: prod.supplies.map(sup => {
						const unitCost = stringNumberParser(sup.unitCost);
						const realCost = stringNumberParser(sup.realCost);

						const unitCostFloat = Number(sup.unitCost);
						const lossCostFloat = Number(sup.realCost);

						return {
							...sup,
							lossCostFloat: lossCostFloat,
							realCost:
								sup.realCost === null
									? sup.unitCost === null
										? null
										: unitCost
									: realCost,
							totalCostFloat: lossCostFloat + unitCostFloat * sup.quantity,
							unitCost: sup.unitCost === null ? null : unitCost,
							unitCostFloat: unitCostFloat,
						};
					}),
				};
			}),
	});

	public getCmvReportAtPlace = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		CmvReport[]
	>({
		fnPromise: args =>
			enterprise.getCmvReportAtPlace({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public fetchPlaceProducts = new fetchModel<
		{
			placeId: string;
		},
		PlaceProduct[]
	>({
		fnPromise: args => enterprise.getPlaceProducts({ placeId: args.placeId }),
		onError: err => showErrorNotification(err.message),
	});

	public getCmvReport = new fetchModel<
		{ placeId: string; since: Date; until: Date; type: CmvReportType },
		CmvReportResult
	>({
		fnPromise: args => enterprise.getCmvReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public exportCmvReport = new fetchModel<
		{ placeId: string; since: Date; until: Date; type: CmvReportType },
		string
	>({
		fnPromise: args => enterprise.exportCmvReport(args),
		onError: err => showErrorNotification(err.message),
	});

	public getProductsWithoutRetroUnitCostFilled = new fetchModel<
		{ placeId: string; since: Date; until: Date },
		ProductWithoutCostAtStorage[]
	>({
		fnPromise: args => enterprise.getProductsWithoutRetroUnitCostFilled(args),
		onError: err => showErrorNotification(err.message),
	});

	public updateProductsWithoutUnitCostOnSells = new fetchModel<
		{
			placeId: string;
			products: UpdatedProductWithoutCostAtStorage[];
			successMessage?: string;
		},
		void
	>({
		fnPromise: args =>
			enterprise.updateProductsWithoutUnitCostOnSells({
				placeId: args.placeId,
				products: args.products,
			}),
		onSuccess: (_value, args) => {
			if (args.successMessage) {
				showSuccessNotification(args.successMessage);
			}
		},
		onError: err => showErrorNotification(err.message),
	});
}
