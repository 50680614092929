export const multivendor = {
	closings: {
		columns: {
			eventDate: "Event date",
			fixedFee: "Fixed rental fee",
			generalFeeValue: "General variable fee",
			generalPercentageFee: "Variable fee on billing",
			otherProductsFeeValue: "Variable fee on special products",
			payDate: "Pay date",
			totalSoldValue: "Total billing",
			vendorName: "Vendor",
			vendorValueToReceive: "Amount receivable",
		},
		emptyText: "No data to display.",
		filename: "closing-by-vendor",
		title: "Closing by vendor",
	},
	productsSold: {
		title: "Products sold by vendor",
	},
	revenues: {
		description: "Multivendor reports of each vendor",
		title: "Billing by vendor",
		filename: "billing_by_vendor",
		columns: {
			name: "Vendor",
			total: "Total",
			totalSoldValue: "Products sold",
			totalTipValue: "Service",
			subtotal: "Subtotal",
			rent: "Rent",
			revenue: "Revenue",
		},
	},
};
