import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import { EVENT_KEYS } from "./keys";
import enterprise from "#resources/api/enterprise-client";
import {
	AllOfflineActionTypes,
	EventList,
	FiltersActionsFromEvent,
	Pagination,
	ReportActionsFromEventResponse,
	ReportPdvZigCardFilters,
	ReportPdvZigCardReport,
} from "#resources/api/enterprise-generated";
import { SdkgenError } from "@sdkgen/browser-runtime";
import { showErrorNotification } from "#resources/helpers/notifications";
import { EnterpriseApiSignature } from "#resources/helpers/api";
import { createQueryFactory } from "../query-factory";

type GetEventsSignature = EnterpriseApiSignature<"getEvents">;
type UseGetEventsOptions = Omit<
	UseQueryOptions<GetEventsSignature["result"], SdkgenError>,
	"queryKey"
>;

export interface IGetAllEventActionsProps {
	eventId: string;
	pagination: Pagination;
	filters: FiltersActionsFromEvent;
}

export const useGetEvents = <TData = EventList>(
	params: GetEventsSignature["args"],
	options?: UseGetEventsOptions,
	select?: (data: EventList) => TData,
) => {
	const { placeId, month, year } = params;

	return useQuery({
		...options,
		queryKey: EVENT_KEYS.getEventsMonthYear(placeId, `${month}/${year}`),
		queryFn: () => enterprise.getEvents({ placeId, month, year }),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
		select,
	});
};

export const useHasOpenedEvent = (placeId: string) => {
	const month = new Date().getMonth();
	const year = new Date().getFullYear();

	return useGetEvents({ placeId, month, year }, {}, eventList => {
		const hasOpenedEvent = eventList.events.find(event => event.status === "Running");

		return Boolean(hasOpenedEvent);
	});
};

export const useGetAllEventActions = (args: IGetAllEventActionsProps) => {
	return useQuery<ReportActionsFromEventResponse, SdkgenError>({
		queryKey: EVENT_KEYS.getAllEventActions(args),
		queryFn: () => enterprise.getAllEventActions(args),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
	});
};

export const useGetAllOfflineActionTypes = () => {
	return useQuery<AllOfflineActionTypes[], SdkgenError>({
		queryKey: EVENT_KEYS.getAllOfflineActionTypes,
		queryFn: () => enterprise.getAllOfflineActionTypes(),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
	});
};

export const getAllEventActionsFromTotem = (args: IGetAllEventActionsProps) => {
	return useQuery<ReportActionsFromEventResponse, SdkgenError>({
		queryKey: EVENT_KEYS.getAllEventActionsFromTotem(args),
		queryFn: () => enterprise.getAllEventActionsFromTotem(args),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
	});
};

export const useGetReportPdvZigCardErrors = (args: {
	eventId: string;
	pagination: Pagination;
	filters: ReportPdvZigCardFilters;
}) => {
	return useQuery<ReportPdvZigCardReport, SdkgenError>({
		queryKey: EVENT_KEYS.getReportPdvZigCardErrors(args),
		queryFn: () => enterprise.getReportPdvZigCardErrors(args),
		throwOnError(error) {
			showErrorNotification(error.message);

			return false;
		},
	});
};
export const { useApiHook: useGetEvent } = createQueryFactory("getEvent");
export const { useApiHook: useGetEventClosingDataToConference } = createQueryFactory(
	"getEventClosingDataToConference",
);
