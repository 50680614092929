import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import {
	EditPromoterPlace,
	PlaceActivePromoters,
	PlacePromoter,
} from "#resources/api/enterprise-generated";
import i18n from "#i18n/index";

const t = i18n.t;
export class PromoterStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public clean() {
		this.getPlacePromoters.reset();
		this.getPendingPromoters.reset();
		this.activePromoter.reset();
		this.getPromoterRoles.reset();
		this.getActivePromotersInPlace.reset();
	}

	public getPlacePromoters = new fetchModel<{ placeId: string }, PlacePromoter[]>({
		fnPromise: args => enterprise.getPlacePromoters(args),
		onError: error => showErrorNotification(error.message),
	});
	public getPendingPromoters = new fetchModel<{ placeId: string }, PlacePromoter[]>({
		fnPromise: args => enterprise.getPendingPromoters(args),
		onError: error => showErrorNotification(error.message),
	});

	public getPromoterRoles = new fetchModel({
		fnPromise: () => enterprise.getPromoterRoles(),
		onError: error => showErrorNotification(error.message),
	});

	public editPromoterInPlace = new fetchModel<
		{ placeId: string; promoterId: string; promoter: EditPromoterPlace },
		PlacePromoter
	>({
		fnPromise: args => enterprise.editPromoterInPlace(args),
		onError: error => showErrorNotification(error.message),
		onSuccess: () => {
			showSuccessNotification(t("place:guestList.promoter.promoter.store.messageEdit"));
		},
	});

	public activePromoter = new fetchModel<{ placeId: string; id: string }, PlacePromoter>({
		fnPromise: args => enterprise.activatePromoter(args),
		onError: error => showErrorNotification(error.message),
		onSuccess: () => {
			showSuccessNotification(t("place:guestList.promoter.promoter.store.messageActive"));
		},
	});

	public deactivatePromoter = new fetchModel<
		{ placeId: string; id: string },
		PlacePromoter
	>({
		fnPromise: args => enterprise.deactivatePromoter(args),
		onError: error => showErrorNotification(error.message),
		onSuccess: () => {
			showSuccessNotification(
				t("place:guestList.promoter.promoter.store.messageDeactive"),
			);
		},
	});

	public invitePromoters = new fetchModel<{ placeId: string; emails: string[] }, void>({
		fnPromise: args => enterprise.invitePromoters(args),
		onError: error => showErrorNotification(error.message),
		onSuccess: () => {
			showSuccessNotification(
				t("place:guestList.promoter.promoter.store.messageSuccess"),
			);
		},
	});

	public getActivePromotersInPlace = new fetchModel<
		{
			placeId: string;
		},
		PlaceActivePromoters[]
	>({
		fnPromise: args => enterprise.getActivePromotersInPlace(args),
		onError: err => showErrorNotification(err.message),
	});
}
