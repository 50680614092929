export const fiscalReport = {
	activeTab: "fiscal-report",
	columns: {
		date: "Date",
		emitted: "Billing issued",
		emittedNfce: "Consumer Notes",
		emittedNfse: "Service Notes",
		eventName: "Event",
		total: "Total",
		totalReceipt: "Issuable Billing",
	},
	errorNotification: "Deadline {{date}}",
	fiscalProfile: "Fiscal Profile",
	period: "Period",
	sheet: {
		columns: {
			date: "Date",
			emitted: "Billing issued",
			emittedNfce: "Consumer Notes",
			emittedNfse: "Service Notes",
			eventName: "Event",
			totalReceipt: "Issuable Billing",
		},
		filename: "fiscal_report",
		title: "Tax Report",
	},
	title: "Tax Report",
};
