import * as React from "react";
import * as ReactGA from "react-ga";
import { inject, observer } from "mobx-react";
import ErrorBoundary from "#components/error-boundary";

import s from "./app.scss";
import { AuthStore } from "#stores/auth";
import FaviconImage from "#public/favicon.png";

const Favicon = require("react-favicon");
export interface Props {
	isLoggedIn: boolean;
	authStore?: AuthStore;
}

export interface State {
	errored?: boolean;
}

@inject("authStore")
@observer
class App extends React.Component<Props, State> {
	public state: State = {};

	public componentDidMount() {
		this.props.authStore!.fetchCurrentEmployee();

		if (window.performance) {
			ReactGA.timing({
				category: "Page",
				value: window.performance.now(),
				variable: "load",
			});
		}
	}

	public render() {
		return (
			<ErrorBoundary>
				<Favicon url={FaviconImage} />
				<div className={s.app}>
					<div className={s.winterIsComing}>
						<div className={s.snow}></div>
					</div>
					{this.props.children}
				</div>
			</ErrorBoundary>
		);
	}
}

export default App;
