import { passwordCardHeader } from "./password-card-header";
import { passwordCardDeleteModal } from "./password-card-delete-modal";

export const passwordCard = {
	blockCard: "Block card",
	columns: {
		authorizedTransactioneration: "Operation/Authorized Transaction",
		cardCreator: "Card creator",
		cardNumber: "Card number",
		cardOwner: "Card owner",
		cardStatus: "Status do cartão",
		date: "Date/Hour",
		pdvId: "PDV Id",
		employeeName: "Employee",
		sourceId: "Transaction Id",
	},
	passwordCardDeleteModal,
	passwordCardHeader,
	statusEmployeeCard: {
		active: "Active",
		blocked: "Blocked",
		inactive: "Inactive",
	},
	stockHistory: "Stock History",
	filename: "stock_history",
};
