export const newEvent = {
	form: {
		addButton: "Add",
		backgroundEventPicture: "Event cover photo:",
		cancel: "Cancel",
		editButton: "To edit",
		eventPicture: "Event photo:",
		labelBegin: "Start:",
		labelDescription: "Description:",
		labelName: "Name:",
		peopleCapacity: "Event capacity",
		peopleCapacityTooltip: "Number of people the event holds.",
	},
	modalTitle: "Add event",
	title: "New event",
};
