import { details } from "./details";
export const clients = {
	atEventNameTitle: "at {{eventName}}",
	details,
	errorCountIssuedInvoice: "type of error was encountered when issuing notes",
	errorCountIssuedInvoices: "error types were encountered when issuing notes",
	fileTitle: "Customers at the event {{eventName}} {{eventDate}}",
	filename: "customers_at_event_{{eventName}}_{{eventDate}}",
	issueAllInvoicesButton: "Issue all notes",
	issueLoading: "Issuing notes...",
	issuedInvoice: "note was issued successfully",
	issuedInvoices: "notes were issued successfully",
	onlyClientsCheckbox: "Customers with unissued notes",
	period: "Period",
	searchPlaceholder: "Search by name, cpf or phone",
	table: {
		appTransactionsValue: "Paid by app",
		bonusTransactionsValue: "Paid with bonus",
		checkInTime: "Entry time",
		checkOutTime: "Departure time",
		document: "CPF",
		housePostPaidTransactionsValue: "Postpaid at home",
		housePrePaidTransactionsValue: "Prepaid at home",
		name: "Name",
		noDataText: "No data to display.",
		phone: "Phone",
		total: "Total",
	},
	thisEventTitle: "in this Event",
	title: "Customers",
	until: "Until",
};
