export const homeInventoryControl = {
	titleHome: "Inventory",
	buttonCreate: "Create inventory",
	description:
		"The inventory plays a very important role in the business by helping to control the goods and identifying losses and possible misplacements.",
	mostRecent: {
		titleSection: "Most recent",
	},
	inventoryHistory: {
		input: {
			search: "Search for",
			placeholder: "Title, stock, or stockist",
		},
		select: {
			filterByPeriod: "Filter by period",
			filterByStatus: "Filter by status",
		},
		titleTableColumns: {
			inventoryTitle: "Inventory title",
			lastEditor: "Last editor",
			lastEdition: "Last edition",
			stockist: "Stockist",
			stock: "Stock",
			categories: "Categories",
			status: "Status",
			actions: "Actions",
		},
		filters: {
			search: "Search",
			filter: "Filter",
		},
	},
};
