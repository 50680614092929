export const eventConference = {
	titlePage: "Conferência de eventos",
	infoPage:
		"Ao processar um evento, ele envia as informações de faturamento para o contas a pagar, contas a receber, fluxo de caixa e regex.",
	viewDataConference: "Ver conferência",
	viewDataConferenceDetails: "Detalhes da conferência",
	dataActions: {
		viewDetails: "Ver detalhes",
		eventProcess: "Processar evento",
	},
	tableColumns: {
		date: "Data",
		eventName: "Nome do evento",
		totalIncome: "Faturamento bruto",
		status: "Status",
		actions: "Ações",
	},
	textPerStatus: {
		loading: "Aguarde, estamos processando as conferências",
		success: "Todas as conferências foram processadas com sucesso",
		conclude: "Realizada",
	},
	titleProcess: {
		processing: "Processando",
		processed: "Processado",
		processConferences: "Processar conferências",
	},
	filter: {
		searchName: {
			title: "Busque por eventos",
			label: "Digite o nome do evento",
		},
		searchDate: {
			title: "Período",
		},
		searchStatus: {
			title: "Mostrar status",
		},
	},
	buttonProcessMultiple: "Processar eventos",
};
