export const financialReport = {
	fileName: "billing-{{event}}",
	finished: {
		end: "Closed: {{end}}",
		not: "The event has not closed",
	},
	label: "Resume",
	reportIn: "Billing for the event '{{event}}'",
	tabs: {
		bonusByProduct: {
			title: "Bonus per product",
			filename: "bonus_per_product",
			columns: {
				product: "Product",
				category: "Category",
				value: "Bonus received in the event",
				bonusSpentFromOtherEvents: "Bonus received in other events",
				total: "Total",
				totalCategory: "Total ({{category}})",
			},
			cards: {
				totalProductsValue: "Total bonus in Products",
				totalTipValue: "Total bonus in Tip",
				totalValue: "Total",
				receivedInPeriod: "Received at event",
				receivedAtAnotherPeriod: "Received at other events",
			},
		},
		bonusReport: {
			title: "Bonus per customer",
			filenameBonus: "bonus-per-customer",
			filenameAnticipated: "anticipated-consummation",
			filenameComsumption: "consummation",
			total: "Total of {{category}}",
			columns: {
				client: "Client",
				date: "Date",
				reason: "Reason",
				receivedValue: "Amount received",
				spentValue: "Amount spent in the event",
				spentValueInOtherEvents: "Amount spent in other events",
				givenBy: "Given by",
				total: "Total",
			},
		},
	},
};
