export const contentProductDetail = {
	fieldName: {
		image: "Imagem",
		name: "Nome",
		productType: "Tipo do produto",
		unitOfMeasurement: "Unidade de medida",
		category: "Categoria",
		menuCategory: "Categoria do menu",
		price: "Preço",
		description: "Descrição",
		drinkStyle: "Estilo da bebida",
		alcoholContent: "ABV - Teor alcoólico",
		hasAlcohol: "Contém álcool",
		hiddenZigApp: "Não exibir o produto no ZigApp",
		sincMyTapp: "Sincronizar com MyTapp",
		stockableProduct: "Produto estocável",
		productReturn: "Produto retornável",
		brand: "Marca",
		variableValue: "Preço variável",
	},
	requiredField: "Campo obrigatório",
	editImage: "Clique na imagem para editar",
};
