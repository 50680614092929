export const entrance = {
	add: "Adicionar",
	addEntrance: "Adicionar entrada",
	cancel: "Cancelar",
	deleteEntrance: "Deseja deletar a entrada {{entranceName}}?",
	edit: "Editar",
	entranceForm: {
		addEntrance: "Adicionar Entrada",
		bonus: "Bônus",
		category: "Categoria",
		categoryPlaceholder: "Selecione a categoria da entrada",
		cest: "CEST",
		cestPlaceholder: "Digite o CEST da entrada",
		code: "SKU",
		codePlaceholder: "Digite a SKU referente a entrada",
		minimumConsumptionValue: "Consumação antecipada",
		details: "Informações da entrada",
		editEntrance: "Editar entrada",
		fiscalData: "Dados fiscais",
		fiscalGroup: "Grupo fiscal",
		fiscalGroupPlaceholder: "Selecione o grupo fiscal da entrada",
		fiscalProfile: "Perfil fiscal",
		fiscalProfilePlaceholder: "Selecione o perfil fiscal da entrada",
		image: "Foto",
		consumptionObligation: "Consumação mínima",
		minimumValueCharged: "Valor mínimo cobrado",
		minimumValueChargedTooltip:
			"A Valor mínimo cobrado é o menor valor que o cliente irá pagar na conta e é a soma da consumação mínima com o preço da entrada",
		name: "Nome",
		namePlaceHolder: "Digite o nome da entrada",
		ncm: "NCM",
		ncmPlaceholder: "Digite o NCM dessa entrada",
		price: "Preço",
		variablePrice: "Preço variável",
		newCategory: "Você criará uma nova categoria",
	},
	errors: {
		consumption: "Não é possivel cadastrar mais de um tipo de consumação",
		mandatoryField: "Esse campo é obrigatório",
		validValue: "Digite um valor válido",
	},
	table: {
		active: "Ativo",
		bonus: "Bônus",
		category: "Categoria",
		consumptionObligation: "Consumação mínima",
		entrance: "Entrada",
		minimumConsumptionValue: "Consumação antecipada",
		value: "Valor",
		variable: "Variável",
	},
	title: "Entradas",
};
