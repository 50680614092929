export const lossReport = {
	add: "Add loss",
	emptyText: "No data was found",
	export: "Download to Excel",
	description:
		"The loss report aggregates all stock movements marked as losses. This way, the administrator can have an overview of the products that have been lost.",
	periodInfo: "Displaying report from {{since}} to {{until}}",
	addModal: {
		count: "Quantity",
		date: "Date",
		loading: "Loading...",
		lossQuantity: "Lost amount",
		none: "No products found",
		other: "Add another product",
		productSelect: "Select product",
		storageSelect: "Select stock",
	},
	columns: {
		manual: "Manual loss",
		product: "Product",
		production: "Production loss",
		refund: "Chargeback loss",
		sku: "SKU",
		subtotal: "Subtotal",
		inventory: "Inventory",
		inventoryLoss: "Inventory loss",
		total: "Total loss",
		totalLoss: "Total loss value",
	},
	label: "Losses",
	period: "Period",
	sheet: {
		fileName: "loss-report",
		manual: "Manual loss",
		product: "Product",
		production: "Production loss",
		refund: "Chargeback loss",
		sku: "SKU",
		inventory: "Inventory",
		inventoryLoss: "Inventory loss",
		subtotal: "Subtotal",
		title: "Loss Report",
		basicStorageUnit: "Unit",
		total: "Total loss",
		totalLoss: "Total loss value",
	},
	title: "Loss Report",
	validations: {
		validDate: "Select a valid date",
		validProduct: "Select a valid product",
		validQuantity: "Insert a valid quantity",
		validStorage: "Select a valid storage",
	},
};
