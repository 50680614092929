export const emissionErrors = {
	drawer: {
		clients: "Clientes",
		count: "Quantidade",
		date: "Primeiro dia não emitido",
		details: "Detalhes da última nota com erro",
		message: "Mensagem",
		products: "Produtos",
		profile: "Perfil fiscal",
		status: "Status",
		title: "Histórico de erros",
		type: "Tipo de erro",
		updatedAt: "Último dia não emitido",
	},
	export: "Exportar Excel",
	period: "Período",
	table: {
		columns: {
			count: "Quantidade",
			createdAt: "Primeiro dia não emitido",
			errorMessage: "Mensagem",
			errorType: "Tipos de erro",
			fiscalProfileId: "Perfil fiscal",
			status: "Status",
			updatedAt: "Último dia não emitido",
		},
		errorTypeLabel: {
			unknown: "Desconhecido",
		},
		statusLabel: {
			pending: "Pendente",
			resolved: "Resolvido",
		},
	},
	tabs: {
		invoices: "Notas não emitidas",
		products: "Produtos não emitidos",
	},
	title: "Erros de emissão",
};
