export const service = {
	descriptionInfoWarning:
		"Os campos abaixo são obrigatórios para emissão de notas fiscais. E se não forem modificados, as notas fiscais serão emitidas com o padrão fiscal da Zig.",
	insertCestManual: "Adicionar CEST manual?",
	labelFields: {
		CEST: "CEST",
		NCM: "NCM",
		code: "Código",
		name: "Nome",
		taxGroup: "Grupo fiscal",
		taxProfile: "Perfil fiscal",
	},
	messageErrorNotReturnCest: "Não foi possível retornar CEST para NCM informado",
	messageErrorNotReturnTaxGroups: "Não foi possível retornar os grupos fiscais",
	placeholderFields: {
		CEST: "Inserir CEST",
		NCM: "Inserir NCM",
		code: "Código do serviço",
		name: "Inserir nome",
		selectCEST: "Selecionar CEST",
		taxGroup: "Selecionar grupo fiscal",
		taxProfile: "Perfil fiscal",
	},
	titleInfoWarning: "Dados necessários para emissão",
};
