import { crudMessage } from "./crud-message";
import { modalImportClients } from "./modal-import-clients";
import { modalMyModels } from "./modal-my-models";
export const message = {
	columnsTableClients: {
		attendance: "Visits",
		cpf: "CPF",
		name: "Customer Name",
		ticket: "Average ticket",
	},
	crudMessage,
	emptyWhatsappList:
		"No location in the organization has numbers registered on WhatsApp. Contact Zig's customer service team and request the registration of a number to be able to send messages via WhatsApp to your customers.",
	footer: {
		confirmWithSaveGroup: "Save group and go forward",
		confirmWithoutSaveGroup: "Forward without saving group",
		forward: "Forward",
		save: "Save change and edit message",
	},
	header: {
		isCreate: {
			subtitle:
				"Select a house in your organization to list the recipient customer group.",
			title: "New message",
		},
		isEdit: {
			subtitle: "Change the box to select another recipient group.",
			title: "Edit Scheduled Message",
		},
	},
	import: "Import excel sheets",
	infos: {
		agree: "Understood",
		text:
			"By importing the customer's contact list, for the purpose of sending SMS, you confirm that they have accepted the receipt of messages, based on the General Data Protection Law (LGPD).",
		title: "Customer Information Responsibility",
	},
	modalImportClients,
	modalMyModels,
	modalSaveGroup: {
		filtersList: "Applied filters",
		groupName: {
			placeholder: "Select a name for the new customer group",
			title: "Group name",
		},
		text:
			"Save the filters applied to create a group of customers and make it easier to send SMS in the future.",
		title: "Save group",
	},
	myModels: "My models",
	places: "Houses",
	sheet: {
		columns: {
			cpf: "CPF",
			name: "Name",
			observation: "Observation",
			phone: "Phone",
		},
		title: "Customer List (example)",
	},
};
