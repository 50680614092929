import * as React from "react";

export interface IWarning {
	id: string;
	message: string;
}

export interface IGlobalWarningsContextData {
	warnings: IWarning[];
	addWarning: (warning: IWarning) => void;
	removeWarning: (id: string) => void;
}

export const GlobalWarningsContext = React.createContext(
	{} as IGlobalWarningsContextData,
);

export function GlobalWarningsProvider({ children }: React.PropsWithChildren<{}>) {
	const [warnings, setWarnings] = React.useState<IWarning[]>([]);

	function addWarning(warning: IWarning) {
		setWarnings(warnings => warnings.concat(warning));
	}

	function removeWarning(id: string) {
		setWarnings(warnings => warnings.filter(w => w.id !== id));
	}

	return (
		<GlobalWarningsContext.Provider value={{ addWarning, removeWarning, warnings }}>
			{children}
		</GlobalWarningsContext.Provider>
	);
}

export function useGlobalWarnings() {
	return React.useContext(GlobalWarningsContext);
}
