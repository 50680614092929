export const products = {
	bottomText: "Todas as alterações feita aqui irão afetar todos os cardápios",
	error:
		"não foi possivel intentificar o produto corespondente de {{name}}, baixe uma nova planilha modelo e tenha cuidado com a coluna 'indentificador'",
	errorIn: "Erro em ",
	header: {
		add: "Adicionar produtos",
		delete: "Apagar produtos",
		deleteConfirm: "Tem certeza que deseja apagar todos os produtos selecionados?",
		edit: "Editar produtos em massa",
		massDelete: "Apagar produtos em massa",
		search: "Buscar produtos",
	},
	sucess: "Todos produtos editados com sucesso",
	title: "Produtos",
};
