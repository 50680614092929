export const whatsapp = {
	actionButtons: {
		actionButton: "Ações",
		addbutton: "Adicionar outro botão",
		formatValidation: {
			phoneNumber: "Número de telefone inválido",
			url: "Link inválido",
		},
		noneButton: "Nenhum",
		optionsAction: {
			label: {
				accessWebsite: "Acessar site",
				buttonText: "Texto do botão",
				country: "País",
				makeCall: "Fazer ligação",
				phoneNumber: "Número de telefone",
				typeAction: "Tipo de ação",
				websiteLink: "Link do site",
			},
			placeholder: {
				accessWebsite: "Acesse nosso site",
				callNow: "Ligar agora",
				enterNumber: "Insira o número aqui",
				insertLink: "Insira o link aqui",
			},
		},
		quickResponseAction: {
			placeholderQuickResponse: "Digite aqui a resposta rápida",
			title: "Resposta rápida {{numbering}}",
		},
		quickResponseButton: "Resposta rápida",
		subTitle: "São botões que aparecem como opções clicáveis logo após a sua mensagem",
		title: "Botões - Opcional",

		validationFields: {
			requiredField: "Campo obrigatório {{field}}",
		},
	},
	contentControlModal: {
		alcoholicBeverages: "Bebidas alcoólicas",
		confirmation: {
			label:
				"Confirmo que li as políticas do WhatsApp Business e estou ciente do que pode ser divulgado.",
			paragraph:
				"O Facebook possui políticas sobre o tipo de conteúdo divulgado pelo WhatsApp. Você confirma que leu as ",
			underlined:
				"políticas e está ciente do que pode e não pode ser divulgado pela plataforma?",
		},
		paragraph1: {
			text:
				"O Whatsapp e o Facebook possui políticas claras de uso. Todos os conteúdos de comunicação que passam pela plataforma precisam ser revisados e aprovados para garantir que eles não violem as políticas do Whatsapp. A seguir, temos alguns usos proibidos retirados da própria documentação da",
			textLink:
				"Política do WhatsApp Business. Para ler a documentação completa, clique aqui",
		},
		paragraph2: {
			text:
				"Considerando o contexto em que a ZigPay atua, devemos deixar claras algumas das políticas contidas na documentação de política comercial do Whatsapp que provavelmente interfere no que você pode divulgar na plataforma. Listamos algumas abaixo, mas é muito importante que você leia a",
			textLink: "documentação completa de política comercial.",
		},
		paragraph3: {
			exampleBold: "Exemplos:",
			politicsBold: "Política:",
			text: "as empresas não podem vender ou promover a venda de bebidas alcoólicas",
		},
		paragraph4: {
			areAllowedBold: "São permitidas",
			text:
				"divulgações sobre livros ou DVDs sobre bebidas alcoólicas. Ou Itens relacionados a bebidas alcoolicas como taças, refrigerantes e suportes para garrafas de vinho.",
		},
		paragraph5: {
			areNotAllowedBold: "Não são permitidas",
			text:
				"divulgações sobre bebidas alcoólicas ou kits para produção de bebidas alcoólicas.",
		},
		title: "Controle de conteúdo",
	},
	newTemplatePage: {
		footer: {
			newModelButton: "Solicitar novo modelo",
			title: "Prévia da mensagem",
		},
		glossary: {
			client: {
				description: "Nome do cliente",
				name: "Nome do cliente",
			},
			date: {
				description: "Dia da semana ou data específica",
				name: "Período",
			},
			description:
				"Para poder reaproveitar esse modelo, digite o código abaixo entre colchetes como no exemplo: “[{{variable}}]”",
			discount: {
				description: "Valor percentual do desconto",
				name: "Porcentagem de desconto",
			},
			originalProductPrice: {
				description: "Preço original do produto específico",
				name: "Preço do produto",
			},
			productName: {
				description: "Nome do produto",
				name: "Nome do produto específico",
			},
			productPromotionalPrice: {
				description: "Preço promocional do produto específico",
				name: "Preço promocional do produto",
			},
			observation: {
				name: "Observação",
				description: "Observação",
			},
			title: "Glossário de variáveis",
		},
		glossaryVariables: {
			client: "cliente",
			date: "data",
			discount: "promocao-desconto",
			productName: "produto-nome",
			productOriginalPrice: "produto-preco-original",
			productPromotionalPrice: "produto-preco-promocional",
			observation: "observacao",
		},
		header: {
			subtitle: {
				link: "Lembre-se de sempre seguir as diretrizes de conteúdo do WhatsApp",
				text:
					"A solicitação do novo modelo pode demorar alguns dias. Digite abaixo o que	deseja incluir no seu novo modelo.",
			},
			title: "Solicitar novo modelo para",
		},
		imageLabel: "Imagem",
		imageTooltipMessage: "Anexe uma imagem para enviar no seu template",
		invalideVariableMessage: "Você deve utilizar uma das variáreis do glossário",
		messageErrorUpload: "O anexo deve ser do tipo imagem.",
		modelTitle: "Título do modelo",
		modelTitlePlaceholder: "Digite o título",
		modelTitleTooltipMessage: "Informe um título para seu template",
		removeFileButtonLabel: "Clique aqui para anexar uma imagem",
		textareaLabel: "Conteúdo da mensagem",
		textareaPlaceholder:
			"Escreva o conteúdo do modelo aqui. Dica: para adicionar variáveis e poder alterá-las no futuro, siga o glossário ao lado.",
		textareaTooltipMessage: "Escreva o conteúdo do modelo que gostaria de criar",
		warning: {
			description:
				"O WhatsApp pode demorar até 3 dias para autorizar o novo modelo. Durante esse período você pode enviar outras mensagens a partir dos outros modelos da área anterior.",
			responseDeadline: "Prazo de resposta",
		},
	},
	section: {
		emptyTemplateState: {
			text:
				"Solicite um novo template para enviar o mensagens de WhatsApp para seus clientes",
			title: "Nenhum template cadastrado",
		},
		footer: {
			btnRequestNewModel: "Solicitar novo modelo",
			btnUnderstandWhy:
				"Entenda porque você só pode enviar mensagens de WhatsApp a partir de modelos",
		},
		form: {
			checkboxLabel: "Utilizar nome do cliente a partir do banco",
			checkboxObservationLabel: "Utilizar campo observação a partir do banco",
			previewTitle: "Prévia da mensagem",
			title: "Defina os parâmetros da mensagem",
		},
		header: {
			editTitle: "Editar mensagem WhatsApp",
			goBack: "Voltar e selecionar outro modelo",
			model: "Modelo",
		},
		tags: {
			approvedModel: "Modelo aprovado",
			modelInValidation: "Modelo em validação",
			modelRecieved: "Modelo recebido pelo Whatsapp",
			modelRefused: "Modelo não aprovado pelo Whatsapp",
		},
		understandWhyModal: {
			text:
				"O Whatsapp e o Facebook possuem políticas claras de uso. Todos os conteúdos de comunicação que passam pela plataforma precisam ser revisados e aprovados para garantir que eles não violem as políticas do Whatsapp. Para garantir que as suas políticas não sejam violadas, você só poderá iniciar conversas utilizando esse modelo de mensagem. Sendo que o facebook tem o direito de revisar e recusar os modelos de mensagem a qualquer momento.",
			title:
				"Entenda porque você só pode enviar mensagens de WhatsApp a partir de modelos",
			understandButton: "Entendi",
		},
	},
};
