import RootStore from "../../../stores";
import * as React from "react";
import { formatCurrency } from "#helpers/currency";
import {
	CURRENCIES,
	CURRENCIES_NARROW_SYMBOLS,
	DEFAULT_CURRENCY,
	defaultOptions,
	ICurrencyOptions,
	ResultCurrency,
	SupportedCurrencies,
} from "#helpers/currency/utils";

type DefaultRecord = Record<string, number>;
type CurrencyHookOptions = ICurrencyOptions;

function createHookOptions(
	currency: SupportedCurrencies,
	options?: ICurrencyOptions,
): ICurrencyOptions {
	const currencyLanguage = CURRENCIES[currency];

	if (!options) return { ...defaultOptions, currency, language: currencyLanguage };

	// Handle when currency doesn´t exists in currency Record
	if (!currencyLanguage) return options;

	return { ...options, currency, language: currencyLanguage };
}

export function useCurrency<T extends DefaultRecord>(
	values?: T,
	options?: CurrencyHookOptions,
): ResultCurrency<T> {
	const { organizationStore } = React.useContext(RootStore);
	const [formatedValues, setValues] = React.useState<Record<keyof T, string> | null>(
		null,
	);

	// this aproach is not the best and we need to change
	const valuesKey = Object.entries(values || []).join(",");
	const memoizedValues = React.useMemo(() => values, [valuesKey]);

	const currency = getCurrency();
	const currencyNarrowSymbol = CURRENCIES_NARROW_SYMBOLS[currency];
	const hookOptions = createHookOptions(currency, options);

	const hookFormatCurrency = (value: number) => formatCurrency(value, hookOptions);

	function getCurrency(): SupportedCurrencies {
		// priority for currency defined on options
		// defined on options -> defined on organization -> defined in this hook
		return options?.currency || organizationStore.currencyOrg || DEFAULT_CURRENCY;
	}

	React.useEffect(() => {
		if (memoizedValues) setValues(formatAllCurrencyValues(memoizedValues));
	}, [memoizedValues]);

	function formatAllCurrencyValues(values: T) {
		if (!values) return {};
		const newValues = Object.entries(values).map(([key, value]) => [
			key,
			hookFormatCurrency(value),
		]);
		return Object.fromEntries(newValues);
	}

	return {
		currencyNarrowSymbol,
		formatCurrency: hookFormatCurrency,
		values: formatedValues,
	};
}
