import { reports } from "./reports";

import { addBillModalForm } from "./add-bill-modal-form";
import { coalaAddMenuModal } from "./coala-add-menu-modal";
import { coalaAddProductModal } from "./coala-add-product-modal";
import { forms } from "./forms";
import { withdrawsCards } from "#i18n/translations/pt-BR/components/withdraws-cards";
import { pixTypeInput } from "./pix-type-input";
import { onboarding } from "./onboarding";

export const componentsPT = {
	addBillModalForm,
	pixTypeInput,
	addProduct: {
		emptyProductFeatureForm: {
			description:
				"Selecione um modelo personalizado de informações do produto. Para criar um novo modelo acesse a página de",
			link: "modelos de funcionalidade de produto.",
			title: "Nenhum modelo de funcionalidade selecionado",
		},
	},
	toggleProducts: "Não, apenas esse produto",
	ballStatusIndicator: {
		invalid: "Status inválido",
		offline: "offline",
		online: "online",
	},
	coalaAddMenuModal,
	coalaAddMenuProductModal: {
		add: "Adicionar produto",
		price: "Preço de venda",
		product: "Produto",
		productInformation: "Informações do produto",
		title: "Adicionar produto ao menu",
		validateField: "Campo obrigatório",
	},
	coalaAddProductModal,
	coalaCategorizedProductsTable: {
		all: "Todos",
		emptyMessage: "Nenhum produto encontrado",
		mountable: "Montável",
		price: "Preço",
		product: "Produto",
		sku: "SKU",
	},
	coalaMassEditModal: {
		download: "Download",
		editMassProduct: "Edição de produtos em massa",
		label: "Nome do arquivo",
		products: "Produtos",
		selectDisclaimer:
			"Realize download dos produtos selecionados em excel e edite-os. Em seguida faça upload do arquivo para atualizarmos seu cardápio",
		sheet: {
			category: "Categoria",
			description: "Descrição",
			filename: "editar-produtos-em-massa-delivery",
			id: "IDENTIFICADOR (NÃO EDITAR)",
			invalidFormat: "Formato inválido",
			name: "Nome",
			price: "Preço",
			sku: "SKU",
		},
		xlsxDisclaimer:
			"Agora que você fez a edição dos produtos em excel, faça upload do arquivo que iremos atualizar sua lista de produtos neste cardápio",
	},
	comingSoon: "Em breve",
	confirmModal: {
		cancel: "Cancelar",
		ok: "Ok",
		title: "Confirmação necessária",
	},
	consolidatedHistory: {
		translateEventStatus: {
			canceled: "Cancelado",
			created: "Fechado",
			finished: "Fechado",
			running: "Aberto",
		},
		translatePaymentMethod: {
			anticipated: "Antecipado",
			app: "Aplicativo",
			appPrePayment: "Pagamento via app",
			bonus: "Bônus",
			cash: "Dinheiro",
			creditCard: "Crédito",
			dashboard: "Dashboard",
			debitCard: "Débito",
			pix: "Pix",
			postEvent: "Após o evento",
			rappi: "Rappi",
			voucher: "Voucher",
			zigPosCredit: "Zig pós crédito",
			zigPosCreditInInstallments: "Parcelamento",
			zigPosDebit: "Zig pós débito",
			integratedFoodVoucher: "Vale-refeição",
		},
	},
	containers: {
		noMatch: "Página não encontrada",
	},
	dataTable: {
		empty: "Sem dados para serem exibidos",
		table: "Tabela",
	},
	dialogs: {
		clientInvoices: {
			email: "Email",
			issuedAt: "Data e Hora",
			links: "Links",
			loading: "Carregando...",
			name: "Consumidores",
			noInvoices: "Não há notas emitidas para exibir",
			onSubmitEmailForm: "Enviar notas fiscais por email",
			openCoupon: "Abrir Cupom",
			openPdf: "Abrir PDF",
			send: "Enviar",
			somethingWrong: "Algo de errado aconteceu",
			submitButton: "Emitir notas",
			title: "Notas fiscais de {{clientName}}",
			updatePage: "Atualize a página e tente novamente",
		},
		clientTransactions: {
			buyers: "Compradores",
			date: "Hora",
			product: "Produtos",
			refunded: "Estornado?",
			sellerName: "Vendedor",
			title: "Extrato do cliente '{{clientName}}'",
		},
	},
	errorBoundary: {
		description:
			"A nossa equipe já foi notificada do erro. Por favor, recarregue a página e tente novamente.",
		descriptionWithError:
			"Caso o problema seja recorrente, informe a nossa equipe o código acima.",
		subtitle: "Código do erro",
		title: "Algo de errado aconteceu",
	},
	eventTag: {
		sent: {
			title: "Conferência enviada",
			tooltip: "Conferência de caixa enviada para o backoffice financeiro.",
		},
		pending: {
			title: "Conferência pendente",
			tooltip:
				"Conferência de caixa pendente para ser enviado para o backoffice financeiro.",
		},
		openPdv: "PDV's em aberto",
		openTable: "Mesas em aberto",
	},
	fileUpload: "Clique ou arraste aqui para anexar",
	formStatus: {
		error: {
			description: "Não foi possível concluir.",
			title: "Ops... Algo não saiu como planejado.",
		},
		success: "Concluído com sucesso!",
	},
	forms,
	globalWarnings: {
		blockedPlace: "O local {{placeName}} está bloqueado",
		blockedPlaces: "Os locais {{placeName}} estão bloqueados",
		certificatesAboutToExpire:
			"Os certificados digitais dos perfis fiscais {{value}} irão expirar em menos de 30 dias!",
		expiredCertificates:
			"Os certificados digitais dos perfis fiscais {{value}} expiraram!",
		isApproved:
			"Atenção! Esse local se encontra em estado de pré-aprovação, não será possivel abrir eventos até que a aprovação seja feita.",
		requests_one:
			"Atenção! Você possui {{value}} solicitação de saque de vendor pendentes",
		requests_other:
			"Atenção! Você possui {{value}} solicitações de saque de vendor pendentes",
	},
	helpers: {
		defaultErrorMessage: "Ocorreu um erro com a sua solicitação",
		humanizedWithdrawStatus: {
			paid: "Pago",
			pending: "Pendente",
			processing: "Em processamento",
			refused: "Negado",
		},
		humanizedWithdrawType: {
			anticipation: "Antecipação",
			withdraw: "Saque",
			withdrawAndAnticipation: "Saque com antecipação",
		},
		parsePaymentMethodV2: {
			bonus: "Bônus",
			cash: "Dinheiro",
			creditCard: "Cartão de crédito",
			debitCard: "Cartão de débito",
			foodVoucher: "Vale-refeição",
			integratedFoodVoucher: "Vale-refeição",
			pix: "Pix",
			postEvent: "Pós evento",
			voucher: "Voucher",
			zigPosCredit: "Crédito integrado",
			zigPosDebit: "Débito integrado",
			ame: "Ame",
			anticipated: "Antecipado",
			zigPosCreditInInstallments: "Crédito parcelado",
			rappi: "Rappi",
			ifood: "Ifood",
			uber: "Uber",
			deliveryOnline: "Delivery Online",
			dashboard: "Dashboard",
			online: "Online",
			cashless: "Cashless",
			mbway: "MBWay",
			total: "Total",
			minimumConsumption: "Consumação antecipada",
			app: "App",
		},
		tabs: {
			closings: "Fechamento por vendor",
			config: "Configurações",
			discount: "Descontos",
			emissionErrors: "Erros de emissão",
			emited: "Emissões",
			extract: "Extrato de vendor",
			fiscalReport: "Relatório fiscal",
			invoices: "Notas Fiscais",
			recharges: "Recargas",
			menus: "Cardápios",
			missingInvoices: "Notas Faltantes",
			missingInvoicesForeign: "Notas Pendentes",
			notEmitedProducts: "Produtos não emitidos",
			orderReport: "Relatório de pedidos",
			productReport: "Relatório por produto",
			productSoldByVendor: "Produtos vendidos por vendor",
			products: "Produtos",
			search: "Busca",
			trasactions: "Transações",
			vendorReport: "Relatório de vendors",
			vendors: "Vendors",
			vendorsRevenue: "Faturamento por vendor",
			debtorsReport: "Contas em Aberto",
		},
		tooltips: {
			bonusByProductReport: "Bônus por produto",
			cashierBalance: "Receita por caixa",
			closeCashier: "Fechamento de caixa",
			entranceReport: "Entrada",
			financialReport: "Fechamento",
			minimumConsumptionReport: "Consumação antecipada",
			soldEntrances: "Total de entradas vendidas",
			soldProducts: "Total de produtos vendidos",
			tipReport: "Relatório de Gorjeta",
			waiterReport: "Vendas por garçom",
			vat: "O IVA está incluído no preço cadastrado do produto.",
			netValue:
				"O Valor Líquido é calculado subtraindo o desconto e a porcentagem IVA do produto.",
			invoiceValue:
				"Corresponde ao valor total da nota fiscal, essa pode incluir mais de 1 produto, portanto, mais de uma linha nessa tabela",
		},
		xlsx: {
			data: "Dados",
			day: "{{title}} no dia {{date}}",
			emissionDate: "Documento emitido {{date}}",
			selectedPeriod: "período selecionado {{since}} até {{until}}",
			sinceUntil: "{{title}} entre {{since}} e {{until}}",
			zigEmissionDate: "ZIGPAY - Data de emissão {{date}}",
			emissionInfo: "Documento emitido por {{currentEmployee}}, {{date}}",
		},
		displayFiltersInfo: {
			none: "Filtros: Sem filtros aplicados",
			hasFilters: "Filtros: {{filters}}",
		},
	},
	logo: "Logo",
	monthPicker: {
		dateInfoNotAvailable: "Visualização não disponível",
		monthDays: "Total de {{monthDays}} dias",
	},
	noInformation: "Sem informações",
	notAvailable: {
		isGrouped: "Existem produtos sem custo cadastrado",
		isNotGrouped: "Custo não cadastrado",
		na: "N/A",
	},
	notification: {
		error: "Erro!",
		success: "Sucesso!",
		warning: "Atenção!",
	},
	openSalesReport: {
		hooks: {
			exportAllSalesFromEmployee: {
				date: "Data",
				filename: "vendas-avulsas-de-{{employeeName}}",
				filenameEvent: "-no-evento-{{eventName}}",
				filenamePeriod: "-de-{{since}}-ate-{{until}}",
				isRefunded: "Foi estornada",
				method: "Método",
				refundedAt: "Estornada em",
				refundedBy: "Estornada por",
				title: "Vendas Avulsas de {{employeeName}} ",
				titleEvent: "no evento {{eventName}} ",
				titlePeriod: "de {{since}} até {{until}}",
				value: "Valor",
			},
			exportSalesByEmployee: {
				employee: "Funcionário",
				filename: "vendas-avulsas-por-funcionario",
				filenameEvent: "no-evento-{{eventName}}",
				filenamePeriod: "-de-{{since}}-ate-{{until}}",
				title: "Vendas Avulsas por Funcionário ",
				titleEvent: "no evento {{eventName}} ",
				titlePeriod: "de {{since}} até {{until}}",
			},
		},
		parseOpenSaleMethod: {
			credit: "Crédito",
			debit: "Débito",
			pix: "Pix",
			creditInInstallments: "Parcelamento",
			integratedFoodVoucher: "Vale-refeição",
		},
	},
	paginator: {
		showLess: "Mostrar menos",
		showMore: "Mostrar mais ({{remainingElements}})",
	},
	reportNavbar: {
		cashflow: {
			title: "Financeiro",
			extract: "Extrato",
			expensses: "Despesas/Receitas",
			history: "Histórico",
			demonstrative: "Demonstrativo",
		},
		competence: "Competência",
		dueDate: "Vencimento",
		expenses: {
			perAnything: "Pago a...",
			perCategory: "Por Categoria",
			perCostCenter: "Por Centro de Custo",
			perDay: "Por Dia",
			perDescripion: "Por Descrição",
			perHistory: "Histórico",
			perType: "Por Tipo",
			title: "DESPESAS",
		},
		receipts: {
			perAnything: "Recebido de...",
			perCategory: "Por Categoria",
			perCostCenter: "Por Centro de Custo",
			perDay: "Por Dia",
			perDescripion: "Por Descrição",
			perHistory: "Histórico",
			perType: "Por Tipo",
			title: "RECEBIMENTOS",
		},
	},
	pendingContracts: {
		approveContract: "Aprovar contrato",
		description: "Você possui contratos pendentes de acordo.",
		deselectAllContracts: "Desmarcar todos os contratos",
		modalApproveConfirmation: {
			description:
				"Para finalizar a assinatura e confirmar a aprovação enviaremos um código para o e-mail cadastrado. Ao finalizar a assinatura, você só conseguirá cancelar	entrando em contato com o canal de suporte.",
			header: "Confirmação de aprovação",
			reminder:
				"Lembrete: verifique sua caixa de spam, o e-mail com o código pode ter sido	direcionado para ela.",
			sendCode: "Enviar código",
		},
		modalCodeConfirmation: {
			codePlaceholder: "Insira o código enviado",
			confirmSign: "Confirmar assinatura",
			description:
				"Insira o código de confirmação para concluir a assinatura da solicitação.",
			verifyCode: "Código de confirmação",
		},
		request: "Solicitação #{{request}}",
		selectAllContracts: "Selecionar todos os contratos",
		title: "Termos e contratos pendentes",
	},
	reportTab: {
		letterhead: "Evento '{{name}} ({{begin}})'",
		notAuthorized: "Você não está autorizado a ver nenhum relatório dessa seção",
	},
	reportTabs: {
		notAuthorized: "Você não está autorizado a ver nenhum relatório dessa seção",
	},
	selectImage: {
		computer: "computador",
		notInformed: "Nome não informado",
		productImage: "Foto do produto",
		searchMoreOptions: "Procurar mais opções do banco de dados",
		select: "Selecione uma foto",
		selectFrom: "Selecionar do ",
		setTagInput: "procure pelo produto usando palavras chave",
	},
	table: {
		deleteDescription:
			"O convidado será excluído permanentimente. Deseja continuar assim mesmo?",
		deleteGuest: "Deletar convidado",
		emptyText: "Sem dados a serem exibidos.",
		excludeGuest: "Excluir convidado",
		reports,
	},
	tabsBackoffice: {
		accountsPayable: "Contas a pagar",
		accountsReceivable: "Contas a receber",
		providers: "Clientes e fornecedores",
		costCenter: "Centro de Custo",
		planAccounts: "Plano de Contas",
		checkingAccount: "Conta Corrente",
		reports: "Relatórios",
		cashflow: "Fluxo de caixa",
		finExManagerialReport: "ReGex",
		bankAccounts: "Contas bancárias",
		conciliation: "Conciliação via OFX",
		noticeIsFreeModule: "Módulo gratuito até",
	},
	transactionalHistory: {
		translateAcquirerTansactionType: {
			credit: "Crédito",
			debit: "Débito",
		},
	},
	withdrawsCards,
	reportPrintTemplate: {
		printDate: "Emitido dia",
		reportPeriod: "Período do relatório",
		emissionInfo: "Emitido por",
		eventDate: "Data do evento",
	},
	inputFile: {
		invalidFileFormat: "Formato de arquivo inválido, formatos válidos: {{formats}}",
		multipleFilesUploaded: "Múltiplos arquivos enviados",
		remove: "Remover",
	},
	notifications: {
		title: "Notificações",
		read: "Todos",
		unread: "Não lidos",
		emptyNotifications: "Você chegou no final da lista!",
		emptyUnreadNotifications: "Você está atualizado de todas as notícias!",
		noNews: "Sem novidades!",
		levelTag: "Nível {{tag}}",
		classification: {
			alert: "Instabilidade",
			release: "Lançamento",
			warning: "Melhoria",
		},
		level: {
			app: "App do usuário",
			organization: "Organização",
			place: "Local",
			event: "Evento",
			pdv: "Pdv",
		},
		onboarding,
	},
	placeCheckbox: {
		selectAll: "Selecionar todos",
	},
	placeFilter: {
		placeholder: "Filtrar por local",
	},
	header: {
		filterError: "O array de fields não pode ter mais de 4 itens.",
		perDay: "Por dia",
		byPeriod: "Por período",
	},
};
