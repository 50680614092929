export const employeesRole = {
	table: {
		roleName: "Nome do cargo",
		access: "Acesso",
		collaboratorsQuantity: "Quantidade de colaboradores no cargo",
		none: "Nenhum",
	},
	masterOperationPDVAndDashboard: "Master - Operação (PDV) e Dashboard",
	operationPDV: "Operação (PDV)",
	dashboard: "Dashboard",
	roleNeedsASlug: "O cargo precisa de um slug",
	collaborators: "Colaboradores",
	collaborator: "Colaborador",
	editRole: "Editar cargo",
	deleteRole: "Excluir cargo",
	newRole: "Novo cargo",
	noneCollaborator: "Nenhum colaborador",
	deleteRoleModal: {
		title: "Deseja excluir cargo?",
		describe:
			"Ao excluir um cargo, todos os colaboradores vinculados a ele, perdem o vínculo.",
	},
	header: {
		roles: "Cargos",
		describe:
			"Adicione, edite e delete cargos para serem utilizados no dashboard ou no PDV",
		searchRole: "Buscar cargo",
		searchRolePlaceholder: "Busque por nome do cargo",
		closeSearch: "Fechar busca",
		alertMessage:
			"A partir da separação de funcionários em dash e pdv não é mais possível criar ou usar cargos com permissões dos dois tipos.",
	},
	roleModal: {
		PDVRole: "Operação",
		PDVDescription: "Cargo para colaboradores com permissão de acesso a <b>PDVs</b>",
		PDVFooterText: "Criar colaborador PDV",
		enterpriseRole: "Gerencial",
		enterpriseDescription:
			"Cargo para colaboradores com permissão de acesso ao <b>Dashboard</b>",
		enterpriseFooterText: "Criar colaborador dash",
		newManagerRole: "Novo cargo gerencial",
		newOperationRole: "Novo cargo de operação",
		operationPermission: "Permissões operacionais",
		managerPermission: "Permissões gerenciais",
	},
	form: {
		roleNameDescription: "Selecione o tipo de cargo que deseja criar",
		roleNameRequired: "O nome do cargo é obrigatório",
		roleName: "Nome do cargo",
		roleNamePlaceholder: "Insira o nome do novo cargo",
		roleNameError: "Nome do cargo inválido",
		importExistingRolePermissions: "(Opcional) Importar permissões de cargo existente",
		selectExistingRole: "Selecione um cargo existente",
		roleType: "Tipo do cargo",
		operational: "PDV",
		managerial: "Dashboard",
		master: "Master",
		saveEditions: "Salvar alterações",
		createNewRole: "Criar novo cargo",
		discardChanges: "Descartar alterações",
		discardCreation: "Descartar criação",
	},
};
