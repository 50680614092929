export const entranceBilling = {
	tabs: {
		pageTabs: {
			boxOffice: "Revenue",
			soldEntrances: "Tickets Sold",
			employeeReport: "Tickets by Employee",
		},
		boxOfficeBilling: {
			filename: "ticket_billing",
			label: "Ticket Billing",
			percentage: "percentage",
			quantity: "quantity",
			revenue: "revenue",
			value: "value",
		},
		entranceReport: {
			columns: {
				count: "Quantity",
				discountValue: "Discount",
				entrance: "Entrance",
				name: "Name",
				subtotal: "Subtotal",
				totalValue: "Total value",
				unitValue: "Unit value",
			},
			filename: "tickets-sold-by-employees",
			label: "Tickets sold by employees",
			sheet: {
				count: "Quantity",
				discountValue: "Discount",
				fileName: "entrance",
				name: "Name",
				subtotal: "Subtotal",
				title: "Entrance",
				totalValue: "Total value",
				unitValue: "Unit value",
			},
			total: "Total",
			totalCategory: "Total ({{category}})",
		},
		soldEntrances: {
			label: "Total tickets sold",
			tabs: {
				total: "Total",
				clients: "Customers by Ticket",
			},
			columns: {
				name: "Name",
				count: "Units sold",
				averageValue: "Average price",
				totalValue: "Total",
			},
			total: "Total receipt",
			sheet: {
				title: "Tickets sold",
				fileName: "tickets_sold",
				name: "Nome",
				average: "Average value",
				count: "Quantity",
				totalValue: "Price",
			},
			filter: "Filter by hour",
			since: "since",
			until: "until",
			clientsReport: {
				title: "List of Customers by Ticket Sold",
				filterLabel: "Filter by Ticket Sold",
				filterPlaceholder: "Add filters by ticket",
				clients: "Customers",
				entranceValue: "Ticket Value",
				consumption: "Consumption",
				total: "Total",
				name: "Name",
				document: "Document",
				entrance: "Ticket",
				checkin: "Checkin",
				checkout: "Checkout",
				fileName: "customers-by-ticket-sold_{{date}}",
			},
		},
	},
};
