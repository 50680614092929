import { promotionInfo } from "./promotion-info";
import { awards } from "./awards";
import { resume } from "./resume";
import { sideTab } from "./side-tab";

export const promotions = {
	card: {
		active: "Ativa",
		expiresAt: "Expira em {{date}} Dia(s)",
		inactive: "Inativa",
		noExpirationDate: "Sem prazo de expiração",
		subtitle: "Desconto de {{discount}}",
		withoutDiscount: "Sem Desconto",
	},
	create: "Criar promoção",
	createPromotion: {
		awards,
		goals: {
			description:
				"As metas são parâmetros que vão determinar o que o seu cliente precisa fazer para ganhar a promoção.",
			title: "Metas",
		},
		promotionInfo,
		resume,
		usageConditions: {
			description:
				"Nessa etapa você pode definir parâmetros de uso da promoção. Quando o cliente ganha a promoção, ele só pode utilizá-la se seguir essas condições de uso.",
			title: "Condições de uso",
		},
	},
	empty: "A busca não encontrou resultados",
	filter: {
		active: "Ativas",
		all: "Todas",
		inactive: "Inativas",
		label: "Filtro",
	},
	search: "Pesquise por nome da promoção",
	selected: {
		active: "Ativa",
		clientList: "Lista de clientes",
		clientListModal: {
			addClient: {
				label: "Adicionar clientes à lista",
				placeholder: "Digite CPF do cliente",
				placeholderForeign: "Digite o telefone do cliente",
				addClient: "Adicionar cliente",
				foreign: "Estrangeiro",
			},
			search: "Busque por nome ou CPF",
			searchForeign: "Busque por nome ou telefone",
			remove: "Remover clientes selecionados",
			columns: {
				cpf: "CPF",
				name: "Nome",
				cpfForeign: "Documento",
				phone: "Telefone",
			},
			error: "Não foi possível adicionar os clientes abaixo:",
			title: "Lista de clientes {{promotionName}}",
		},
		copy: "Criar cópia",
		discountValue: {
			title: "Desconto de {{discount}}",
			unavailable: "Indisponível",
		},
		erasePromotion: {
			confimation: "Tem certeza que quer apagar a promoção?",
			title: "Apagar promoção",
		},
		expirationTime: {
			noExpires: "Sem prazo de expiração",
			title: "Expira em: {{date}} Dias",
		},
		inactive: "Inativa",
		maxUses: {
			title: "{{uses}} usos por pessoa",
			withoutLimit: "Sem limite de uso individual",
		},
		productsList: {
			all: "Todos os produtos",
			seeLess: "ver menos",
			seeMore: "ver mais",
			title: "PRODUTOS EM PROMOÇÃO",
			unavailable: "Produto indisponível",
		},
		title: "Nome da promoção",
	},
	sideTab,
	title: "Promoções",
	withoutPromotions: "Não existem promoções cadastradas",
};
