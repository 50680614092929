export const errorsValidations = {
	anticipation: {
		accountData: {
			accountAgency: "Digite o número da agência",
			accountAgencyInvalid: "Agência inválida",
			accountBank: "Selecione o banco ao qual a conta pertence",
			accountDigit: "Digite o dígito da conta",
			accountDocument: "Digite o documento do titular da conta (CPF/CNPJ)",
			accountDocumentInvalid: "Documento inválido",
			accountHolder: "Digite o nome do titular da conta",
			accountName: "Digite o nome para identificar a conta",
			accountNumber: "Digite o número da conta",
			accountPixKey: "Chave PIX inválida",
			accountType: "Selecione o tipo da conta",
			accountTypeExisting: "Selecione uma das contas existentes",
			accountTypeWrapper: "Preencha os dados bancários ou chave PIX",
			noAccountType: "Selecione um tipo de conta para a antecipação",
			enterBankData: "Informe o {{bankKeyType}}",
		},
		value: {
			liquidValue:
				"Valor solicitado maior que valor total líquido disponível ({{totalAvailable}})",
			noLiquidValue: "Digite o valor líquido para a antecipação",
			noTotalValue: "Digite o valor total a ser antecipado",
			reason: "Digite um motivo para a antecipação",
			totalValue:
				"Valor solicitado maior que valor total bruto disponível ({{totalAvailable}})",
		},
	},
	bankSlip: {
		anticipation: "Necessária antecipação",
		attachment: "Anexe o arquivo do boleto",
		barCode: "Digite o código de barras do boleto",
		basicInfoValue:
			"Valor solicitado maior que valor total bruto disponível ({{totalAvailable}})",
		beneficiary: "Digite o beneficiário do boleto",
		dueDate:
			"ATENÇÃO: O boleto só será pago se o saldo restante cobrir os custos com juros e multa.",
		invalidBarCode: "Código de barras inválido",
	},
	withdraw: {
		value: {
			accountBank: "Selecione o banco ao qual a conta pertence",
			accountChoice: "Selecione um tipo de conta para a solicitação",
			accountDigit: "Digite o dígito da conta",
			accountHolder: "Digite o nome do titular da conta",
			accountName: "Digite o nome para identificar a conta",
			accountNumber: "Digite o número da conta",
			accountType: "Selecione o tipo da conta",
			agencyInvalid: "Agência inválida",
			agencyNumber: "Digite o número da agência",
			anticipation: "Necessária antecipação",
			documentInvalid: "Documento inválido",
			documentNumber: "Digite o documento do titular da conta (CPF/CNPJ)",
			existingAccount: "Selecione uma das contas existentes",
			noWithdrawValue: "Digite o valor para o saque",
			pixKeyInvalid: "Chave PIX inválida",
			reason: "Digite um motivo para a solicitação",
			receiverDataWrapper: "Preencha os dados bancários ou chave PIX",
			withdrawValue:
				"Valor solicitado maior que valor total bruto disponível ({{totalAvailable}})",
			enterBankData: "Informe o {{bankKeyType}}",
			bankDataInvalid: "O {{bankKeyType}} digitado é inválido",
		},
	},
};
