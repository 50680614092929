export const consolidated = {
	excelButton: "Export to Excel",
	extract: "Extract",
	filename: "extract-zig",
	formatFee: "From {{minFee}} to {{maxFee}}",
	table: {
		fee: "Fee",
		feeMax: "Maximum Fee",
		feeMin: "Minimum Fee",
		name: "Name",
		payValue: "Receivable/Payable Zig",
		section: "Section",
		totalValue: "Total Value",
	},
};
