export const booking = {
	reserve: {
		create: "Criar pacote",
		empty: {
			subtitle:
				"Crie seu primeiro pacote clicando no botão ¨Criar pacote¨ no topo dessa página",
			title: "Nenhum pacote para esse evento",
		},
		label: "Pacotes",
		reserveCard: {
			guests: "{{count}} convidados",
			none: "Nenhuma promoção selecionada",
			people: "{{count}} pessoas confirmadas",
			promotions: "{{count}} promoções",
		},
		reserveDetail: {
			confirmed: "{{count}} confirmados",
			copyModal: {
				copy: "Copiar",
				defaultReserveName: "Cópia de {{name}}",
				errors: {
					inputs: "Os campos não foram preenchidos corretamente.",
					length: "O nome do pacote não atingiu a quantidade mínima de caracteres.",
					name: "O nome do pacote é igual ao nome original.",
				},
				placeholder: "Nome da cópia do pacote",
				text: "Deseja criar cópia de reserva ",
				title: "Criar cópia do pacote",
			},
			delete: "Tem certeza que deseja apagar esse produto?",
			guests: "Convidados",
			guestsCount: "{{count}} convidados",
			invoices: "Notas geradas",
			issueInvoice: "Emitir nota fiscal",
			modal: {
				add: "Adicionar grupo fiscal",
				addDiscount: "Adicionar descontos na nota",
				cest: "CEST",
				chooseOptions: "Escolha uma das opções",
				cnpj: "CNPJ",
				completeValue: "Completar o valor do pacote",
				cpf: "CPF",
				discount: "Descontos",
				errors: {
					inputs: "Campos não foram preenchidos corretamente",
					value: "Inclua algum produto com valor para utilizar essa funcionalidade",
				},
				fiscalGroup: "Grupo Fiscal",
				fiscalProfile: "Perfil Fiscal",
				inputMultiplePaymentsManual: {
					add: "Adicionar pagamento",
					label: "Método de pagamento",
					placeholder: "Selecione",
					value: "Valor",
				},
				invoiceAlert: "Notas com valor maior que R$ 10,000 serão divididas.",
				invoiceValue: "Valor da nota",
				issueInvoice: "Emitir nota",
				ncm: "NCM",
				none: "Nenhum",
				optionalTip: {
					add: "Adicionar gorjeta",
					title: "Gorjeta (opcional)",
				},
				packageValue: "Valor do pacote",
				paymentMethod: "Método pagamento",
				remove: "Remover",
				removeTip: "Remover gorjeta",
				tip: "Gorjeta",
				title: "Emitir nota fiscal para {{name}}",
				total: "Total",
				value: "Valor",
			},
			none: "Nenhuma promoção selecionada",
			of: "de",
			peopleArrived: {
				subtitle: "Já saiu",
				title: "No evento",
			},
			peopleDidntArrive: {
				subtitle: "Sem informações",
				title: "Desconhecido",
			},
			promotions: "Promoções do pacote",
			tableMultiplePayment: {
				columns: {
					paymentMethod: "Método de pagamento",
					value: "Valor",
				},
				total: "Total adicionado",
			},
			title: "Pacote de",
			value: "Valor do pacote",
		},
		reserveModal: {
			clientInfo: "Informações do cliente",
			create: "Criar",
			edit: "Editar pacote",
			errors: {
				inputs: "Preencha todos os campos obrigatórios para a categoria selecionada.",
				promotion: "Nenhuma promoção selecionada",
				selectedTemplate: "O modelo não pôde ser encontrado",
			},
			estimatedPeopleCount: "Número de convidados",
			expiresAt: "Expira em",
			inactive: " (Promoção inativa)",
			modalTitle: {
				create: "Criar pacote",
				edit: "Editar {{name}}",
				newTemplate: "Criar modelo",
				template: "Criar a partir de modelo",
			},
			models: "Modelos",
			name: "Nome",
			newTemplate:
				"Atenção! O modelo criado poderá ser utilizado por todos os eventos deste local.",
			promotion: "Promoção",
			promotionLabel: "Promoção {{number}}",
			template:
				"O pacote pode ser customizado, mas criará associação ao modelo para ser usado em relatórios e métricas.",
			updateModal: {
				description: "Descrição",
				percentage: "Porcentagem",
				productName: "Nome do produto",
				promotionName: "Nome da promoção",
			},
			value: "Valor do pacote",
		},
		reserveOptions: {
			allEvents: "todos os eventos",
			canBeUsedIn: "Poderá ser usado em",
			create: {
				label: "Defina como será o pacote oferecido e se desejar inclua uma promoção.",
				name: "Crie pacote customizado",
			},
			createTemplate: {
				label:
					"Crie um modelo customizado de pacote para utilizar futuramente em seus eventos.",
				name: "Crie novo modelo",
			},
			createWithTemplate: {
				label:
					"Crie um pacote para seus clientes com base nos modelos criados anteriormente.",
				name: "Crie a partir de modelo",
			},
			fromThisPlace: "deste local",
			managePackages: "Gerenciar pacotes",
		},
		reserveTemplate: {
			empty: "O local não possui nenhum modelo cadastrado.",
			guests: "Convidados",
			loading: "Carregando ...",
			modalConfirm: {
				content: "Ele não poderá mais ser utilizado em nenhum evento deste local.",
				title: "Tem certeza de que quer remover o modelo?",
			},
			promotions: "Promoções",
			remove: "Remover",
			value: "Valor do pacote",
		},
		search: "Pesquise por pacotes",
	},
	reserveReport: {
		label: "Relatório de pacotes",
		subtitle: {
			default: "{{count}} pacotes ativos durante o evento",
			none: "Nenhum pacote ativo durante o evento",
			one: "um pacote ativo durante o evento",
		},
	},
};
