import { sanitizeBreakingSpaces } from "../breaking-spaces";
import {
	CENTS_BASE,
	DEFAULT_CURRENCY,
	defaultOptions,
	ICurrencyOptions,
	MIN_FRACTION_DIGITS,
} from "./utils";

// TODO adicionar teste
export function convertFromCents(value: number) {
	return Math.abs(value / CENTS_BASE);
}

// TODO adicionar teste
export function convertToCents(value: number) {
	return Math.round(value * CENTS_BASE);
}

export function formatCurrency(value: number, options?: ICurrencyOptions) {
	const { language, currency, centsBase, withoutSymbol } = {
		...defaultOptions,
		...options,
	};

	const isNegativeValue = value < 0;

	const formatedValue = centsBase ? convertFromCents(value) : value;

	if (withoutSymbol) {
		return formatedValue.toLocaleString(language, {
			minimumFractionDigits: MIN_FRACTION_DIGITS,
		});
	}

	const formatter = new Intl.NumberFormat(language, {
		currency: currency || DEFAULT_CURRENCY,
		style: "currency",
	});

	const result = sanitizeBreakingSpaces(formatter.format(formatedValue));

	return isNegativeValue ? `-${result}` : result;
}
