import classNames from "classnames";
import * as React from "react";
import s from "./spinner.scss";

export type SpinnerSize = "small" | "medium" | "large";

export interface ISpinnerProps extends React.HTMLAttributes<HTMLDivElement> {
	size?: SpinnerSize;
	className?: string;
}

export function Spinner({ className, size = "large", ...props }: ISpinnerProps) {
	return (
		<div className={s.container}>
			<div
				className={classNames(s.spinner, className, {
					[s.small]: size === "small",
					[s.medium]: size === "medium",
					[s.large]: size === "large",
				})}
				{...props}
			/>
		</div>
	);
}
