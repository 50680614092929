import { inRange } from "lodash-es";

import { BasicStorageUnit } from "#resources/api/enterprise-generated";

import { sanitizeBreakingSpaces } from "../breaking-spaces";
import { DEFAULT_LANGUAGE, IntlLanguages } from "../language";

interface IPercentageOptions {
	language?: IntlLanguages;
	maximumFractionDigits?: number;
}

const PERCENT_MAX_VALUE = 1;
const PERCENT_DEFAULT_LANGUAGE: IntlLanguages = "en-US";
const MAX_FRACTION_VALUE = 2;

export function formatPercentage(value: number, options?: IPercentageOptions) {
	const { maximumFractionDigits, language } = {
		language: PERCENT_DEFAULT_LANGUAGE,
		maximumFractionDigits: 2,
		...options,
	};

	if (value > PERCENT_MAX_VALUE) {
		console.warn(`Careful. Most of time percentage value should be less than 1`);
	}

	return Intl.NumberFormat(language, {
		maximumFractionDigits,
		style: "percent",
	}).format(value);
}

interface IAbbreviationOptions {
	language?: IntlLanguages;
	maximumFractionDigits?: number;
	minimumFractionDigits?: number;
}

export function formatAbbreviation(value: number, options?: IAbbreviationOptions) {
	const { maximumFractionDigits, minimumFractionDigits, language } = {
		language: DEFAULT_LANGUAGE,
		maximumFractionDigits: MAX_FRACTION_VALUE,
		...options,
	};

	const compactValue = Intl.NumberFormat(language, {
		maximumFractionDigits,
		minimumFractionDigits,
		notation: "compact",
	}).format(value);
	return sanitizeBreakingSpaces(compactValue);
}

interface ISimpleNumberOptions {
	language?: IntlLanguages;
}

export function formatNumber(value: number, options?: ISimpleNumberOptions) {
	const { language } = {
		language: DEFAULT_LANGUAGE,
		...options,
	};
	return value.toLocaleString(language);
}

export function inRangeIncluding(n: number, start: number, end?: number | undefined) {
	return inRange(n, start, end == undefined ? undefined : end + 1);
}

export const stringNumberToCents = (
	value: string | number | null,
	ube: BasicStorageUnit | null,
	multiplier?: number,
): number => {
	const isKgOrL = ube === "kilogram" || ube === "liter";

	const numberValue = typeof value === "string" ? Number(value) : value;

	if (numberValue === null || isNaN(numberValue)) {
		return 0;
	}

	return Math.max(
		Math.trunc(
			numberValue *
				100 *
				(multiplier ?? 1) *
				(isKgOrL && multiplier === undefined ? 1000 : 1),
		),
		0,
	);
};
