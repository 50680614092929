import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import { showErrorNotification } from "#resources/helpers/notifications";
import { CampaignsInvestmentResult } from "#resources/api/enterprise-generated";

export class RoiStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public getCampaignsInvestment = new fetchModel<
		{ placeIds: string[]; since: Date; until: Date },
		CampaignsInvestmentResult
	>({
		fnPromise: args => enterprise.getCampaignsInvestment(args),
		onError: err => showErrorNotification(err.message),
	});
}
