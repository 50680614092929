export const soldBaseProductReport = {
	emptyText: "No data to display.",
	filename: "product_general_output_report",
	tabTitle: "General output of products",
	table: {
		code: "SKU",
		count: "Quantity",
		product: "Product",
	},
};
