export const homeInventoryControl = {
	titleHome: "Inventário",
	buttonCreate: "Criar inventário",
	description:
		"O inventário de estoque tem um papel muito relevante para o negócio ajudando no controle das mercadorias além de identificar perdas e possíveis extravios.",
	mostRecent: {
		titleSection: "Mais recentes",
	},
	inventoryHistory: {
		input: {
			search: "Buscar por",
			placeholder: "Título, estoque ou estoquista",
		},
		select: {
			filterByPeriod: "Filtrar por período",
			filterByStatus: "Filtrar por status",
			allStatus: "Todos os status",
		},
		titleTableColumns: {
			inventoryTitle: "Título do inventário",
			lastEditor: "Último editor",
			lastEdition: "Última edição",
			stockist: "Estoquista",
			stock: "Estoque",
			categories: "Categorias",
			status: "Status",
			actions: "Ações",
		},
		filters: {
			search: "Busca",
			filter: "Filtrar",
		},
	},
};
