export const report = {
	empty: {
		filters: {
			subtitle: "Select another option and try again",
			title: "No results were found for this selection.",
		},
		noneList: {
			subtitle: "Reports will appear here when you have a registered list",
			title: "Looks like you haven't created a list yet.",
		},
		search: "We did not find results for your search.",
	},
	file: {
		checkinDetails: "Check-in details",
		consumptionTotal: "Total in consumption",
		document: "CPF",
		entranceTotal: "Total of entrance",
		entranceType: "Entrance type",
		filename: "guest-list-report",
		listName: "List Name",
		name: "Name",
		numberOfActiveGuests: "Check-in performed",
		numberOfGuests: "Total guest",
		promoter: "Promoter",
		titleByGuestList: "Report by guest list",
		titleGeneral: "General report",
		total: "Total General",
		upgrade: "Entrance upgrade",
		phone: "Phone",
		birthDate: "Date of birth",
	},
	filters: {
		period: "Period",
		promoter: {
			placeholder: "Add filter by promoters",
			title: "Filter by promoter",
		},
		relation: {
			placeholder: "Add filters by list",
			title: "Filter by guest list",
		},
	},
	search: "Search",
	tabs: {
		byList: {
			columns: {
				consumptionTotal: "Total in consumption",
				entranceTotal: "Total in input",
				guests: "Guests",
				promoters: "Promoters",
				relationLists: "Lists",
				total: "Grand total",
			},
			title: "By guest list",
		},
		general: {
			cards: {
				consumptionTotal: "Total in consumption",
				entranceTotal: "Total in input",
				guests: "Guests",
				promoters: "Promoters",
				relationLists: "Lists",
				total: "Grand total",
			},
			columns: {
				checkin: "Check in details",
				consumption: "Consumption",
				cpf: "CPF",
				entrance: "Input type",
				generalTotal: "Grand total",
				guestName: "Name",
				listName: "List",
				promoter: "Promoter",
				upgradeEntrance: "Entry upgrade",
				phone: "Phone",
				birthDate: "Date of birth",
			},
			title: "Overview",
		},
	},
	templateTitle: "General Report",
	title: "Reports",
};
