export const productReport = {
	columns: {
		baseValue: "Base value",
		count: "Quantity",
		integration: "Integration",
		name: "Product",
		sku: "SKU",
		totalValue: "Total price",
	},
	sheet: {
		allOrdersProductsReport: {
			columns: {
				baseUnitPrice: "Base Price",
				count: "Quantity",
				deliveryOrderId: "Order Identifier",
				name: "Product",
				obs: "Note",
				productId: "Product Identifier",
				sku: "SKU",
				totalUnitPrice: "Total Price",
			},
			title: "Product Order Report",
		},
		filename: "report-delivery",
		generalProductReport: {
			columns: {
				baseValue: "Base value",
				count: "Quantity",
				integration: "Integration",
				name: "Product",
				sku: "SKU",
				totalValue: "Total price",
			},
			title: "Delivery Products Report",
		},
	},
	title: "Delivery report by product",
	vendors: {
		coala: "Whitelabel",
		ifood: "Ifood",
		rappi: "Rappi",
		ubereats: "Uber eats",
	},
};
