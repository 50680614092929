export const tabsLabels = {
	closed: {
		emptyState: {
			description: "no momento não existem operações fechadas",
			title: "não existem operações fechadas",
		},
		label: "operações fechadas",
	},
	open: {
		emptyState: {
			description: "no momento não existem operações em aberto",
			title: "não existem operações em aberto",
		},
		label: "operações em aberto",
	},
};
