export const withdraws = {
	filename: "advance-withdrawals-bankslips",
	table: {
		datetime: "Request date",
		type: "Type",
		status: "Status",
		requestedValue: "Requested amount",
		liquidAnticipatedValue: "Anticipated value",
		proRataFee: "Pro rata",
		appliedFee: "Fee applied",
		proRataFeeTooltip:
			"Advance rate proportional to the period of the balance to be brought forward",
		totalBruteValue: "Total amount",
		bankAccount: "Bank account",
		ticketPayment: "Payment of bank slip",
		clientObservation: "Reason",
		obs: "Observation",
		paymentMethod: "Payment method",
		attachments: "Attachments",
		confirmWithdrawBtnLabel: "Confirm request",
	},
	withdrawType: {
		anticipation: "Anticipation",
		withdraw: "Withdraw",
		withdrawAndAnticipation: "Withdraw and Anticipation",
		bankslip: "Bankslip",
	},
	statusType: {
		pending: "Pending",
		paid: "Paid",
		refused: "Refused",
		processing: "Processing",
		pendingPlaceConfirmation: "Pending Confirmation",
		cancelled: "Cancelled",
		processingCancellation: "Processing cancellation",
		pixProcessing: "Pix Processing",
		pendingManagerApproval: "Pending Manager Approval",
		canceledPlaceConfirmation: "Cancelled",
	},
	paymentType: {
		bankAccount: "Bank Account",
		bankPaymentSlip: "Bank Payment Slip",
	},
	filters: {
		searchAccountPlaceholder: "Search by account",
		filterTypePlaceholder: "Filter by type",
		filterStatusPlaceholder: "Filter by status",
	},
};
