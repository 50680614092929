export const importNewList = {
	alert: "Before importing the spreadsheet, ensure that all blank lines are removed",
	altText: "Example of how the spreadsheet to be imported should be structured",
	errorMessageDefault:
		"Invalid worksheet, download it again and make the necessary changes. In case of empty lines in red, delete and try again.",
	form: {
		at: "at",
		colorSelect: "Assign a color",
		couvertPercentage: "Discount on couvert",
		description: {
			label: "Description",
			limit: "{{count}} characters left",
			placeholder: "Add a note to your list if you want",
		},
		entranceIds: {
			label: "Associated Entry",
			placeholder: "Select associated entry type",
		},
		errorExpires: "Expired date and time.",
		errorName: "Enter the name of the list",
		expiresAtDate: "List expiration",
		listName: "List name",
		documentIsRequired: "Document is required",
		canBeInvitedByLink: "Allow share list via link",
		addGuestTimeLimit: "Guest insertion deadline",
	},
	format: "Worksheet format",
	goBackModal: {
		description:
			"When you leave this page you will lose all the information you have filled in, do you want to exit anyway?",
		leavePage: "Leave page",
		title: "Leave",
	},
	goBackText: "Go back to lists",
	importList: "Import an existing list into this event",
	middleTitle: "To import a new list just upload a spreadsheet",
	or: "or",
	relationUsers: "Customers to be added to the list",
	secondTitle: "Import new or existing list",
	selectText: "Import list from such Venue for this event",
	sheetFileName: "Guest list",
	successMessage: "Spreadsheet successfully imported",
	title: "Import new guest list",
	error: "Error",
	list: "List",
	message: "Message",
};
