export const pos = {
	methodTranslate: {
		credit: "Credit",
		debit: "Debit",
	},
	operations: {
		columns: {
			brand: "Flag",
			date: "Date and time",
			lastDigits: "Digits",
			method: "Method",
			value: "Value",
		},
		search: "Search by flag, digits or value",
		title: "Operations",
	},
	others: "Others",
	selectDate: "Select a day to view transactions",
	sidebar: {
		clear: "Clean",
		credit: "Credit",
		day: "Day",
		debit: "Debit",
		info: "Select one or more dates to see details.",
		title: "Total of {{month}}",
		total: "Total",
	},
	title: "POS sales",
};
