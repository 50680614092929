import { Pagination, ReportPdvZigCardFilters } from "#resources/api/enterprise-generated";
import { IGetAllEventActionsProps } from "./query";

export const EVENT_KEYS = {
	getEvents: ["events"],
	getEventsMonthYear: (placeId: string, monthYear: string) => [
		...EVENT_KEYS.getEvents,
		placeId,
		monthYear,
	],
	getAllEventActions: (props: IGetAllEventActionsProps) => ["getAllEventActions", props],
	getAllOfflineActionTypes: ["getAllOfflineActionTypes"],
	getAllEventActionsFromTotem: (props: IGetAllEventActionsProps) => [
		"getAllEventActionsFromTotem",
		props,
	],
	getReportPdvZigCardErrors: (props: {
		eventId: string;
		pagination: Pagination;
		filters: ReportPdvZigCardFilters;
	}) => ["getReportPdvZigCardErrors", props],
} as const;
