export const helpers = {
	humanize: {
		paymentMethod: {
			ame: "Ame",
			anticipated: "Anticipated",
			app: "App",
			automaticDebit: "Automatic Debit",
			bankTransfer: "Bank transfer",
			bleedings: "Sangria",
			boleto: "Bank slip",
			bonus: "Bonus",
			cash: "Cash",
			check: "Check",
			consumptionFromBar: "Early consummation",
			creditCard: "Credit card",
			dashboard: "Dashboard",
			debitCard: "Debit card",
			deliveryOnline: "Delivery Online",
			ifood: "Ifood",
			other: "Other",
			pix: "Pix",
			postEvent: "Post event",
			promissory: "Promissory note",
			rappi: "Rappi",
			uber: "Uber",
			voucher: "Voucher",
			zigCredit: "Zig Credit",
			zigDebit: "Zig Debit",
			zigPosCredit: "Zig post credit",
			zigPosDebit: "Zig post debit",
			zigPosCreditInInstallments: "Installment",
			integratedFoodVoucher: "Food Voucher",
		},
	},
	string: {
		grams: "Grams",
		kilogram: "Kilograms",
		liter: "Liter",
		lowercase: {
			grams: "grams",
			kilogram: "kilogram",
			liter: "liter",
			milliliter: "milliliter",
			unit: "unit",
		},
		milliliter: "Mililiter",
		unit: "Unit",
	},
};
