export const adjustments = {
	filename: "ajustes",
	label: {
		advance: "Adiantamento",
		advanceFee: "Taxa de Adiantamento",
		anticipationFee: "Taxa de Antecipação",
		balanceAdjustment: "Balanceamento de Saldo",
		balanceRetention: "Retenção de Saldo",
		balanceTransfer: "Transferência Entre Places",
		bonifications: "Outras Bonificações",
		cardActivations: "Taxa de Ativação",
		equipmentsMaintenance: "Manutenção de Equipamentos",
		equipmentsPurchase: "Compra de Equipamentos",
		equipmentsRental: "Aluguel de Equipamentos",
		expiredRecharges: "Recargas Expiradas",
		generalZigMachineTPV: "Faturamento via adquirência integrada ZIG",
		generalZigMachineTax: "Taxas de Adquirência sobre Máquinas Integradas",
		genericOperationsFee: "Despesas Gerais de Operação",
		genericServices: "Outros Serviços e Cobranças",
		notUsedRecharges: "Sobra de Recargas ",
		operatorFee: "Diária de Operador",
		others: "Outros",
		payments: "Pagamentos",
		paymentsTax: "Taxa de Pagamento a Fornecedores",
		pos: "Faturamento em Máquinas Não Integradas (POS)",
		posTax: "Taxa de Adiquirência sobre Máquinas Não Integradas (POS)",
		reimbursements: "Reembolsos a Clientes",
		renegotiation: "Renegociação",
		residueRemain: "Retenção de Resíduo",
		missingCards: "Cartões Extraviados",
		billingInvoice: "Cobrança Nota Fiscal",
		guaranteedMinimumSupplement: "Complemento Mínimo Garantido",
		commandFiscalPurchase: "Compra de Comanda Fiscal",
		accountsReceivableLegal: "CR - Jurídico",
		cardDiscount: "Desconto de Cartões",
		discountGeneralOperatingExpenses: "Desconto Despesas Gerais de Operação",
		discountOperatorDailyRate: "Desconto Diária de Operador",
		generalDiscount: "Desconto Geral",
		dailyCoordinatorRate: "Diárias de Coordenadores",
		dailyHeadsRate: "Diárias de Heads",
		setupDailyCoordinatorRate: "Diárias de Setup Coordenadores",
		setupDailyHeadsRate: "Diárias de Setup Heads",
		setupDailySupervisorsRate: "Diárias de Setup Supervisores",
		setupDailyTechnicalRate: "Diárias de Setup Técnico",
		setupDailyTraineeRate: "Diárias de Setup Trainee",
		dailySupervisorRate: "Diárias de Supervisores",
		dailyTechnicianRate: "Diárias de Técnico",
		dailyTraineeRate: "Diárias de Trainee",
		damagedEquipment: "Equipamento Avariados",
		integratedAPPBilling: "Faturamento App Integrado",
		apartCreditBilling: "Faturamento Crédito Apartado",
		integratedCreditBilling: "Faturamento Crédito Integrado",
		apartDebitBilling: "Faturamento Débito Apartado",
		integratedDebitBilling: "Faturamento Débito Integrado",
		apartBillingPIX: "Faturamento PIX Apartado",
		integratedPIXBilling: "Faturamento PIX Integrado",
		integratedAdvancedRechargeBilling: "Faturamento Recarga Antecipado Integrado",
		interestandPenalties: "Juros e Multa",
		softwareLicense: "Licença de Software",
		replacedMachines: "Máquinas Substituidas",
		minimumGuaranteedpermachine: "Mínimo Garantido por máquina",
		otherEquipment: "Outros Equipamentos",
		rebate: "Rebate",
		setupAdvancedRecharge: "Setup Recarga Antecipada",
		setupRefundSite: "Setup Site de Reembolso",
		acquirementfeeforIntegratedZIGApplication:
			"Taxa de adquirência sobre Aplicativo ZIG Integrado",
		acquiringfeeonapartCredit: "Taxa de adquirência sobre Crédito Apartado",
		acquiringfeeforIntegratedCredit: "Taxa de adquirência sobre Crédito Integrado",
		acquiringfeeforapartDebit: "Taxa de adquirência sobre Débito Apartado",
		acquiringfeeonIntegratedDebit: "Taxa de adquirência sobre Débito Integrado",
		acquiringfeeforapartPIX: "Taxa de adquirência sobre PIX Apartado",
		acquiringfeeforIntegratedPIX: "Taxa de adquirência sobre PIX Integrado",
		acquiringfeeforAdvancedRecharge: "Taxa de adquirência sobre Recarga Antecipada",
		refundfee: "Taxa de Reembolso",
		relatedpartyretentionfee: "Taxa de retenção de partes relacionadas",
		rappiFee: "Taxa Rappi",
		SMSFee: "Taxa SMS",
		residualFee: "Taxa sobre Resíduo",
		cardtoCashExchange: "Troca de Cartões por Dinheiro",
		cardtoProductExchange: "Troca de Cartões por Produto",
		travelFood: "Alimentação de Viagem",
		shipping: "Frete",
		accommodation: "Hospedagem",
		transport: "Transporte",
		pixRefundFee: "Taxa Reembolso PIX",
		softwareLicenseMachine: "Licença de Software Máquina",
		softwareLicenseTotem: "Licença de Software Totem",
		integratedAdvanceDebitBilling: "Faturamento Débito Antecipado Integrado",
		integratedAdvanceCreditBilling: "Faturamento Crédito Antecipado Integrado",
		integratedAdvancePixBilling: "Faturamento Pix Antecipado Integrado",
		integratedAdvanceDebitFee: "Taxa Débito Antecipada Integrado",
		integratedAdvanceCreditFee: "Taxa Crédito Antecipada Integrado",
		integratedAdvancePixFee: "Taxa Pix Antecipada Integrado",
		zeDeliveryDiscount: "Desconto Zé Delivery",
		ticketPrinting: "Impressão de ingressos",
		validatorRental: "Licença de Validadores",
		zeDeliveryRetention: "Retenção Zé Delivery",
		valueAddedTax: "IVA",
		moneyTPV: "TPV Dinheiro",
		acquiringFeeForIntegratedInstallmentCredit:
			"Taxa de adquirência sobre Crédito Parcelado Integrado",
		issuedCharge: "Cobrança Emitida",
		softwareLicenseGiftback: "Licença de Software Giftback",
		loanBracelet: "Empréstimo de pulseira",
		lendingCards: "Empréstimo de cartão",
		externalResourceContribution: "Aporte de recursos externos",
	},
	noDataToShow: "Não há nada a mostrar para o período selecionado",
	table: {
		actions: "Ações",
		balance: "Saldo",
		category: "Categoria",
		date: "Data de competência",
		noObs: "Sem observação",
		obs: "Observação",
		payDate: "Data de liquidação",
		total: "Total",
		totalToPay: "A pagar",
		totalToReceive: "A receber",
	},
	title: "Ajustes",
};
