export const passwordCardHeader = {
	blockButton: "Block multiple cards",
	main: {
		description:
			"Password cards are used instead of typed passwords and work by approximation, thus speeding up the operation. In this way, only those who have these cards will be able to authorize certain actions at the POS, offering greater control and security for relevant procedures such as bleeding and reversal.",
		labelText: "Search",
		placeholder: "Search by card owner",
		statusFilter: "Show only active cards",
		title: "Password card",
	},
	stockHistory: {
		cardOwner: "Card owner: {{name}}",
		title: "Password card - Stock history",
	},
};
