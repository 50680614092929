export const packages = {
	header: {
		subtitle: "Add balance to send messages to your customers",
		title: "Add balance",
	},
	modalPackage: {
		addBalance: "and add {{amount}} balance sms?",
		footer: {
			sellPackage: "Buy SMS package",
		},
		for: "by",
		label: "Do you want to buy the Package",
		title: "Buy package",
	},
	packageCard: {
		forSMS: "by SMS",
		sell: "Buy package",
		sms: "SMS",
		tagLabel: "Tag Label",
	},
	title: "Packages",
	xlsx: {
		date: "Date and time",
		filename: "sms-campaigns",
		impactedCustomers: "Impacted customers",
		message: "Message",
		place: "Place",
		status: "Status",
		title: "SMS Campaign History",
	},
};
