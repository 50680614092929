export const service = {
	descriptionInfoWarning:
		"The fields below are required for issuing invoices. And if not	are modified, invoices will be issued with Zig's fiscal standard.",
	insertCestManual: "Add manual CEST?",
	labelFields: {
		CEST: "CEST",
		NCM: "NCM",
		code: "Code",
		name: "Name",
		taxGroup: "Tax group",
		taxProfile: "Tax profile",
	},
	messageErrorNotReturnCest: "Could not return CEST to informed NCM",
	messageErrorNotReturnTaxGroups: "Unable to return tax groups",
	placeholderFields: {
		CEST: "Insert CEST",
		NCM: "Insert NCM",
		code: "Service code",
		name: "Insert name",
		selectCEST: "Select CEST",
		taxGroup: "Select Tax group",
		taxProfile: "Tax profile",
	},
	titleInfoWarning: "Data required for issuance",
};
