export const bills = {
	addBill: "Add bill",
	addBillSuccessNotification: "Conta criada com sucesso.",
	addTransfer: "Add transfer",
	billFields: {
		account: "Account",
		accountType: "Account Type",
		category: "Category",
		costCenter: "Cost center",
		description: "Description",
		discount: "Discount",
		dueDate: "Competence Date",
		filename: "exampleOfAccounts",
		fine: "Fine",
		interest: "Fees",
		observation: "Observation",
		payDate: "Payday",
		payed: "Paid?",
		paymentMethod: "Payment method",
		performDate: "Due date",
		reconciled: "Reconciled",
		supplier: "Supplier",
		value: "Value",
	},
	competence: "Competence",
	dueDate: "Due date",
	exportSpreadsheet: "Export spreadsheet",
	importSpreadsheet: "Import spreadsheet",
	modalTransfer: {
		add: "Add transfer",
		date: "Date",
		description: "Description",
		destinationAccount: "Destination account",
		edit: "Edit transfer",
		paid: "Paid?",
		sourceAccount: "Origin account",
		value: "Value",
	},
	printTitle: {
		any: "All bills",
		default: "Bills",
		toPay: "Bills to pay",
		toReceive: "Bills to receive",
		transfer: "Transfer",
	},
	printTitleCaps: {
		any: "ALL BILLS",
		toPay: "BILLS TO PAY",
		toReceive: "BILLS TO RECEIVE",
		transfer: "TRANSFERS",
	},
	tableBill: {
		account: "Receiving account",
		description: "Description",
		dueDate: "Due date",
		expireToday: "Expires today",
		expired: "Expired",
		notInformed: "Not informed",
		paid: "Paid?",
		performDate: "Competency date",
		value: "Value",
	},
	tableTransfer: {
		actions: "Actions",
		date: "Date",
		description: "Description",
		destinationAccount: "Destination account",
		paid: "Paid?",
		popConfirm: "Are you sure you want to delete this transfer?",
		sourceAccount: "Origin account",
		value: "Value",
	},
};
