export const editMenu = {
	add: "Add Product",
	deleteConfirm: "Are you sure you want to delete the menu?",
	deleteProducts: "Remove Products",
	deleteProductsConfirm:
		"Are you sure you want to remove all selected products from the menu?",
	errorEditNotification: "Error in ",
	errorProductIdentify:
		"unable to intentify the corresponding product of {{name}}, download a new template sheet and be careful with the 'identifier' column",
	goBack: "Back to all menus",
	massDelete: "Remove products in bulk",
	massEdit: "Edit products in bulk",
	menuActive: "Activate menu",
	okText: "Yes, I want it!",
	search: "Search products",
	successNotification: "All product information has been edited successfully",
	updatedPrices: "Updated Prices",
	warningNotification:
		"Menu edited, but there was a problem registering the opening hours:",
};
