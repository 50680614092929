export const transferForm = {
	balanceAvailable: "Balance available",
	enoughBalanceMessage: "Balance available for transfer",
	loadingTargetPlaceData: "Loading data from target place...",
	reviewLabels: {
		balanceAfterTransferAtSourcePlace: "Balance after transfer at source place",
		balanceAfterTransferAtTargetPlace: "Balance after transfer at target place",
	},
	selectPlaceLabel: "Select target place",
	selectPlacePlaceholder: "Select place to receive transfer",
	submitFailedMessage: "Failed to transfer value",
	submitSucceededMessage: "Value transferred successfully",
	transferTotalValueBtn: "Transfer all balance available",
	wizardLabels: {
		review: "Review data",
		target: "Target",
		value: "Value to transfer",
	},
};
