export const pos = {
	title: "Dispositivos: PDVs e Totens do Evento",
	open: "{{active}} de {{total}} PDV´s em aberto",
	info:
		"É necessário que o PDV esteja conectado à internet para sincronização das configurações.",
	searchPlaceholder: "Buscar dispositivo ou funcionário",
	noName: "Sem nome",
	error: "Essa operação só é permitida quando não tem nenhum PDV selecionado",
	deviceConfigColumn: {
		bluetooth: "Bluetooth",
		bluetoothMachine: "Maquininha bluetooth",
		connected: "conectada",
		disconnect: "desconectada",
		mobileData: "Dados móveis",
		nfc: "NFC",
		noData: "sem dados",
		off: "desligado",
		on: "ligado",
		tryingToConnect: "Tentando conectar à maquininha bluetooth",
		wifi: "Wifi",
		device: "Histórico da Maquininha",
	},
	editConfig: {
		bluetooth: "Bluetooth",
		configs: {
			checkbox: "Tentar parear maquininha bluetooth",
			label: "Configurações",
		},
		confirmMessage:
			"Tem certeza que deseja salvar as alterações? Ambos o wifi e dados móveis do(s) dispositivo(s) estão desabilitados, desta forma não será possível visualizar as informações atualizadas do dispositivo.",
		errorMessage:
			"Uma dessas preferencias 'Habilitar venda' e 'Habilitar venda em grupo' precisa estar ativa",
		label: "Editar Configurações",
		noName: "Preferência sem nome",
		preferences: "Preferências",
		title: "Modificar PDVs ({{count}})",
		wifi: "Wi-fi",
	},
	columns: {
		fingerPrint: "CÓDIGO",
		product: "PRODUTO",
		version: "VERSÃO",
		employee: "COLABORADOR",
		activation: "ATIVAÇÃO",
		lastUpdate: "ÚLTIMA ATUALIZAÇÃO",
		cleanedDataStatus: "LIMPOU?",
		hasFinishedActivity: {
			label: "OPERAÇÃO",
			finished: "Encerrado",
			open: "Em aberto",
		},
		devicePlatform: "MODELO",
		deviceConfig: "STATUS",
		date: "DATA",
		serialNumber: "SN",
	},
	settings: "Configurações",
	humanizedPreferencies: {
		dettachOnCheckout: "Desanexar automaticamente no checkout",
		hideDettachOption: "Esconder desanexar",
		hidePaymentModeVoucher: "Esconder modo de pagamento voucher",
		isGroupedSellEnabled: "Habilitar venda em grupo",
		isKeyboardOpenEnabled: "Teclado já aberto na busca",
		isNormalSellEnabled: "Habilitar venda",
		isNumericKeyboardInObsEnabled: "Observação com teclado numérico",
		isSearchByCodeEnabled: "Busca numérica",
		isSellWithoutCardEnabled: "Habilitar venda sem cartão",
		isStockTransfEnabled: "Transferir estoque",
		isStorageTransferPrinterLanActive:
			"Ativar impressora de transferencia de estoque por LAN",
		printTwoTickets: "Imprimir duas vias de venda",
		printViaBtForWaiter: "Mostrar botão para imprimir pedidos pendentes de garçons",
		registerWithoutCard: "Registro sem cartão",
		removeCreditDebitPaymentOption: "Remover opção de Débito e Crédito",
	},
	search: {
		label: "Busca",
		placeholder: "Busque por funcionário ou código do pdv",
	},
	deviceHistoryColumns: {
		date: "DATA/HORA",
		userName: "CLIENTE",
		paymentMethod: "MÉTODO DE PAGAMENTO",
		amount: "VALOR",
		usedValue: "VALOR USADO",
		status: "STATUS",
	},
	status: {
		approved: "APROVADO",
		manualCanceled: "CANCELADO MANUALMENTE",
		transferred: "TRANSFERIDO",
		open: "EM ABERTO",
		refunded: "ESTORNADO",
	},
	credit: "Crédito",
	debit: "Débito",
};
