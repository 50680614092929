export const addBillModalForm = {
	account: "Conta",
	accountPlaceholder: "Selecione uma conta",
	attachment: "Anexo",
	billAttachmentSize: "Arquivo muito grande (máx 20 MB)",
	billType: "Tipo",
	billtypePlaceholder: "Selecione um tipo",
	costCenter: "Centro de custo",
	costCenterPlaceholder: "Selecione um centro de custo",
	deleteBill: "Tem certeza que deseja excluir esta conta?",
	description: "Descrição",
	descriptionPlaceholder: "Insira uma descrição",
	dueDate: "Data de vencimento",
	invalidValue: "Valor inválido",
	loading: "Carregando...",
	observation: "Observação",
	payDate: "Data de pagamento",
	paymentMethod: "Método de pagameto",
	paymentMethodPlaceholder: "Selecione um método de pagamento",
	paymentMethods: {
		app: "Aplicativo",
		automaticDebit: "Débito automático",
		bankTransfer: "Transferência bancária",
		boleto: "Boleto bancário",
		check: "Cheque",
		creditCard: "Cartão de crédito",
		debitCard: "Cartão de débito",
		money: "Dinheiro",
		promissory: "Promissória",
	},
	performDate: "Data de competência",
	reconciled: "Reconciliado",
	status: "Pago",
	supplier: "Fornecedor",
	supplierPlaceholder: "Selecione um fornecedor",
	toPay: "Pagar",
	toReceive: "Receber",
	validateField: "Campo obrigatório",
	value: "Valor",
};
