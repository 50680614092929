export const crudMessage = {
	campaignModal: {
		confirmButton: "Create campaign",
		placeholder: "Enter the campaign name",
		title: "Campaign name",
		tooltipMessage:
			"OPTIONAL: Link this send to a campaign to make it easier to analyze the performance of multiple sends together.",
	},
	campaignSelect: {
		createOrSearch: "Search by campaign or create a new one",
		placeholder: "Select campaign",
		text:
			"OPTIONAL: Link this send to a campaign to make it easier to analyze the performance of multiple sends together.",
		title: "Campaign",
	},
	campaignsDetail: {
		canceled: "Cancelled",
		canceledMessage: "The message that was supposed to be sent on {{day}} was cancelled",
		client: "client",
		clients: "clients",
		columns: {
			clientName: "Client Name",
			cpf: "CPF",
			gender: "Gender",
			phone: "Phone",
			status: "Status",
		},
		genders: {
			female: "Woman",
			male: "Man",
			other: "Other",
			uninformed: "Not informed",
		},
		programmedMessage: "Your message was scheduled on {{day}} to be sent on {{date}}",
		sent: "Sent",
		sentInDay: "Sent on the day {{day}} to",
	},
	content: "Message Content",
	create: {
		client: "person",
		clients: "people",
		mes: "Click here to see hints about the content",
		subtitle: {
			sms:
				"Enter the content that will be sent to the selected customer list. Your message will impact ",
			whatsapp:
				"Select a template based on what you want to communicate and then change its parameters.",
		},
		subtitleTemplateList:
			"Select a template based on what you want to communicate and then change its parameters.",
		title: "New message",
	},
	day: "Day",
	edit: {
		subtitle: "Rewrite the message content or change the sending schedule.",
		title: "Edit Scheduled Message",
	},
	errors: {
		campaign: "Unable to create campaign.",
		editMessage: {
			sendNow: "Data updated. Message will be sent shortly.",
			success: "Data updated.",
		},
		place: "Could not identify place",
		smsCost: "SMS cost needs to be configured",
	},
	footer: {
		reprogram: "Reprogram SMS sending",
		scheduleShipping: "Schedule SMS sending",
		send: "Send SMS now",
	},
	hour: "Hour",
	modalMessage: {
		addBalance: "Add Balance",
		messageScheduled: {
			at: "at",
			cancelOrEdit:
				"If you want to unsubscribe or change the content, go back to the message list and edit the shipping information.",
			client: "client",
			clients: "clients",
			clientsCount: "Your message will be sent to ",
			day: "day",
			of: "of",
		},
		messageSent: {
			client: "client",
			clients: "clients",
			clientsCount: "Your message was sent to ",
			of: "of",
		},
		messageTips: {
			tipFour:
				"Launch of a new product? Filter your best customers and send all your product information.",
			tipThree:
				"Is there going to be an interesting promotion? Filter the group of customers who are entitled to the promotion and send targeted SMS.",
			tipTwo:
				"You can include important links for your customers. Links to your landing page or event ticket make it easier for the customer to access information and purchase tickets.",
			tips: "Tips of what you can do",
			typeOne:
				"A well-written message impacts your customers in a unique way! As you have {{characters}} characters, ideally your message is succinct, clear and direct.",
		},
		notBalance:
			"You don't have enough balance to send SMS to the selected group. Do you want to add the remaining balance?",
		title: {
			messageScheduled: "Scheduled message",
			messageSent: "Message sent",
			messageTips: "Message Tips",
		},
	},
	program: "Schedule shipping",
	resumeCard: {
		amountMessageNotReceived: {
			error: {
				sms: "SMS not received by error",
				whatsapp: "messages not received by error",
			},
			notRecieved: {
				sms: "SMS not received",
				whatsapp: "messages not received",
			},
			optout: {
				sms: "SMS not received by optout",
				whatsapp: "messages not received by optout",
			},
		},
		amountMessageReceived: {
			sms: "SMS received",
			whatsapp: "received messages",
		},
		amountMessageSent: "Total recipients",
		footerText: {
			addedValue: "The value of {{value}} will be added to Zig's monthly charge payment.",
			charactersLimit:
				"Character count has exceeded the limit. The SMS cost has been changed and the value of {{value}} will be added to Zig's monthly charge payment.",
			noExtraCost:
				"As there was no change in the amount of the recipient list, there will be no extra cost.",
			smsBalanceAddedValue:
				"The extra value of {{value}} will be added to Zig's monthly charge payment.",
			smsBalanceSubtractedValue:
				"The value of {{value}} will be deducted from Zig's monthly charge payment.",
			summarizes: "Messages to opt-out customers will not be charged.",
		},
		messageCost: "Shipping Cost",
		messageInProcess: {
			sms: "SMS in process",
			whatsapp: "Message in process",
		},
		postShipmentBalance: "Post Shipment Balance",
		rescheduledShippingCost: "Rescheduled Shipping Cost",
		resume: "Submission summary",
		sms: "SMS",
	},
	sendNow: "Send Now",
	success: {
		createTemplate: "New template requested",
	},
	summarizes: "Shipping data",
	textArea: {
		at: "at",
		character: "remaining character",
		characters: "characters remaining",
		content: "Message Content",
		copy: "Copy message content",
		footer:
			"Tip: To add variables and be able to change them in the future, type [VARIABLE] in square brackets.",
		placeholder: "Write the message content for the selected group...",
		successCopy: "Message copied to clipboard",
	},
};
