import { soldProducts } from "./sold-products";
import { chipsBurned } from "./chips-burned";
import { paymentMethodReport } from "./payment-method-report";
export const barReports = {
	bar: "Bar {{name}}",
	tabs: {
		chipsBurned,
		waiterReport: {
			label: "Vendas",
			filter: "Filtrar por tipo de transação",
		},
		deliveredProductsByBar: {
			label: "Produtos entregues por bar",
		},
		consolidatedBarReport: {
			filter: "Filtrar por tipo de transação",
			label: "Financeiro por bar",
			tab: "Faturamento por bar",
			sheet: {
				fileName: "financeiro-por-bar",
				name: "Produto",
				quantity: "Quantidade",
				title: "Financeiro por bar",
				totalDiscount: "Desconto total",
				totalValue: "Valor bruto",
				value: "Valor líquido",
				totalTax: "Imposto Total",
				vat: {
					totalValue: "Valor com IVA",
					valueWithDiscount: "Valor com desconto",
					value: "Valor sem IVA",
					totalTax: "Valor do IVA",
					totalTaxPercentage: "Porcentagem total do IVA (%)",
				},
			},
			total: "Total",
			paymentMethodReport,
		},
		soldProductReport: {
			filter: "Filtrar por tipo de transação",
			label: "Saída geral de produtos",
			sheet: {
				count: "Quantidade",
				fileName: "relatorio_de_saida_geral_de_produtos",
				fiscalCode: "SKU",
				name: "Produto",
				title: "Saída geral de produtos",
			},
		},
		soldProducts,
		reprintReport: {
			title: "Reimpressão de fichas",
			filename: "reimpressao-de-fichas",
			byTransaction: "Por transação",
			byProduct: "Por produto",
			variable: "Variável",
			variableTooltip:
				"O valor unitário de produtos com valor variável é a média dos valores vendidos",
			table: {
				authorizedBy: "Funcionário autorizador",
				date: "Data/hora",
				employee: "Funcionário Logado",
				transactionId: "ID da transação",
				device: "Máquina",
				product: "Produto",
				total: "Total",
				reprintTotal: "Total em reimpressões",
				reprintProductTotal: "Total de produtos reimpressos",
				authorizedByPassword: "Autorizado por senha",
				quantity: "Quantidade",
				productName: "Nome do produto",
				unitValue: "Valor unitário",
				value: "Valor",
			},
			tooltipTitle:
				"Esse relatório só irá trazer dados se os PDVs estiverem na versão 5.0.190 ou superior.",
		},
	},
	undefinedBar: "Bar Indefinido",
	productReturnsReport: {
		title: "Devolução de produto",
	},
};
