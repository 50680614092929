export const clients = {
	place: "Local",
	titleWithPlace: "Lista de clientes",
	searchTitle: "Tipo de documento",
	searchPlaceholder: "Digite o documento",
	searchClientByName: "Digite o nome",
	documentTypePlaceHolder: "Selecione o tipo de documento",
	filterTitle: "Filtros avançados",
	filterByProductTitle: "Filtrar por produto",
	clearFilter: "Limpar todos os campos",
	applyFilter: "Aplicar filtros",
	cancel: "Cancelar",
	daysOfTheWeek: {
		friday: "Sexta",
		monday: "Segunda",
		saturday: "Sábado",
		sunday: "Domingo",
		thursday: "Quinta",
		tuesday: "Terça",
		wednesday: "Quarta",
	},
	filter: {
		addProduct: "Adicionar outro produto",
		gender: "Gênero",
		noProducts: "Nenhum produto encontrado",
		period: "Período",
		product: "Produto",
		productsConsumed: "Produtos consumidos",
		quantity: "Quantidade",
		removeProduct: "Remover produto",
		selectProduct: "Selecione um produto",
		genderPlaceholder: "Selecione o(s) gênero(s)",
		periodFrequency: "Frequência",
		firstTime: "1ª vez",
		frequencyPlaceholder: "Escolha a frequência do cliente",
		daysOfTheWeek: "Dias da semana",
		daysOfTheWeekPlaceholder: "Selecione os dias da semana",
		consumptionVolume: "Consumação mínima",
		notUsedRecharges: "Resíduo de recarga mínima",
		birthdateRange: "Período de Aniversário",
		birthdateYear: "Ano de Aniversário",
		yearPlaceholder: "aaaa",
		products: "Produto(s)",
		productsPlaceholder: "Selecione o(s) produto(s)",
		errors: {
			type: "Dados inválidos",
			birthdate: {
				year: "Insira um ano entre 1900 e o ano atual",
			},
		},
	},
	gender: {
		female: "Mulher",
		male: "Homem",
		others: "Outros",
	},
	productError: "A quantidade de {{product}} deve ser maior que zero",
	search: "Buscar",
	table: {
		birthDate: "Data de aniversário",
		consumption: "Consumiu",
		countClients_one: "cliente encontrado",
		countClients_other: "clientes encontrados",
		countTicketClients: "ticket médio por cliente",
		cpf: "CPF",
		email: "E-mail",
		infoLabelTable: "Selecione uma Mesa para ver detalhes.",
		emptyText: "Sem dados a serem exibidos",
		firstTimeAtPlace: "1ª vez",
		hasApp: "Aplicativo ativo",
		infoLabel: "Selecione um cliente para ver detalhes.",
		moreResults: "Carregar mais resultados",
		moreThan: "mais de ",
		name: "Cliente",
		phone: "Telefone",
		presences: "Presença",
		remaningValueOnPlace: "Saldo disponível",
		uninformed: "Não informado",
	},
};
