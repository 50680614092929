export const cmvCost = {
	alert: "Atenção! Existem produtos com quantidade negativa que devem ser revisados.",
	averageCost: "Custo médio x preço de venda",
	averageCostSelection: "Custo médio x preço de venda da seleção",
	export: "Exportar para Excel",
	filter: {
		apply: "Filtrar",
		category: {
			all: "Todas categorias",
			label: "Selecionar categoria",
			title: "Categoria",
		},
		clear: "Limpar filtro",
		label: "Filtrar por",
		product: {
			all: "Todos produtos",
			label: "Selecionar produto",
			title: "Produto",
		},
		storage: {
			all: "Todos estoques",
			label: "Selecionar estoque",
			title: "Estoque",
		},
	},
	label: "Custos",
	products: "Produtos",
	selectTotalCost: "Custo total da seleção",
	showAll: "Mostrar todos {{count}} produto(s)",
	showLess: "Mostrar menos",
	storageTotalCost: "Custo total em estoque",
	table: {
		checkbox: "Não mostrar itens com ficha técnica",
		columns: {
			averageCost: "Custo médio",
			code: "SKU",
			count: "Quantidade",
			margin: "Margem",
			name: "Produto",
			realCost: "Preço de venda",
			stockValue: "Valor em estoque",
		},
	},
	sheet: {
		title: "Relatório de Custos",
		fileName: "relatorio-de-custos",
		name: "Produto",
		fiscalCode: "SKU",
		count: "Quantidade",
		unityCount: "Quantidade (Unidade)",
		averageCost: "Custo médio",
		stockValue: "Valor em estoque",
		realCost: "Preço de venda",
		margin: "Margem",
	},
};
