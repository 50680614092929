import { message } from "./message";
import { packages } from "./packages";
import { whatsapp } from "./whatsapp";

export const communication = {
	actions: {
		campaignCategoryModal: {
			placeholder: "Selecione uma campanha",
			successMessage: "Campanha atrelada a mensagem",
			text: "Selecionar campanha",
			textButtonConfirm: "Confirmar seleção",
			title: "Atrelar envio à campanha",
			tooltipMessage:
				"OPCIONAL: Atrele esse envio à uma campanha para facilitar a análise da performance de múltiplos envios juntos.",
		},
		cancelModal: {
			at: "às",
			cancel: "Cancelar envio",
			clients: "clientes no dia",
			close: "Fechar",
			confirm: "Ao cancelar o envio, seu saldo retornará à sua conta em algumas horas.",
			text: "Você deseja cancelar o envio da mensagem agendada para ",
		},
		list: {
			canceled: "Ver relatório da mensagem",
			schedule: {
				cancelShipping: "Cancelar envio",
				editAddressees: "Editar destinatários",
				editMessage: "Editar conteúdo da mensagem",
				hitchCampaign: "Atrelar envio à campanha",
			},
			sent: "Ver relatório de envio",
		},
	},
	at: "às",
	columns: {
		campaign: "Campanha",
		channel: "Canal",
		date: "Data e hora",
		impactedCustomers: "Clientes impactados",
		message: "Mensagem",
		place: "Casa",
		status: "Status",
	},
	export: "Exportar para Excel",
	label: "Mensagens enviadas",
	message,
	modalChannel: {
		channel: {
			email: {
				description:
					"Selecione uma casa, filtre os clientes e envie emails personalizados",
				title: "E-mail",
			},
			sms: {
				description:
					"Selecione uma casa, filtre os clientes e envie mensagens de texto padrão",
				title: "SMS",
			},
			whatsapp: {
				description:
					"Selecione uma casa, filtre os clientes e envie mensagens de WhatsApp",
				title: "WhatsApp",
			},
		},
		tags: {
			comingSoon: "Em breve",
			new: "Novo",
		},
		title: "Canal de comunicação",
	},
	new: "Nova mensagem",
	packages,
	permissionDenied: "Nenhum local com permissão para gerenciar mensagens de WhatsApp",
	status: {
		canceled: "cancelado",
		delivered: "entregue",
		error: "erro",
		notSent: "não enviado",
		optout: "optout",
		scheduled: "agendado",
		sending: "enviando",
		sent: "enviado",
	},
	title: "Mensagens",
	warningCard: {
		label: "O valor de {{value}} será adicionado ao pagamento da cobrança mensal da Zig.",
		title: "Pagamento",
	},
	whatsapp,
};
