export const fiscalReport = {
	activeTab: "relatorio-fiscal",
	columns: {
		date: "Data",
		emitted: "Faturamento emitido",
		emittedNfce: "Notas de consumo",
		emittedNfse: "NOtas de serviço",
		eventName: "Evento",
		total: "Total",
		totalReceipt: "Faturamento emissível",
	},
	errorNotification: "Data limite {{date}}",
	fiscalProfile: "Perfil fiscal",
	period: "Período",
	sheet: {
		columns: {
			date: "Data",
			emitted: "Faturamento emitido",
			emittedNfce: "Notas de consumo",
			emittedNfse: "Notas de serviço",
			eventName: "Evento",
			totalReceipt: "Faturamento emissível",
		},
		filename: "relatório_fiscal",
		title: "Relatório Fiscal",
	},
	title: "Relatório fiscal",
};
