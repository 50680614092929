export const report = {
	empty: {
		filters: {
			subtitle: "Selecione outra opção e tente novamente",
			title: "Não encontramos resultado para essa seleção",
		},
		noneList: {
			subtitle: "Os relatórios aparecerão aqui quando você tiver uma lista cadastrada",
			title: "Parece que você não criou uma lista ainda",
		},
		search: "Não encontramos resultado para a sua busca",
	},
	file: {
		checkinDetails: "Detalhes do check-in",
		consumptionTotal: "Total em consumo",
		document: "CPF",
		entranceTotal: "Total de entrada",
		entranceType: "Tipo de entrada",
		filename: "relatorio-lista-de-convidados",
		listName: "Nome da Lista",
		name: "Nome",
		numberOfActiveGuests: "Check-in realizados",
		numberOfGuests: "Total de convidado",
		promoter: "Promoter",
		titleByGuestList: "Relatório por lista de convidados",
		titleGeneral: "Relatório geral",
		total: "Total geral",
		upgrade: "Upgrade de entrada",
		phone: "Telefone",
		birthDate: "Data de nascimento",
	},
	filters: {
		period: "Período",
		promoter: {
			placeholder: "Adicione filtro por promoters",
			title: "Filtrar por promoter",
		},
		relation: {
			placeholder: "Adicione filtros por lista",
			title: "Filtrar por lista de convidados",
		},
	},
	search: "Buscar",
	tabs: {
		byList: {
			columns: {
				consumptionTotal: "Total em consumo",
				entranceTotal: "Total em entrada",
				guests: "Convidados",
				promoters: "Promoters",
				relationLists: "Listas",
				total: "Total geral",
			},
			title: "Por lista de convidados",
		},
		general: {
			cards: {
				consumptionTotal: "Total em consumo",
				entranceTotal: "Total em entrada",
				guests: "Convidados",
				promoters: "Promoters",
				relationLists: "Listas",
				total: "Total geral",
			},
			columns: {
				checkin: "Detalhes do check-in",
				consumption: "Consumo",
				cpf: "CPF",
				entrance: "Tipo de entrada",
				generalTotal: "Total geral",
				guestName: "Nome",
				listName: "Lista",
				promoter: "Promoter",
				upgradeEntrance: "Upgrade de entrada",
				phone: "Telefone",
				birthDate: "Data de nascimento",
			},
			title: "Visão geral",
		},
	},
	templateTitle: "Relatório Geral",
	title: "Relatórios",
};
