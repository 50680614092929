export const search = {
	activeTab: "search",
	placeholder: "Nº da nota fiscal",
	search: "Buscar",
	export: "Exportar Excel",
	foreignDescription:
		"Permite buscar pelo número do documento fiscal e filtrar por período.",
	sheet: {
		title: "Busca de notas fiscais",
		filename: "busca_de_notas",
		columns: {
			xml: "XML",
			cancelledXml: "XML de Cancelamento",
			jsonInvoice: "JSON Nota Fiscal",
			jsonCreditNote: "JSON Nota de Crédito",
			emitted: "Data de emissão",
			everestUploadTime: "Data de upload no Everest",
			isCanceled: "Cancelada",
		},
	},
	columns: {
		xml: {
			label: "XML",
			download: "Baixar XML da nota fiscal",
			labelCancelledInvoice: "XML da Nota Fiscal Cancelada",
			downloadCancelledInvoice: "Baixar XML de Cancelamento",
		},
		json: {
			labelInvoice: "JSON do documento",
			labelCreditNote: "JSON da Nota de Crédito",
			download: "Baixar JSON",
		},
		downloadPDF: "Baixar PDF",
		emitted: "Data de emissão",
		transactionDate: "Data da transação",
		everestUploadTime: "Data de upload no Everest",
		isCanceled: "Cancelada",
		type: "Tipo",
	},
	title: "Busca de notas fiscais",
};
