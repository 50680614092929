export const tableRangeSettings = {
	card: {
		barsLabel: "Impressão nos bares",
		title: "Mesas {{from}} a {{to}}",
	},
	deleteModal: {
		message:
			"Você deseja excluir a configuração de impressão do intervalo de mesas {{from}} a {{to}? Ao completar esta ação você perderá os dados armazenados e não terá como recuperá-los.",
		title: "Excluir intervalo de mesas",
	},
	drawer: {
		title: "intervalo de mesas",
	},
	form: {
		addIntervalBtn: "Adicionar outro intervalo",
		bars: {
			label: "Bares que imprimirão os pedidos das mesas acima",
			placeholder: "Pesquise por nome",
		},
		editingWarningTooltip: "Não é possível adicionar novos intervalos no modo edição.",
		errors: {
			atLeastOneBar: "Selecione pelo menos um bar para impressão (intervalo {{number}})",
			fromTableAlreadyUsed: "Número da mesa inicial já utilizado (intervalo {{number}})",
			missingFromTable: "Digite o número da mesa inicial (intervalo {{number}})",
			missingToTable: "Digite o número da mesa final (intervalo {{number}})",
			toTableAlreadyUsed: "Número da mesa final já utilizado (intervalo {{number}})",
		},
		generalWrapperLabel: "Intervalo {{number}}",
		intervalWrapperLabel: "Intervalo de mesas",
		removeIntervalBtn: "Remover intervalo",
		status: {
			errors: {
				description:
					"Não foi possível adicionar o intervalo de mesas informado. Verifique se não há repetição de alguma mesa cuja configuração de impressão já tenha sido feita anteriormente.",
				title: "Ops... Algo não saiu como planejado.",
			},
			success: {
				titleCreating: "Intervalo de mesas configurado com sucesso!",
				titleEditing: "Edição de intervalo de mesas salva.",
			},
		},
	},
	header: {
		addBtn: "Adicionar intervalo de mesas",
		filter: {
			label: "Filtrar por bar",
			optionAllLabel: "Mostrar todos",
			placeholder: "Selecione ou procure o bar desejado",
		},
	},
};
