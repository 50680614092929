import { pos } from "./pos";
import { posBar } from "./pos-bar";
import { barReports } from "./bar-reports";
import { panel } from "./panel";
import { clients } from "./clients";
import { conference } from "./conference";
import { entranceBilling } from "./entrance-billing";
import { eventReports } from "./event-reports";
import { financialReport } from "./financial-report";
import { zigTags } from "./zig-tags";
import { booking } from "./booking";
import { multivendor } from "./multivendor";
import { sellChip } from "./sell-chip";
import { operationReports } from "./operation-reports";
import { hawkersReport } from "./hawkers-report";
import { tableReports } from "./table-reports";

export const eventEN = {
	barReports,
	booking,
	clients,
	entranceBilling,
	eventReports,
	financialReport,
	multivendor,
	panel,
	pos,
	posBar,
	sellChip,
	zigTags,
	conference,
	operationReports,
	hawkersReport,
	tableReports,
};
