export const loyalty = {
	commingSoon: {
		cards: {
			paper: {
				description:
					"Seus clientes poderão acompanhar e fazer a troca dos pontos ganhos através do Zig.app.",
				title: "Diga adeus ao papelzinho",
			},
			retainCustomers: {
				description:
					"Escolha os locais participantes, prêmios, pontuações e engaje os seus clientes.",
				title: "Retenha clientes",
			},
			trackResults: {
				description:
					"Você poderá acompanhar o desempenho dos seus programas de fidelidade.",
				title: "Acompanhe os resultados",
			},
		},
		description:
			"Você poderá escolher diversas formas de como seus clientes irão pontuar e os prêmios que eles irão ganhar. Aumente a retenção de clientes incentivando eles a visitarem locais ou comprar um produto em troca de pontos, entre outras opções presentes no módulo de fidelização.",
		subtitle:
			"Melhore seus resultados e a experiência dos seus clientes a partir dos programas de fidelidade.",
	},
	title: "Fidelização de clientes",
	tabs: {
		programs: {
			createSteps: {
				points: {
					pointRules: {
						npsAnswer: {
							mainTitle: "Resposta de pesquisa de satisfação",
							description:
								"Defina o valor dos pontos que os clientes ganharão quando responderem a pesquisa de satisfação",
							detailTitle: "Pontuação por resposta de pesquisa de satisfação",
						},
					},
					npsAnswerPoints: {
						inputLabel: "Pontuação",
						inputPlaceholder: "Digite a pontuaçao ao realizar pagamento por aplicativo",
						continueButtonText: "Adicionar pontuação",
					},
					paidWithApp: {
						inputLabel: "Pontuação",
						mainTitle: "Pagamento com Aplicativo",
						inputPlaceholder: "Digite a pontuaçao por resposta de pesquisa de satisfação",
						continueButtonText: "Adicionar pontuação",
						detailsTitle: "Pontuação por Pagamento com Aplicativo",
						subtitleRow: "Pagar conta ou compra com aplicativo",
						description:
							"Defina a pontuação que seus clientes ganharão ao realizar pagamento da sua conta/compra com aplicativo",
					},
				},
			},
		},
	},
};
