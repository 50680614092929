import React, { ReactNode } from "react";
import { Button, IButtonProps } from "#components/primitive";
import Icon from "#components/icon";
import { Modal } from "antd";
import classNames from "classnames";

import s from "./modal-onboarding.module.scss";

import { useDisclosure } from "#resources/hooks";

const DEFAULT_WIDTH = 624;

type Action = {
	label: string | ReactNode;
	onClick?: () => void;
	buttonProps?: Partial<Omit<IButtonProps, "children" | "onClick">>;
};

export type ModalOnboardingProps = {
	onClose?: () => void;
	/**
	 * Default value: 624
	 */
	width?: number;
	title: string | ReactNode;
	content: string | ReactNode;
	className?: string;
	actions: {
		primary: Action;
		secondary?: Action;
	};
	bannerSrc: string;
};

export const ModalOnboarding = ({
	onClose: _onClose,
	width = DEFAULT_WIDTH,
	title,
	content,
	actions,
	className,
	bannerSrc,
}: ModalOnboardingProps) => {
	const { isOpen, onClose } = useDisclosure({
		initialValue: true,
		onCloseCallback: _onClose,
	});

	return (
		<Modal
			footer={null}
			width={width}
			onCancel={onClose}
			className={classNames(s.modal, className)}
			open={isOpen}
		>
			<div className={s.wrapper}>
				{bannerSrc && <img className={s.banner} src={bannerSrc} alt="Banner" />}

				<div className={s.content}>
					<h2>{title}</h2>

					<p>{content}</p>

					<footer>
						{actions.secondary && (
							<Button
								variant="secondary"
								onClick={() => {
									actions.secondary?.onClick?.();
									onClose();
								}}
								{...actions.secondary.buttonProps}
							>
								{actions.secondary.label}
							</Button>
						)}

						<Button
							trailingIcon={<Icon icon="chevron-right" />}
							onClick={() => {
								actions.primary.onClick?.();
								onClose();
							}}
							{...actions.primary.buttonProps}
						>
							{actions.primary.label}
						</Button>
					</footer>
				</div>
			</div>
		</Modal>
	);
};
