import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import enterprise from "#resources/api/enterprise-client";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import {
	BaseMusicTag,
	BaseTag,
	EditPlaceDetail,
	PlaceDetail,
	PlaceDetailImage,
	PlaceDetailMusicTag,
	PlaceDetailTag,
	WorkingDays,
} from "#resources/api/enterprise-generated";
import i18n from "#i18n/index";

const t = i18n.t;

export class WhatsUpStore {
	public rootStore: RootStore;

	constructor(rootSore: RootStore) {
		this.rootStore = rootSore;
	}

	public clean() {
		this.getPlaceDetail.reset();
		this.setPlaceDetailStatus.reset();
		this.getPlaceDetailTags.reset();
		this.getAllBaseTags.reset();
		this.getWorkingDays.reset();
		this.editPlaceDetailTags.reset();
		this.setWorkingDays.reset();
		this.addImageToPlaceDetail.reset();
		this.deletePlaceDetailImage.reset();
		this.getAllMusicBaseTags.reset();
		this.getPlaceDetailMusicTags.reset();
		this.editPlaceDetailMusicTags.reset();
	}

	public getPlaceDetail = new fetchModel<
		{
			placeId: string;
		},
		PlaceDetail
	>({
		fnPromise: args => enterprise.getPlaceDetail(args),
		onError: err => showErrorNotification(err.message),
	});

	public editPlaceDetail = new fetchModel<
		{
			placeId: string;
			detail: EditPlaceDetail;
		},
		PlaceDetail
	>({
		fnPromise: args => enterprise.editPlaceDetail(args),
		onError: err => showErrorNotification(err.message),
	});

	public setPlaceDetailStatus = new fetchModel<
		{
			placeId: string;
			status: boolean;
		},
		PlaceDetail
	>({
		fnPromise: args => enterprise.setPlaceDetailStatus(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: placeDetail =>
			showSuccessNotification(
				placeDetail.appearsInApp
					? t("store:whatsUpStore.setPlaceDetailStatus_active")
					: t("store:whatsUpStore.setPlaceDetailStatus_inactive"),
			),
	});

	public getPlaceDetailTags = new fetchModel<
		{
			placeId: string;
		},
		PlaceDetailTag[]
	>({
		fnPromise: args => enterprise.getPlaceDetailTags(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllBaseTags = new fetchModel<{}, BaseTag[]>({
		fnPromise: () => enterprise.getAllBaseTags(),
		onError: err => showErrorNotification(err.message),
	});

	public getWorkingDays = new fetchModel<
		{
			placeId: string;
		},
		WorkingDays
	>({
		fnPromise: args => enterprise.getWorkingDays(args),
		onError: err => showErrorNotification(err.message),
	});

	public editPlaceDetailTags = new fetchModel<
		{
			placeId: string;
			tags: PlaceDetailTag[];
		},
		PlaceDetailTag[]
	>({
		fnPromise: args => enterprise.editPlaceDetailTags(args),
		onError: err => showErrorNotification(err.message),
	});

	public setWorkingDays = new fetchModel<
		{
			placeId: string;
			days: WorkingDays;
		},
		WorkingDays
	>({
		fnPromise: args => enterprise.setWorkingDays(args),
		onError: err => showErrorNotification(err.message),
	});

	public addImageToPlaceDetail = new fetchModel<
		{
			placeId: string;
			data: Buffer;
		},
		PlaceDetailImage
	>({
		fnPromise: args => enterprise.addImageToPlaceDetail(args),
		onError: err => showErrorNotification(err.message),
	});

	public deletePlaceDetailImage = new fetchModel<
		{
			placeId: string;
			imageId: string;
		},
		void
	>({
		fnPromise: args => enterprise.deletePlaceDetailImage(args),
		onError: err => showErrorNotification(err.message),
	});

	public getAllMusicBaseTags = new fetchModel<{}, BaseMusicTag[]>({
		fnPromise: () => enterprise.getAllMusicBaseTags(),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceDetailMusicTags = new fetchModel<
		{
			placeId: string;
		},
		PlaceDetailMusicTag[]
	>({
		fnPromise: args => enterprise.getPlaceDetailMusicTags(args),
		onError: err => showErrorNotification(err.message),
	});

	public editPlaceDetailMusicTags = new fetchModel<
		{
			placeId: string;
			musicTagIds: PlaceDetailMusicTag[];
		},
		PlaceDetailMusicTag[]
	>({
		fnPromise: args => enterprise.editPlaceDetailMusicTags(args),
		onError: err => showErrorNotification(err.message),
	});
}
