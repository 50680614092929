export const storageHistory = {
	title: "Relatório de Movimentação",
	description:
		"Neste relatório, é possível analisar as movimentações de entrada e saída por estoque, produto e categoria durante o período desejado, levando em consideração as perdas associadas.",
	label: "Movimentação",
	filename: "relatorio-de-movimentacao",
	print: "Imprimir",
	export: "Download em Excel",
	cleanFilters: "Limpar filtros",
	emptyState: {
		title: "Selecione e aplique os filtros para começar",
		description:
			"Você precisa selecionar ao menos um estoque, uma categoria ou um produto e o período para começar",
	},
	storages: {
		label: "Estoques",
		placeholder: "Busque por estoque",
	},
	filterBy: {
		label: "Filtrar por",
		category: "Categoria",
		product: "Produto",
	},
	products: {
		label: "Produtos",
		placeholder: "Busque por produto",
	},
	categorys: {
		label: "Categorias",
		placeholder: "Busque por categoria",
	},
	period: "Período",
	filter: "Aplicar filtros",
	hideProducts: "Não mostrar itens com ficha técnica",
	dateFilterBy: {
		label: "Filtrar período por",
		options: {
			events: "Eventos entre o período",
			interval: "Todos entre o período",
		},
	},
	columns: {
		cardExchange: "Devoluções",
		inicial: "Quantidade Inicial",
		input: "Entrada de produtos",
		lost: "Perdas",
		manual: "Ajuste Manual",
		name: "Produto",
		productionIn: "Entrada de produção",
		productionOut: "Saída de produção",
		result: "Final",
		sell: "Vendas",
		transfer: "Transferência interna",
		ube: "Unidade",
		lossPercentage: "Porcentagem de perdas",
	},
	editModal: {
		alert: "Alerta",
		below: "abaixo de",
		critical: "Crítico",
		delete: "Excluir alerta",
		deleteWarning: "Tem certeza que deseja excluir o alerta de {{productName}}?",
		invalidText:
			"O valor de estoque em alerta não pode ser menor que o valor de estoque critico",
		storage: "Estoque",
		storageIn: "Estoque em",
		title: "Editar alertas de estoque para {{productName}}",
	},
	printable: {
		final: "Final",
		initialQuantity: "Qnt. Inicial",
		internalTransfer: "Transf. Interna",
		losses: "Perdas",
		manualAdjustment: "Ajuste Manual",
		product: "Produto",
		productEntry: "Ent. de Prod.",
		productionEntry: "Ent. de Produção",
		productionOutput: "Saída de Produção",
		sales: "Vendas",
		withoutProducts: "Sem produtos selecionados",
	},
	storageReport: "Relatório de estoque",
};
