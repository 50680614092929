export const eventReports = {
	cashierClosings: {
		all: "All",
		balanceTitle: "Income per cashier",
		balanceTitleDay: "Income per cashier on day {{day}}",
		card: {
			bleeding: "Bleeding",
			cardDevolution: "Card devolutions",
			cash: "Cash",
			casher_one: "{{numberOfCashiers}} Cashier",
			cashier_other: "{{numberOfCashiers}} Cashiers",
			changeFund: "Change fund",
			closed: "Closed",
			difference: "Difference",
			modality: "Modality",
			noFlag: "No Flag",
			notClosed_one: "({{numberOfOpenCashiers}} not closed)",
			notClosed_other: "({{numberOfOpenCashiers}} not closed)",
			open: "Open",
			received: "Billed",
			productDevolution: "Product Returns",
			rechargeDevolutions: "Recharge devolutions",
			total: "TOTAL",
			xlsx: {
				cashier: "Cashier",
				closed: "Closed",
				closingCashier: "Closing by Cashier",
				closingResume: "Closing Summary",
				difference: "Difference",
				filename: "closing-by-cashier-in-place",
				filenameEvent: "closing-by-cashier-{{eventDate}}",
				modality: "Modality",
				received: "Billed",
			},
		},
		cashier: "Cashier",
		filenameBalance: "income_per_cashier",
		header: {
			addBleed: "Add bleed",
			addChange: "Add change fund",
			addObs: "Add observation",
			cashierHistory: "View cashier history",
			closeCashier: "Are you sure you want to close the {{cashierName}} cashier?",
			closeCashierButton: "Close cashier",
			historyTitle: "Cashier history - {{cashierName}}",
			obs: "Observation",
			reopenCashier: "Are you sure you want to reopen the cashier?",
			reopenCashierButton: "Reopen cashier",
			transactionalHistory: "View transactional history",
		},
		info: {
			addNewModality: "Add new modality",
			billed: "Invoiced",
			bleeding: "Bleeding",
			bleeds: "Bleeds",
			cancel: "Cancel changes",
			cardDevolution: "Card devolution",
			cashierNotClosed: "Cashier not closed",
			cashierReceipt: "Income by cashier",
			cashier_one: "cashier",
			cashier_other: "cashier",
			changeFund: "Change fund",
			changes: "Changes",
			closeCashier: "Close cashier",
			closed: "Closed",
			closingCashier: "Cashier closing",
			createBleed: "Create bleed",
			createChange: "Add change fund",
			devolutions: "Returned",
			modality: "Modality",
			negative: "Difference",
			notClosed: "not closed",
			rechargeDevolutions: "Recharge devolutions",
			save: "Save closing",
			searchCashier: "Search cashier by name",
			totalBilled: "Total Billed",
			totalClosed: "Total Closed",
		},
		modal: {
			addFlag: "Add flag",
			cashierPosts: "Post-paid transactions",
			cashierRecharges: "Recharges",
			cashiers: "Cashier",
			changeFund: "Change fund",
			column: {
				author: "Employee",
				client: "Customer",
				clients: "Customer(s)",
				zigCode: "ZigTag",
				date: "Date",
				method: "Method",
				obs: "Observation",
				payments: "Payments",
				total: "Total",
				valueComsumed: "Consumed Value",
				valueRecharged: "Recharged value",
			},
			history: "Historic",
			historyTitle: "History of {{name}}",
			saveValues: "Saving values",
			value: "Value",
		},
		valueGreaterThanZero: "The value must be greater than 0",
		value: "Value",
		noCard: "No card",
		noCashierSelected: "No cashier selected",
		paymentMethod: {
			ame: "Ame",
			anticipated: "Anticipated",
			bonus: "Bonus",
			cash: "Money",
			credit: "Credit",
			dashboard: "Dashboard",
			debit: "Debit",
			deliveryOnline: "Delivery Online",
			devolution: "Devolutions",
			foodVoucher: "Food Voucher",
			ifood: "Ifood",
			installment: "Installment",
			pix: "Pix",
			postEvent: "Paid after event",
			rappi: "Rappi",
			subtotal: "Subtotal",
			total: "Total",
			uber: "Uber",
			voucher: "Voucher",
			zigCredit: "Credit Zig",
			zigDebit: "Zig Debit",
		},
		postPaid: "Postpaid transactions",
		recharges: "Recharges",
		total: "Total",
		transactionsTitle: "History of {{name}}",
	},
	debtorsReport: {
		modal: {
			addPayment: "Add payment",
			bonus: "Pay with bonus",
			cash: "Cash",
			column: {
				bonus: "Bonus",
				value: "Value",
			},
			confirm: "Confirm",
			creditCard: "Credit card",
			debitCard: "Debit card",
			eventScope: "event",
			multiplePaymentConfirm_part1: "This payment ",
			multiplePaymentConfirm_part2: "will pay {{value}}",
			multiplePaymentConfirm_part3:
				"from customer in {{scope}}, are you sure you want to do this?",
			multiplePaymentError: "Attention! Add a payment method",
			discharge: "Discharge",
			observation: "Observation",
			observationPlaceholder: "Add an observation",
			openConsumption: "Open consumption",
			pay: "Pay",
			paymentConfirm_part1: "This payment",
			paymentConfirm_part2: "will pay ALL customer debt",
			paymentConfirm_part3: "in {{scope}}, are you sure you want to do this?",
			paymentConsumption: "Paying in consumption",
			paymentMethod: "Payment method",
			paymentMethodError: "Select a payment method",
			paymentService: "Paying in service",
			placeScope: "place",
			postEvent: "Mark as paid",
			reason: "Justification",
			reasonPlaceholder: "Add bonus justification",
			select: "Select",
			service: "Service ({{tip}}%)",
			submitError:
				"It is only possible to overpay up to 25% of the total outstanding amount.",
			total: "Total",
			voucher: "Voucher",
			yes: "Yes",
			pixCharge: "PIX's charge",
			sendCharge: "Send Charge",
			openValues: "Open values",
			totalDebit: "Total debit",
			pixValue: "Pix value",
			email: "Email",
			emailPlaceholder: "Email's customer",
			fieldRequired: "Field Required",
			emailError: "Invalid email",
			phoneError: "Invalid phone",
			openService: "Open Service",
		},
		title: "Open accounts",
		alertTitleOpenBill: "It is not possible to send bills in the event report",
		alertMessageOpenBill:
			"To send bills, go to the [Locations] menu > select the location > [Financial Reports] menu > 'Outstanding Accounts' tab and use the link in the 'Charge' column.",
	},
	individualLimitPost: {
		columns: {
			client: "Customer",
			date: "Time",
			employeeName: "Employee",
			emptyText: "No data to display.",
			limit: "Limit increase",
			newLimite: "New limit",
			username: "User",
		},
		filename: "individual_pos_limit_change_report",
		searchPlaceholder: "Search by user, id or name",
		title: "Customer information",
		titleTab: "Individual post limit change",
	},
	minimumConsumption: {
		title: "Early consumption",
	},
	openedBillPayment: {
		columns: {
			client: "Customer",
			document: "Document",
			employeeName: "Paid for",
			method: "Method",
			openedEventDate: "Open account date",
			tipValue: "Tip value",
			name: "Customer",
			reason: "Reason",
		},
		filename: "rappi_discount_report",
		rappiDiscounts: "DRappi Discounts",
		title: "Rappi Discounts",
	},
	rechargeConsumption: {
		title: "Recharge devolution",
		filename: "recharge-devolution",
		detailDialog: "View recharge returned by the cashier",
		rechargesRefundedBy: "Recharges returned by {{author}}",
		header: {
			title: "Recharge devolutions",
			description: "View the report of all recharge devolutions",
		},
		printTitle: "Reports of recharge devolutions",
		paymentIn: "Pmt. in {{category}}",
		categoryPayment: {
			cash: "cash",
			pix: "pix",
			rescue: "recharge website",
		},
		tableTitle: "Devolution in {{category}}",
		emptyTableDescription: "There were no refunds in {{category}}",
		modalTitle: "Recharges returned in {{category}}",
		columns: {
			ame: "Ame",
			anticipated: "Anticipated",
			author: "Cashier",
			cash: "Cash",
			client: "Client",
			credit: "Credit",
			dashboard: "Dashboard",
			debit: "Debit",
			deliveryOnline: "Online Delivery",
			ifood: "Ifood",
			method: "Method",
			pix: "PIX",
			rappi: "Rappi",
			total: "Total",
			uber: "Uber",
			value: "Value",
			voucher: "Voucher",
			totalCashReturn: "Total refunded in cash",
			totalPixReturn: "Total refunded in pix",
			totalRescueReturn: "Total returned by recharge site",
			sumReturned: "total returned added",
		},
		columnsDetailsTable: {
			client: "Client",
			paymentMethod: "Payment method",
			value: "Value",
			totalCashReturn: "Total refunded in cash",
			totalPixReturn: "Total refunded in pix",
			totalRescueReturn: "Total returned by recharge site",
			sumReturned: "total returned added",
		},
		excel: {
			title: "Recharges returned in ",
			fileName: "recharges-devolutions-details",
		},
	},
	refunded: {
		zigTable: "Zig Table",
		zigTag: "Zig Card",
		columns: {
			canceled: "Cancelado",
			category: "Category",
			clients: "Customers",
			count: "Quantity",
			date: "Date",
			moreRefundedBuyer_other: "and {{buyers}} more people",
			moreRefundedBuyer_one: "and {{buyers}} more person",
			operationType: "Operation",
			name: "Product's/Entrances name",
			refundObs: "Observation",
			refunded: "Refunded",
			refundedBy: "Refunded By",
			soldBy: "Sold By",
			totalCount: "Total amount",
			totalRefunded: "Total ({{total}} refunded)",
			totalValue: "Total Value",
			type: "Type",
			value: "Value",
			reason: "Reason",
		},
		filename: "refunded-products",
		title: "Refunded/Cancelled Products",
	},
	resumeReport: {
		fileName: "billing-{{eventName}}",
		finished: {
			end: "Closed: {{end}}h",
			not: "The event has not closed",
		},
		reportIn: "Billing for event '{{eventName}}'",
		title: "Resume",
	},
	servicesRemoval: {
		clientInfo: "Customer Information",
		columns: {
			date: "Date",
			name: "Customer",
			reason: "Reason",
			total: "Total Removed",
			value: "Value",
			employee: "Employee",
		},
		goTo: "go to",
		title: "Removal of services by cashier",
		titlePrint: "Services Removed",
	},
	tabs: {
		afterClosingEvent: "Actions synced after event",
		bonusByProduct: "Bonus per product",
		cashierClosings: "Cashier closings",
		consumptionObligation: "Minimum consumption",
		debtors: "Open accounts",
		devolution: "Card devolution per box",
		discounts: "Account discounts",
		forciblySynchronizedBalances: "Forcibly Synchronized Balances",
		individualLimitPost: "Change of individual post limit",
		minimumConsumption: "Early consumption",
		openSales: "Open sales",
		openedBillPayment: "Open account payments",
		promotion: "Promotions used",
		rappiDiscounts: "Rappi Discounts",
		rechargeConsumption: "Recharges devolution",
		refunded: "Refunded entrances/products",
		refundedRecharges: "Refunded recharges",
		resume: "Resume",
		servicesRemoval: "Services removed",
		userBonus: "Bonus per customer",
		tipReport: "Tip Report",
		transactionTransferReport: "Transaction transfers",
	},
	afterClosingEvent: {
		title: "Actions synced after event",
		description: "Report with actions or transactions after the event closes.",
		searchLabel: "Search by employee's name",
		searchPlaceHolder: "Enter the employee's name",
		filterTransactionLabel: "Filter by transaction type",
		filterOriginLabel: "Visualize",
		offlineActionLabel: "After closing",
		historyMachineLabel: "History machine",
		historyMachineAfterClosingLabel: "History after closing",
		optionAll: "All transactions",
		optionOfflineAction: "Transactions synchronized after event close",
		optionHistoryMachine: "Transactions machine's history",
		optionHistoryMachineAfterClosing: "Transactions machine's history after event close",
		columns: {
			transactionType: "Transaction type",
			employee: "Employee",
			customerName: "Users name",
			date: "Date/Hour of sync",
			value: "value",
			origin: "origin",
		},
	},
	forciblySynchronizedBalance: {
		title: "Forcibly Synchronized Balances",
		table: {
			columns: {
				date: "Date/Time",
				user: "Client",
				employee: "Employee",
				reason: "Justification",
				balance: "Synchronized Balance",
			},
		},
		successfulExportMsg: "Spreadsheet successfully exported!",
	},
};
