export const cashierAdjustmentsReport = {
	excelFileName: "cashier-adjustments-{{timestamp}}",
	filters: {
		byAuthor: {
			label: "Filter by Employee",
			placeholder: "Select a employee",
		},
		byCashier: {
			label: "Filter by Cashier",
			placeholder: "Select a cashier",
		},
		byType: {
			label: "Filter by Type",
			placeholder: "Select a type",
		},
	},
	successfullExportMsg: "Spreadsheet exported successfully!",
	table: {
		columns: {
			author: "Employee",
			cashier: "Cashier",
			obs: "Observation",
			timestamp: "Date/Hour",
			type: "Type",
			value: "Value",
		},
	},
	adjustmentTypes: {
		bleeding: "Bleeding",
		pettyCash: "Petty Cash",
	},
	cashierAdjustmentForm: {
		inputLabels: {
			value: "Value",
			obs: "Observation",
		},
		errors: {
			emptyValue: "Type a value",
		},
	},
	cashierAdjustmentModal: {
		title: "Add {{adjustmentType}}",
		notAllowedText: "This action cannot be performed when the event has already ended.",
	},
	exchangeTotal: "Total in change fund",
	cashWithdrawal: "Total in withdrawal",
	title: "Bleedings and Petty Cash",
};
