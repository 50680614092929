import * as React from "react";

export default function ProdTheme() {
	return (
		<style type="text/css" style={{ display: "none" }}>
			{`
			:root {
				--color-error-darkest: rgb(115, 10, 70);
				--color-info-light: rgb(125, 230, 220);
				--color-success-light: rgb(103, 225, 181);
				--color-success-lightest: rgb(206, 242, 229);
				--color-error-medium: rgb(245, 60, 90);
				--color-error-dark: rgb(175, 30, 80);
				--color-info-lightest: rgb(215, 250, 240);
				--color-light-l1: rgb(183, 182, 191);
				--color-light-l2: rgb(211, 210, 217);
				--color-error-light: rgb(250, 140, 140);
				--color-light-l3: rgb(234, 233, 237);
				--color-success-medium: rgb(0, 198, 127);
				--color-light-l4: rgb(245, 245, 247);
				--color-light-l5: rgb(255, 255, 255);
				--color-success-dark: rgb(0, 141, 90);
				--color-info-medium: rgb(45, 175, 180);
				--color-warning-lightest: rgb(255, 234, 181);
				--color-info-dark: rgb(0, 80, 90);
				--color-warning-medium: rgb(243, 175, 0);
				--color-warning-dark: rgb(188, 135, 0);
				--color-warning-darkest: rgb(105, 76, 0);
				--color-dark-d1: rgb(23, 23, 26);
				--color-dark-d2: rgb(47, 46, 51);
				--color-info-darkest: rgb(0, 60, 75);
				--color-warning-light: rgb(250, 205, 89);
				--color-success-darkest: rgb(0, 66, 42);
				--color-dark-d3: rgb(58, 57, 64);
				--color-dark-d4: rgb(82, 80, 89);
				--color-dark-d5: rgb(118, 115, 128);
				--color-error-lightest: rgb(255, 225, 215);
				--color-primary-light: rgb(95, 165, 235);
				--color-primary-lightest: rgb(200, 230, 250);
				--color-primary-dark: rgb(0, 45, 140);
				--color-primary-pure: rgb(0, 80, 195);
				--color-highlight-darkest: rgb(0, 75, 120);
				--color-secondary-darkest: rgb(15, 0, 70);
				--color-highlight-light: rgb(155, 250, 255);
				--color-highlight-lightest: rgb(205, 250, 255);
				--color-secondary-light: rgb(150, 90, 220);
				--color-secondary-lightest: rgb(225, 200, 250);
				--color-highlight-dark: rgb(0, 140, 180);
				--color-secondary-dark: rgb(30, 0, 105);
				--color-highlight-pure: rgb(0, 240, 250);
				--color-primary-darkest: rgb(0, 20, 95);
				--color-secondary-pure: rgb(55, 0, 145);
			}			
			`}
		</style>
	);
}
