export const clients = {
	averagePublicPerWeekDay: {
		averageTicket: "Ticket Médio",
		label: "Valor médio de público por dia da semana durante o período",
		title: "Público médio por dia da semana",
	},
	clientRecordPerDay: {
		label: "Maiores quantidades de público durante o período",
		none: "Ninguém",
		people: "{{num}} pessoas",
		person: "{{num}} pessoa",
		title: "Recorde de público",
	},
	headerChart: {
		averagePublic: "Público médio",
		filters: {
			label: "Filtros",
			men: "Homens",
			others: "Outros",
			women: "Mulheres",
		},
		label: "durante o período",
		total: "Total de clientes",
	},
	topClients: {
		infoLabel: "Selecione um cliente para ver detalhes.",
		label: "Valor durante o período",
		title: "TOP Clientes",
	},
	topTables: {
		title: "TOP Mesas",
		label: "Valor durante o período",
		infoLabel: "Selecione uma mesa para ver detalhes.",
	},
};
