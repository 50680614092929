export const xlsxPicker = {
	cell: {
		falseHasAlcohol: "FALSE",
		trueHasAlcohol: "TRUE",
	},
	columns: {
		bars: "Bars",
		category: "Category",
		cest: "Fiscal - CEST",
		description: "Description",
		fiscalCode: "SKU",
		fiscalProductGroup: "Fiscal - Tax group",
		fiscalProfile: "Fiscal - Tax profile",
		hasAlcohol: "Contains alcohol?",
		id: "Product ID",
		image: "Image",
		kind: "Product type",
		menuCategory: "Menu category",
		name: "Name",
		ncm: "Fiscal - NCM",
		price: "Price",
		products: "products",
		variablePrice: "Variable price",
		beerBrand: "Beer brand",
		beerStyle: "Beer style",
		beerAbv: "ABV - Alcohol by volume",
	},
	sheetDownload: "Download sample table",
	sheetFeedback: {
		errorMessage: "Invalid worksheet, download it again and make the necessary changes.",
		successMessage: "Valid worksheet!",
	},
	sheetHumanizeErrorMessage: {
		default: "There was an error importing the sheet",
		emptySheetRows: "This spreadsheet has empty lines, please remove the empty lines.",
		emptyHeaderCell: "Missing name in columns",
		emptySheet: "This worksheet is empty",
		sheetPoorlyFormatted:
			"Format spreadsheet for Calibri font, size 11, remove spaces, unnecessary line breaks and bold",
		unidentifieldColumn: "Column names error",
		verifyBaseSheet:
			", please check the sample spreadsheet template or use it as a base spreadsheet.",
	},
	sheetNameExample: "(example)",
	sheetNameInvalid: "(invalid)",
	sheetXlsxPicker: "Choose a worksheet",
};
