export const coalaAddProductModal = {
	add: "Add product",
	addItem: "Add item",
	addItemName: "Item {{length}}",
	average: "Average",
	category: "Category",
	categoryPlaceholder: "Product category",
	description: "Description",
	descriptionPlaceholder: "Product description",
	edit: "Edit product",
	equivalentProduct: "Equivalent product",
	higher: "Higher",
	max: "Max",
	maxCount: "Qty maximum selectable",
	min: "Min",
	mountableProduct: "Mountable product",
	name: "Name",
	namePlaceholder: "Product's name",
	noCategory: "Without category",
	price: "Value",
	pricing: "Pricing",
	product: "Product",
	productPrice: "Product value",
	save: "Save changes",
	searchProduct: "Search by products",
	sku: "SKU",
	step: "STEP {{index}}",
	sum: "Sum",
	title: "Title",
	titlePlaceholder: "Step title",
	validadeField: "Required field",
	zigCategory: "Zig category",
};
