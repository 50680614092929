export const storages = {
	addNewStorage: "Add new storage",
	addProductModal: {
		form: {
			addedProducts: "Added Products",
			submit: "Finish import",
			successNotification: "Imported Data",
		},
		manual: {
			addOtherProduct: "Add another product",
			costValue: "Cost price",
			error: {
				noneValidProduct: "No valid product selected",
				zeroCount: "Cannot add product with quantity 0",
			},
			label: "Manually",
			product: "Product",
			quantity: "The amount",
		},
		noteInfo: {
			accessKey: "Access Key",
			addBill: "Add Account Payable",
			addSupplier: "Add issuer to your favorites",
			bill: "Account",
			cpnj: "CNPJ",
			error:
				"Some problem occurred with the note and it was not possible to create the Supplier",
			number: "Note number",
			operationType: "Nature of Operation",
			provider: "Supplier",
			removeBill: "Remove Account Payable",
			series: "Series",
			supplierName: "Issuer",
			title: "Invoice Data",
			totalValue: "Total value",
			unavailable: "Unavailable",
		},
		product: {
			code: "SKU",
			commercialQuantity: "Qty",
			commercialUnit: "Un",
			cost: "Cost price per",
			description: "Product Description",
			equals: "equivalent to",
			label: "Product",
			skip: "Skip",
			unitValue: "Unit price",
		},
		title: "Add Products",
		xml: {
			label: "Import by Invoice (XML)",
			upload: "Click or drag files to upload",
		},
	},
	confirmMessage: "Are you sure you want to delete this storage?",
	createStorage: "Create storage",
	deleteAllProducts: "Remove all products",
	deleteProduct: {
		title: "Are you sure you want to delete this product?",
		tooltip: "Delete product",
	},
	editStorage: "Edit storage",
	error: "Error updating inventories",
	label: " Storage",
	modalImportInvoice: {
		errors: {
			notKeyAccess: "Unable to fetch XML access key",
		},
		labelInputs: {
			storage: "Storage",
			xmlKey: "XML key",
		},
		placeholders: {
			selectStorage: "Select stock",
		},
		typeOptionsImport: {
			xmlFile: "Attach XML file",
			xmlKeyAccess: "Insert XML Access Key",
		},
		validationMessage: {
			emptyFileXml: "XML file not selected",
			emptySelectTypeImport: "Select an import option",
			emptyStorage: "Select a stock",
			emptyXmlKey: "Access Key field empty",
		},
	},
	modalTransfer: "Transfer products from {{storage}}",
	newStorage: "New storage",
	removeStorage: "Delete storage",
	search: "Look for a storage",
	storageCard: {
		addProduct: "Add product",
		edit: "Edit storage",
		error: "Cannot {{action}} product with quantity 0",
		modal: {
			add: "add and your unit cost price",
			addProducts: "Add Products",
			amount: "Enter the amount you want",
			costValue: "Cost Price",
			count: "Quantity",
			note: "Note",
			removeProducts: "Remove Products",
		},
		modalTransfer: {
			error: {
				selectCount: "Select an amount",
				selectStorage: "Select a stock",
			},
			okText: "Transfer",
			quantity: "Quantity",
			storage: "Stock",
			title: "Transfer {{name}}",
		},
		productSearch: "Search for a product",
		transfer: "Transfer products",
	},
	storageList: {
		central: "General",
		default: "Place",
		emptyArea: "No stocks to display",
		emptyMessage: "No items to show",
	},
	storageName: "Storage name",
	tableProducts: {
		add: "Add product",
		columns: {
			actions: "Actions",
			category: "Category",
			count: "Quantity",
			name: "Product",
		},
		cost: "Cost price",
		countError: "Cannot add product with quantity 0",
		product: "Product",
		productError: "Product not found",
		quantity: "Quality",
		total: "in total",
	},
	transferProductModal: {
		destinyStorage: {
			label: "Target storage",
			placeholder: "Select a storage",
		},
		error: {
			addProduct: "Product cannot be added without quantity",
			selectProduct: "Negative quantity in storage. Product could not be selected",
		},
		printTransfer: {
			associate: "Click here to associate them",
			download: "Download Excel from transfers",
			infoText:
				"Transfer carried out successfully, see the summary of transferred products below",
			none: "None",
			notFound: "Not Found",
			notTransnfer: "The following products could not be transferred",
			print: "Print Transfer Document",
			quantity: "Quantity",
			responsible: "Responsible for destination stock",
			sheet: {
				columns: {
					count: "Quantity",
					name: "Product",
					totalCost: "Total Cost",
					unit: "Unit",
					unitaryCost: "Unit Cost",
				},
				filename: "stock-transfer",
				title: "Transfer from stock {{selectedStorage}} to {{toStorage}}",
			},
			successNotification: "Associated and transferred products",
			text:
				"This document proves the transfer of products from the stock {{fromStorage}} to the stock {{toStorage}}. On the day {{date}} made by {{employee}}. The transferred products are in the following list :",
			title: "Transfer Proof",
			unassociated: "They are not associated with the target location",
			withoutName: "Without Name",
		},
		product: {
			label: "Product",
			placeholder: "Select a product",
		},
		quantity: "The amount",
		table: {
			empty: "No selected products",
			product: "Product",
			quantity: "The amount",
			totalCost: "Total cost",
			unityCost: "Unit cost",
		},
		transferFor: {
			central: "General Storage",
			label: "Transfer to",
			local: "Local Storage",
			placeSelect: {
				label: "Destination",
				placeholder: "Select a location",
			},
		},
	},
	transferResume: "Transfer report",
	zeroBackoffice: "Clear all products in storage",
};
