import { service } from "./service";
import { general } from "./general";
import { company } from "./company";
import { fiscal } from "./fiscal";

export const steps = {
	company,
	fiscal,
	general,
	service,
};
