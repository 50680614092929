export const financial = {
	details: "Ver detalhes",
	event: "Eventos",
	filename: "extrato-diario",
	modality: "Modalidade",
	noDataToShow: "Não há nada a mostrar para o período selecionado",
	noResume: "Não há nenhum fechamento no período",
	pay: "A pagar",
	payvalue: "A Pagar/A Receber",
	receive: "A receber",
	summaryTitle: "Resumo para {{date}}",
	table: {
		balance: "Saldo",
		datetime: "Data",
		in: "Entrada",
		out: "Saída",
		total: "Total",
		withdrew: "Resgatado",
	},
	title: "Extrato diário",
};
