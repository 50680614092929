export const addBillModalForm = {
	account: "Account",
	accountPlaceholder: "Select an account",
	attachment: "Attachment",
	billAttachmentSize: "File too large (max 20 MB)",
	billType: "Type",
	billtypePlaceholder: "Select a type",
	costCenter: "Cost center",
	costCenterPlaceholder: "Select a cost center",
	deleteBill: "Are you sure you want to delete this account?",
	description: "Description",
	descriptionPlaceholder: "Enter a description",
	dueDate: "Due date",
	invalidValue: "Invalid value",
	loading: "Loading...",
	observation: "Observation",
	payDate: "Payday",
	paymentMethod: "Payment method",
	paymentMethodPlaceholder: "Select a payment method",
	paymentMethods: {
		app: "App",
		automaticDebit: "Automatic debit",
		bankTransfer: "Bank transfer",
		boleto: "Bank slip",
		check: "Check",
		creditCard: "Credit card",
		debitCard: "Debit card",
		money: "Cash",
		promissory: "Promissory",
	},
	performDate: "Perform date",
	reconciled: "Reconciled",
	status: "Paid",
	supplier: "Supplier",
	supplierPlaceholder: "Select a supplier",
	toPay: "Pay",
	toReceive: "To receive",
	validateField: "Required field",
	value: "Value",
};
