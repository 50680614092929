export const pos = {
	methodTranslate: {
		credit: "Crédito",
		debit: "Débito",
	},
	operations: {
		columns: {
			brand: "Bandeira",
			date: "Data e hora",
			lastDigits: "Dígitos",
			method: "Método",
			value: "Valor",
		},
		search: "Pesquise por bandeira, dígitos ou valor",
		title: "Operações",
	},
	others: "Outros",
	selectDate: "Selecione um dia para visualizar transações",
	sidebar: {
		clear: "Limpar",
		credit: "Crédito",
		day: "Dia",
		debit: "Débito",
		info: "Selecione uma ou mais datas para ver detalhes.",
		title: "Total de {{month}}",
		total: "Total",
	},
	title: "Vendas POS",
};
