import {
	BaseCategory,
	CreatedResults,
	DeleteProductResult,
	EditedProduct,
	EditedProductThroughImport,
	EditedResults,
	EditProductImage,
	GroupedByCategoryOrganizationProduct,
	NewProduct,
	OrganizationFeatures,
	OrganizationProduct,
	OrganizationWithFeatures,
	OrgConsumptionTimeline,
	Place,
	PlaceProductFiscalData,
	PlaceReport,
	PlacesProductsWereSoldBody,
	ProductKind,
	ProductSold,
} from "#resources/api/enterprise-generated";
import { fromPromise, IPromiseBasedObservable } from "#helpers/mobx-utils";
import { action, observable } from "mobx";
import { showErrorNotification, showSuccessNotification } from "#helpers/notifications";
import { jobExecutor } from "#resources/helpers/job-executor";
import { ITooglePlacesState } from "../pages/org/products/interfaces";
import { OrganizationModel } from "#models/organization";
import { RootStore } from ".";
import enterprise from "#api/enterprise-client";
import { fetchModel } from "#helpers/fetch-model";
import i18n from "#i18n/index";
import { SupportedCurrencies } from "#resources/helpers/currency/utils";

export interface plainCatFather {
	id: string;
	name: string;
	parents: parent[];
	products: OrganizationProduct[];
}

interface parent {
	name: string;
	id: string;
}

interface IEditProducts {
	product: OrganizationProduct;
	placeId: string;
}

const t = i18n.t;

export type orgFeatures = "multivendor" | "vendorAnticipation" | "loyalty";

export class OrganizationStore {
	public rootStore: RootStore;

	@observable
	public organizations: OrganizationModel[] = [];
	@observable
	public organization: OrganizationModel | null = null;

	@observable
	public orgProducts: OrganizationProduct[] = [];

	@observable
	public fetchPlacesPromise: IPromiseBasedObservable<void> | null = null;
	@observable
	public fetchCurrentOrganizationPromise: IPromiseBasedObservable<void> | null = null;

	@observable
	public isAddingOrEditingProduct: boolean = false;

	@observable
	public isUpdatingTableProduct: boolean = false;

	@observable
	public isFetchingGetOrganizationProductsWithCategories: boolean = false;

	@observable
	public organizationProducts: OrganizationProduct[] = [];

	@observable
	public plaincategories: BaseCategory[] = [];

	@observable
	public isEditingOrAddingMultipleProducts: boolean = false;

	@observable
	public currencySymbolOrg: string | null = null;

	@observable
	public currencyOrg: SupportedCurrencies | undefined = undefined;

	@observable
	public orgFeatures: OrganizationFeatures[] = [];

	public constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public clean() {
		this.organizations = [];
		this.orgProducts = [];
		this.organization = null;
		this.fetchPlacesPromise = null;
		this.fetchCurrentOrganizationPromise = null;
		this.currencySymbolOrg = null;
		this.currencyOrg = undefined;
		this.orgFeatures = [];
	}

	public updateNoteFromServer = (incomeOrganization: OrganizationWithFeatures) => {
		let organization = this.organizations.find(t => t.id === incomeOrganization.id);
		if (!organization) {
			organization = new OrganizationModel(this, incomeOrganization);

			this.organizations.push(organization);
		} else {
			organization.sync(incomeOrganization);
		}
		this.currencySymbolOrg = organization.currencySymbol;
		this.currencyOrg = (organization.currency as SupportedCurrencies) || undefined;
		return organization;
	};

	public resolve = (incomeOrganization: OrganizationWithFeatures) =>
		this.updateNoteFromServer(incomeOrganization);

	@action
	public fetchCurrentOrganization = () => {
		this.fetchCurrentOrganizationPromise = fromPromise(
			enterprise.getCurrentOrganization().then(org => {
				this.organization = this.resolve(org);
				this.orgFeatures = this.resolve(org).features;
			}),
		);
		return this.fetchCurrentOrganizationPromise;
	};

	public hasFeature = (featureId: orgFeatures): boolean => {
		const feature = this.orgFeatures.find(f => f.id === featureId);
		return Boolean(feature);
	};

	@action
	public getOrganizationProducts = async () => {
		try {
			this.orgProducts = await enterprise.getAllProducts();
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		}
	};

	@action
	public addProductToOrg = async (
		prod: NewProduct,
		image: EditProductImage | null,
		newCategoryName?: string,
	) => {
		try {
			this.isUpdatingTableProduct = true;

			const newProduct = await enterprise.addNewProductToOrganization({
				image,
				product: { ...prod },
			});

			if (newCategoryName) {
				this.plaincategories = await enterprise.getPlainCategories();
			}
			if (newProduct) {
				const productAdded = await enterprise.getProduct({ id: newProduct.id! });
				this.organizationProducts = [...this.organizationProducts, productAdded];
			}
			this.isUpdatingTableProduct = false;
			this.generateObjectOfProductWithCategories();
			showSuccessNotification(t("store:organizationStore.addProductToOrg"));
			return newProduct;
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
			this.getOrganizationProductsWithCategories();
			this.isUpdatingTableProduct = false;
			return null;
		}
	};

	@action
	public addMultipleOrgProducts = async (
		products: NewProduct[],
		hasNewCategory: boolean,
	) => {
		this.isUpdatingTableProduct = true;
		if (products.length > 0) {
			try {
				const prods: OrganizationProduct[] = [];
				for (const p of products) {
					prods.push(
						await enterprise.addNewProductToOrganization({ image: null, product: p }),
					);
				}

				if (hasNewCategory) {
					this.plaincategories = await enterprise.getPlainCategories();
				}

				const addedProducts = await Promise.all(
					prods.map(p => enterprise.getProduct({ id: p.id! })),
				);
				this.organizationProducts = [...this.organizationProducts, ...addedProducts];
				this.generateObjectOfProductWithCategories();
				showSuccessNotification(t("store:organizationStore.addMultipleOrgProducts"));
			} catch (err) {
				if (err instanceof Error) {
					showErrorNotification(err.message);
				}
			}
		}
		this.isUpdatingTableProduct = false;
	};

	@action
	public editOrgProduct = async (
		id: string,
		prod: NewProduct,
		image: EditProductImage | null,
	) => {
		this.isUpdatingTableProduct = true;

		let newProduct: OrganizationProduct | null = null;
		try {
			if (image) {
				await enterprise.editProductImage({ id, image });
			}

			const editedProduct = await enterprise.editProduct({ id, product: { ...prod } });

			if (editedProduct) {
				newProduct = await enterprise.getProduct({ id: editedProduct.id! });
				this.organizationProducts = [
					...this.organizationProducts.filter(product => product.id !== newProduct!.id),
					newProduct,
				];
			}
			showSuccessNotification(t("store:organizationStore.editOrgProduct"));
			this.generateObjectOfProductWithCategories();
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
			this.getOrganizationProductsWithCategories();
		}

		this.isUpdatingTableProduct = false;
		return newProduct;
	};

	@action
	public editMultipleOrgProducts = async (
		productsToEdit: EditedProductThroughImport[],
	) => {
		this.isUpdatingTableProduct = true;
		if (productsToEdit.length > 0) {
			try {
				const editedProds = await enterprise.editProductsThroughImport({
					products: productsToEdit,
				});

				this.plaincategories = await enterprise.getPlainCategories();

				editedProds.forEach(p => {
					this.organizationProducts = this.organizationProducts.filter(
						product => product.id !== p.id,
					);
				});

				this.organizationProducts = [...this.organizationProducts, ...editedProds];

				this.generateObjectOfProductWithCategories();
				showSuccessNotification(t("store:organizationStore.editMultipleOrgProducts"));
			} catch (err) {
				if (err instanceof Error) {
					showErrorNotification(err.message);
				}
			}
		}
		this.isUpdatingTableProduct = false;
	};

	@action
	public deleteProduct = async (id: string) => {
		this.isUpdatingTableProduct = true;

		enterprise
			.deleteProduct({ id })
			.then(rtn => {
				if (rtn.success) {
					this.organizationProducts = this.organizationProducts.filter(
						product => product.id !== id,
					);
					this.orgProducts = this.orgProducts.filter(p => p.id !== id);
					showSuccessNotification(t("store:organizationStore.deleteProduct"));
				} else {
					showErrorNotification(t("store:organizationStore.deleteProductError"));
				}
			})
			.catch(e => {
				showErrorNotification(e.message);
			})
			.finally(() => {
				this.isUpdatingTableProduct = false;
				this.generateObjectOfProductWithCategories();
			});
	};

	private addPlaceToObject = (places: Place[], placeId: string) => {
		const placeObj = places.find(place => place.id === placeId);
		return { id: placeObj!.id!, name: placeObj!.name };
	};

	private removePlaceFromObject = (places: Place[], placeId: string) =>
		places.find(place => place.id === placeId);

	@action
	public tooglePlaces = async (
		placeStates: ITooglePlacesState,
		productId: string,
		places: Place[],
		selectedProduct: OrganizationProduct,
	) => {
		this.isUpdatingTableProduct = true;

		const placeToRemove: string[] = [];
		const placesToAdd: string[] = [];

		for (const place in placeStates.newPlaces) {
			if (placeStates.newPlaces[place] !== placeStates.oldPlaces[place]) {
				if (placeStates.newPlaces[place]) {
					placesToAdd.push(place);
				} else {
					placeToRemove.push(place);
				}
			}
		}

		const promises: Promise<void>[] = [
			...placesToAdd.map(id => {
				selectedProduct.places.push(this.addPlaceToObject(places, id));
				return enterprise.addProductToPlace({
					fiscalData: {
						cest: null,
						fiscalProductGroupId: null,
						fiscalProfileId: null,
						ncm: selectedProduct.fiscalNcm,
					},
					placeId: id,
					productId,
				});
			}),
			...placeToRemove.map(id => {
				const placeToRemoveObj = this.removePlaceFromObject(places, id);
				selectedProduct.places = selectedProduct.places.filter(
					place => place.id !== placeToRemoveObj!.id,
				);
				return enterprise.removeProductFromPlace({ placeId: id, productId });
			}),
		];

		this.isUpdatingTableProduct = false;
		showSuccessNotification(t("store:organizationStore.tooglePlaces"));

		await Promise.all(promises).catch(err => {
			if (err instanceof Error) {
				showErrorNotification(err.message);
			}
		});

		this.getOrganizationProductsWithCategories();

		this.orgProducts = this.orgProducts.filter(p => p.id !== productId);
	};

	public addProductToPlace = new fetchModel<
		{
			placeId: string;
			productId: string;
			fiscalData: PlaceProductFiscalData | null;
		},
		string | null
	>({
		fnPromise: args =>
			enterprise
				.addProductToPlace(args)
				.then(() => args.productId)
				.catch(() => null),
		onError: err => showErrorNotification(err.message),
	});

	@observable
	public organizationProductsWithCategories: plainCatFather[] = [];

	public findParents(
		allCats: BaseCategory[],
		catToFind: BaseCategory,
	): BaseCategory[] | null {
		if (catToFind.parentId === null) return null;
		const parent = allCats.find(c => c.id === catToFind.parentId);
		if (!parent) return null;
		if (parent.parentId) return [parent, ...(this.findParents(allCats, parent) || [])];
		else return [parent];
	}

	public plainSubCategoriesWithFathers(
		products: OrganizationProduct[],
		plaincategories: BaseCategory[],
	): plainCatFather[] {
		const output: plainCatFather[] = plaincategories.map(cat => {
			const rtn = this.findParents(plaincategories, cat);
			return {
				id: cat.id,
				name: cat.name,
				parents: rtn ? rtn.map(c => ({ id: c.id, name: c.name })) : [],
				products: products.filter(product => product.categoryId === cat.id),
			};
		});
		return output;
	}

	@action
	public getOrganizationProductsWithCategories = async () => {
		if (!this.isFetchingGetOrganizationProductsWithCategories) {
			this.isFetchingGetOrganizationProductsWithCategories = true;
			try {
				this.organizationProducts = await enterprise.getAllProducts();
				this.plaincategories = await enterprise.getPlainCategories();
				this.generateObjectOfProductWithCategories();
			} catch (err) {
				if (err instanceof Error) {
					showErrorNotification(err.message);
				}
			}
			this.isFetchingGetOrganizationProductsWithCategories = false;
		}
	};

	@action
	public generateObjectOfProductWithCategories = () => {
		this.organizationProductsWithCategories = this.plainSubCategoriesWithFathers(
			this.organizationProducts,
			this.plaincategories,
		);
	};

	public placesReport = new fetchModel<
		{ placeIds: string[]; from: Date; until: Date },
		PlaceReport[]
	>({
		fnPromise: ({ placeIds, from, until }) =>
			enterprise.getClosingReportsByPlace({ placeIds, since: from, until }),
		onError: err => showErrorNotification(err.message),
	});

	public getAllProducts = new fetchModel<{}, OrganizationProduct[]>({
		fnPromise: () => enterprise.getAllProducts({}),
		onError: err => showErrorNotification(err.message),
	});

	public getConsumptionTimeLineAtOrganizationBy30MinByDay = new fetchModel<
		{ placesId: string[]; since: Date; until: Date },
		OrgConsumptionTimeline[]
	>({
		fnPromise: ({ placesId, since, until }) =>
			enterprise.getConsumptionTimeLineAtOrganizationBy30MinByDay({
				placesId,
				since,
				until,
			}),
		onError: err => showErrorNotification(err.message),
	});
	@action
	public addMultipleProductsInPlace = async (
		placesId: string[],
		selectedProducts: OrganizationProduct[],
	) => {
		this.isUpdatingTableProduct = true;

		const productsToRemove: IEditProducts[] = [];
		const productsToAdd: IEditProducts[] = [];

		for (const product of selectedProducts) {
			const currentPlacesId = product.places.map(place => place.id);

			for (const currPlaceId of currentPlacesId) {
				if (!placesId.includes(currPlaceId)) {
					productsToRemove.push({ placeId: currPlaceId, product: product });
				}
			}

			for (const placeId of placesId) {
				if (!currentPlacesId.includes(placeId)) {
					productsToAdd.push({ placeId, product: product });
				}
			}
		}

		try {
			await jobExecutor<IEditProducts, void>(
				productsToRemove,
				product =>
					enterprise.removeProductFromPlace({
						placeId: product.placeId,
						productId: product.product.id,
					}),
				undefined,
				err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
					}
					console.log(err.message);
				},
			);

			await jobExecutor<IEditProducts, void>(
				productsToAdd,
				product =>
					enterprise.addProductToPlace({
						fiscalData: {
							cest: null,
							fiscalProductGroupId: null,
							fiscalProfileId: null,
							ncm: product.product.fiscalNcm,
						},
						placeId: product.placeId,
						productId: product.product.id,
					}),
				undefined,
				err => {
					if (err instanceof Error) {
						showErrorNotification(err.message);
						console.log(err.message);
					}
				},
			);

			await this.getOrganizationProductsWithCategories();
			this.isUpdatingTableProduct = false;
			showSuccessNotification(t("store:organizationStore.addMultipleProductsInPlace"));
		} catch (err) {
			if (err instanceof Error) {
				showErrorNotification(err.message);
				console.log(err.message);
			}
		}
	};

	public getPlaces = new fetchModel<{}, Place[]>({
		fnPromise: () => enterprise.getPlaces(),
	});

	public getReportIdForLoggedOrganization = new fetchModel<{}, string>({
		fnPromise: () => enterprise.getReportIdForLoggedOrganization(),
		onError: error => showErrorNotification(error.message),
	});

	public getProductsSoldAtOrg = new fetchModel<
		{ since: Date; until: Date; orgId: string; placeIds?: string[] | null },
		ProductSold[]
	>({
		fnPromise: args => enterprise.productsSoldAtOrg(args),
		onError: error => showErrorNotification(error.message),
	});

	public getPlacesProductsWereSoldAtOrg = new fetchModel<
		{
			orgId: string;
			placeIds?: string[] | null;
			since: Date;
			until: Date;
			productId: string;
			isMountable: boolean;
		},
		PlacesProductsWereSoldBody[]
	>({
		fnPromise: args => enterprise.placesProductsWereSoldAtOrg(args),
		onError: error => showErrorNotification(error.message),
	});

	public removeProduct = new fetchModel<
		{
			id: string;
		},
		DeleteProductResult
	>({
		fnPromise: args => enterprise.deleteProduct(args),
		onSuccess: () => showSuccessNotification(t("store:organizationStore.deleteProduct")),
		onError: () => showErrorNotification(t("store:organizationStore.deleteProductError")),
	});

	public getAllProductsGroupedByCategory = new fetchModel<
		{},
		GroupedByCategoryOrganizationProduct[]
	>({
		fnPromise: args => enterprise.getAllProductsGroupedByCategory(args),
		onError: error => showErrorNotification(error.message),
	});

	public getPlainCategories = new fetchModel<{}, BaseCategory[]>({
		fnPromise: args => enterprise.getPlainCategories(args),
		onError: error => showErrorNotification(error.message),
	});

	public getProductKinds = new fetchModel<{}, ProductKind[]>({
		fnPromise: args => enterprise.getProductKinds(args),
		onError: error => showErrorNotification(error.message),
	});

	public getProduct = new fetchModel<{ id: string }, OrganizationProduct>({
		fnPromise: args => enterprise.getProduct(args),
		onError: error => showErrorNotification(error.message),
	});

	public addNewProductToOrganizationAndPlaces = new fetchModel<
		{ product: NewProduct; placesIds: string[]; image?: EditProductImage | null },
		CreatedResults
	>({
		fnPromise: args => enterprise.addNewProductToOrganizationAndPlaces(args),
		onSuccess: () => {
			showSuccessNotification(t("store:organizationStore.addProductToOrg"));
			this.getOrganizationProductsWithCategories();
			this.generateObjectOfProductWithCategories();
		},
		onError: error => showErrorNotification(error.message),
	});

	public editProductAndPlaceProducts = new fetchModel<
		{ placesIds: string[]; product: EditedProduct },
		EditedResults
	>({
		fnPromise: args => enterprise.editProductAndPlaceProducts(args),
		onSuccess: (result, args) => {
			if (result.error.length) {
				result.error.map(error => showErrorNotification(error.reason));
			}
			if (result.success.length) {
				showSuccessNotification(t("store:organizationStore.editOrgProduct"));
				this.getOrganizationProductsWithCategories();
				const updateOrgProducts = this.organizationProducts.map(product => {
					if (product.id === args.product.id) {
						return {
							...product,
							...args.product,
						};
					} else {
						return {
							...product,
						};
					}
				});
				this.organizationProductsWithCategories = this.plainSubCategoriesWithFathers(
					updateOrgProducts,
					this.plaincategories,
				);
			}
		},
		onError: error => showErrorNotification(error.message),
	});

	public editProductImage = new fetchModel<
		{ id: string; image: EditProductImage; placeId?: string | null },
		string | null
	>({
		fnPromise: args => enterprise.editProductImage(args),
		onError: error => showErrorNotification(error.message),
	});

	public get isOrgForeign(): boolean {
		return Boolean(this.organization?.countryISO3 !== "BRA");
	}

	public deleteMultipleProducts = new fetchModel<{ productIds: string[] }, void>({
		fnPromise: args => enterprise.deleteMultipleProducts(args),
		onSuccess: (_, args) => {
			showSuccessNotification(
				t("store:organizationStore.deleteMultipleOrgProducts", {
					products: args.productIds.length,
				}),
			);

			this.getOrganizationProductsWithCategories();
			this.organizationProductsWithCategories = this.plainSubCategoriesWithFathers(
				this.organizationProducts.filter(prod => !args.productIds.includes(prod.id)),
				this.plaincategories,
			);
		},
		onError: error => showErrorNotification(error.message),
	});

	@action
	public addMultipleProducts = async (
		v: ITooglePlacesState,
		selectedProducts: OrganizationProduct[],
	) => {
		this.isUpdatingTableProduct = true;
		const selectedPlaces = Object.entries(v.newPlaces)
			.filter(([, isSelected]) => isSelected)
			.map(([placeId]) => placeId);

		const productsToAdd: IEditProducts[] = selectedProducts.flatMap(product => {
			const currentPlaceIds = new Set(product.places.map(place => place.id));

			return selectedPlaces
				.filter(placeId => !currentPlaceIds.has(placeId))
				.map(placeId => ({ product, placeId }));
		});

		const addProductPromises = productsToAdd.map(({ product, placeId }) =>
			enterprise.addProductToPlace({
				placeId,
				productId: product.id,
				fiscalData: {
					cest: null,
					ncm: product.fiscalNcm,
					fiscalProfileId: null,
					fiscalProductGroupId: null,
				},
			}),
		);

		await Promise.all(addProductPromises);
		await this.getOrganizationProductsWithCategories();
		this.isUpdatingTableProduct = false;
		showSuccessNotification(t("store:organizationStore.addMultipleProductsInPlace"));
	};
}
