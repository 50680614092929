export const withdrawRequestDrawer = {
	anticipationOption: "Antecipação",
	bankSlid: {
		bankSlipInfo: "Informações do boleto",
		bankSlipValue: "Valor do boleto",
		dataReview: "Revisão dos dados",
		form: {
			anticipationTax: "Taxa de antecipação",
			anticipationValue: "Valor bruto a antecipar",
			attachment: "Selecionar arquivo do boleto",
			available: "Saldo disponível para saque",
			bankSlipInfo: "Informações do boleto",
			bankSlipValue: "Valor do boleto",
			barCode: "Número do código de barras",
			beneficiary: "Beneficiário",
			calculating: "Calculando...",
			dueDate: "Vencimento",
			liquidAmmountRequested: "Valor líquido solicitado",
			liquidValue: "Valor líquido",
			liquidValuePlaceholder: "Digite aqui o valor líquido",
			thidPartyWithdrawFee: "Taxa de saque para terceiros",
			totalValue: "Valor bruto total",
			valueToBeAnticipated: "Valor líquido a antecipar",
			valuesReview: "Revisão dos valores",
		},
		transactionDetails: {
			available: "Valor de saque",
			calculating: "Calculando...",
			paymentValue: "Valor do pagamento",
			taxValue: "Taxa de antecipação",
			title: "Detalhamento da transação",
			totalValue: "Valor total",
			valueToBeAnticipated: "Valor líquido de antecipação",
		},
	},
	bankslipPaymentOption: "Pagamento de boleto",
	bankslipValue: "Valor do boleto",
	downloadBankslipPdf: "Baixar boleto em PDF",
	expireDate: "Vencimento",
	pendingBankslip: "Boleto pendente",
	title: "Solicitar saque ou pagamento de boleto",
	titleBankslip: "Pagamento de boleto",
	titlePendingBankslip: "Pagamento de boleto Zig",
	titleTransfer: "Transferir saldo entre places",
	titleWithdraw: "Solicitar saque",
	transferOption: "Transferência entre places",
	warning:
		"Caso o valor solicitado ultrapasse o saldo disponível para saque, o mesmo será inteirado com o valor disponível para antecipação, no qual incidirá a taxa de antecipação.",
	withdraw: {
		account: "Conta",
		accountInfo: "Informações da conta",
		accountPlaceholder: "Digite ou selecione a conta",
		dataReview: "Revisão dos dados",
		errorMessage: "Não foi possível concluir a solicitação.",
		liquidValue: "Valor líquido",
		liquidValuePlaceholder: "Digite aqui o valor líquido",
		reason: "Motivo da solicitação",
		reasonPlaceholder: "Digite aqui o motivo para o saque",
		successMessage: "Saque solicitado com sucesso!",
		warning:
			"Solicitações realizadas após às 10h30 (horário de Brasília) só serão atendidas no dia seguinte.",
		warningAccountChoice:
			"Será cobrado uma taxa de {{value}} para saque em contas de terceiros.",
		withdrawValue: "Valor do saque",
	},
	withdrawOption: "Saque",
};
