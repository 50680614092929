import { globalKeysEN } from "./global-keys";
import { placeEN } from "./place";
import { orgEN } from "./org";
import { componentsEN } from "./components";
import { eventEN } from "./event";
import { navEN } from "./nav";
import { storeEN } from "./store";
import { noAuthEN } from "./no-auth";
import { backofficeEN } from "./backoffice";
import { teamEN } from "./team";
import { sharedEN } from "./shared";

export const en_US = {
	backoffice: backofficeEN,
	components: componentsEN,
	event: eventEN,
	globalKeys: globalKeysEN,
	nav: navEN,
	noAuth: noAuthEN,
	org: orgEN,
	place: placeEN,
	store: storeEN,
	shared: sharedEN,
	team: teamEN,
};
