export const whatsapp = {
	actionButtons: {
		actionButton: "Actions",
		addButton: "Add another button",
		formatValidation: {
			phoneNumber: "Invalid phone number",
			url: "Invalid website link",
		},
		noneButton: "None",
		optionsAction: {
			label: {
				accessWebsite: "Access website",
				buttonText: "Button text",
				country: "Country",
				makeCall: "Make a call",
				phoneNumber: "Phone number",
				typeAction: "Type of action",
				websiteLink: "Website link",
			},
			placeholder: {
				accessWebsite: "Access our site",
				callNow: "Call now",
				enterNumber: "Enter number here",
				insertLink: "Insert the link here",
			},
			observation: {
				name: "Observation",
				description: "Observation",
			},
		},
		quickResponseAction: {
			placeholderQuickResponse: "Enter quick answer here",
			title: "Quick response {{numbering}}",
		},
		quickResponseButton: "Quick response",
		subTitle:
			"These are buttons that appear as clickable options right after your message",
		title: "Optional - Buttons",

		validationFields: {
			requiredField: "Required field {{field}}",
		},
	},
	contentControlModal: {
		alcoholicBeverages: "Alcoholic beverages",
		confirmation: {
			label:
				"I confirm that I have read the WhatsApp Business policies and am aware of what may be disclosed.",
			paragraph:
				"Facebook has policies on the type of content posted by WhatsApp. Do you confirm that you have read the",
			underlined:
				"policies and are aware of what can and cannot be disclosed by the platform?",
		},
		paragraph1: {
			text:
				"Whatsapp and Facebook have clear usage policies. All communication content that passes through the platform needs to be reviewed and approved to ensure that it does not violate Whatsapp policies. The following are some prohibited uses taken from the documentation itself.",
			textLink: "WhatsApp Business Policy. To read the full documentation, click here.",
		},
		paragraph2: {
			text:
				"Considering the context in which ZigPay operates, we must make clear some of the policies contained in Whatsapp's commercial policy documentation that are likely to interfere with what you can disclose on the platform. We have listed a few below, but it is very important that you read the",
			textLink: "Complete commercial policy documentation.",
		},
		paragraph3: {
			exampleBold: "Examples:",
			politicsBold: "Policy:",
			text: "Companies may not sell or promote the sale of alcoholic beverages",
		},
		paragraph4: {
			areAllowedBold: "Are allowed:",
			text:
				"Disclosures about books or DVDs about alcoholic beverages. Or Alcohol related items such as glasses, soft drinks and wine bottle holders.",
		},
		paragraph5: {
			areNotAllowedBold: "Not allowed:",
			text:
				"Disclosures about alcoholic beverages or kits for the production of alcoholic beverages.",
		},
		title: "Content control",
	},
	newTemplatePage: {
		footer: {
			newModelButton: "Request new model",
			title: "Message preview",
		},
		glossary: {
			client: {
				description: "Client name",
				name: "Client name",
			},
			date: {
				description: "Day of the week or specific date",
				name: "Period",
			},
			description:
				"To reuse this template, enter the code below between square brackets as in the example: “[date]”",
			discount: {
				description: "Discount percentage value",
				name: "Discount percentage",
			},
			originalProductPrice: {
				description: "Original price of the specific product",
				name: "Price of the product",
			},
			productName: {
				description: "Product's name",
				name: "Specific product name",
			},
			productPromotionalPrice: {
				description: "Specific product promotional price",
				name: "Product promotional price",
			},
			title: "Glossary of variables",
		},
		glossaryVariables: {
			client: "client",
			date: "date",
			discount: "discount",
			productName: "product-name",
			productOriginalPrice: "product-original-price",
			productPromotionalPrice: "product-promotional-price",
			observation: "observation",
		},
		header: {
			subtitle: {
				link: "Remember to always follow WhatsApp content guidelines",
				text:
					"Requesting the new template may take a few days. Type below what you want to include in your new template.",
			},
			title: "Request new model for",
		},
		imageLabel: "Image",
		imageTooltipMessage: "Attach an image to upload in your template",
		invalideVariableMessage: "You must use one of the glossary variables",
		messageErrorUpload: "The attachment must be an image type.",
		modelTitle: "Model title",
		modelTitlePlaceholder: "Enter the title",
		modelTitleTooltipMessage: "Enter a title for your template",
		removeFileButtonLabel: "Click here to attach an image",
		textareaLabel: "Message content",
		textareaPlaceholder:
			"Write the template content here. Tip: to add variables and be able to change them in the future, follow the glossary on the side.",
		textareaTooltipMessage: "Write the content of the template you would like to create",
		warning: {
			description:
				"WhatsApp can take up to 3 days to authorize the new model. During this period you can send other messages from the other models in the previous area.",
			responseDeadline: "Response deadline",
		},
	},
	section: {
		emptyTemplateState: {
			text: "Request a new template to send WhatsApp messages to your customers",
			title: "No template registered",
		},
		footer: {
			btnRequestNewModel: "Request new model",
			btnUnderstandWhy:
				"Understand why you can only send WhatsApp messages from templates",
		},
		form: {
			checkboxLabel: "Use customer name from bank",
			checkboxObservationLabel: "Use observation field from bank",
			previewTitle: "Message Preview",
			title: "Set message parameters",
		},
		header: {
			editTitle: "Edit WhatsApp message",
			goBack: "Go back and select another model",
			model: "Model",
		},
		tags: {
			approvedModel: "Approved model",
			modelInValidation: "Model in validation",
			modelRecieved: "Model received by Whatsapp",
			modelRefused: "Model not approved by Whatsapp",
		},
		understandWhyModal: {
			text:
				"Whatsapp and Facebook have clear usage policies. All communication content that passes through the platform needs to be reviewed and approved to ensure that it does not violate Whatsapp policies. To ensure that your policies are not violated, you will only be able to initiate conversations using this message template. Since facebook has the right to review and refuse the message templates at any time.",
			title: "Understand why you can only send WhatsApp messages from templates",
			understandButton: "Understood",
		},
	},
};
