export const withdraws = {
	filename: "saques-antecipacoes-boletos",
	paymentType: {
		bankAccount: "Conta bancária",
		bankPaymentSlip: "Pagamento a fornecedores",
	},
	statusType: {
		canceledPlaceConfirmation: "Cancelado",
		cancelled: "Cancelado",
		paid: "Pago",
		pending: "Pendente",
		pendingManagerApproval: "Pendente de aprovação",
		pendingPlaceConfirmation: "Aguardando Confirmação",
		pixProcessing: "Processando Pix",
		processing: "Processando",
		processingCancellation: "Processando cancelamento",
		refused: "Negado",
	},
	table: {
		appliedFee: "Taxa aplicada",
		attachments: "Anexos",
		bankAccount: "Conta bancária",
		clientObservation: "Motivo",
		confirmWithdrawBtnLabel: "Confirmar solicitação",
		datetime: "Data da solicitação",
		liquidAnticipatedValue: "Valor antecipado",
		obs: "Observação",
		paymentMethod: "Método de pagamento",
		proRataFee: "Pró rata",
		proRataFeeTooltip: "Taxa de antecipação proporcional ao período do saldo a antecipar",
		requestedValue: "Valor solicitado",
		status: "Status",
		ticketPayment: "Pagamento de boleto",
		totalBruteValue: "Valor bruto total",
		type: "Tipo",
	},
	title: "Saques, Antecipações e Boletos",
	withdrawType: {
		anticipation: "Antecipação",
		bankslip: "Boleto",
		withdraw: "Saque",
		withdrawAndAnticipation: "Saque e Antecipação",
	},
	filters: {
		searchAccountPlaceholder: "Buscar por conta",
		filterTypePlaceholder: "Filtrar por tipo",
		filterStatusPlaceholder: "Filtrar por status",
	},
};
