export const coalaAddProductModal = {
	add: "Adicionar produto",
	addItem: "Adicionar item",
	addItemName: "Item {{length}}",
	average: "Média",
	category: "Categoria",
	categoryPlaceholder: "Categoria do produto",
	description: "Descrição",
	descriptionPlaceholder: "Descrição do produto",
	edit: "Editar produto",
	equivalentProduct: "Produto equivalente",
	higher: "Maior",
	max: "Max",
	maxCount: "Qtd. máxima selecionável",
	min: "Min",
	mountableProduct: "Produto montável",
	name: "Nome",
	namePlaceholder: "Nome do produto",
	noCategory: "Sem categoria",
	price: "Valor",
	pricing: "Precificação",
	product: "Produto",
	productPrice: "Valor do produto",
	save: "Salvar alterações",
	searchProduct: "Pesquise por produtos",
	sku: "SKU",
	step: "ETAPA {{index}}",
	sum: "Soma",
	title: "Título",
	titlePlaceholder: "Titulo da etapa",
	validadeField: "Campo obrigatório",
	zigCategory: "Categoria Zig",
};
