export const zigTags = {
	block: {
		success: "Tag bloqueada com sucesso",
	},
	empty: {
		title: "Search a zig tag to manage it",
	},
	form: {
		errors: {
			zigTagRequired: "Type the tag number to search",
		},

		label: "Buscar Zig Tag",
		placeholder: "Type the tag number",
		searchBtn: "Search",
	},
	table: {
		actions: {
			blockTag: "Block Tag",
		},
		columns: {
			actions: "Actions",
			isBlocked: "Is Blocked?",
			userDocument: "Documento do usuário",
			userName: "User",
			zigCode: "Zig Code",
		},
	},
	title: "Manage Zig Tags",
};
