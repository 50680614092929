export const importNewList = {
	alert:
		"Antes de importar a planilha, garanta que todas as linhas em branco sejam removidas",
	altText: "Exemplo de como a planilha a ser importada deve ser estruturada",
	errorMessageDefault:
		"Planilha inválida, baixe-a novamente e faça as alterações necessárias. Em caso de linhas vazias em vermelho, exclua e tente novamente.",
	importList: "Importe uma lista existente para este evento",
	selectText: "Importar lista do Local tal para este evento",
	relationUsers: "Clientes que serão adicionados a lista",
	error: "Erro",
	list: "Lista",
	message: "Mensagem",
	form: {
		at: "às",
		colorSelect: "Atribuir uma cor",
		couvertPercentage: "Desconto no couvert",
		description: {
			label: "Descrição",
			limit: "Restam {{count}} caracteres",
			placeholder: "Adicione uma observação para sua lista caso queira",
		},
		entranceIds: {
			label: "Entrada associada",
			placeholder: "Selecione o tipo de entrada associada",
		},
		errorExpires: "Data e hora expirada.",
		errorName: "Informe o nome da lista",
		expiresAtDate: "Expiração da lista",
		listName: "Nome da lista",
		documentIsRequired: "Documento é obrigatório",
		canBeInvitedByLink: "Compartilhamento de lista via link",
		addGuestTimeLimit: "Prazo limite de inserção de convidados",
	},
	format: "Formato da planilha",
	goBackModal: {
		description:
			"Ao sair dessa página você perderá todas as informações preenchidas, deseja sair mesmo assim?",
		leavePage: "Sair da página",
		title: "Sair",
	},
	goBackText: "Voltar para listas",
	middleTitle: "Para importar uma nova lista basta fazer upload de uma planilha",
	or: "ou",
	secondTitle: "Importar lista nova ou existente",
	sheetFileName: "Lista de convidados",
	successMessage: "Planilha importada com sucesso",
	title: "Importar nova lista de convidados",
};
