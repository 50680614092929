import { productCard } from "./product-card";
import { invoicecCardInfo } from "./invoice-card-info";
import { duplicatesCardInfo } from "./duplicates-card-info";
import { paymentCardInfo } from "./payment-card-info";

export const importXml = {
	duplicatesCardInfo,
	invoicecCardInfo,
	paymentCardInfo,
	productCard,
	stockItens: "Itens do estoque",
	title: "Importação de nota fiscal",
};
