// IS BUILDING FOR PRODUCTION
export const isProductionBuild = process.env.NODE_ENV === "production";
export const isDevelopmentBuild = process.env.NODE_ENV === "development";

// API ENDPOINT, BE AWARE OF DANGER
export const isMain = process.env.CI_COMMIT_REF_NAME?.trim() === "main";
export const isStaging = process.env.CI_COMMIT_REF_NAME?.trim() === "staging";
export const isDev = process.env.CI_COMMIT_REF_NAME?.trim() === "develop";

/* Tooling setup  */
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import isLeapYear from "dayjs/plugin/isLeapYear";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";
import UTC from "dayjs/plugin/utc";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isLeapYear);
dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.extend(UTC);
dayjs.extend(isBetween);

import * as ReactGA from "react-ga";

import "normalize.css/normalize.css";
import { message } from "antd";

function setupTracking() {
	if (isProductionBuild) {
		console.log("VERSION", __webpack_hash__);

		ReactGA.initialize("UA-52146804-2", {});
		ReactGA.set({ env: process.env.NODE_ENV || "development" });
	}
}

function setupServiceWorker() {
	const runtime = require("@lcdp/offline-plugin/runtime");
	runtime.install({
		onUpdateReady: () => {
			runtime.applyUpdate();
		},
		onUpdated: () => window.location.reload(),
		onUpdating: () => {
			message.loading("Baixando atualizações", 0);
		},
	});

	try {
		if (localStorage.getItem("build") !== __webpack_hash__) {
			localStorage.setItem("build", __webpack_hash__);
			runtime.update();
		}
	} catch {
		runtime.update();
	}
}

function setupLocale() {
	const DEFAULT_LANGUAGE = "pt-BR";
	const locale = localStorage.getItem("lng") ?? DEFAULT_LANGUAGE;

	if (locale === "en-US") {
		dayjs.locale("en");
		dayjs.locale("en");
		return;
	}

	if (locale === "fr-FR") {
		dayjs.locale("fr-FR");
		dayjs.locale("fr-FR");
		return;
	}

	require("dayjs/locale/pt-br");
	dayjs.locale("pt-br");
	dayjs.locale("pt-br");
}

export async function setupAppConfiguration() {
	setupLocale();
	setupTracking();
	console.log("env", process.env);
	console.log("process.env.CI_COMMIT_REF_NAME", process.env.CI_COMMIT_REF_NAME);

	// prod or dev environment stuff
	if (isProductionBuild) {
		setupServiceWorker();
	}
}
