export const employees = {
	tabs: {
		employees: {
			addEmployeeZigtagError: "Erro ao adicionar colaborador com a Zigtag",
			addModal: "Adicionar colaborador",
			checkAll: "Selecionar colaboradores ({{counter}})",
			columns: {
				bar: {
					label: "Bar",
					none: "Nenhum bar selecionado",
				},
				custom: "Personalizado",
				name: "Colaborador",
				places: {
					label: "Locais",
					none: "Nenhum local selecionado",
				},
				role: "Cargo",
				username: "Login",
				zigtag: "ZigTag",
			},
			createEmployee: {
				barsSelect: {
					bars: "Bares",
					menu: "Cardápio",
					print: "Impressão",
				},
				fakeBarBlocklistPermission: "Restringir visualização de produtos do cardápio",
				menuVisualization: {
					label: "Tipos",
					placeholder: "Selecione o tipo de visualização",
					title: "Visualização do cardápio",
					type: {
						grid: "Padrão",
						smallGrid: "Foto pequena",
					},
				},
				noSelectedPlace: "Selecione um local para habilitar essa permissão",
				onlyOneSelect: "Para habilitar essa permissão, selecione apenas um local",
				validation: {
					initialValueSmaller: "Valor inicial deve ser menor que o final",
					login: "Insira um login válido",
					name: "Insira um nome válido",
					negative: "Valores não podem ser negativos",
					password: "Insira uma senha válida",
					role: "Selecione um cargo",
					same: "Os valores preenchidos estão iguais",
					selectedPlaces: "Pelo menos um local é necessário",
					twoValues: "Os dois valores devem ser preenchidos",
				},
			},
			deleteModal: {
				content: "Atenção! Os colaboradores serão removidos permanentemente.",
				title: "Deseja remover {{count}} colaborador(es)?",
			},
			deleteWarning: "Ops! Um problema ocorreu, tente novamente",
			edit: {
				data: {
					employeeNumber: {
						end: "Até",
						label: "Adicionar mais de um colaborador",
						start: "De",
						tooltip: "Apenas para mais de um colaborador dentro do mesmo cargo",
					},
					label: "Dados do colaborador",
					login: "Login",
					name: "Nome do colaborador",
					password: "Senha",
					photo: "Foto",
					zigCard: "Zig Card",
				},
				extra: {
					fichasAmount: {
						label: "Quantidade de vias em ficha",
						placeholder: "Quant. de vias",
					},
					label: "Informações extras",
					type: "Tipo de colaborador",
				},
				label: "Editar colaborador",
				places: {
					label: "Locais",
					selectedBar: {
						label: "Bares",
						placeholder: "Nenhum",
					},
					selectedPlace: "Locais",
					mainBar: "Bar principal / ponto de venda",
				},
				role: {
					employeeRole: {
						custom: "Personalizado",
						label: "Cargo",
						none: "Nenhum",
						placeholder: "Escolha um cargo",
					},
					label: "Cargos e permissões",
					permissions: "Permissões",
				},
				seeProducts: {
					blockedBar: {
						label: "Bloquear bares",
						placeholder: "Nenhum",
					},
					label: "Ver produtos dos bares",
				},
			},
			editButton: "Editar colaboradores",
			exportButton: "Exportar colaboradores",
			deleteButton: "Remover colaboradores",
			editMultiple: {
				bar: "Bares",
				confirm: "Deseja modificar os seguintes colaboradores?",
				fichasAmount: "Quantidade de vias em ficha",
				info: "Campos não preenchidos terão seus valores iniciais mantidos",
				label: "Editar colaborador ({{count}})",
				none: "Nenhum",
				number: "Numerar a partir de",
				places: "Locais",
				rename: "Renomear",
				role: "Mudar cargo",
				mainBar: "Bar principal",
			},
			employeeDelete: {
				customized: "Cargo customizado",
				text:
					"Você realmente deseja deletar {{name}} ({{role}})'? Essa ação não pode ser desfeita",
			},
			filter: "Filtrar por locais",
			importModal: {
				buttonText: "Escolha uma planilha",
				columns: {
					fichasAmount: "Quantidade de vias em fichas",
					name: "Nome",
					password: "Senha",
					username: "Nome do usuário",
					zigTag: "ZigTag",
				},
				custom: "Personalizado",
				errorNotification: "Nenhuma planilha selecionada",
				label: "Importar colaboradores",
				passwordRequirements: `Funcionários que tenham a permissão "Acesso ao Dashboard" devem seguir as seguintes regras para senha: mínimo de 6 caracteres, pelo menos uma letra.`,
				permissions: "Permissões",
				places: "Locais e Bares",
				requiredField: "Esse campo é obrigatório",
				role: "Cargo",
				selectPlace: "Ao menos um local deve ser selecionado",
				sheetFileName: "Colaboradores",
			},
			importSuccess: "Colaboradores importados com sucesso",
			label: "Equipe",
			search: "Pesquisar",
			uncheckAll: "Desmarcar todos colaboradores ({{counter}})",
		},
		roles: {
			columns: {
				employees: "Colaboradores",
				none: "Nenhum",
				options: "Opções",
				role: "Cargo",
			},
			create: "Criar cargo",
			edit: "Editar cargo",
			isNameInvalid: "Os campos não foram preenchidos corretamentes",
			label: "Cargos",
			modal: {
				importPermissions: {
					label: "Importar permissões",
					placeholder: "Ex: Caixa",
				},
				permissions: "Permissões",
				placesBar: {
					bar: "Bares",
					blocked: "Bloquear bares",
					chooseBar: "Escolha um bar",
					chooseLocal: "Escolha um local",
					chooseMinimum: "Escolha no mínimo um bar",
					none: "Nenhum",
					place: "Locais",
				},
				roleName: {
					error: "Nome inválido",
					label: "Nome do cargo",
					placeholder: "Ex: Garçom",
				},
			},
			removeRole: "Tem certeza que deseja remover o cargo?",
			roleError: "Cargo não encontrado, tente novamente",
			search: "Pesquise por cargo",
		},
	},
};
