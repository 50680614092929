export const modalImportClients = {
	add: "Add customers",
	addedClient: "client added",
	addedClients: "added clients",
	clients: "Clients",
	cpf: "CPF",
	downloadSheet: "Download sample sheet",
	emptyMessage: {
		description:
			"Add customers by CPF or by importing a list of CPFs from the example worksheet.",
		title: "No customers added",
	},
	feedback: {
		default: "An error occurred while importing the sheet",
		download: "Download",
		emptyHeaderCell: "Columns missing name",
		emptySheet: "This sheet is empty",
		errorMessage: "Invalid worksheet, download it again and make necessary changes.",
		sheetPoorlyFormatted:
			"Format spreadsheet for Calibri font, size 11, remove spaces, unnecessary line breaks and bold",
		unidentifiedColum: "Error in column names",
		verifyBaseSheet:
			", please check the sample spreadsheet template or use it as a base spreadsheet.",
	},
	import: "Import customers",
	importSheet: "Import Sheet",
	invalidClientList: "Client List (invalid)",
	name: "Name",
	phone: "Phone",
};
