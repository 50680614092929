export const notEmitedProducts = {
	activeTab: "not-emitted-products",
	columns: {
		combo: "Combo",
		count: "Quantity",
		firstNotEmittedSellDate: "First day not issued",
		lastNotEmittedSellDate: "Last day not issued",
		name: "Product",
	},
	export: "Export to Excel",
	sheet: {
		columns: {
			combo: "Combo",
			count: "Quantity",
			firstNotEmittedSellDate: "First day not issued",
			lastNotEmittedSellDate: "Last day not issued",
			name: "Product",
		},
		filename: "not_issued_products",
		title: "Products not issued",
	},
	title: "Products not issued",
};
