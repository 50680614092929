import { message } from "./message";
import { packages } from "./packages";
import { whatsapp } from "./whatsapp";
export const communication = {
	actions: {
		campaignCategoryModal: {
			placeholder: "Select a campaign",
			successMessage: "Campaign linked to message",
			text: "Select campaign",
			textButtonConfirm: "Confirm selection",
			title: "Link sending to campaign",
			tooltipMessage:
				"OPTIONAL: Link this send to a campaign to make it easier to analyze the performance of multiple sends together.",
		},
		cancelModal: {
			at: "at",
			cancel: "Cancel shipping",
			clients: "customers in the day",
			close: "Close",
			confirm:
				"When you cancel the shipment, your balance will return to your account within a few hours.",
			text: "Do you want to cancel sending the scheduled message for ",
		},
		list: {
			canceled: "View message report",
			schedule: {
				cancelShipping: "Cancel shipping",
				editAddressees: "Edit Recipients",
				editMessage: "Edit message content",
				hitchCampaign: "Link sending to campaign",
			},
			sent: "View shipping report",
		},
	},
	at: "at",
	columns: {
		campaign: "Campaign",
		channel: "Channel",
		date: "Date and time",
		impactedCustomers: "Impacted customers",
		message: "Message",
		place: "Place",
		status: "Status",
	},
	export: "Exportar para Excel",
	label: "Messages Sent",
	message,
	modalChannel: {
		channel: {
			email: {
				description: "Select a house, filter clients and send personalized emails",
				title: "Email",
			},
			sms: {
				description: "Select a home, filter clients and send standard text messages",
				title: "SMS",
			},
			whatsapp: {
				description: "Select a house, filter customers and send WhatsApp messages",
				title: "Whatsapp",
			},
		},
		tags: {
			comingSoon: "Coming soon",
			new: "New",
		},
		title: "Channel",
	},
	new: "New message",
	packages,
	permissionDenied: "No location allowed to manage WhatsApp messages",
	status: {
		canceled: "canceled",
		delivered: "delivered",
		error: "error",
		notSent: "not sent",
		optout: "optout",
		scheduled: "scheduled",
		sending: "sending",
		sent: "sent",
	},
	title: "Messages",
	warningCard: {
		label: "The value of {{value}} will be added to Zig's monthly charge payment.",
		title: "Payment",
	},
	whatsapp,
};
