import { importNewList } from "./import-new-list";
import { newList } from "./new-list";
import { report } from "./report";
import { promoter } from "./promoter";
import { infos } from "./infos";

export const guestList = {
	promoter,
	title: "Guest list",
	importList: "Import List",
	createList: "Create List",
	searchList: "search list",
	list_one: "List",
	list_other: "Lists",
	guestsNotImported: "Guests not imported",
	addGuestError: {
		relationReachesLimit: "List has already reached the guest limit",
		userAlreadyExists: "Guest already registered on the list",
		userWithoutDocument: "Guest without document",
	},
	columns: {
		active: "ACTIVE",
		description: "Description",
		userCount: "Guests",
		guest: "Guest",
		expiresAt: "Expiration",
		inactive: "DISABLED",
		name: "List Name",
		status: "Status",
	},

	deleteModal: {
		confirm: "Delete List",
		message: "Are you sure you want to delete these lists?",
		title: "Delete List",
	},
	emptyList: {
		defaultMessage: "Looks like you haven't created a list yet.",
		defaultText: "The lists will appear here when you have a registered list.",
	},
	importNewList,
	infos,
	list: {
		delete: {
			list: "Delete list",
			lists: "Delete lists",
			permanentlyList: "Permanently delete list",
			permanentlyLists: "Permanently delete lists",
			selectedList:
				"The selected list will be permanently removed and its information (guests, check-in, average ticket, entry, consumption, promoters) will be removed from the reports. This action cannot be undone. Do you want to continue anyway?",
			selectedLists:
				"The selected lists will be permanently removed and their information (guests, check-in, average ticket, entry, consumption, promoters) will be removed from the reports. This action cannot be undone. Do you want to continue anyway?",
		},
		goBack: "Back to Lists",
		import: {
			description: "To import a guest list just upload a spreadsheet",
			label: "Import guest sheet",
		},
		print: {
			date: "Issuance date",
			description: "Description",
			discount: "Discount type",
			entrance: "Associated entry",
			expiresAt: "Expires at",
			noEntrance: "No entry associated with this list.",
		},
		sheet: {
			error:
				"Invalid worksheet, download it again and make the necessary changes. In case of empty lines in red, delete and try again.",
			title: "Guest List",
		},
		success: "Worksheet imported successfully",
		warning: "Before importing the spreadsheet, ensure that all blank lines are removed",
	},
	menuDrop: {
		delete: "Delete",
		disable: "Disable list",
		download: "Download",
		edit: "Edit",
		enable: "Enable List",
		print: "Print",
	},
	newList,
	report,
	sheet: {
		columns: {
			cpf: "CPF",
			name: "Name",
			phone: "Phone",
			promoter: "Promoter",
		},
		error: "Invalid worksheet",
		example: "list (example)",
		filename: "list_{{name}}",
		title: "List: {{name}}",
	},
	utils: {
		expires: "Expires",
		expiresAt: "Expires in",
		hasExpired: "Expired there",
		insertLastName: "Insert a last name",
		invalidName: "Invalid name",
	},
};
