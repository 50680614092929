import {
	BackofficeBillPlanCategory,
	BackofficeBillPlanCategoryBase,
} from "#resources/api/enterprise-generated";
import { action, observable } from "mobx";
import { RootStore } from "./";
import enterprise from "#resources/api/enterprise-client";
import { includes } from "lodash-es";
import { autobind } from "core-decorators";

export type IPlanOfAccounts = Omit<BackofficeBillPlanCategory, "children"> & {
	children: any;
};

interface IBillPlanPath {
	name: string;
	value: string;
}

@autobind
export class PlanAccountStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	@observable
	public accountActive: string = "";

	@observable
	public planIsLoading: boolean = false;

	@observable
	public planOfAccountsColumns: IPlanOfAccounts[][] = [];

	@observable
	public billPlan: IPlanOfAccounts[] = [];

	@observable
	public billPlanPath: IBillPlanPath[] = [];

	@observable
	public backofficeBillPlanCategory: BackofficeBillPlanCategory[] = [];

	@observable
	public backofficeBillPlansTreeLeafs: BackofficeBillPlanCategoryBase[] = [];

	@action
	public togglePlanIsLoading(status: boolean) {
		this.planIsLoading = status;
	}

	@action
	public changeActiveAccount(id: string) {
		this.accountActive = id;
	}

	@action
	public createBackofficeBillPlanCategory(name: string, parentId: string | null) {
		return enterprise.createBackofficeBillPlanCategory({
			name,
			parentId,
			placeId: this.rootStore.placeStore.place?.id!,
		});
	}

	@action
	public async getBackofficeBillPlans() {
		return enterprise
			.getBackofficeBillPlans({ placeId: this.rootStore.placeStore.place?.id! })
			.then(billPlans => {
				this.backofficeBillPlanCategory = billPlans;
				this.billPlan = billPlans;
				this.clearPlanOfAccountsColumns();
				this.planOfAccountsColumns.push(billPlans);
			});
	}

	@action
	public getBackofficeBillPlansWithPath = async () => {
		const billTree = await enterprise.getBackofficeBillPlansTreeLeafs({
			placeId: this.rootStore.placeStore.place?.id!,
		});
		this.billPlanPath = billTree.map(bill => ({
			name: bill.path.replace(/\w+./, "").split(".").join(" > ").replace(/_/g, " "),
			value: bill.id,
		}));
	};

	@action
	public clearBillPlanPath = () => {
		this.billPlanPath = [];
	};

	@action
	public addPlanOfAccountsColumn(plan: IPlanOfAccounts[], index: number) {
		this.planOfAccountsColumns = this.planOfAccountsColumns.slice(0, index + 1);

		if (!includes(this.planOfAccountsColumns, plan)) {
			this.planOfAccountsColumns.push(plan);
		}
	}

	@action
	public deleteBackofficeBillPlanCategory(id: string) {
		return enterprise.deleteBackofficeBillPlanCategory({
			id,
			placeId: this.rootStore.placeStore.place?.id!,
		});
	}

	@action
	public getBackofficeBillPlansTreeLeafs() {
		return enterprise.getBackofficeBillPlansTreeLeafs({
			placeId: this.rootStore.placeStore.place?.id!,
		});
	}

	@action
	public editBillPlanCategoryName(id: string, newName: string) {
		return enterprise.editBackofficeBillPlanCategoryName({
			id,
			name: newName,
			placeId: this.rootStore.placeStore.place?.id!,
		});
	}

	@action
	public editBillPlanCategoryParent(id: string, newParentId: string | null) {
		return enterprise.editBackofficeBillPlanCategoryParent({
			id,
			newParentId,
			placeId: this.rootStore.placeStore.place?.id!,
		});
	}

	@action
	public createSuggestedBackofficeBillPlan() {
		return enterprise.createBackofficeSuggestedBillPlan({
			placeId: this.rootStore.placeStore.place?.id!,
		});
	}

	@action
	public clearPlanOfAccountsColumns() {
		this.planOfAccountsColumns.splice(0);
	}

	@action
	public moveCategoryInTree(account: IPlanOfAccounts, newParentId: string) {
		let currentIndex: number[] = [];
		let parentIndex: number[] = [];
		this.planOfAccountsColumns.forEach((column, index) => {
			const indexFind = column.findIndex(plan => plan.id === account.id);
			if (indexFind >= 0) {
				currentIndex = [index, indexFind];
			}
		});

		this.planOfAccountsColumns.forEach((column, index) => {
			const indexFind = column.findIndex(plan => plan.id === newParentId);
			if (indexFind >= 0) {
				parentIndex = [index, indexFind];
			}
		});

		const [currentIndexColumn, currentIndexLine] = currentIndex;
		const [parentIndexColumn, parentIndexLine] = parentIndex;

		this.planOfAccountsColumns[parentIndexColumn][parentIndexLine].children.push(account);

		this.planOfAccountsColumns[currentIndexColumn].splice(currentIndexLine, 1);
		if (
			!this.planOfAccountsColumns[currentIndexColumn].length &&
			currentIndexColumn < this.planOfAccountsColumns.length
		) {
			this.planOfAccountsColumns.splice(currentIndexColumn + 1);
		}
	}

	public exportBackofficeBillPlan = () =>
		enterprise.exportBackofficeBillPlan({
			placeId: this.rootStore.placeStore.place?.id!,
		});

	public importBackofficeBillPlan = (file: Buffer) =>
		enterprise.importBackofficeBillPlan({
			file,
			placeId: this.rootStore.placeStore.place?.id!,
		});

	public deleteBackofficeBillPlan = () =>
		enterprise.deleteBackofficeBillPlan({
			placeId: this.rootStore.placeStore.place?.id!,
		});
}
