import { combo } from "./combo";
import { supplies } from "./supplies";
import { bars } from "./bars";
import { grabAndGo } from "./grab-and-go";
import { duplicateMountableProduct } from "./duplicate-mountable-product";
import { xlsxPicker } from "./xlsx-picker";
import { newAndEditProduct } from "./new-and-edit-product";
import { beerDispenser } from "./beer-dispenser";
import { cmv } from "./cmv";
import { productArea } from "./product-area";
import { productDetail } from "./product-detail";
import { technicalSheet } from "./technical-sheet";

export const products = {
	bars,
	beerDispenser,
	cmv,
	combo,
	editProducts: {
		categoryChange: "Mudar categoria:",
		categoryNone: "Nenhuma",
		confirm: "Deseja modificar os seguintes produtos ?",
		save: "Salvar",
		title: "Editar Produto",
		typeChange: "Mudar tipo:",
		typeNone: "Nenhum",
	},
	grabAndGo,
	newAndEditProduct,
	productFeatureForm: {
		fields: "Campos",
		observation: "Observação",
		org: "ORG",
		selectHere: "Selecione aqui...",
		templatesOptionsPlaceholder: "Selecione o modelo",
		typeHere: "Digite aqui...",
		validateMessage: "Esse campo é obrigatório.",
		zig: "ZIG",
	},
	productArea,
	productDetail,
	technicalSheet,
	duplicateMountableProduct,
	productsTab: {
		activeAll: "Ativar todos",
		activeAllQuestion: "Ativar todos?",
		addProductButton: "Adicionar Produto",
		checkAllItems: "Selecionar todos produtos ({{counter}})",
		deleteProducts: "Remover produtos",
		deleteSelect: "Excluir Selecionados",
		deleteSelectedProducts: "Excluir Selecionados",
		disableAll: "Desativar todos",
		disableAllQuestion: "Desativar todos?",
		editProducts: "Editar Produtos",
		exportToExcelButton: "Exportar relatório",
		importProductsButton: "Importar produtos",
		syncWithDelivery: "Sincronizar com delivery",
		editProductsViaExcel: "Editar produtos via excel",
		buttonDownloadQrCodes: "Baixar QR codes",
		downloadQrCodeAppSell: "Baixar QR codes de venda para o Zig App",
		findFiscalGroupError: "Grupo fiscal cadastrado não encontrado",
		findFiscalProfileError: "Perfil fiscal cadastrado não encontrado",
		table: {
			activeBars: "Bares Ativos",
			name: "Produto",
			fiscalData: "Dados Fiscais",
			mountableDescription: "Produto Montável",
			hasProductionRule: "Ficha Técnica",
			value: "Preço",
			active: "Ativado",
			actions: "Ações",
			edit: "Editar",
			duplicate: "Duplicar produto montável",
			delete: "Apagar",
			yesOption: "Sim",
			noOption: "Não",
			onDeleteProductMessage: "Deseja deletar o produto {{productName}}?",
			variable: "Variável",
			fiscalCode: "SKU",
		},
		onDeleteMessage: {
			title_one: "Deseja remover {{counter}} produto?",
			title_other: "Deseja remover {{counter}} produtos?",
			content: "Atenção! Os produtos serão removidos permanentemente.",
			okText: "Sim",
			cancelText: "Não",
		},
		syncModal: {
			title: "Sincronizar produtos",
			chooseYourMenusMultiSelect: "Escolha seus cardápios",
			chooseTheVariablesCheckbox: {
				title:
					"Escolha as váriaveis que seráo sincronizadas para produtos já cadastrados no delivery:",
				all: "Todos",
				price: "Preço",
				category: "Categoria",
				subcategory: "Subcategoria",
				order: "Ordem",
				picture: "Foto",
				name: "Nome",
				description: "Descrição",
				mountableItems: "Itens montáveis",
			},
			activeSyncOptionCheckbox: "Ativar os produtos sincronizados para venda delivery",
			alertMessage:
				"Os produtos que não estão no delivery serão criados com as mesmas informações",
			successFeedback: "Sucesso, todos produtos sincronizados!",
			warningFeedback: "Alguns produtos tiveram problemas de sincronia.",
			warningFeedbackTable: {
				type: "Tipo",
				description: "Descrição",
			},
		},
		importModal: {
			import: "Importar",
			downloadSheet: "Baixar planilha",
			buttonText: "Escolher planilha",
			title: "Importar dispositivos de planilha",
			sheetFileName: "Produtos",
			activateProduct: "Ativar produtos importados",
			alertMessage:
				"Atenção! o valor dos produtos é informado em centavos, ex: 123 equivale a R$ 1,23",
			table: {
				columns: {
					product: "Produto",
					error: "Erro",
					description: "Descrição",
				},
				errors: {
					invalidTaxData: "Dados fiscais",
					ncm: "NCM",
					cest: "CEST",
					duplicatedFiscalCode: "SKU duplicado",
					productNotFound: "Produto não encontrado",
					categoryWithSubcategory: "Categoria",
					supercategoryNotFound: "Tipo do produto",
					name: "Nome",
					category: "Categoria",
					hasAlcohol: "Contém álcool",
					isHiddenFromApp: "Ocultar produto",
					value: "Preço",
					kind: "Tipo do produto",
					beerAbv: "ABV - Teor alcoólico",
					other: "Desconhecido",
				},
				errorsDescription: {
					name: "Nome do produto não informado",
					category: "Categoria do produto não informada",
					hasAlcohol: "O campo precisa ser verdadeiro ou falso",
					isHiddenFromApp: "O campo precisa ser verdadeiro ou falso",
					value: "O preço do produto é inválido",
					kind: "O tipo do produto é inválido",
					beerAbv: "ABV - Teor alcoólico deve ser um número",
					other: "Erro desconhecido",
				},
			},
		},
		xlsxPicker,
		getTitleDialogActive: "Ativar produto",
		getTitleDialogDisable: "Desativar produto",
		getDescriptionDialogActive:
			"Deseja ativar esse produto nos produtos montáveis que ele compõe?",
		getDescriptionDialogDisable:
			"Deseja desativar esse produto nos produtos montáveis que ele compõe?",
		limitOver: "Limite acima de {{limit}} selecionados",
		onDeleteSelectProductsError: "Ops! Um problema ocorreu, tente novamente",
		productWithoutCategory: "Produtos sem categoria do menu",
		productsByCategory: "Produtos por Categoria",
		searchLabel: "Pesquisar",
		searchPlaceholder: "Buscar produtos...",
		sortByCategory: "Organizar por categoria",
		sortByMenuCategory: "Organizar por categoria do menu",
		tabTitle: "Produtos",
		testEmission: {
			loadingText: "Aguarde, estamos testando os produtos selecionados",
			success: "Dados fiscais testados com sucesso",
			successDescription: "Não foi encontrado nenhum erro nos dados apresentados",
			testing: "Testando {{currentTest}}/{{length}}",
			titleError: "Ops, encontramos alguns erros",
			titleSuccess: "Testando dados fiscais - Sucesso",
			titleTesting: "Testando dados fiscais",
			updating: "Atualizando produtos...",
		},
		testFiscalData: "Testar dados fiscais",
		uncheckAllItems: "Desmarcar todos produtos ({{counter}})",
		manageProducts: "Gestão de produtos",
		managePlaceProducts: "Gerencie os produtos do local",
	},
	supplies,
};
