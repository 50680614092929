export const chipsBurned = {
	byEmployee: {
		label: "Fichas queimadas por funcionário",
		sheetFileName: "fichas-queimadas-funcionario",
	},
	columns: {
		count: "Quantidade",
		discount: "Descontos",
		fracionalCount: {
			grams: "gramas",
			kg: "Kilos",
			label: "Quantidade Frac.",
			lt: "Litros",
		},
		name: "Nome",
		subtotal: "Subtotal",
		totalValue: "Total",
		unitaryCount: "Quantidade Unit.",
		unitaryValue: "Valor unitário",
		type: "Tipo",
	},
	label: "Total de fichas queimadas",
	loading: "Gerando planilha",
	mountableModal: {
		count: "Quantidade",
		name: "Nome",
		section: "Sessão Montável",
	},
	mountables: {
		category: "Categoria Produto",
		count: "Quantidade",
		fiscalCode: "SKU",
		label: "Montaveis",
		name: "Item Montável",
		product: "Produto",
		section: "Etapa Montável",
	},
	sheetFileName: "total-fichas-queimadas",
	since: "De",
	total: "TOTAL",
	totalItem: "Total",
	until: "Até",
};
