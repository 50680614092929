import { service } from "./service";
import { general } from "./general";
import { company } from "./company";
import { fiscal } from "./fiscal";
import { tip } from "./tip";

export const steps = {
	tip,
	company,
	fiscal,
	general,
	service,
};
