export const beerDispenser = {
	emptyText: "No beer found.",
	error: {
		invalidIp: "Invalid IP format",
		requiredField: "Required field",
	},
	ip: "IP",
	modal: {
		addBeerDispenser: "Add draft beer",
		deleteDispenser: "Delete draft beer",
		deleteText: "Are you sure you want to delete the draft beer <b>{{chopName}}</b>?",
		dispenserIp: "Draft beer IP",
		editBeerDispenser: "Edit draft beer",
		name: "Name",
		number: "Number",
		product: "Product",
		productTooltip: "Only products in milliliters/liters",
	},
	newBeerDispenser: "New draft beer",
	number: "Beer tap number",
	product: "Product",
	searchPlaceholder: "Search",
	title: "Draft Beer",
	description:
		"Create and customize a practical self-service solution for beverages at your point of sale.",
	partner: "Partner",
	active: "Active",
	inactive: "Inactive",
	confirmation: {
		activate: "Activate",
		inactivate: "Inactivate",
		title: "{{activate}} AutoBar",
		subtitle:
			"Are you sure you want to {{activate}} the AutoBar? By doing so, it will be ready to be used at your point of sale.",
	},
	modalSelector: {
		title: "New AutoBar",
		description: "Choose the type of system you want to use in the AutoBar:",
		options: {
			beerTap: "BEERTAP",
			smartpos: "SMARTPOS",
		},
	},
};
