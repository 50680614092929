export const booking = {
	reserve: {
		bookDetail: {
			confirmed: "{{count}} confirmed",
			copyModal: {
				copy: "Copy",
				defaultReserveName: "Copy of {{name}}",
				errors: {
					inputs: "The fields were not filled in correctly.",
					length: "The package name has not reached the minimum number of characters.",
					name: "The package name is the same as the original name.",
				},
				placeholder: "Package copy name",
				text: "Do you want to create a backup copy",
				title: "Create copy of package",
			},
			delete: "Are you sure you want to delete this product?",
			guests: "Guests",
			guestsCount: "{{count}} guests",
			invoices: "Invoices generated",
			issueInvoice: "Issue invoice",
			modal: {
				add: "Add tax group",
				addDiscount: "Add discounts to bill",
				basket: "CEST",
				chooseOptions: "Choose one of the options",
				cnpj: "CNPJ",
				completeValue: "Complete the package value",
				cpf: "CPF",
				discount: "Discounts",
				errors: {
					inputs: "Fields were not filled in correctly",
					value: "Include some product with value to use this functionality",
				},
				fiscalGroup: "Fiscal Group",
				fiscalProfile: "Fiscal Profile",
				inputMultiplePaymentsManual: {
					add: "Add payment",
					label: "Payment method",
					placeholder: "Select",
					value: "Value",
				},
				invoiceAlert: "Bills with a value greater than BRL 10,000 will be split.",
				invoiceValue: "Invoice value",
				issueInvoice: "Issuing note",
				ncm: "NCM",
				none: "None",
				optionalTip: {
					add: "Add tip",
					title: "Tip (optional)",
				},
				packageValue: "Package value",
				paymentMethod: "Payment Method",
				remove: "Remove",
				removeTip: "Remove tip",
				title: "Issue invoice for {{name}}",
				total: "Total",
				type: "Tip",
				value: "Value",
			},
			none: "No promotion selected",
			of: "of",
			peopleArrived: {
				subtitle: "Already out",
				title: "At the event",
			},
			peopleDidntArrive: {
				subtitle: "No information",
				title: "Unknown",
			},
			promotions: "Package Promotions",
			tableMultiplePayment: {
				columns: {
					paymentMethod: "Payment Method",
					value: "Value",
				},
				total: "Total added",
			},
			title: "Package of",
			value: "Package value",
		},
		create: "Create package",
		empty: {
			subtitle:
				"Create your first package by clicking the ¨Create package¨ button at the top of this page",
			title: "No packages for this event",
		},
		label: "Packages",
		reserveCard: {
			guests: "{{count}} guests",
			none: "No promotion selected",
			people: "{{count}} confirmed people",
			promotions: "{{count}} promotions",
		},
		reserveModal: {
			clientInfo: "Customer Information",
			create: "Create",
			edit: "Edit package",
			errors: {
				inputs: "Please fill in all required fields for the selected category.",
				promotion: "No promotion selected",
				selectedTemplate: "The template could not be found",
			},
			estimatedPeopleCount: "Number of guests",
			expiresAt: "Expires in",
			inactive: "(Promotion inactive)",
			modalTitle: {
				create: "Create package",
				edit: "Edit {{name}}",
				newTemplate: "Create Template",
				template: "Create from template",
			},
			models: "Models",
			name: "Name",
			newTemplate:
				"Attention! The created template can be used by all events in this location.",
			promotion: "Promotion",
			promotionLabel: "Promotion {{number}}",
			template:
				"The package can be customized, but it will create association with the model to be used in reports and metrics.",
			updateModal: {
				description: "Description",
				percentage: "Percent",
				productName: "Product Name",
				promotionName: "Promotion name",
			},
			value: "Package value",
		},
		reserveOptions: {
			allEvents: "all events",
			canBeUsedIn: "Can be used in",
			create: {
				label:
					"Define how the package will be offered and if you want to include a promotion.",
				name: "Create custom package",
			},
			createTemplate: {
				label: "Create a custom package template for future use in your events.",
				name: "Create new model",
			},
			createWithTemplate: {
				label:
					"Create a package for your customers based on the templates created earlier.",
				name: "Create from template",
			},
			fromThisPlace: "from this location",
			managePackages: "Manage packages",
		},
		reserveTemplate: {
			empty: "The location does not have any model registered.",
			guests: "Guests",
			loading: "Loading ...",
			modalConfirm: {
				content: "It can no longer be used in any event at this location.",
				title: "Are you sure you want to remove the template?",
			},
			promotions: "Promotions",
			remove: "Remove",
			value: "Package value",
		},
		search: "Search for packages",
	},
	reserveReport: {
		label: "Package Report",
		subtitle: {
			default: "{{count}} packages active during event",
			none: "No packages active during the event",
			one: "a packagen active during the event",
		},
	},
};
