export const discountCategory = {
	activeCategories: "Categorias de desconto ativas",
	allCategories: "Todas as categorias de desconto",
	create: "Criar nova categoria",
	drawer: {
		create: "Criar categoria de desconto",
		edit: "Editar categoria de desconto",
	},
	errorNotification: "Selecione um local primeiro",
	filter: {
		label: "Filtrar por",
		placeholder: "Escolher filtro",
	},
	form: {
		and: "e",
		between: "Entre",
		discount: {
			label: "Desconto dado em",
			percentage: "Porcentagem",
			value: "Reais",
		},
		employees: {
			label: "Funcionários autorizados a utilizar a categoria",
			placeholder: "Pesquise por nome",
		},
		errors: {
			estabilishedType: "Selecione uma opção acima",
			name: "Insira um nome válido",
		},
		estabilishedType: {
			fixed: "Valor fixo",
			label: "Estabelecido",
			range: "Faixa",
		},
		name: {
			label: "Nome da categoria",
			placeholder: "Dê um nome à categoria de desconto",
		},
	},
	inactiveCategories: "Categorias de desconto inativas",
	label: "Crie, edite, delete, ative ou desative categorias de desconto",
	modal: {
		message: "Você deseja excluir a categoria de '{{name}}'?",
		title: "Excluir categoria de desconto",
	},
	search: {
		label: "Pesquisar",
		placeholder: "Pesquisar nome da categoria de desconto",
	},
	table: {
		discountType: "DESCONTO DADO EM",
		editCategory: "Editar categoria de desconto",
		employee: "Funcionário com Permissão",
		emptyText: "Parece que você ainda não criou uma categoria de desconto",
		hideEmployees: "Ocultar funcionários com permissão",
		loading: "Carregando",
		name: "NOME DA CATEGORIA DE DESCONTO",
		removeCategory: "Excluir categoria de desconto",
		showEmployees: "Visualizar funcionários com permissão",
		status: "STATUS",
		value: "FAIXA OU VALOR FIXO",
	},
	title: "Categorias de Descontos",
};
