export const financial = {
	bonus: {
		label: " Bônus",
	},
	filters: {
		average: "Ticket médio",
		bonus: "Bônus",
		income: "Receita",
	},
	financialChartCards: {
		average: " Média",
		averageTicket: "Ticket Médio",
		billing: "Faturamento",
		bonus: "Bônus",
	},
	graphCards: {
		averageTicket: {
			label: "Recordes de ticket médio",
			subtitle: "Valor faturado por dia durante o período {{periodLabel}}",
		},
		averageTicketPerWeekDay: {
			label: "Dias da semana com maiores tickets médios",
			subtitle:
				"Valor médio do Ticket Médio por dia da semana durante o período {{periodLabel}}",
		},
		billingsPerPeriod: {
			label: "Dias com maiores receitas",
			subtitle: "Valor faturado por dia da semana durante o período {{periodLabel}}",
		},
		billingsPerWeekDay: {
			label: "Dias da semana com maiores receitas",
			subtitle:
				"Valor médio faturado por dia da semana durante o período {{periodLabel}}",
		},
		bonusPerWeek: {
			label: "Dias da semana com mais bônus",
			subtitle: "Valor bonificado por dia durante o período {{periodLabel}}",
		},
		consumptionTime: {
			columns: {
				friday: "Sexta",
				general: "Geral",
				monday: "Segunda",
				period: "Período",
				saturday: "Sábado",
				sunday: "Domingo",
				thursday: "Quinta",
				tuesday: "Terça",
				wednesday: "Quarta",
			},
			filename: "total_de_venda_por_horario",
			formatPeriod: {
				currPeriod: "às {{currPeriod}}",
				nextPeriod: "{{currPeriod}} até {{nextPeriod}}",
			},
			label: "Total de venda por horário",
			subtitle: "Período: {{periodLabel}}",
			tooltip: {
				date: "Total consumido às {{date}}",
				dates: "Total consumido de {{since}} até {{until}}:",
			},
		},
		highestBonus: {
			label: "Maiores bonificações",
			subtitle: "Valor bonificado por dia durante o período {{periodLabel}}",
		},
		paymentMethod: {
			anticipated: "Antecipado",
			app: "Aplicativo",
			cash: "Dinheiro",
			credit: "Crédito",
			dashboard: "Dashboard",
			debit: "Débito",
			label: "Métodos de pagamento",
			others: "Outros",
			postEvent: "Após o evento",
			subtitle:
				"Valor faturado por método de pagamento durante o período {{periodLabel}}",
			uniformed: "Não informado",
			voucher: "Voucher",
			zigCredit: "Crédito Zigpay",
			zigDebit: "Débito Zigpay",
		},
	},
	income: {
		label: "Comparar com",
		labelChart: " Faturado",
	},
	methodFilters: {
		cash: "Dinheiro",
		credit: "Crédito",
		debit: "Débito",
		total: "Média",
		zigCredit: "Crédito Zigpay",
		zigDebit: "Débito Zigpay",
	},
	periodLabel: "({{since}} até {{until}})",
	ticket: {
		average: " Ticket Médio",
		real: " Ticket Real",
	},
};
