export const newAndEditProduct = {
	cancelButton: "Cancelar",
	confirmButton: "Confirmar",
	duplicateButton: "Duplicar",
	errors: {
		abvQuantity: "O percentual deve ser menor que 100%",
		categoryId: "Escolha ou crie uma categoria",
		length: "Máx. {{length}} caracteres",
		name: "Use apenas caracteres válidos",
		newCategory: "Você criará uma nova categoria",
		productFeature: "A funcionalidade {{wrongFeature}} não está preenchida corretamente.",
		productFeatures:
			"As funcionalidades {{wrongFeatures}} não estão preenchidas corretamente.",
		productRules: "Existem insumos não preenchidos",
		proportionalQuantity: "A quantidade deve ser maior que 0",
		requiredField: "Esse campo é obrigatório",
		wrongSection: "A Etapa {{wrongStep}} não está preenchida corretamente.",
		wrongSections: "As Etapas {{wrongSteps}} não estão preenchidas corretamente.",
		fiscalCode: "Apenas números, pontos e letras",
	},
	forms: {
		details: {
			beerABV: "ABV - Teor alcoólico",
			beerIbu: "IBU - Unidades Internacionais de Amargor",
			beerBrand: "Marca",
			beerStyle: "Estilo da bebida",
			category: "Categoria",
			description: "Descrição",
			fiscalDataMessage: "O código do produto será gerado automaticamente",
			hasAlcohol: "Contém Álcool",
			hasFiscalData: "Dados fiscais",
			hasMountableProduct: "Produto montável",
			isStockable: "Produto estocável",
			hasProductRule: "Ficha Técnica",
			hasProportionalProduct: "Produto em g/ml",
			isHiddenFromApp: "Não exibir produto no aplicativo ZigApp",
			measurement: "Unidade de Medida",
			menuCategory: "Categoria do Menu",
			syncWithBeerIntegration: "Sincronizar com {{integration}}",
			beerIntegrationWarning:
				"Não é possível desativar essa função com 'Sincronizar com {{integration}}' ativa",
			beerIntegrationDisabledWarning:
				"Não é possível desativar essa função com 'Sincronizar com {{integration}}' ativa",
			beerIntegrationEnabledWarning:
				"Não é possível ativar essa função com 'Sincronizar com {{integration}}' ativa",
			beerIntegrationPlaceFeatureWarning:
				"Função desabilitada. Entre em contato com o suporte para ativa-la no admin.",
			isSupplyToggle: "Insumo",
			name: "Nome do Produto",
			picture: "Foto",
			price: "Preço",
			priceIn: "Preço em",
			selectType: "Selecione um tipo de produto",
			type: "Tipo do Produto",
			variablePrice: "Preço variável",
			productReturn: "Produto retornável",
		},
		fiscal: {
			cest: "CEST",
			code: "SKU",
			codeErrorMessage: "Caso não seja preenchido, o código será gerado automaticamente",
			fiscalGroup: "Grupo fiscal",
			fiscalProfile: "Perfil fiscal",
			ncm: "NCM",
		},
		mountable: {
			addStep: "Adicionar Etapa",
			getDescriptionDialog: {
				activate: "Deseja ativar esse item nos demais produtos montáveis que ele compõe?",
				disable:
					"Deseja desativar esse item nos demais produtos montáveis que ele compõe?",
			},
			getTitleDialog: {
				activate: "Ativar item montável",
				disable: "Desativar item montável",
			},
			information: "INFORMAÇÕES",
			infos: {
				invalidValue: "Valor inválido",
				label: "Informações",
				max: "Max",
				min: "Min",
				precification: "Precificação",
				precificationErrorMessage: "Escolha um método",
				precificationSelect: {
					average: "Média",
					averageTooltip: "A média dos itens selecionados será incrementada",
					higher: "Maior valor",
					higherTooltip:
						"O maior valor entre os itens será adicionado ao valor do produto",
					label: "Escolha...",
					sum: "Adicional",
					sumTooltip: "Todo item adicionado será incrementado no valor do produto",
				},
				title: "Título",
				titleErrorMessage: "Nome inválido",
				tooltipMax: "Máximo de itens a ser selecionado.",
				tooltipMin: "Mínimo de itens a ser selecionado.",
				validateMaxMinErrors: {
					maxBiggerThenMin: "Valor máximo deve ser maior que o valor mínimo",
					maxValueZero: "O valor máximo não pode ser igual à zero",
					negativeValue: "Não é permitido valor negativo",
				},
			},
			items: {
				additionalItems: "Adicionar outro item",
				itemKey: "Item {{itemKey}}",
				label: "Itens",
				maxQuantity: "Quantidade máx selecionável",
				maxQuantityTooltip:
					"Quantidade máxima de vezes que este item pode ser selecionado na seção",
				storageCount: "Quantidade abatida",
				storageCountErrorMessage: "Quantidade inválida",
				storageCountTooltip: "Quantidade que será abatida do estoque por unidade.",
				title: "Item",
				titleErrorMessage: "Nenhum item selecionado",
				value: "Valor",
				valueErrorMessage: "Valor adicional inválido",
			},
			itemsTitle: "ITENS",
			step: "Etapa",
		},
		productRule: {
			addInput: "Adicionar outro insumo",
			generatedQuantity: "Quantidades geradas do produto",
			input: "Insumo",
			loss: "Perda",
			quantity: "Quantidade",
		},
		proportionalValue: {
			grams: "Gramas",
			measurement: "Unidade mensurável",
			mililiters: "Mililitros",
			quantity: "Quantidade",
		},
	},
	titles: {
		add: "Adicionar Produto",
		downloadQRCodeToSell: "Baixar QR code para venda",
		edit: "Editar Produto",
		hasFiscalData: "Dados fiscais",
		hasMountableProduct: "Produto montável",
		hasProductRule: "Ficha Técnica",
		hasProportionalProduct: "Produto em g/ml",
		productDetails: "Detalhes do produto",
		releaseValue: "Preço de solta",
	},
	undefined: "indefinido",
};
