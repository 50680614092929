export const beerDispenser = {
	emptyText: "Nenhuma chopeira encontrada.",
	error: {
		invalidIp: "Formato de IP inválido",
		requiredField: "Campo obrigatório",
	},
	ip: "IP",
	modal: {
		addBeerDispenser: "Adicionar chopeira",
		deleteDispenser: "Excluir chopeira",
		deleteText: "	Você tem certeza de que deseja excluir a chopeira <b>{{chopName}}</b>?",
		dispenserIp: "IP da chopeira",
		editBeerDispenser: "Editar chopeira",
		name: "Nome",
		number: "Número",
		product: "Produto",
		productTooltip: "Somente produtos em mililitros/litros",
	},
	newBeerDispenser: "Nova chopeira",
	number: "Nº da torneira",
	product: "Produto",
	searchPlaceholder: "Procurar",
	title: "Chopeira",
	description:
		"Crie e personalize uma solução prática de autoatendimento exclusivo para bebidas no seu ponto de venda.",
	partner: "Parceiro",
	active: "Ativo",
	inactive: "Inativo",
	confirmation: {
		activate: "Ativar",
		inactivate: "Inativar",
		title: "{{activate}} AutoBar",
		subtitle:
			"Você tem certeza que deseja {{activate}} o AutoBar? Com isso, ele estará apto para ser usado no seu ponto de venda.",
	},
	modalSelector: {
		title: "Novo AutoBar",
		description: "Escolha o tipo de sistema que deseja utilizar no AutoBar:",
		options: {
			beerTap: "BEERTAP",
			smartpos: "SMARTPOS",
		},
	},
};
