export const invoices = {
	title: "Invoices",
	filename: "invoices",
	table: {
		invoiceNumber: "Number",
		issueDate: "Issue date",
		period: "Period",
		status: "Status",
		value: "Value",
		generatedAs: "Generated as",
		pdf: "PDF",
		noAttachments: "No attachments",
		attachments: "Attachments",
	},
	status: {
		scheduled: "Scheduled",
		synchronized: "Synchronized",
		authorized: "Authorized",
		processingCancellation: "Processing Cancellation",
		canceled: "Canceled",
		cancellationDenied: "Cancellation Denied",
		error: "Error",
		zigPending: "Pending",
	},
	emptyText: "There is nothing to show for the selected period",
};
