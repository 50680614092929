export const crudMessage = {
	campaignModal: {
		confirmButton: "Criar campanha",
		placeholder: "Insira o nome da campanha",
		title: "Nome da campanha",
		tooltipMessage:
			"OPCIONAL: Atrele esse envio à uma campanha para facilitar a análise da performance de múltiplos envios juntos.",
	},
	campaignSelect: {
		createOrSearch: "Busque por campanha ou crie uma nova",
		placeholder: "Selecionar campanha",
		text:
			"OPCIONAL: Atrele esse envio à uma campanha para facilitar a análise da performance de múltiplos envios juntos.",
		title: "Campanha",
	},
	campaignsDetail: {
		canceled: "Cancelado",
		canceledMessage: "A mensagem que seria enviada no dia {{day}} foi cancelada",
		client: "cliente",
		clients: "clientes",
		columns: {
			clientName: "Nome do cliente",
			cpf: "CPF",
			gender: "Gênero",
			phone: "Telefone",
			status: "Status",
		},
		genders: {
			female: "Mulher",
			male: "Homem",
			other: "Outro",
			uninformed: "Não informado",
		},
		programedMessage:
			"Sua mensagem foi programada no dia {{day}} para ser enviada no dia {{date}}",
		sent: "Enviado",
		sentInDay: "Enviado no dia {{day}} para",
	},
	content: "Conteúdo da mensagem",
	create: {
		client: "pessoa",
		clients: "pessoas",
		show: "Clique aqui para ver dicas sobre o conteúdo",
		subtitle: {
			sms:
				"Digite o conteúdo que será enviado para a lista de clientes selecionado. Sua mensagem vai impactar ",
			whatsapp:
				"Selecione um template com base no que deseja comunicar e depois altere os seus parâmetros.",
		},
		subtitleTemplateList:
			"Selecione um template com base no que deseja comunicar e depois altere os seus parâmetros.",
		title: "Nova mensagem",
	},
	day: "Dia",
	edit: {
		subtitle: "Reescreva o conteúdo da mensagem ou altere a programação do envio.",
		title: "Editar mensagem programada",
	},
	errors: {
		campaign: "Não foi possível criar a campanha.",
		editMessage: {
			sendNow: "Dados atualizados. Mensagem será enviada em instantes.",
			sucess: "Dados atualizados.",
		},
		place: "Não foi possível identificar o place",
		smsCost: "Custo do SMS precisa ser configurado",
	},
	footer: {
		reprogram: "Reprogramar envio de SMS",
		scheduleShipping: "Agendar envio de SMS",
		send: "Enviar SMS agora",
	},
	hour: "Hora",
	modalMessage: {
		addBalance: "Adicionar Saldo",
		messageScheduled: {
			at: "às",
			cancelOrEdit:
				"Caso queira cancelar o envio ou alterar o conteúdo, volte para a lista de mensagens e edite as informações de envio.",
			client: "cliente",
			clients: "clientes",
			clientsCount: "Sua mensagem será enviada para ",
			day: "dia",
			of: "de",
		},
		messageSent: {
			client: "cliente",
			clients: "clientes",
			clientsCount: "Sua mensagem foi enviada para ",
			of: "de",
		},
		messageTips: {
			tipFour:
				"Lançamento de algum produto novo? Filtre os seus melhores clientes e envie todas as informações do seu produto.",
			tipOne:
				"Uma mensagem bem escrita impacta seus clientes de maneira única! Como você tem {{characters}} caracteres, o ideal é que a sua mensagem seja sucinta, clara e direta.",
			tipThree:
				"Vai rodar uma promoção interessante? Filtre o grupo de clientes que tem direito a promoção e envie SMS direcionado.",
			tipTwo:
				"Você pode incluir links importantes para os seus clientes. Links para a sua landing page ou para o ticket do evento facilita o acesso do cliente à informações e compra de ingressos.",
			tips: "Dicas do que você pode fazer",
		},
		notBalance:
			"Você não possui saldo suficiente para enviar SMS para o grupo selecionado. Deseja adicionar o saldo restante?",
		title: {
			messageScheduled: "Mensagem programada",
			messageSent: "Mensagem enviada",
			messageTips: "Dicas de mensagem",
		},
	},
	program: "Programar envio",
	resume: "Dados do envio",
	resumeCard: {
		amountMessageNotReceived: {
			error: {
				sms: "SMS não recebidos por erro",
				whatsapp: "mensagens não recebidas por erro",
			},
			notRecieved: {
				sms: "SMS não recebidos",
				whatsapp: "mensagens não recebidas",
			},
			optout: {
				sms: "SMS não recebidos por optout",
				whatsapp: "mensagens não recebidas por optout",
			},
		},
		amountMessageReceived: {
			sms: "SMS recebidos",
			whatsapp: "mensagens recebidas",
		},
		amountMessageSent: "Total de destinatários",
		footerText: {
			addedValue:
				"O valor de {{value}} será adicionado ao pagamento da cobrança mensal da Zig.",
			charactersLimit:
				"Quantidade de caracteres ultrapassou o limite. O custo do SMS foi alterado e o valor de {{value}} será adicionado ao pagamento da cobrança mensal da Zig.",
			noExtraCost:
				"Como não houve alteração na quantidade da lista de destinatários, não terá nenhum custo extra.",
			smsBalanceAddedValue:
				"O valor extra de {{value}} será acrescentado ao pagamento da cobrança mensal da Zig.",
			smsBalanceSubtractedValue:
				"O valor de {{value}} será abatido do pagamento da cobrança mensal da Zig.",
			summarizes: "Mensagens para clientes com optout não serão cobradas.",
		},
		messageCost: "Custo do envio",
		messageInProcess: {
			sms: "SMS em processamento",
			whatsapp: "Mensagem em processamento",
		},
		postShipmentBalance: "Saldo pós envio",
		rescheduledShippingCost: "Custo do envio reprogramado",
		resume: "Resumo do envio",
		sms: "SMS",
		summarizes: "Resumo do envio",
	},
	sendNow: "Enviar agora",
	success: {
		createTemplate: "Novo template solicitado",
	},
	textArea: {
		at: "às",
		character: "caracter restante",
		characters: "caracteres restantes",
		content: "Conteúdo da mensagem",
		copy: "Copiar conteúdo da mensagem",
		footer:
			"Dica: para adicionar variáveis e poder alterá-las no futuro, digite [VARIÁVEL] entre colchetes.",
		placeholder: "Escreva o conteúdo da mensagem para o grupo selecionado...",
		sucessCopy: "Mensagem copiada para área de transferência",
	},
};
