import {
	cardComponent,
	closingCardComponent,
	expenseFormComponent,
	expensesTableComponent,
	listComponent,
	missingExpensesDataWarningCard,
	totalCardComponent,
} from "./components";
import { closingDetailsPage, listPage } from "./pages";
import { tabsLabels } from "./types";

export const eventOperations = {
	components: {
		cardComponent,
		closingCardComponent,
		expenseFormComponent,
		expensesTableComponent,
		listComponent,
		missingExpensesDataWarningCard,
		totalCardComponent,
	},
	pages: {
		closingDetailsPage,
		listPage,
	},
	types: {
		tabsLabels,
	},
};
