export const notEmitedProducts = {
	activeTab: "not-emited-products",
	columns: {
		combo: "Combo",
		count: "Quantidade",
		firstNotEmittedSellDate: "Primeiro dia não emitido",
		lastNotEmittedSellDate: "Último dia não emitido",
		name: "Produto",
	},
	export: "Exportar Excel",
	sheet: {
		columns: {
			combo: "Combo",
			count: "Quantidade",
			firstNotEmittedSellDate: "Primeiro dia não emitido",
			lastNotEmittedSellDate: "Último dia não emitido",
			name: "Produto",
		},
		filename: "produtos_nao_emitidos",
		title: "Produtos não emitidos",
	},
	title: "Produtos não emitidos",
};
