export const closings = {
	calendar: {
		toReceive: "Receivable",
		totalSoldValue: "Total value",
	},
	choosePeriod: "Choose period to export spreadsheet",
	download: "Download sheet",
	modal: {
		details: "Day details",
		eventDate: "Event Date",
		fixedFee: "Fixed Rent Fee",
		generalFeeValue: "General variable fee",
		generalPercentageFee: "Variable fee on billing",
		otherProductsFeeValue: "Variable Fee on Special Products",
		payDate: "Payment date",
		totalSoldValue: "Total Billing",
		vendorName: "Vendor",
		vendorValueToReceive: "Amount to receive",
	},
	sheet: {
		columns: {
			eventDate: "Event Date",
			fixedFee: "Fixed Rent Fee",
			generalFeeValue: "General variable fee",
			generalPercentageFee: "Variable fee on billing",
			otherProductsFeeValue: "Variable Fee on Special Products",
			payDate: "Payment date",
			totalSoldValue: "Total Billing",
			vendorName: "vendor",
			vendorValueToReceive: "Amount to receive",
		},
		filename: "vendors-closing",
		title: "Vendor Closing",
	},
	title: "Closing by vendor",
};
