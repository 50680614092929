import { accountsPayableReceivable } from "./accounts-payable-receivable";
import { bills } from "./bills";
import { finExManagerialReport } from "./finex-managerial-report";
import { planOfAccounts } from "./plan-of-accounts";
import { customerAndSupplier } from "./customer-and-supplier";
import { stockTransferHistory } from "./stock-transfer-history";
import { CONCILIATION_PT_BR } from "#pages/backoffice/conciliation/translations/conciliation-pt-br";
import { eventConference } from "./event-conference";

export const backofficePT = {
	eventConference,
	accountsPayableReceivable,
	stockTransferHistory,
	bills,
	cashFlow: {
		pageTitle: "Fluxo de caixa",
		pageParagraph:
			"Essa ferramenta possibilita apurar o saldo disponível no momento e projetar o futuro, para que exista sempre capital de giro acessível tanto para o custeio da operação da empresa, quanto para o investimento em melhorias.",
		tooltipMessage:
			"As linhas de categorias do nível {{level}} exibem a soma de todos os níveis agrupados dentro dela.",
		fileName: "fluxo-de-caixa",
		topActions: {
			lblAccountName: "Buscar por Conta",
			plhAccountName: "Insira o nome da conta",
			lblDate: "Filtrar por Vencimento",
			btnPdf: "Exportar para PDF",
			btnExcel: "Exportar para Excel",
		},
		totalizers: {
			income: "Total receitas",
			costs: "Total custos",
			expenses: "Total despesas",
			balance: "Total do período",
		},
		rowTotal: "Saldo do Período",
		rowHead: "Saldo inicial",
		rowTail: "Saldo do dia",
		all: "Todas",
		viewPerDay: "Visualizar por dia",
		viewPerMonth: "Visualizar por mês",
		columns: {
			income: "Receitas",
			costs: "Custos",
			expenses: "Despesas",
			level: "Nível",
			name: "Nome",
		},
		status: {
			paid: "Pago",
			received: "Recebido",
			opening: "Em aberto",
		},
	},
	costCenter: {
		costCenter: "Centros de custo",
		addCostCenter: "Adicionar centro de custo",
		editCostCenter: "Editar centro de custo",
		namePlaceholder: "Nome do centro de custo",
		name: "Nome",
		action: "Ações",
	},
	planOfAccounts,
	bankAccounts: {
		titlePage: "Contas bancárias",
		addAccount: "Cadastrar conta",
		columnsTable: {
			name: "Nome",
			bank: "Banco",
			agency: "Agência",
			purpose: "Finalidade da conta",
			actions: "Ações",
			account: "Conta",
			cpfOrCnpj: "CPF/CNPJ",
			ownerName: "Titular",
			currentBalance: "Saldo atual",
			initialBalance: "Saldo inicial",
		},
		form: {
			newNameBankAccount: "Nome da conta",
			bank: "Instituição bancária",
			agency: "Código da agência",
			purpose: "Finalidade da conta",
			account: "Conta corrente com dígito",
			cpfOrCnpj: "CPF/CNPJ",
			ownerName: "Titular",
			addAccount: "Adicionar conta bancária",
			namePlaceholder: "Insira um nome para a conta",
			bankPlaceholder: "Busque por instituição",
			purposePlaceholder: "Busque pelo tipo de conta",
			selectPurpose: "Selecione a finalidade da conta",
			followInstructions: "Siga as instruções para continuar",
			accessBankAccount:
				"Para continuar, acesse seu internet banking e verifique se é possível fazer o download do seu extrato em formato OFX.",
			verifyBankAccount:
				"Se o banco não exportar esse formato de arquivo, clique em Concluir para avançar sem a validação.",
			successContent:
				"As informações importadas do OFX estão de acordo com o preenchimento realizado na etapa anterior",
			inconsistentContent:
				"Algumas informações importadas do OFX não estão de acordo com o preenchimento realizado na etapa anterior. Ao prosseguir substituiremos as informações preenchidas pelas importadas.",
			emptyBankData:
				"Algumas informações necessárias para a validação da conta não estão presentes no arquivo OFX selecionado. Clique em continuar para finalizar o cadastro sem a validação.",
			imported: "Importadas",
			registered: "Registradas",
			titlesContentVerifield: {
				success: "Informações verificadas",
				inconsistency: "Informações conflitantes",
				emptyBankData: "Informações faltantes",
			},
			ofxTitles: {
				upload: "Clique ou arraste o arquivo OFX",
				file: "Arquivo OFX",
			},
			incompatibleFile: "Arquivo incompatível",
			purposeList: {
				inHouseFlow: "Caixa (fundo de troco)",
				creditCard: "Cartao de crédito",
				virtualWallet: "Carteira virtual",
				applicationAccount: "Conta investimento",
				checkingAccount: "Conta Corrente",
				savingsAccount: "Conta Poupança",
			},
			accountPlaceholder: "Insira a conta",
			agencyPlaceholder: "Insira o código",
			cpfPlaceholder: "000.000.000-00",
			ownerNamePlaceholder: "Nome do titular da conta",
			unlink: "Desvincular conta bancária",
			linkBankAccount: "Vincular conta bancária",
			defaultAccount: "Definir como conta principal:",
			defaultAccountTooltip:
				"A conta principal é utilizada para preencher automaticamente os formulários que necessitem dessa informação.",
			default: "Padrão",
			initialBalance: "Saldo inicial em R$",
			initialBalanceDate: "Data do saldo inicial",
			balancePlaceholder: "0,00",
			balanceDatePlaceholder: "dd/mm/aaaa",
			saveChanges: "Salvar alterações",
			errors: {
				required: "Campo obrigatório",
				name: "Digite um nome válido",
				account: "Digite um número de conta válido",
				agency: "Digite um número de agência válido",
				cpf: "CPF inválido",
				cnpj: "CNPJ inválido",
			},
		},
		confirmModal: {
			editInfo: "Editar informações",
			cancelMessage:
				"Você não salvou as alterações realizadas. Deseja continuar mesmo assim?",
			cancelButton: "Sair sem salvar",
			confirmButton: "Salvar alterações",
			deleteAccount: "Excluir conta",
			deleteMessage:
				"A exclusão de conta é uma ação definitiva. Deseja prosseguir com a exclusão?",
			deleteTransferError:
				"A exclusão dessa conta não pode ser realizada pois está vinculada a pagamentos ou recebimentos registrados.",
			deleteMainError:
				"A exclusão dessa conta não pode ser realizada pois está registrada como conta principal.",
			updateMainAccount: "Alterar conta principal",
			updateMainAccountMessage:
				"Já existe uma conta cadastrada como principal neste local, caso você prossiga essa conta será substituída. Deseja continuar ainda assim?",
			confirmUpdate: "Alterar conta",
		},
		emptyStateTitle: "Você ainda não possui contas cadastradas",
		emptyStateSubtitle: "Clique em adicionar nova conta para começar",
		notification: {
			deleteAccount: "Conta excluída com sucesso",
			createAccount: "Conta criada com sucesso",
			updateAccount: "Conta editada com sucesso",
		},
	},
	billType: {
		actions: "Ações",
		addCategory: "Adicionar categoria de conta",
		addType: "Adicionar tipo de conta",
		categories: "Categorias",
		categoryName: "Nome da categoria de conta",
		createCategory: "Criar categoria de conta",
		createType: "Criar tipo de conta",
		createTypeIn: "Criar tipo de conta em {{categoryName}}",
		editCategory: "Editar categoria de conta",
		editType: "Editar tipo de conta",
		editTypeIn: "Editar tipo de conta em {{categoryName}}",
		name: "Nome",
		typeName: "Nome do tipo conta",
	},
	reports: {
		graphs: {
			balance: "Balanço",
			category: "Categoria",
			costCenterCaps: "CENTRO DE CUSTO",
			date: "Data",
			dateCaps: "DATA",
			description: "Descrição",
			descriptionCaps: "DESCRIÇÃO",
			expense: "Despesa",
			expenseCaps: "DESPESA",
			expenses: "Despesas",
			finalBalance: "Saldo final",
			finalBalanceExtract: "Balanço final",
			period: "Período",
			periodBalance: "Balanço do período",
			periodBalanceExtract: "Balanço no período",
			previousBalance: "Saldo anterior",
			previousBalanceExtract: "Balanço previo",
			profit: "Lucro no exercício",
			receipt: "Receita",
			receiptCaps: "RECEBIMENTO",
			receipts: "Receitas",
			result: "Resultado antes do imposto",
			supplierCaps: "FORNECEDOR",
			tax: "Imposto",
			totalCaps: "TOTAL",
			totalExpenses: "Total despesas",
			totalPaid: "Total Pago",
			totalReceipts: "Total recebimentos",
			totalReceived: "Total Recebido",
			type: "Tipo",
			typeCaps: "TIPO",
			until: "até",
			value: "Valor",
			valueCaps: "VALOR",
		},
		noComponent: "Aguarde em breve estará disponível",
		reportNames: {
			cashFlow: {
				demonstrative: "Demonstrativo",
				expensses: "Despesas/Receitas",
				extract: "Extrato",
				history: "Histórico",
			},
			expense: {
				perAnything: "Despesa Pago a.",
				perCategory: "Despesa por Categoria",
				perCostCenter: "Despesa por Centro de Custo",
				perDay: "Despesa por Dia",
				perDescripion: "Despesa por Descrição",
				perHistory: "Despesa Histórico",
				perType: "Despesa por Tipo",
			},
			receipts: {
				perAnything: "Receita Recebido de...",
				perCategory: "Receita por Categoria",
				perCostCenter: "Receita por Centro de Custo",
				perDay: "Receita por Dia",
				perDescripion: "Receita por Descrição",
				perHistory: "Receita Histórico",
				perType: "Receita por Tipo",
			},
		},
	},
	finExManagerialReport,
	customerAndSupplier,
	viewLevel: "Visualizar nível {{level}}",
	conciliation: CONCILIATION_PT_BR,
};
