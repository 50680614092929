export const newAndEditProduct = {
	cancelButton: "Cancel",
	confirmButton: "Confirm",
	duplicateButton: "Duplicate",
	errors: {
		abvQuantity: "The percentage must be less than 100",
		categoryId: "Choose or create a category",
		length: "Máx. {{length}} characters",
		name: "Use only valid characters",
		newCategory: "You will create a new category",
		productFeature: "The {{wrongFeature}} functionality is not populated correctly.",
		productFeatures: "The {{wrongFeatures}} features are not populated correctly.",
		productRules: "There are unfilled inputs",
		proportionalQuantity: "The amount must be greater than 0",
		requiredField: "This field is mandatory",
		wrongSection: "Step {{wrongStep}} is not filled in correctly.",
		wrongSections: "Steps {{wrongSteps}} are not filled in correctly.",
		fiscalCode: "Only numbers, dots and letters",
	},
	forms: {
		details: {
			beerABV: "ABV - Alcohol Content",
			beerIbu: "IBU - International Bitterness Units",
			beerBrand: "Brand",
			beerStyle: "Beer Style",
			category: "Category",
			description: "Description",
			fiscalDataMessage: "The product code will be generated automatically",
			hasAlcohol: "Contains Alcohol",
			hasFiscalData: "Tax data",
			hasMountableProduct: "Mountable product",
			hasProductRule: "Datasheet",
			hasProportionalProduct: "Product in g/ml",
			isHiddenFromApp: "Do not display product in ZigApp application",
			measurement: "Unit of measurement",
			menuCategory: "Menu Category",
			syncWithBeerIntegration: "Synchronize with {{integration}}",
			beerIntegrationWarning:
				"It is not possible to disable this function with 'Sync with {{integration}}' active.",
			beerIntegrationPlaceFeatureWarning:
				"Disabled function. Please contact support to activate it in the admin.",
			name: "Product's name",
			picture: "Photograph",
			price: " Price",
			priceIn: "Price in",
			selectType: "Select a product type",
			type: "Product Type",
			variablePrice: "Variable price",
			beerIntegrationDisabledWarning:
				"It is not possible to disable this function with 'Sync with {{integration}}' active.",
			beerIntegrationEnabledWarning:
				"It is not possible to enabled this function with 'Sync with {{integration}}' active.",
			productReturn: "Returnable Product",
			isSupplyToggle: "Supply",
		},
		fiscal: {
			cest: "CEST",
			code: "SKU",
			codeErrorMessage: "If not filled in, the code will be generated automatically",
			fiscalGroup: "Tax group",
			fiscalProfile: "Tax profile",
			ncm: "NCM",
		},
		mountable: {
			addStep: "Add Step",
			getDescriptionDialog: {
				activate:
					"Do you want to activate this item in the other mountable products it makes up?",
				disable:
					"Do you want to disable this item in the other mountable products it makes up?",
			},
			getTitleDialog: {
				activate: "Activate mountable item",
				disable: "Disable mountable item",
			},
			information: "INFORMATION",
			infos: {
				invalidValue: "Invalid value",
				label: "Information",
				max: "Max",
				min: "Min",
				precification: "Pricing",
				precificationErrorMessage: "Choose a method",
				precificationSelect: {
					average: "Average",
					averageTooltip: "The average of the selected items will be incremented",
					higher: "Highest value",
					higherTooltip:
						"The highest value among the items will be added to the product value",
					label: "Choice...",
					sum: "Additional",
					sumTooltip: "Every item added will be incremented in the product value",
				},
				title: "Title",
				titleErrorMessage: "Invalid name",
				tooltipMax: "Maximum items to be selected.",
				tooltipMin: "Minimum items to be selected.",
				validateMaxMinErrors: {
					maxBiggerThenMin: "Maximum value must be greater than the minimum value",
					maxValueZero: "The maximum value cannot be equal to zero",
					negativeValue: "Negative value is not allowed",
				},
			},
			items: {
				additionalItems: "Add another item",
				itemKey: "Item {{itemKey}}",
				label: "Items",
				maxQuantity: "Selectable max quantity",
				maxQuantityTooltip:
					"Maximum number of times this item can be selected in the section",
				storageCount: "Quantity reduced",
				storageCountErrorMessage: "Invalid quantity",
				storageCountTooltip: "Quantity to be deducted from stock per unit.",
				title: " Item",
				titleErrorMessage: "No items selected",
				value: "Value",
				valueErrorMessage: "Invalid additional value",
			},
			itemsTitle: "ITEMS",
			step: "Stage",
		},
		productRule: {
			addInput: "Add another input",
			generatedQuantity: "Generated product quantities",
			input: "Input",
			loss: "Loss",
			quantity: "The amount",
		},
		proportionalValue: {
			grams: "Grams",
			measurement: "Measurable unit",
			mililiters: "Milliliters",
			quantity: "The amount",
		},
	},
	titles: {
		add: "Add Product",
		downloadQRCodeToSell: "Download QR Code to sell",
		edit: "Edit Product",
		hasFiscalData: "Tax data",
		hasMountableProduct: "Mountable product",
		hasProductRule: "Datasheet",
		hasProportionalProduct: "Product in g/ml",
		productDetails: "Product Details",
		releaseValue: "Release value",
	},
	undefined: "undefined",
};
