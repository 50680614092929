exports = module.exports = require("../../../node_modules/.pnpm/css-loader@0.28.11/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-components-not-usual-state--index-notUsualState{height:100%;width:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;color:#4c4c4c;flex:1}.src-components-not-usual-state--index-notUsualState .src-components-not-usual-state--index-icon{font-size:4rem}.src-components-not-usual-state--index-notUsualState .src-components-not-usual-state--index-title{font-size:1.25rem;font-weight:bold}.src-components-not-usual-state--index-notUsualState .src-components-not-usual-state--index-subtitle{font-size:1.125rem;font-weight:bolder}", ""]);

// exports
exports.locals = {
	"notUsualState": "src-components-not-usual-state--index-notUsualState",
	"icon": "src-components-not-usual-state--index-icon",
	"title": "src-components-not-usual-state--index-title",
	"subtitle": "src-components-not-usual-state--index-subtitle"
};