export const entranceBilling = {
	tabs: {
		boxOfficeBilling: {
			title: "Ticket Billing",
			tabLabel: "Billing",
		},
		entranceReport: {
			title: "Total Tickets Sold",
			tabLabel: "Tickets Sold",
			tabs: {
				total: "Total",
				clients: "Customers by Ticket",
			},
			columns: {
				name: "Name",
				count: "The amount",
				unitValue: "Unitary value",
				subtotal: "Subtotal",
				discountValue: "Discounts",
				totalValue: "Amount",
			},
			description: "Entrance report",
			printButton: "Print report",
			exportButton: "Export relatório",
			selectPeriod: "Select the period",
			periodDescription: `Values referring to the period {{since}} - {{until}}`,
			cards: {
				totalBoxOffice: "Total box office",
				entranceValue: "Entrance value",
				minimumConsumption: "Minimum consumption",
			},
			emptyText: "No data found",
		},
	},
};
