export const search = {
	activeTab: "search",
	placeholder: "Invoice number",
	search: "Search",
	export: "Export to Excel",
	foreignDescription:
		"Allows searching by the fiscal document number and filtering by period.",
	sheet: {
		title: "Search for invoices",
		filename: "note_search",
		columns: {
			xml: "XML",
			cancelledXml: "Canceled XML",
			jsonInvoice: "JSON Invoice",
			jsonCreditNote: "JSON Credit Note",
			emitted: "Issuance date",
			everestUploadTime: "Date uploaded to Everest",
			isCanceled: "Canceled",
		},
	},
	columns: {
		xml: {
			label: "XML",
			download: "Download invoice XML",
			labelCancelledInvoice: "Canceled Invoice XML",
			downloadCancelledInvoice: "Download Cancellation XML",
		},
		json: {
			labelInvoice: "Document JSON",
			labelCreditNote: "Credit Note JSON",
			download: "Download the JSON",
		},
		downloadPDF: "Download PDF",
		emitted: "Issuance date",
		transactionDate: "Transaction date",
		everestUploadTime: "Date uploaded to Everest",
		type: "Type",
		isCanceled: "Canceled",
	},
	title: "Search for invoices",
};
