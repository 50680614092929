export const purchaseInvoice = {
	detailingProduct: {
		unassociatedProduct: "Unassociated product",
		columns: {
			nf: "NF",
			supplierName: "Supplier",
			emission: "Emission Date",
			unitQuantity: "QTD (NF)",
			commercialUnit: "UN (NF)",
			unitValue: "Unit Cost (NF)",
			totalValue: "Total cost (NF)",
			processedAt: "Inventory entry date",
			invoiceEntry: "Imported Product",
			associateProduct: "Associated product (ZIG)",
			unitQuantityZig: "QTD (ZIG)",
			commercialUnitZig: "UN (ZIG)",
			unitValueZig: "Unit cost (ZIG)",
		},
		emptyText: "There are no unassociated products in this tax profile",
		excelExportDetailingProduct: {
			fileName: "detailing_product",
			title: "Detailing by product",
		},
		fields: {
			isAssociatedProduct: "Unassociated products only",
			searchDateRange: {
				emissionDate: { end: "End date issue", start: "Start date issue" },
				processedAtDate: { end: "End date entry", start: "Start date entry" },
			},
			searchNf: "Enter the note number",
			searchSupplier: "Enter issuer name",
		},
	},
	importHistory: {
		actionTable: {
			delete: "Delete",
			download: "Download",
			unprocess: "Unprocess",
		},
		columns: {
			createdAt: "Import Date",
			emission: "Emission Date",
			nf: "NF",
			status: "Status",
			statusMessage: {
				Deprocessed: "Deprocessed",
				Unprocessed: "Unprocessed",
				processed: "Processed",
			},
			supplier: "Supplier",
			totalValue: "Value",
		},
		excelExportHistoryImport: {
			filename: "import_history",
			title: "Import history",
		},
		fields: {
			searchDateEmissionRange: {
				end: "End Date Issue",
				start: "Start Date Issue",
			},
			searchDateImportRange: {
				end: "End Import Date",
				start: "Start Date Import",
			},
			searchNf: "Note number",
			searchSupplier: "Supplier Name",
		},
		modalDelete: {
			cancelText: "Cancel",
			message1: "Invoice deletion",
			message2: "It's permanent. Do you want to continue anyway?",
			okText: "Delete",
			title: "Delete Note",
		},
		modalEdit: {
			associetedProduct: "Associeted product",
			cancelText: "Cancel",
			equals: "equals",
			okText: "Associate product",
			selectStorage: "Select Storage",
			storage: "Storage",
			title: "Associate product",
			unitValue: "Cost price per unit",
		},
		modalUnprocess: {
			cancelText: "Cancel",
			closeText: "Close",
			message1: "When unprocessing a invoice you generate the following actions:",
			message2: "The products added to the stock will be removed;",
			message3: "The posting of the invoice will be removed from accounts payable.",
			message4: "Do you want to unprocess the invoice anyway?",
			okText: "Unprocess Invoice",
			title: "Unprocess Invoice",
		},
		table: {
			emptyText: "There are no notes to display",
			reportPrint: "Import history",
		},
	},
	importationInvoices: {
		backups: {
			placeholder: "Download a backup",
			error: "Error: {{error}}",
			empty: "No backups available",
		},
		btnUpdate: "Update",
		columns: {
			issueDate: "Issue Date",
			issuerCnpj: "CNPJ or CPF",
			issuerName: "Issuer Name",
			status: {
				authorized: "Authorized",
				imported: "Imported",
				notAllowed: "Not Authorized",
				title: "Status",
				xmlUnavailable: "XML Unavailable",
			},
			totalValue: "Total Value",
		},
		emptyText: "No Purchase Invoice",
		fiscalProfiles: "Select fiscal profile",
		issueDate: "Filter by date",
		issuer: "Filter by supplier name",
		xmlUnavailableInfo:
			"To make the XML files with unavailable status available, it is necessary to carry out the invoice manifestation. If you have any doubts about the manifestation, consult your accountant.",
		modalInfoImport: {
			notAllowed: {
				title: "Not allowed",
			},
			xmlUnavailable: {
				reason:
					"The XML code for this invoice is not yet available. Wait to import the invoice.",
				title: "XML unavailable",
			},
		},
		status: "Filter by status",
		title: "Select a Fiscal Profiles to get started",
	},
	description:
		"The purchase invoice is the document referring to the movement of the stock of goods that your company is receiving. Here you will be able to import your invoices, view your import history, as well as the product breakdown of them.",
	tabs: {
		detailingByProduct: "Detailing by Product",
		historyNf: "Import history",
	},
	titlePage: "Purchase Invoices",
};
