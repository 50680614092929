export const smartposConfig = {
	newAutoBar: "New AutoBar",
	smartpos: "SMARTPOS",
	generalConfigs: "General Configurations",
	autoBarCustomization: "AutoBar Customization",
	logoDescription:
		"Select the Zig logo that best fits your image. Tip: Light background images work better with the black logo. Dark background images, with the white logo.",
	submit: "Save configurations",
	notificationInvalidField:
		"Some mandatory information was not filled in. Please complete them to be able to save your configurations.",
	notificationSuccess: "Your changes have been saved.",
	formSections: {
		choppCharacteristics: {
			title: "1. Chopp Characteristics",
			description: "Fill in according to the chopp specifications.",
		},
		initialScreen: {
			title: "2. Initial Screen",
			description:
				"Choose an image that captures the essence of the brand and the message you want to convey to customers. It is very important that the name or logo of the product also appears in this image.",
		},
		productLogo: {
			title: "3. Product Logo",
			description:
				"Upload an image with your product's logo to customize the shopping screen and the drink dispenser. Tip: use an image with a transparent or white background.",
		},
		primaryColor: {
			title: "4. Primary Color",
			description: "Choose a color to be used on primary buttons and actions.",
		},
	},
	fields: {
		beerType: {
			label: "Select the type of drink:",
		},
		autoBarName: {
			label: "AutoBar Name",
			placeholder: "Enter the name of the AutoBar",
		},
		menu: {
			label: "Linked Menu",
			placeholder: "Select the linked menu",
		},
		connectionType: {
			label: "Connection Type",
			placeholder: "Select the connection type",
		},
		partner: {
			label: "Partner",
			placeholder: "Select the partner",
		},
		choppName: {
			label: "Chopp Name",
			placeholder: "Enter the name of the chopp",
		},
		choppStyle: {
			label: "Style",
			placeholder: "Ex.: Lager",
			tooltip:
				"The beer style is the categorization of the beverage based on characteristics such as ingredients, production, taste, color, alcohol content, and origin, making it easier to choose and compare different types. Ex.: Lager.",
		},
		ibu: {
			label: "IBU",
			placeholder: "Ex.: 25",
			tooltip:
				"IBU is an international acronym that stands for International Bitterness Unit, which can be translated as international bitterness community. Ex.: 25",
		},
		alcoholContent: {
			label: "Alcohol Content%",
			placeholder: "Ex.: 5",
			tooltip:
				"Alcohol content refers to the amount of alcohol that a drink contains. Ex.: 5%.",
		},
		choppDescription: {
			label: "Chopp Description",
			placeholder: "Create a description of up to 100 characters",
		},
		dontAddInitialScreen: {
			label: "Do not add initial screen.",
		},
		initialScreenImage: {
			placeholder: "1280x780 pixels (.PNG or .JPG)",
		},
		productImage: {
			placeholder: "1280x780 pixels (.PNG or .JPG)",
		},
		primaryColor: {
			label: "Primary Color",
		},
	},
	cropImageModal: {
		title: "Add Image",
		okText: "Save Image",
	},
	preview: {
		title: "Preview",
		needHelp: "Need Help",
		language: "Language",
		initialScreen: {
			touchToStart: "TOUCH SCREEN TO START",
		},
		productScreen: {
			productName: "Product Name",
			choppType: "CHOPP TYPE",
			withdrawalCode: "Enter withdrawal code",
			pay: "Pay {{price}}",
		},
		choppScreen: {
			title: "You have 3 chopp(s).",
			subtitle: "Release the beer dispenser and wait for it to finish.",
			cta: "Release chopper",
		},
	},
	confirmationScenarios: {
		submitWithNoCustomization: {
			title: "Customization",
			subtitle:
				"You have not customized your AutoBar. If you wish to proceed without customizing, the primary color and the Zig. images will be on the initial, product, and your AutoBar's dispenser screens. Do you want to continue like this?",
			confirmActionText: "Customize AutoBar",
			cancelActionText: "Save without customizing",
		},
		delete: {
			title: "Delete AutoBar",
			subtitle:
				"Are you sure you want to delete this AutoBar? Your changes will be permanently discarded. Do you want to continue like this?",
			confirmActionText: "Delete AutoBar",
		},
		goBack: {
			title: "Discard changes",
			subtitle:
				"Your changes will be permanently discarded. Do you want to continue like this?",
			confirmActionText: "Discard changes",
		},
	},
};
