export const errorsValidations = {
	anticipation: {
		accountData: {
			accountAgency: "Enter the agency number",
			accountAgencyInvalid: "Invalid agency",
			accountBank: "Select the bank to which the account belongs",
			accountDigit: "Enter the account digit",
			accountDocument: "Enter the account holder's document (CPF/CNPJ)",
			accountDocumentInvalid: "Invalid document",
			accountHolder: "Enter the name of the account holder",
			accountName: "Enter the name to identify the account",
			accountNumber: "Enter account number",
			accountPixKey: "Invalid PIX key",
			accountType: "Select account type",
			accountTypeExisting: "Select one of the existing accounts",
			accountTypeWrapper: "Fill in your bank details or PIX key",
			noAccountType: "Select an account type for the advance",
			enterBankData: "Informe o {{bankKeyType}}",
		},
		value: {
			liquidValue:
				"Amount requested greater than net amount available ({{totalAvailable}})",
			noLiquidValue: "Enter the net amount for the anticipation",
			noTotalValue: "Enter the total amount for the anticipation",
			reason: "Enter a reason for anticipation",
			totalValue:
				"Amount requested greater than total amount available ({{totalAvailable}})",
		},
	},
	bankSlip: {
		anticipation: "Anticipation required",
		attachment: "Attach the bank slip file",
		barCode: "Enter the bank slip barcode",
		basicInfoValue:
			"Amount requested greater than total amount available ({{totalAvailable}})",
		beneficiary: "Enter the beneficiary of the bank slip",
		dueDate:
			"ATTENTION: The bank slip will only be paid if the remaining balance covers interest and fine costs.",
		invalidBarCode: "Invalid barcode",
	},
	withdraw: {
		value: {
			accountBank: "Select the bank to which the account belongs",
			accountChoice: "Select an account type for the request",
			accountDigit: "Enter the account digit",
			accountHolder: "Enter the name of the account holder",
			accountName: "Enter the name to identify the account",
			accountNumber: "Enter account number",
			accountType: "Select account type",
			agencyInvalid: "Invalid agency",
			agencyNumber: "Enter the agency number",
			anticipation: "Anticipation required",
			documentInvalid: "Invalid document",
			documentNumber: "Enter the account holder's document (CPF/CNPJ)",
			existingAccount: "Select one of the existing accounts",
			noWithdrawValue: "Enter the amount for the withdrawal",
			pixKeyInvalid: "Invalid PIX key",
			reason: "Enter a reason for the request",
			receiverDataWrapper: "Fill in your bank details or PIX key",
			withdrawValue:
				"Amount requested greater than total amount available ({{totalAvailable}})",
			enterBankData: "Enter the {{bankKeyType}}",
			bankDataInvalid: "The {{bankKeyType}} entered is invalid",
		},
	},
};
