export const productFeatureTemplates = {
	activeTab: "product-feature-templates",
	form: {
		addProduct: {
			fields: "Preencha pelo menos um campo",
			isRequired: "Esse campo é obrigatório",
			wrongFields: {
				field: "campo",
				from: "dos",
				label: "Preencha todos os dados",
				of: "do",
			},
		},
		createModel: "Criar modelo",
		editModel: "Editar modelo",
		isRequired: "Esse campo é obrigatório",
		templateFieldsContainer: {
			add: "Adicionar campo",
			field: "Campo {{number}}",
			isRequired: "Campo {{number}} é obrigatório?",
			label: "Campos do modelo",
			name: {
				label: "Nome do campo {{number}}",
				placeholder: "Digite o nome do campo {{number}}",
			},
			remove: "Remover campo",
			type: {
				bool: "Verdadeiro/Falso",
				enum: "Opções",
				label: "Tipo do campo {{number}}",
				number: "Numeral",
				placeholder: "Selecione o tipo do campo",
				text: "Texto",
			},
			value: {
				label: "Opções do campo {{number}}",
				placeholder: "Valor {{count}} do campo {{number}}",
			},
		},
		templateName: {
			label: "Nome do modelo",
			placeholder: "Digite o nome do modelo",
		},
		templateObservation: {
			label: "Observação",
			placeholder: "Digite alguma observação (opcional)",
		},
	},
	header: {
		add: "Adicionar modelo",
	},
	modal: {
		add: "Adicionar",
		edit: "Editar",
		title: "modelo de funcionalidade de produto",
	},
	templateList: {
		actions: {
			edit: "Editar modelo",
			remove: "Remover modelo",
			title:
				"Deseja realmente remover o modelo? Todas as funcionalidades que o usam serão desativadas também.",
		},
		status: {
			title:
				"Deseja realmente desativar o modelo? Todas as funcionalidades que o usam serão desativadas também.",
		},
	},
	title: "Modelos de funcionalidade de produto",
	tooltip: "Crie modelos com campos personalizados para o seu produto",
};
