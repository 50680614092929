import { autobind } from "core-decorators";
import * as React from "react";
import Dialog from "../dialog-components/dialog";
import FilledButton from "#components/form-components-shared/buttons/filled-button";
import PlainInput from "#components/form-components-shared/inputs/plain-input";

type ModalType = "alert" | "confirm" | "prompt";

interface Props {
	onAlert: () => void;
	onConfirm: (value: boolean) => void;
	onPrompt: (value: string | null) => void;
}

interface State {
	inputText?: string;
	type: ModalType | null;
	content?: React.ReactNode;
}

import s from "./index.scss";
import i18n from "#i18n/index";

const t = i18n.t;

@autobind
class ModalManagerComponent extends React.Component<Props, State> {
	public state: State = {
		type: null,
	};

	private onDismiss() {
		if (this.state.type === "confirm") {
			this.props.onConfirm(false);
		} else if (this.state.type === "prompt") {
			this.props.onPrompt(null);
			this.setState({ inputText: undefined });
		} else if (this.state.type === "alert") {
			this.props.onAlert();
		} else {
			throw new Error("Uncovered modal type: " + this.state.type);
		}
	}

	private onConfirm(ev: React.FormEvent<HTMLButtonElement>) {
		ev.preventDefault();

		if (this.state.type === "alert") {
			this.props.onAlert();
		} else if (this.state.type === "confirm") {
			this.props.onConfirm(true);
		} else if (this.state.type === "prompt") {
			this.props.onPrompt(this.state.inputText!);
			this.setState({ inputText: undefined });
		} else {
			throw new Error("Uncovered modal type: " + this.state.type);
		}
	}

	private onChangeInput(inputText: string) {
		this.setState({ inputText });
	}

	public render() {
		if (!this.state.type) return null;

		return (
			<Dialog
				className={s.dialog}
				name={null}
				title={t("components:confirmModal.title")}
				onCloseDialog={this.onDismiss}
			>
				<div className={s.content}>{this.state.content}</div>

				{this.state.type === "confirm" || this.state.type === "prompt" ? (
					<form className={s.action}>
						{this.state.type === "prompt" ? (
							<div className={s.input}>
								<PlainInput
									autoFocus
									type="text"
									onChange={this.onChangeInput}
									value={this.state.inputText || ""}
								/>
							</div>
						) : null}

						<div className={s.buttons}>
							<FilledButton
								type="reset"
								className={s.dismissButton}
								onClick={this.onDismiss}
							>
								{t("components:confirmModal.cancel")}
							</FilledButton>

							<FilledButton
								type="submit"
								className={s.confirmButton}
								onClick={this.onConfirm}
							>
								{t("components:confirmModal.ok")}
							</FilledButton>
						</div>
					</form>
				) : null}
			</Dialog>
		);
	}
}

@autobind
export default class ModalManager extends React.Component {
	private reject: ((v: Error) => void) | null = null;
	private resolve: ((v: string | boolean | undefined | null) => void) | null = null;

	private componentProps: Props = {
		onAlert: this.onAlert,
		onConfirm: this.onConfirm,
		onPrompt: this.onPrompt,
	};

	private managerComponent: ModalManagerComponent | null = null;

	private onRef(m: ModalManagerComponent | null) {
		this.managerComponent = m;
	}

	private onConfirm(v: boolean) {
		try {
			if (!this.resolve) {
				const error = new Error("ModalManager's onConfirm called with unset promise");

				if (this.reject) {
					this.reject(error);
					return;
				} else throw error;
			}

			this.resolve(v);
		} finally {
			this.closeModal();
		}
	}

	private onPrompt(v: string | null) {
		try {
			if (!this.resolve) {
				const error = new Error("ModalManager's onPrompt called with unset promise");

				if (this.reject) {
					this.reject(error);
					return;
				} else throw error;
			}

			this.resolve(v);
		} finally {
			this.closeModal();
		}
	}

	private onAlert() {
		try {
			if (!this.resolve) {
				const error = new Error("ModalManager's onPrompt called with unset promise");

				if (this.reject) {
					this.reject(error);
					return;
				} else throw error;
			}

			this.resolve(undefined);
		} finally {
			this.closeModal();
		}
	}

	private setPromise<V extends string | null | undefined | boolean>() {
		if (this.reject || this.resolve) {
			throw new Error("Invalid call to ModalManager: can't open two modals at once");
		}

		const self = this;
		const promise = new Promise<V>((resolve, reject) => {
			self.reject = reject;
			self.resolve = resolve as typeof self.resolve;
		});

		return promise;
	}

	private closeModal() {
		if (!this.managerComponent) {
			throw new Error(
				"Invalid call to ModalManager.closeModal: managerComponent is falsy",
			);
		}

		this.reject = null;
		this.resolve = null;
		this.managerComponent.setState({
			content: null,
			type: null,
		});
	}

	private openModal<V extends string | null | undefined | boolean>(
		type: ModalType,
		content: React.ReactNode,
	) {
		if (!this.managerComponent) {
			throw new Error(
				"Invalid call to ModalManager.openModal: managerComponent is falsy",
			);
		}

		const promise = this.setPromise<V>();
		this.managerComponent.setState({
			content,
			type,
		});

		return promise;
	}

	public alert(content: React.ReactNode) {
		return this.openModal<undefined>("alert", content);
	}

	public confirm(content: React.ReactNode) {
		return this.openModal<boolean>("confirm", content);
	}

	public prompt(content: React.ReactNode) {
		return this.openModal<string | null>("prompt", content);
	}

	public render() {
		return (
			<div className={s.modals}>
				<ModalManagerComponent ref={this.onRef} {...this.componentProps} />
			</div>
		);
	}
}
