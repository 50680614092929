export const modalImportClients = {
	add: "Adicionar clientes",
	addedClient: "cliente adicionado",
	addedClients: "clientes adicionados",
	clients: "Clientes",
	cpf: "CPF",
	downloadSheet: "Baixar planilha de exemplo",
	emptyMessage: {
		description:
			"Adicione clientes pelo CPF ou importando uma lista de CPFs a partir da planilha de exemplo.",
		title: "Nenhum cliente adicionado",
	},
	feedback: {
		default: "Ocorreu um erro ao importar a planilha",
		download: "Baixar",
		emptyHeaderCell: "Falta nome nas colunas",
		emptySheet: "Essa planilha está vazia",
		errorMessage:
			"Planilha inválida, baixe-a novamente e faça as alterações necessárias.",
		sheetPoorlyFormatted:
			"Formate a planilha para fonte Calibri, tamanho 11, remova os espaços, quebras de linhas desnecessárias e negrito",
		unidentifiedColum: "Erro nos nomes das colunas",
		verifyBaseSheet:
			", por favor verifique o modelo da planilha exemplo ou utilize-a como planilha base.",
	},
	import: "Importar clientes",
	importSheet: "Importar planilha",
	invalidClientList: "Lista de Clientes (inválida)",
	name: "Nome",
	phone: "Telefone",
};
