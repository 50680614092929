import { formatter } from "./formatter";

export const promotion = {
	categoryModal: {
		label: "Product or category",
		noneProduct: "No products found",
		placeholder: "Search for a product or category",
		title: "Add New Product",
	},
	copyModal: {
		message: "Do you want to create a copy of the promotion",
		okText: "Copy",
		placeholder: "Promo copy name",
		title: "Create promotion copy",
	},

	creationStep: {
		error: "Unable to redirect page.",
		formatter,
		steps: {
			awards: "Awards",
			goals: "Goals",
			promotionInfo: "Promotion Data",
			resume: "Summary",
			usageConditions: "Conditions of Use",
		},
		stepsPages: {
			goals: {
				add: "Add goal",
				conditions: {
					birthday: {
						day: "Birthday Boy",
						month: "Birthday of the month",
						week: "Birthday boy of the week",
					},
					checkin: {
						label: "Arrival time",
					},
					gender: {
						female: "Women",
						male: "Men",
						others: "Others",
					},
					minimumRecharge: {
						label: "Minimum recharge amount",
					},
				},
				error: "Condition ALL CLIENTS needs to be removed",
				label: "Recurrence of earnings",
				new: "New goal",
				placeholder: "Select a maximum recurrence of earnings",
			},
			usageConditions: {
				conditions: {
					expiration: {
						label: "Calendar days:",
						suffix: "Days",
					},
					modal: {
						add: "Add usage condition",
						new: "New usage condition",
					},
					period: {
						beginTime: "From:",
						endTime: "Until:",
						error: "Invalid time format for conversion",
					},
					week: {
						infoText:
							"As events can start on one day of the week and end on the other, the promotion is valid on the day the event starts. So the customer can use the promotion the next day if the event started the day before.",
						validPromotion: "Valid Promotion",
					},
				},
			},
		},
		stepsWrapper: {
			description: "Description not found",
			save: "Save changes",
			title: "Title not found",
		},
	},
	defaultName: "Copy of {{promotion}}",
	errors: {
		nameLength: "The promotion name has not reached the minimum number of characters.",
		notFilledCorrectly: "The fields were not filled in correctly.",
		sameName: "The copy name is the same as the original name.",
	},
	peopleTable: {
		download: "Download sample worksheet",
		error: "Invalid format",
		example: "example worksheet",
		import: "Import Sheet",
	},
	table: {
		goals: {
			empty: "No goal",
			label: "Promotion target list",
			new: "New goal",
		},
		usageConditions: {
			empty: "No usage conditions",
			label: "List of conditions of use of the promotion",
			new: "New usage condition",
		},
	},
};
