/* eslint-disable max-len */
import { ConciliationTranslations } from "./conciliation-pt-br";

export const CONCILIATION_ES_MX: ConciliationTranslations = {
	pageTitle: "Conciliación vía OFX",
	pageParagraph:
		"Al descargar el estado de cuenta de tu cuenta corriente o de ahorro en formato OFX, disponible en los principales bancos, e importarlo en el panel, ahorras tiempo en la gestión del dinero de tu negocio.",
	importModal: {
		title: "Importación OFX",
		accountBankNotRegisteredTitle: "Cuenta bancaria no registrada",
		triggerButton: "Importar archivo OFX",
		cancelButton: "Cancelar",
	},
	uploadOfxForm: {
		inputInstruction: "Haz clic o arrastra el archivo OFX",
		fileType: "Archivo OFX",
		description:
			"Exporta el archivo OFX de tu cuenta que contenga solo los movimientos relevantes para evitar generar transacciones no deseadas",
		submitButton: "Importar",
		validation: {
			fileRequired: "El archivo OFX es obligatorio",
			fileSize: "El archivo OFX no debe superar los 5 MB",
		},
	},
	submitOfxForm: {
		nameFile: "Nombre del archivo:",
		periodImported: "Período importado:",
		periodImportedValue: "{{start}} hasta {{end}}",
		bankName: "Institución bancaria:",
		labelCurrency: "Saldo final el {{date}}",
		labelCheckValue: "Confirmo el monto de {{value}} al final de este estado de cuenta",
		submitButton: "Confirmar monto",
		alerts: {
			accountNotCreated: {
				title: "Esta cuenta aún no ha sido registrada",
				description:
					"Esta cuenta bancaria aún no ha sido registrada. <b>Haga clic aquí</b> para registrarse y continuar con la importación del OFX.",
			},
			accountNotValidated: {
				title: "Esta cuenta aún no ha sido validada.",
				description:
					"Esta cuenta bancaria fue registrada con el nombre {{name}} pero sus datos aún no han sido validados. Utilizaremos los datos exportados en este OFX para realizar la validación automática.",
			},
		},
	},
	feedbackOfxStep: {
		success: {
			title: "Importación realizada con éxito",
			itemsImported:
				"Se importaron {{send}} transacciones, de las cuales {{new}} son nuevas",
			balance: "Saldo final de {{balance}} el {{date}}",
		},
		error: {
			title: "Vaya... Algo no salió como esperábamos",
			description: "No pudimos importar el OFX. Por favor, inténtalo de nuevo más tarde.",
		},
		confirmButton: "Conciliar transacciones",
	},
	redirectBankFlow: {
		description:
			"Registrar la cuenta bancaria es una acción necesaria antes de importar el OFX.",
		redirectButton: "Registrar cuenta",
	},
	tabs: {
		pendingConciliations: "Conciliaciones pendientes",
		history: "Historial",
		description: {
			pendingConciliations: "Detalles de las importaciones",
			history: "Historial de conciliaciones",
		},
		pendingTab: {
			emptyState: {
				title: "No hay conciliaciones pendientes en este momento",
				description: "Importe un nuevo archivo OFX para conciliar nuevas transacciones",
			},
		},
		historyTab: {
			emptyState: {
				title: "No se encontraron conciliaciones",
			},
		},
	},
	collapse: {
		branch: "Agencia",
		account: "Cuenta Corriente",
		importedBy: "Lanzamientos Importados {{bank}}",
		importedDate: "Período Importado",
		importedDateDescription: "De {{since}} hasta {{until}}",
		conciliationDate: "Fecha de Conciliación",
		actions: {
			undoConciliation: "Deshacer Conciliación",
		},
		unConciliatedAmount: "{{amount}} no conciliadas",
		item: {
			value: "Valor",
			date: "Fecha",
			description: "Descripción",
			chartOfAccount: "Plan de Cuentas",
			paymentMethod: "Método de Pago",
			status: {
				conciliated: "Conciliada",
				refused: "Ignorada",
			},
		},
		cancel: "Cancelar",
		complete: "Concluir conciliación",
	},
	transactionSuccessNotification: "¡Transacción actualizada con éxito!",
	cancelButton: "Cancelar",
	confirmButton: "Confirmar",
	conciliateConciliation: {
		title: "Ignorar transacción",
		description:
			"Al completar la conciliación, las transacciones ignoradas serán <b>eliminadas permanentemente</b> de tus conciliaciones. ¿Aún deseas continuar?",
		error: "¡Aún tienes transacciones pendientes!",
	},
	ignoreTransactionModal: {
		title: "Ignorar Transacción",
		description: "¿Está seguro de que desea ignorar la transacción <b>{{name}}</b>?",
	},
	undoIgnoreTransactionModal: {
		button: "Deshacer ignorar",
		title: "Deshacer Ignorar",
		description:
			"¿Está seguro de que desea deshacer la ignorancia de la transacción <b>{{name}}</b>?",
	},
	undoConciliationTransactionModal: {
		button: "Deshacer conciliación",
		title: "Deshacer Conciliación",
		description:
			"¿Está seguro de que desea deshacer la conciliación de la transacción <b>{{name}}</b>?",
	},
	conciliationModal: {
		title: "Buscar lanzamiento",
		contentTitle: "Información de la importación",
		labelValue: "Valor",
		labelDate: "Fecha",
		labelDescription: "Descripción",
		labelChartAccount: "Plan de cuentas",
		labelPaymentMethod: "Forma de pago",
		buttonCancel: "Cancelar",
		buttonConfirm: "Conciliar",
		table: {
			emptyIsFetched: "No hay cuenta encontrada",
			emptyInitial: {
				title: "Utilice las búsquedas para empezar",
				subtitle:
					"Busque y seleccione un lanzamiento para realizar la conciliación de la transacción abajo.",
			},
		},
	},
};
