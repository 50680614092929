export const zigTags = {
	block: {
		success: "Tag bloqueada com sucesso",
	},
	empty: {
		title: "Busque uma zig tag para gerenciá-la",
	},
	form: {
		errors: {
			zigTagRequired: "Digite o número da tag para buscar",
		},
		label: "Buscar Zig Tag",
		placeholder: "Digite o número da tag",
		searchBtn: "Buscar",
	},
	table: {
		actions: {
			blockTag: "Bloquear Tag",
		},
		columns: {
			actions: "Ações",
			isBlocked: "Está bloqueada?",
			userDocument: "Documento do usuário",
			userName: "Usuário",
			zigCode: "Zig Code",
		},
	},
	title: "Gerenciar Zig Tags",
};
