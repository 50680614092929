import { RootStore } from ".";
import { fetchModel } from "#helpers/fetch-model";
import {
	CreateVendorOrg,
	GetAllVendorFeatures,
	GetVendorsBalances,
	ProductSold,
	UpdateVendorOrg,
	VendorClosing,
	VendorDebtor,
	VendorFeature,
	VendorFinancialAdjustDto,
	VendorOrg,
	VendorPaymentMethods,
	VendorPaymentMethodsResponse,
	VendorProductFee,
	VendorReceiveTip,
	VendorRevenue,
} from "#api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";
import {
	showErrorNotification,
	showSuccessNotification,
} from "#resources/helpers/notifications";
import dayjs from "dayjs";
import i18n from "#i18n/index";

function middleDay(date: Date) {
	return dayjs(date).hour(12).toDate();
}

const t = i18n.t;

export class VendorStore {
	public rootStore: RootStore;

	constructor(rootStore: RootStore) {
		this.rootStore = rootStore;
	}

	public getVendors = new fetchModel<{}, VendorOrg[]>({
		fnPromise: () => enterprise.getVendors(),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorsFromOrganization = new fetchModel<{}, VendorOrg[]>({
		fnPromise: () => enterprise.getVendorsFromOrganization(),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorsForPlace = new fetchModel<
		{
			placeId: string;
		},
		VendorOrg[]
	>({
		fnPromise: args => enterprise.getVendorsForPlace(args),
		onError: err => showErrorNotification(err.message),
	});

	public createVendorFromOrganization = new fetchModel<CreateVendorOrg, VendorOrg>({
		fnPromise: vendor => enterprise.createVendorForOrganization({ vendor }),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => {
			showSuccessNotification(t("store:vendorStore.createVendor"));
		},
	});

	public updateVendorForOrganization = new fetchModel<UpdateVendorOrg, VendorOrg>({
		fnPromise: vendor => enterprise.updateVendorForOrganization({ vendor }),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => {
			showSuccessNotification(t("store:vendorStore.editVendor"));
		},
	});

	public deleteVendor = new fetchModel<
		{
			id: string;
		},
		void
	>({
		fnPromise: args => enterprise.deleteVendor(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: (_, args) => {
			if (this.getVendorsForPlace.value) {
				this.getVendorsForPlace.value = this.getVendorsForPlace.value.filter(
					v => v.id !== args.id,
				);
			}
		},
	});

	public getAllVendorFeatures = new fetchModel<{}, GetAllVendorFeatures[]>({
		fnPromise: () => enterprise.getAllVendorFeatures(),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorProductFees = new fetchModel<
		{
			id: string;
		},
		VendorProductFee[]
	>({
		fnPromise: args => enterprise.getVendorProductFees(args),
		onError: err => showErrorNotification(err.message),
	});

	public editVendorProductFees = new fetchModel<
		{
			id: string;
			fees: VendorProductFee[];
		},
		VendorProductFee[]
	>({
		fnPromise: args => enterprise.editVendorProductFees(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorClosingsAtEvent = new fetchModel<{ eventId: string }, VendorClosing[]>({
		fnPromise: args => enterprise.getVendorClosingsAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorClosings = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		VendorClosing[]
	>({
		fnPromise: args =>
			enterprise.getVendorClosings({
				placeId: args.placeId,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorProductSoldAtEvent = new fetchModel<
		{ vendorId: string; eventId: string },
		ProductSold[]
	>({
		fnPromise: args => enterprise.getVendorProductSoldAtEvent(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorProductSold = new fetchModel<
		{
			vendorId: string;
			placeId: string;
			since: Date;
			until: Date;
		},
		ProductSold[]
	>({
		fnPromise: args =>
			enterprise.getVendorProductSold({
				...args,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorsRevenues = new fetchModel<
		{
			placeId: string;
			since: Date;
			until: Date;
		},
		VendorRevenue[]
	>({
		fnPromise: args =>
			enterprise.getVendorsRevenues({
				placeId: args.placeId,
				since: middleDay(args.since),
				until: middleDay(args.until),
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorsRevenuesAtEvent = new fetchModel<
		{
			eventId: string;
		},
		VendorRevenue[]
	>({
		fnPromise: args =>
			enterprise.getVendorsRevenuesAtEvent({
				eventId: args.eventId,
			}),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorDebtors = new fetchModel<
		{
			vendorId: string;
			since: Date;
			until: Date;
		},
		VendorDebtor[]
	>({
		fnPromise: args => enterprise.getVendorDebtors(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorFeatures = new fetchModel<{ vendorId: string }, VendorFeature[]>({
		fnPromise: args => enterprise.getVendorFeatures(args),
		onError: err => showErrorNotification(err.message),
	});

	public setVendorFeatures = new fetchModel<
		{ vendorId: string; features: string[] },
		void
	>({
		fnPromise: args => enterprise.setVendorFeatures(args),
		onError: err => showErrorNotification(err.message),
	});

	public createAdjustmentsInVendorExtract = new fetchModel<
		{ placeId: string; adjusts: VendorFinancialAdjustDto[] },
		void
	>({
		fnPromise: args => enterprise.createAdjustmentsInVendorExtract(args),
		onError: err => showErrorNotification(err.message),
	});

	public editVendorPaymentMethods = new fetchModel<
		{ placeId: string; vendorsPaymentMethods: VendorPaymentMethods[] },
		VendorPaymentMethodsResponse[]
	>({
		fnPromise: args => enterprise.editVendorPaymentMethods(args),
		onSuccess: () =>
			showSuccessNotification(t("place:settings.vendor.paymentMethodSuccess")),
		onError: err => showErrorNotification(err.message),
	});

	public getPlaceVendorPaymentMethods = new fetchModel<
		{ placeId: string },
		VendorPaymentMethodsResponse[]
	>({
		fnPromise: args => enterprise.getPlaceVendorPaymentMethods(args),
		onError: err => showErrorNotification(err.message),
	});

	public getVendorToReceiveAllTips = new fetchModel<
		{ placeId: string },
		VendorReceiveTip | null
	>({
		fnPromise: args => enterprise.getVendorToReceiveAllTips(args),
		onError: err => showErrorNotification(err.message),
	});

	public setVendorToReceiveAllTips = new fetchModel<
		{ placeId: string; vendorId?: string | null | undefined },
		VendorReceiveTip | null
	>({
		fnPromise: args => enterprise.setVendorToReceiveAllTips(args),
		onSuccess: () =>
			showSuccessNotification(t("place:settings.vendor.serviceTransferSuccess")),
		onError: err => showErrorNotification(err.message),
	});

	public deleteVendorAdjust = new fetchModel<{ id: string }, void>({
		fnPromise: args => enterprise.deleteVendorAdjust(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () =>
			showSuccessNotification(
				t("place:multivendor.extract.tabs.adjustments.deleteAdjustmentSuccess"),
			),
	});

	public getVendorsBalances = new fetchModel<{ placeId: string }, GetVendorsBalances[]>({
		fnPromise: args => enterprise.getVendorsBalances(args),
		onError: err => showErrorNotification(err.message),
	});

	public reprocessAllVendorsAtEvent = new fetchModel<{ eventId: string }, void>({
		fnPromise: args => enterprise.reprocessAllVendorsAtEvent(args),
		onError: err => showErrorNotification(err.message),
		onSuccess: () => showSuccessNotification(t("place:settings.vendor.reprocessSuccess")),
	});
}
