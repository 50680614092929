export const chipsBurned = {
	byEmployee: {
		filename: "fichas-queimadas-funcionario",
		printTitle: "Fichas queimadas por funcionário",
		tabTitle: "Fichas queimadas por funcionário",
	},
	fileName: "total-fichas-queimadas",
	printTitle: "Total de fichas queimadas",
	tabTitle: "Total de fichas queimadas",
	table: {
		count: "Quantidade",
		fracionalCount: "Quantidade Frac.",
		name: "Nome",
		product: "Produto",
		totalValue: "Total",
		unitCount: "Quantidade Unit.",
		unitValue: "Valor unitário",
		unitaryValue: "Valor unitário",
	},
	title: "Total de fichas queimadas",
};
