import { AsyncStore } from "#helpers/mobx/utils";
import { showErrorNotification } from "#helpers/notifications";
import { action, observable } from "mobx";
import { SuggestedImages } from "#resources/api/enterprise-generated";
import enterprise from "#resources/api/enterprise-client";

type TImage = "getSuggestedImageFor";

export class ImageStore extends AsyncStore<TImage> {
	@observable
	public suggestedImages: SuggestedImages[][] = [];

	@observable
	public tags: string[] = [];

	@action
	public getSuggestedImageFor = async (name: string, category: string = "") => {
		try {
			this.suggestedImages = [
				...this.suggestedImages,
				await enterprise.getSuggestedImageFor({
					product: { category, name },
				}),
			];
		} catch (error) {
			if (error instanceof Error) {
				showErrorNotification(error.message);
			}
			return false;
		}
	};

	@action
	addTag = async (tag: string) => {
		if (!this.tags.includes(tag.toLowerCase())) {
			this.tags = [...this.tags, tag.toLowerCase()];
			await this.getSuggestedImageFor(tag.toLowerCase());
		}
	};

	@action
	removeTag = (indexToRemove: number) => {
		if (this.tags.length > 1) {
			this.tags = this.tags.filter((_, i) => indexToRemove !== i);
			this.suggestedImages = this.suggestedImages.filter((_, i) => indexToRemove !== i);
		}
	};

	@action
	public clean = () => {
		this.suggestedImages = [];
		this.tags = [];
	};
}
