import { productsTab } from "./products-tab";
import { categories } from "./categories";

export const products = {
	emptyList: "Nenhum produto encontrado",
	functions: {
		modal: {
			sheet: {
				columns: {
					category: "Categoria",
					fiscalCode: "SKU",
					fiscalNcm: "Fiscal - NCM",
					hasAlcohol: "Contém álcool?",
					id: "Id do produto",
					name: "Nome",
					type: "Tipo do produto",
				},
				inventory: "inventario",
			},
		},
	},
	tabs: {
		categories,
		generalStock: "Estoque Geral",
		productsTab,
	},
};
