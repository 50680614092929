export const fiscalBoard = {
	addPlace: "Adicionar local",
	column: {
		date: "Data",
		errorType: "Tipo de erro",
		total: "Total Emissível",
		type: "Tipo",
		value: "Valor",
	},
	fiscalProfile: "Emissão por perfil fiscal",
	issuingErrors: "Erros de emissão",
	nfce: "NFC-e",
	nfceIssued: "NFC-e emitidas",
	nfse: "NFS-e",
	nfseIssued: "NFS-e emitidas",
	selectMonth: "Selecione um mês",
	selectPlace: "Selecione um local",
};
