export const soldProducts = {
	columns: {
		category: "Category",
		count: "Quantity",
		discount: "Discount",
		fiscalCode: "SKU",
		variable: "Variable",
		devolution: "Devolution",
		variableTooltip:
			"The unit value of products with variable value is the average of the values sold",
		fracionalCount: {
			grams: "grams",
			kg: "Kilos",
			label: "Fractional Quantity",
			lt: "Liters",
		},
		grossTotalValue: "Gross total",
		mountable: "Mountable",
		name: "Name",
		net_sales: "Net sales",
		operationType: "Operation type",
		subtotal: "Subtotal",
		tax_money: "Tax {{currencySymbol}}",
		tax_percentage: "Tax %",
		totalValue: "Total value",
		unitaryCount: "Unit Quantity",
		unitaryValue: "Unit value",
	},
	downloadAllSales: "Download all sales",
	filter: {
		all: "All",
		balcony: "Balcony",
		cashless: "Cashless",
		dashboard: "Dashboard",
		delivery: "Delivery",
		ficha: "Ticket",
		grabngo: "Grab n Go",
		label: "Filter by transaction type",
		placeholder: "All",
		onlineBill: "Online Bill",
		zigQrCode: "Zig QR Code",
		onlineFicha: "Online Ticket",
	},
	label: "Total products sold",
	loading: "Generating spreadsheet",
	mountableModal: {
		count: "Quantity",
		name: "Name",
		section: "Mountable Session",
	},
	mountables: {
		category: "Product Category",
		count: "Quantity",
		fiscalCode: "SKU",
		label: "Mountable",
		name: "Mountable Item",
		product: "Product",
		section: "Mountable Step",
	},
	sheetFileName: "total-products-sold",
	since: "Since",
	total: "TOTAL",
	totalItem: "Total",
	until: "Until",
	zigCard: "Zig Card",
	zigTable: "Zig Table",
	selectDate: "Select date",
	selectTime: "Select time",
};
