export const discountCategory = {
	activeCategories: "Active Discount Categories",
	allCategories: "All discount categories",
	create: "Create new category",
	drawer: {
		create: "Create discount category",
		edit: "Edit Discount Category",
	},
	errorNotification: "Please select a location first",
	filter: {
		label: "Filter by",
		placeholder: "Choose filter",
	},
	form: {
		and: "and",
		between: "Between",
		discount: {
			label: "Discount given in",
			percentage: "Percent",
			value: "Reais",
		},
		employees: {
			label: "Employees authorized to use the category",
			placeholder: "Search by name",
		},
		errors: {
			name: "Please enter a valid name",
			stabilizedType: "Select an option above",
		},
		name: {
			label: "Category Name",
			placeholder: "Give the discount category a name",
		},
		stabilizedType: {
			fixed: "Fixed value",
			label: "Established",
			range: "Range",
		},
	},
	inactiveCategories: "Inactive Discount Categories",
	label: "Create, edit, delete, activate or deactivate discount categories",
	modal: {
		message: "Do you want to delete the category of '{{name}}'?",
		title: "Delete discount category",
	},
	search: {
		label: "Search",
		placeholder: "Search discount category name",
	},
	table: {
		discountType: "DISCOUNT GIVEN ON",
		editCategory: "Edit Discount Category",
		employee: "Employee with Permission",
		emptyText: "Looks like you haven't created a discount category yet",
		hideEmployees: "Hide employees with permission",
		loading: "Loading",
		name: "DISCOUNT CATEGORY NAME",
		removeCategory: "Delete Discount Category",
		showEmployees: "View employees with permission",
		status: "STATUS",
		value: "RANGE OR FIXED VALUE",
	},
	title: "Discount Categories",
};
