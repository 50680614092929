export const sideTab = {
	delete: "Apagar",
	modalConfirm: {
		additionalText:
			"Ao desativar a promoção, todos os clientes que a receberam não poderão utilizá-las até que ela seja ativada novamente. Além disso, apesar de aparecer na lista de promoções do PDV, a brigada não conseguirá concedê-la a nenhum cliente.",
		errorMessage: "Nome da promoção incorreto",
		inactivePomotion: "Desativar promoção",
		infoText: "Digite o nome completo da promoção para confirmar a sua ação",
		placeholderInactivePromotion: "Digite aqui o nome da promoção",
		text:
			"Existem eventos em aberto no momento. Ao desativar essa promoção, será necessário refazer o login manualmente em cada um dos aparelhos.",
		title: "Desativar promoção",
		tooltipInactivePromotion:
			"Você deve digitar o nome da promoção exatamente como está cadastrado para concluir a desativação",
	},
	popConfirm: {
		deletePromotion: "Apagar promoção",
		title: "Tem certeza que quer apagar a promoção?",
	},
	promotionIs: {
		active: "Ativa",
		inactive: "Inativa",
	},
};
