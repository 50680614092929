export const clientArea = {
	label: "Área do Cliente",
	warningNotification:
		"Acesso não permitido. Ative a permissão de funcionário ¨Área do cliente¨.",
	places: "Locais",
	org: "Organização",
	searchPanel: "Nome do painel",
	noPanels: "Sem painéis disponíveis.",
	noPanelsForFilter: "Sem painéis correspondentes aos filtros.",
	autoRefresh: "Atualização automática.",
	fullScreen: "Tela cheia",
	autoUpdatesOn: "Atualizações automáticas ativadas",
	autoUpdatesOff: "Atualizações automáticas desativadas",
	rotateDevice: "Por favor gire seu dispositivo",
};
