export const bills = {
	addBill: "Adicionar conta",
	addBillSuccessNotification: "Conta criada com sucesso.",
	addTransfer: "Adicionar transferência",
	billFields: {
		account: "Conta",
		accountType: "Tipo de Conta",
		category: "Categoria",
		coustCenter: "Centro de Custo",
		description: "Descrição",
		discount: "Desconto",
		dueDate: "Data de Competência",
		filename: "exemploDeContas",
		fine: "Multa",
		interest: "Juros",
		observation: "Observação",
		payDate: "Data de Pagamento",
		payed: "Pago?",
		paymentMethod: "Método de Pagamento",
		performDate: "Data de Vencimento",
		reconciled: "Reconciliado",
		supplier: "Fornecedor",
		value: "Valor",
	},
	competence: "Competência",
	dueDate: "Vencimento",
	exportSpreadsheet: "Exportar planilha",
	importSpreadsheet: "Importar planilha",
	modalTransfer: {
		add: "Adicionar transferência",
		date: "Data",
		description: "Descrição",
		destinationAccount: "Conta destino",
		edit: "Editar transferência",
		paid: "Pago?",
		sourceAccount: "Conta origem",
		value: "Valor",
	},
	printTitle: {
		any: "Todas as contas",
		default: "Contas",
		toPay: "Contas a pagar",
		toReceive: "Contas a receber",
		transfer: "Transferência",
	},
	printTitleCaps: {
		any: "TODAS AS CONTAS",
		toPay: "CONTAS A PAGAR",
		toReceive: "CONTAS A RECEBER",
		transfer: "TRANSFERÊNCIAS",
	},
	tableBill: {
		account: "Conta de recebimento",
		description: "Descrição",
		dueDate: "Data de vencimento",
		expireToday: "Vence hoje",
		expired: "Vencido",
		notInformed: "Não informado",
		paid: "Pago?",
		performDate: "Data de competência",
		value: "Valor",
	},
	tableTransfer: {
		actions: "Ações",
		date: "Data",
		description: "Descrição",
		destinationAccount: "Conta de destino",
		paid: "Pago?",
		popConfirm: "Tem certeza que deseja excluir esta transferência?",
		sourceAccount: "Conta de origem",
		value: "Valor",
	},
};
