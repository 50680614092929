import { employees } from "./employees";
import { financial } from "./financial";
import { clients } from "./clients";

export const businessIntelligence = {
	chartCards: {
		filters: {
			consumption: "Consumption",
			ppu: "PPU",
		},
	},
	clients,
	employees,
	financial,
	header: {
		clients: "Customers",
		employees: "Employees",
		financial: "Financial",
		period: "Period",
	},
};
