export const cmvCost = {
	alert: "Heads up! There are products with negative quantity that should be reviewed",
	averageCost: "Average cost x selling price",
	averageCostSelection: "Average cost x selection selling price",
	export: "Export to Excel",
	filter: {
		apply: "Filter",
		category: {
			all: "All categorys",
			label: "Select category",
			title: "Category",
		},
		clear: "Clean filter",
		label: "Filter by",
		product: {
			all: "All products",
			label: "Select product",
			title: "Product",
		},
		storage: {
			all: "All stocks",
			label: "Select stock",
			title: "Inventory",
		},
	},
	label: "Costs",
	products: "Products",
	selectTotalCost: "Total cost of selection",
	sheet: {
		averageCost: "Average cost",
		count: "Quantity",
		fileName: "cmv-cost-report",
		fiscalCode: "SKU",
		margin: "Margin",
		name: "Product",
		realCost: "Sale price",
		stockValue: "Value in stock",
		title: "CMV",
		unityCount: "Quantity (Unit)",
	},
	showAll: "Show all {{count}} product(s)",
	showLess: "Show less",
	storageTotalCost: "Total cost in stock",
	table: {
		checkbox: "Do not show items with datasheet",
		columns: {
			averageCost: "Average Cost",
			code: "SKU",
			count: "Quantity",
			margin: "Margin",
			name: "Product",
			realCost: "Sale price",
			stockValue: "Value in stock",
		},
	},
};
