export const sellChip = {
	addCollaborator: "Add contributor",
	chipsFound: "{{num}} chips found",
	columns: {
		actionButton: "Refund chip",
		clients: "Customers",
		date: "Date",
		employee: "Employee",
		products: "Products",
		refunded: "Refunded",
		value: "Value",
	},
	modal: {
		okText: "Refund",
		product: "Product",
		products: "Products",
		reason: "Reason",
		reasonError: "This field cannot be empty",
		reasonPlaceholder: "Enter the reason for the refund",
		reasonRequired: "Reason required.",
		returnToStorage: "Return product to storage",
		shouldReturnToStorage: "Should it return to storage?",
		title: "Refund {{client}} chip",
	},
	noChipsFound: "No chips found",
	oneChipFound: "A chip found",
	placeholder_one: "Select the contributor",
	placeholder_other: "Select collaborators",
	searchPlaceholder: "Search by customer or product",
	title: "Chips sold at the event",
};
