export const adjustments = {
	filename: "adjustments",
	label: {
		advance: "Advance",
		advanceFee: "Advance Fee",
		anticipationFee: "Anticipation Fee",
		balanceAdjustment: "Balance Balancing",
		balanceRetention: "Balance Retention",
		bonifications: "Other Bonuses",
		cardActivations: "Activation Fee",
		equipmentsMaintenance: "Equipment maintenance",
		equipmentsPurchase: "Purchase of Equipment",
		equipmentsRental: "Equipment Rental",
		expiredRecharges: "Expired recharges",
		generalZigMachineTPV: "Billing via ZIG integrated acquiring",
		generalZigMachineTax: "Acquisition Rates on Integrated Machines",
		genericOperationsFee: "General Operating Expenses",
		genericServices: "Other Services and Charges",
		notUsedRecharges: "Recharges Leftovers ",
		operatorFee: "Operator's Daily",
		others: "Others",
		payments: "Payments",
		paymentsTax: "Payment Fee to Suppliers",
		posTax: "Acquisition Fee on Non-Integrated Machines (POS)",
		reimbursements: "Refunds to Customers",
		renegotiation: "Renegotiation",
		residueRemain: "Waste Retention",
		wifiAssistance: "Wifi Technical Assistance",
		wifiSetup: "Wifi Setup",
		withdraw: "Withdrawals",
		zigDevicePurchase: "Purchase of Commands",
		zigFee: "Zig Fee",
		missingCards: "Missing Cards",
		billingInvoice: "Billing Invoice",
		guaranteedMinimumSupplement: "Guaranteed Minimum Supplement",
		commandFiscalPurchase: "Command Fiscal Purchase",
		accountsReceivableLegal: "Accounts Receivable - Legal",
		cardDiscount: "Card Discount",
		discountGeneralOperatingExpenses: "Discount General Operating Expenses",
		discountOperatorDailyRate: "Discount Operator Daily Rate",
		generalDiscount: "General Discount",
		dailyCoordinatorRate: "Daily Coordinator Rate",
		dailyHeadsRate: "Daily Heads Rate",
		setupDailyCoordinatorRate: "Setup Daily Coordinator Rate",
		setupDailyHeadsRate: "Setup Daily Heads Rate",
		setupDailySupervisorsRate: "Setup Daily Supervisors Rate",
		setupDailyTechnicalRate: "Setup Daily Technical Rate",
		setupDailyTraineeRate: "Setup Daily Trainee Rate",
		dailySupervisorRate: "Daily Supervisor Rate",
		dailyTechnicianRate: "Daily Technician Rate",
		dailyTraineeRate: "Daily Trainee Rate",
		damagedEquipment: "Damaged Equipment",
		integratedAPPBilling: "Integrated APP Billing  ",
		apartCreditBilling: "Apart Credit Billing",
		apartDebitBilling: "Apart Debit Billing",
		integratedDebitBilling: "Integrated Credit Billing",
		apartBillingPIX: "Apart Billing  PIX ",
		integratedPIXBilling: "Integrated PIX Billing",
		integratedAdvancedRechargeBilling: "Integrated Advanced Recharge Billing",
		interestandPenalties: "Interest and Penalties",
		softwareLicense: "Software License",
		replacedMachines: "Replaced Machines",
		minimumGuaranteedpermachine: "Minimum Guaranteed per machine",
		otherEquipment: "Other Equipment",
		rebate: "Rebate",
		setupAdvancedRecharge: "Setup Advanced Recharge",
		setupRefundSite: "Setup Refund Site",
		acquirementfeeforIntegratedZIGApplication:
			"Acquirement fee for Integrated ZIG Application",
		acquiringfeeonapartCredit: "Acquiring fee on apart Credit",
		acquiringfeeforIntegratedCredit: "Acquiring fee for Integrated Credit",
		acquiringfeeforapartDebit: "Acquiring fee for apart Debit",
		acquiringfeeonIntegratedDebit: "Acquiring fee on Integrated Debit",
		acquiringfeeforapartPIX: "Acquiring fee for apart PIX",
		acquiringfeeforIntegratedPIX: "Acquiring fee for Integrated PIX",
		acquiringfeeforAdvancedRecharge: "Acquiring fee for Advanced Recharge",
		refundfee: "Refund fee",
		relatedpartyretentionfee: "Related party retention fee",
		rappiFee: "Rappi Fee",
		SMSFee: "SMS Fee",
		residualFee: "Residual Fee",
		cardtoCashExchange: "Card to Cash Exchange",
		cardtoProductExchange: "Card to Product Exchange",
		travelFood: "Travel Food",
		shipping: "Shipping",
		accommodation: "Accommodation",
		transport: "Transport",
		pixRefundFee: "PIX Refund Fee",
		softwareLicenseMachine: "Software License Machine",
		softwareLicenseTotem: "Software License Totem",
		integratedAdvanceDebitBilling: "Integrated Advance Debit Billing",
		integratedAdvanceCreditBilling: "Integrated Advance Credit Billing",
		integratedAdvancePixBilling: "Integrated Advance Pix Billing",
		integratedAdvanceDebitFee: "Integrated Advance Debit Fee",
		integratedAdvanceCreditFee: "Integrated Advance Credit Fee",
		integratedAdvancePixFee: "Integrated Advance Pix Fee",
		zeDeliveryDiscount: "Zé Delivery Discount",
		ticketPrinting: "Ticket Printing",
		validatorRental: "Validator License",
		zeDeliveryRetention: "Zé Delivery Retention",
		valueAddedTax: "Value-added Tax",
		moneyTPV: "Money TPV",
		acquiringFeeForIntegratedInstallmentCredit:
			"Acquiring Fee for Integrated Installment Credit",
		issuedCharge: "Issued Charge",
		softwareLicenseGiftback: "Giftback Software License",
		loanBracelet: "Loan Bracelet",
		lendingCards: "Leading Cards",
		externalResourceContribution: "External resource allocation",
	},
	noDataToShow: "There is nothing to show for the selected period",
	table: {
		actions: "Actions",
		balance: "Balance",
		category: "Category",
		date: "Competency date",
		noObs: "No observation",
		obs: "Observation",
		payDate: "Settlement date",
		total: "Total",
		totalToPay: "Payable",
		totalToReceive: "Receivable",
	},
	title: "Adjustments",
};
