import { createQueryFactory } from "../query-factory";

export const { useApiHook: useSimplifiedPlaces } = createQueryFactory(
	"getSimplifiedPlaces",
);

export const { useApiHook: useGetPlaceProducts } = createQueryFactory("getPlaceProducts");
export const { useApiHook: useGetPlaceSettingsGeneralSection } = createQueryFactory(
	"getPlaceSettingsGeneralSection",
);
